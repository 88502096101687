<template>
    <div>
        <v-card>
            <v-toolbar color="secondary" dark>
                <span class="text-h6"> {{ Titulo }} : {{ ItemTotal }}</span>
                <v-spacer></v-spacer>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                            <a href="" style="text-decoration:none !important; color: white;">
                                <v-icon dark>mdi-help-circle</v-icon>
                            </a>
                        </v-btn>
                    </template>
                    <span>Panel de ayuda en desarrollo</span>
                </v-tooltip>
            </v-toolbar>

            <v-card-title>
                <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                    Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-btn v-if="Crear" color="primary" dark class="mb-2" @click="nuevoItem()">
                    Nuevo
                </v-btn>
            </v-card-title>
            <v-data-table dense :headers="Cabecera" :items="Items" :items-per-page="10" :search="search"
                :loading="loading" loading-text="Cargando... Por favor, espere" class="elevation-1"
                no-data-text="No hay datos disponibles"
                :footer-props="{
                    'items-per-page-text': 'Resultados por página:',
                    'page-text': '{0}-{1} de {2}'
                }">
                <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn style="margin-left: 2px; margin-top: 2px" height="22" width="22" color="green" fab
                                x-small dark @click="costos(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-currency-usd</v-icon>
                            </v-btn>
                        </template>
                        <span>Costo</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import router from "@/router";
import axios from "axios";
import {
    mapState
} from "vuex";

export default {
    props: [
        "Titulo",
        "Cabecera",
        "loading",
        "Items",
        "Item",
        "ItemTotal",
        "Excel",
        "Crear",
    ],
    data() {
        return {
            costo: {},
            search: "",
            mostrarIcono: true,
        };
    },
    computed: {
        ...mapState("neumatico", ["TipoCambio"]),
    },
    methods: {
        ...mapActions("neumatico", ["asignarObjetoNeumatico"]),

        costos: function (item) {
            this.asignarObjetoNeumatico(item);
            router.push("/neumaticoreportecosto/" + item.id);
        },
    },
};
</script>
