<template>
<v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
        <v-card-title>
            <v-spacer></v-spacer>
        </v-card-title>
        
        <v-card-text>
            <span class="text-h6"> {{ Descripcion }}</span>
            <br>
        </v-card-text>
        <v-card-actions>
            <br>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="cancelar()">
                Cancelar
            </v-btn>
            <v-btn color="info" @click="confirmar()"> Eliminar </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    props: ["Descripcion", "dialog"],
    data() {
        return {

        }
    },
    methods: {
        cancelar: function () {
            this.$emit("dialog", false);
        },

        confirmar: function () {
            this.$emit("cerrarModalEliminar");
        }
    }
}
</script>
