<template>
<v-app v-if="!isRegister">
    <!-- <div style='position: absolute; z-index: 2; width: 100%;'>
        <a class="s_button" @click="loguearse()"><span> Ingresar </span></a>
    </div> -->

    <v-main>
        <router-view />
    </v-main>
</v-app>
<v-app v-else>
    <!--
    <VOffline
        online-class="online"
        offline-class="offline"
        @detected-condition="onNetworkChange"
    ></VOffline>
    -->
    <NavigationDrawer v-if="!isCliente" />
    <NavigationDrawer2 v-else />

    <!-- Sizes your content based upon application components -->
    <v-main>
        <!-- Provides the application the proper gutter -->
        <v-container fluid class="pa-0 ma-0">
            <!-- If using vue-router -->
            <router-view></router-view>
        </v-container>

        <!--MOSTRAR ALERTA-->
        <v-dialog v-model="dialog" persistent width="600">
            <VSheets :mensaje="mensaje" :tipomensaje="tipomensaje" :icon="icon" @enviarRespuesta="enviarRespuesta" />
        </v-dialog>
    </v-main>
    <!-- <v-footer app> -->
    <!-- -->
    <!-- </v-footer> -->
</v-app>
</template>

<script>
import NavigationDrawer from "@/components/layout/NavigationDrawer.vue";
import NavigationDrawer2 from "@/components/layout/NavigationDrawer2.vue";
import VSheets from "@/components/v-sheets.vue"
import {VOffline} from 'v-offline';
//import { useApi } from "./interceptor/useApi";
import {axios2 as axios} from "./interceptor/useApi";

export default {
    name: "App",
    components: {
        NavigationDrawer,
        NavigationDrawer2,
        VSheets,
        VOffline
    },
    computed: {
        /*
        onNetworkChange: function (status) {
            console.log(status);
            this.online = status;
        }
        */
    },
    data: () => ({
        isRegister: false,
        isCliente: localStorage.getItem("idcliente"),
        messageStr: "",
        online: false,
        dialog: false,
        mensaje: "",
        icon: "",
        tipomensaje: 0,
        suscripcion: null
    }),
    created() {
        this.isRegister = localStorage.getItem("logueo");
    },
    methods: {
        enviartime: function () {
            let id_usuario = localStorage.getItem("id");
            let id_cliente = localStorage.getItem("idcliente");

            if (id_usuario) {
                setInterval(async function () {
                    let hoy = new Date();
                    let fecha = hoy.getFullYear() + '-' + (hoy.getMonth() + 1) + '-' + hoy.getDate() + ' ' + hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
                    const url = "/usuarios/timeuser";
                    const data = {
                        id_usuario: id_usuario,
                        time: fecha
                    };
                    await axios
                        .post(url, data)
                        .then((response) => {
                            //setTimeout(() => (this.$router.go()), 1000)
                            //error 500 y 419 location.href = "/clientes/index"; 
                        })
                        .catch((error) => {
                            this.errorMessage = error.response.data;
                            console.error("error!", error);
                        });

                    if (navigator.onLine) {
                        console.log("conectado");
                    } else {
                        this.logout();
                    }
                }, 10000);
            }

            if(id_cliente){
                setInterval(async function () {
                    let hoy = new Date();
                    let fecha = hoy.getFullYear() + '-' + (hoy.getMonth() + 1) + '-' + hoy.getDate() + ' ' + hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
                    const url = "/accesoscliente/update";
                    const data = {
                        id_usuario: id_usuario,
                        id_cliente: id_cliente,
                        time: fecha
                    };
                    await axios
                        .post(url, data)
                        .catch((error) => {
                            this.errorMessage = error.response.data;
                            console.error("error!", error);
                        });

                    if (navigator.onLine) {
                        console.log("conectado");
                    } else {
                        this.logout();
                    }
                }, 10000);
            }
        },

        periodoprueba: function () {
            let id_cliente = localStorage.getItem("idcliente");
            if (id_cliente) {
                var that = this;
                setInterval(async function () {
                    //const api = useApi();
                    var moment = require("moment");
                    let fecha = moment(new Date());
                    let momentFecha = moment(fecha.format("YYYY-MM-DD"), "YYYY-MM-DD");

                    const url = "/clientesuscripcion/trialperiod";
                    const data = {
                        id_cliente: id_cliente,
                        time: fecha
                    };
                    await axios
                        .post(url, data)
                        .then((response) => {
                            var moment = require("moment");
                            that.suscripcion = response.data.data;
                            if (that.suscripcion) {
                                if (that.suscripcion.trial_period != 'NO APLICA' && that.suscripcion.restart != 1) {
                                    let fechasuscripcion = moment(that.suscripcion.date_end, "YYYY-MM-DD")
                                    let diasfaltantes = fechasuscripcion.diff(momentFecha, 'days');
                                    let cadena = diasfaltantes > 1 ? "días" : "día";

                                    if (diasfaltantes > 0) {
                                        if( fecha > moment(that.suscripcion.suscripcionfechas_date)){
                                            that.dialog = true;
                                            that.tipomensaje = 1;
                                            that.icon = "mdi-clock-check"
                                            that.mensaje = "El periodo de prueba de Tiresoft vence en " + diasfaltantes + " " + cadena + ".";
                                            that.envioMensajePorFecha();
                                        }
                                    } else if (diasfaltantes == 0) {
                                        if( fecha > that.suscripcion.suscripcionfechas_date){
                                            that.dialog = true;
                                            that.tipomensaje = 2
                                            that.icon = "mdi-clock-check"
                                            that.mensaje = "El periodo de prueba de Tiresoft vence hoy. Desea continuar ?";
                                            that.envioMensajePorFecha();
                                        }
                                    } else {
                                        if(that.suscripcion.answer != null){
                                            that.reinicioAplicacion();
                                        }else{
                                            that.dialog = true;
                                            that.tipomensaje = 2;
                                            that.icon = "mdi-clock-check";
                                            that.mensaje = "El periodo de prueba de Tiresoft vence hoy. Desea continuar ?";
                                        }   
                                    }
                                }
                            }
                        })
                        .catch((error) => {
                            this.errorMessage = error.response.data;
                            console.error("error!", error);
                        });
                }, 100000);
            }
        },

        loguearse: function () {
            this.$router.push({
                path: "/login"
            });
        },

        enviarRespuesta: function (rpta) {
            this.dialog = false
            if (rpta != '') {
                this.enviarCorreo(rpta);
            }
        },

        enviarCorreo: async function (rpta) {
            const url = "clientesuscripcion/rptaClienteConPeriodoPrueba";
            let id_cliente = localStorage.getItem("idcliente");

            const data = {
                id: this.suscripcion.id,
                id_cliente: id_cliente,
                rpta: rpta
            };
            await axios
                .post(url, data)
                .then((response) => {
                    //Envia por interno el correo a Jossep
                })
                .catch((error) => {
                    this.errorMessage = error.response.data;
                    console.error("error!", error);
                });
        },

        envioMensajePorFecha: async function () {
            const url = "clientesuscripcion/envioMensajePorFecha";

            const data = {
                id: this.suscripcion.suscripcionfechas_id,
            };
            await axios
                .post(url, data)
                .then((response) => {
                    //Registra si se envio el mensaje al cliente
                })
                .catch((error) => {
                    this.errorMessage = error.response.data;
                    console.error("error!", error);
                });
        },

        reinicioAplicacion: async function () {
            const url = "clientesuscripcion/reinicioAplicacion";
            var that = this;
            const data = {
                id: this.suscripcion.id,
            };
            await axios
                .post(url, data)
                .then((response) => {
                    that.logout();
                    //Registra si se reinicio la aplicacion
                })
                .catch((error) => {
                    this.errorMessage = error.response.data;
                    console.error("error!", error);
                });
        },

        logout: function () {
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();
        }
    },
    mounted() {
        this.isRegister = localStorage.getItem("logueo");
        this.isCliente = localStorage.getItem("idcliente");
        this.enviartime();
        this.periodoprueba();
    }
};
</script>
