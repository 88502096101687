<template>
    <div class="pa-5">
        <v-col lg="12">
            <tabla :Titulo="title" :Cabecera="cabecera" :Items="Items" :loading="loader" :Excel="false" :Crear="true" @loadSuscripcionesFunction="loadSuscripcionesFunction" />
        </v-col>
    </div>
    </template>
<script>
    import { mapActions, mapState } from "vuex";
    import tabla from "@/components/suscripcion/tabla.vue";
    import {axios2 as axios} from "@/interceptor/useApi";
    
    export default {
        name: "Suscripcion",
        components: {
            tabla,
        },
        data() {
            return {
                itemTotal: 0,
                cabecera: [
                {
                        text: "Id",
                        value: "id",
                        align: "left"
                    },
                    {
                        text: "Razón social",
                        value: "razon_social",
                        align: "left"
                    },
                    {
                        text: "Fecha inicio",
                        value: "date_start",
                        align: "left"
                    },
                    {
                        text: "Fecha final",
                        value: "date_end",
                        align: "left"
                    },
                    {
                        text: "Periodo de prueba",
                        value: "trial_period",
                        align: "left"
                    },
                    {
                        text: "Acciones",
                        value: "acciones",
                        align: "center"
                    }
                ],
                loader: false,
                title: "Gestión de Clientes con Suscripción",
                cliente: null
            };
        },
        computed: {
            ...mapState("suscripcion", ["Items"]),
        },
        methods: {
            ...mapActions("suscripcion", ['loadSuscripciones']),

            loadSuscripcionesFunction: function(){
                this.loadSuscripciones();
            }
        },
        async created() {
            this.loadSuscripcionesFunction();
        }
    };
    </script>
    