<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-form enctype="multipart/form-data" ref="form" lazy-validation>
            <v-card>
                <v-card-title>
                    <span v-if="Item.id == 0" class="text-h6">Nuevo Usuarios</span>
                    <span v-else class="text-h6">Editar Usuarios</span>
                    <v-spacer></v-spacer>
                    <span v-if="Item.id > 0">ID: {{ Item.id }}</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field label="Nombres *" hide-details="auto" v-model="Item.nombre" small
                                :rules="requeridos"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field label="Apellidos  *" hide-details="auto" v-model="Item.apellidos" small
                                :rules="requeridos"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field label="Telefono  *" hide-details="auto" type="number" v-model="Item.telefono"
                                small :rules="requeridos"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-autocomplete v-model="Item.selectedRoles" :items="ItemsRoles" style="margin-top: 22px !important;" dense
                                label="Seleccionar Rol" :rules="requeridos"></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <!--  <v-autocomplete v-model="Item.selectedEmpresa" :items="ItemsEmpresa" outlined dense chips small-chips label="Seleccionar Empresa" :rules="requeridos" multiple></v-autocomplete> -->
                            <v-autocomplete class="selectAll" v-model="Item.selectedEmpresa" :items="ItemsEmpresa"
                                outlined dense chips deletable-chips small-chips label="Seleccionar Empresa" :rules="requeridos" multiple>
                                <template v-slot:prepend-item>
                                    <v-checkbox :value="allSelected" @change="toggleSelectAll"
                                        label="SELECCIONAR TODAS LAS EMPRESAS" class="checkboxSelectedAll"
                                        hide-details></v-checkbox>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-col cols="12" class="py-0" >
                            <p><b>Cargar su firma digital (.PNG)</b></p>
                            <div class="d-sm-flex justify-center">
                                <v-col cols="12" class="d-flex justify-center align-center px-0" sm="6">
                                    <img id='imagenFirmaPrevio' :src="Item.imagen ? Item.imagen : imagen"
                                        alt="imagen_no_disponible" width='100' height='100'>
                                </v-col>
                                <v-col cols="12" class="d-flex justify-center align-center top-2 px-0" sm="6">
                                    <v-file-input class="imgFirm" id='img_firma' label="Imagen Firma" filled prepend-icon="mdi-camera"
                                        accept="image/png, .jpeg, .jpg" @change="ObtenerImagen"
                                        :rules="Item.id > 0 ? [] : requeridos"></v-file-input>
                                </v-col>
                            </div>
                        </v-col>


                    </v-row>
                </v-card-text>
                <v-card-title>
                    <span v-if="Item.id == 0" class="text-h6">Datos de Usuario</span>
                    <span v-else class="text-h6">Datos de Usuarios</span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field prepend-icon=" mdi-email" v-model="Item.email" :rules="emailRules"
                                name="email" label="Email" type="text" placeholder="Email" required
                                hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field v-model="Item.pasword" prepend-icon="mdi-lock"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="Item.id > 0 ? [] : [rules.required, rules.min]"
                                :type="show1 ? 'text' : 'password'" label="Contraseña" hint="Al menos 6 caracteres"
                                counter @click:append="show1 = !show1"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="cerrarDialogo()">
                        Cerrar
                    </v-btn>
                    <v-btn v-if="Item.id == 0" color="info" @click="agregar()">
                        Guardar
                    </v-btn>
                    <v-btn v-else color="info" @click="agregar()">
                        Actualizar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
    </v-dialog>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex';
import MensajeTransicion from "../Alerta.vue" 

export default {
    props: ['dialog', 'Item'],
    components: {
        MensajeTransicion
    },
    data() {
        return {
            imagenminiatura: '/images/reporte/imagen_no_disponible.jpg',
            emailRules: [
                v => !!v || 'E-mail es requerido',
                v => /.+@.+\..+/.test(v) || 'E-mail no es valido',
            ],
            show1: false,
            rules: {
                required: v => !!v || 'Requerido.',
                min: v => v.length >= 6 || 'Min 6 characters'
            },
            requeridos: [
                v => !!v || 'Este Campo es Requerido.'
            ],
            mensaje: "",
            verAlerta: false
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
        ...mapGetters('usuario', ['ItemsRoles', 'ItemsEmpresa']),
        imagen() {
            return this.imagenminiatura;
        },

        allSelected() {
            return this.Item.selectedEmpresa.length === this.ItemsEmpresa.length;
        }
    },
    methods: {
        ...mapActions('usuario', ['agregarUsuario']),

        async agregar() {
            if (this.$refs.form.validate()) {
                this.$alertify.confirm(
                    this.Item.id == 0 ? "Realmente desea registra el usuario!" : "¿Realmente desea editar el usuario?",
                    () => {
                        if (this.Item.id == 0) {
                            this.agregarUsuario(this.Item)
                                .then(r => {
                                    this.LimpiarCampos();
                                    this.cerrarDialogo();
                                    this.$alertify.success("Se agrego correctamente el usuario");
                                });
                        } else { 
                            this.agregarUsuario(this.Item)
                            .then(r => {
                                this.LimpiarCampos();
                                this.cerrarDialogo();
                                this.$alertify.success("Se actualizo correctamente el usuario");
                            }); 
                        }
                    }
                ).setHeader('<h3 class="alertTitle">Aviso</h3').set('labels', { ok: 'Aceptar', cancel: 'Cancelar' });
            }
        },

        LimpiarCampos() {
            this.Item.id = 0,
                this.Item.nombre = '',
                this.Item.apellidos = '',
                this.Item.telefono = '',
                this.Item.selectedRoles = '',
                this.Item.selectedEmpresa = [],
                this.Item.imagen_firma = '',
                this.Item.email = '',
                this.Item.pasword = ''
        },

        cerrarDialogo() {
            this.$emit('dialog', false);
        },

        ObtenerImagen() {
            let file = document.getElementById('img_firma').files[0];
            this.Item.imagen_firma = file;
            this.CargarImagen(file)
        },

        CargarImagen(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.imagenminiatura = e.target.result;
            }
            reader.readAsDataURL(file)
        },

        toggleSelectAll(value) {
            if (value) {
                this.Item.selectedEmpresa = this.ItemsEmpresa.map(item => item);
            } else {
                this.Item.selectedEmpresa = [];
            }
        },
    }
}
</script>

<style>
.checkboxSelectedAll {
    margin-top: 0px !important;
    padding: 16px 16px !important;
}

.selectAll .v-select__selections {
    padding: 8px 0px !important;
}

.checkboxSelectedAll .v-input--selection-controls__input {
    margin-right: 32px !important;
}

.checkboxSelectedAll label {
    font-weight: 700;
    color: rgba(0, 0, 0, .87) !important;
}

@media (min-width:600px){
    .imgFirm{
        position: relative;
        top: 10px
    }
}
</style>