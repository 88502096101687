<template>
    <div class="container">
      <canvas
        ref="elemento"
        :id="IdGrafico"
        style="max-height: 450px"
      ></canvas>
    </div>
  </template>
  <script>
  import Chart from "chart.js/auto";
  import ChartDataLabels from "chartjs-plugin-datalabels";
  Chart.register(ChartDataLabels);
  
  export default {
    props: ["Labels", "Datasets","Title", "Labelscale_x", "Labelscale_y", "IdGrafico", "Orientacion"],
    data() {
      return {
        orientacioneje: ""
      };
    },
    methods: {
      generarGrafico: function () {
        var popCanvas = document.getElementById(this.IdGrafico);
        new Chart(popCanvas, {
          plugins: [ChartDataLabels],
          type: "bar",
          data: {
            labels: this.Labels,
            datasets: this.Datasets
          },
          options: {
            indexAxis: this.orientacioneje,
            plugins: {
              title: {
                display: true,
                text: this.Title,
              },
              legend: {
                display: true,
                position: "top",
                labels: {
                  color: "black",
                  font: {
                    size: 11,
                  },
                },
              },
              datalabels: {
                /* anchor puede ser "start", "center" o "end" */
                anchor: "center",
                /* Podemos modificar el texto a mostrar */
                //formatter: (dato) => dato + "%",
                formatter: (dato) => dato,
                /* Color del texto */
                color: "black",
                /* Formato de la fuente */
                font: {
                  family: '"Times New Roman", Times, serif',
                  size: "0",
                  weight: "bold",
                },
                /* Formato de la caja contenedora */
                //padding: "4",
                //borderWidth: 2,
                //borderColor: "darkblue",
                //borderRadius: 8,
                //backgroundColor: "white"
              },
            },
            tooltips: {
              yAlign: "bottom",
              xAlign: "center",
              xPadding: 25,
              yPadding: 15,
              xPadding: 45,
              _bodyAlign: "center",
              _footerAlign: "center",
              backgroundColor: "#ccc",
              titleFontSize: 16,
              titleFontColor: "#0066ff",
              bodyFontColor: "#000",
              bodyFontSize: 14,
              displayColors: false,
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: this.Labelscale_x,
                },
                beginAtZero: true, // Asegura que el eje X (eje horizontal en un gráfico de barras horizontales) comience desde 0
                suggestedMin: 0, // Asegura que el gráfico comience desde 0 en el eje X
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: this.Labelscale_y,
                },
                suggestedMin: 0
              },
            },
          },
        });
      },
    },
    mounted() {
      if(this.Orientacion != undefined || this.Orientacion != null){
        this.orientacioneje = this.Orientacion;
      }
      this.generarGrafico();
    },
  };
  </script>

  