<template>
    <div>
        <Nuevo :dialog="dialog" @dialog="dialog = $event" :Item="itemSelected" />
        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />

        <v-card>
            <v-card-title>
                {{ Titulo }}
                <v-spacer></v-spacer>
                <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                    Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-btn  v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['2']) " color="primary" dark class="mb-2" @click="nuevoItem()">
                    Nuevo
                </v-btn>
            </v-card-title>
            <v-data-table dense :headers="filteredCabecera" :items="Items" :items-per-page="10" :search="search"
                class="elevation-1 mt-2" loading-text="Cargando... Por favor, espere"
                :loading="Loading"
                no-data-text="No hay datos disponibles"
                :footer-props="{
                'items-per-page-text': 'Resultados por página:',
                'page-text': '{0}-{1} de {2}' 
                  }">
                <template v-slot:[`item.img_firma`]="{ item }">
                    <v-chip v-if="item.img_firma" color="success" outlined small>
                        SI
                    </v-chip>
                    <v-chip v-else color="error" outlined small>
                        NO
                    </v-chip>
                </template>
                <template v-slot:[`item.rol`]="{ item }">
                    <v-chip color="success" style="width: 100%; align-items: center; justify-content: center;" outlined small>
                        {{ item.rol }}
                    </v-chip>
                </template>
                <template v-slot:[`item.accion`]="{ item }">
                    <v-tooltip bottom v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['3']) ">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small class="mr-2" @click="editarItem(item)" v-bind="attrs" v-on="on">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['4']) ">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small @click="eliminar(item)" v-bind="attrs" v-on="on">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import {
    mapActions,
    mapState
} from 'vuex';
import Nuevo from './nuevo.vue'
import exportFromJSON from "export-from-json"
import MensajeTransicion from "../Alerta.vue"
import global from '@/global';

export default {
    props: ["Titulo", "Cabecera", "Items", "Excel", "Crear","Loading"],
    components: {
        Nuevo,
        MensajeTransicion
    },
    data() {
        return {
            search: "",
            dialog: false,
            itemSelected: {},
            mensaje: "",
            verAlerta: false,
            global: global 
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
        canShowAccionColumn() {
            return (global._autorizacion_usuario(this.Permisos,this.$route.meta.id,['3','4']) );
        },
        filteredCabecera() {
            return this.Cabecera.filter(col => !(col.value === 'accion' && !this.canShowAccionColumn));
        }
    },
    methods: {
        ...mapActions("usuario", ["eliminarUser"]), 

        nuevoItem() {
            console.log("clcik"); 
            this.itemSelected = {
                id: 0,
                nombre: '',
                apellidos: '',
                telefono: '',
                selectedRoles: '',
                selectedEmpresa: [],
                imagen_firma: '',
                imagen: '',
                email: '',
                pasword: ''
            }
            this.dialog = true; 
        },

        eliminar(item) {  
            this.$alertify.confirm(
                `¿Desea eliminar permanentemente al usuario: ${item.nombre} ${item.apellidos}?`,
                () => {
                    this.eliminarUser(item)
                        .then(r => {
                            this.$alertify.success(`Usuario ${item.nombre} ${item.apellidos} (ID: ${item.id}) eliminado correctamente.`);
                        })
                        .catch(error => {
                            console.log(error)
                        });
                },
                () => this.$alertify.error("Se cancelo la acción")
            ).setHeader('<h3 class="alertTitle">Eliminar Usuario</h3'); 
        },

        editarItem(item) {
            this.itemSelected = item;
            this.dialog = true;
        },

        DescargarExcel() {
            const data = this.Items;
            const nombreArchivo = 'download';
            const exportType = exportFromJSON.types.xls; //xls, json, css, html,txt 
            exportFromJSON({
                data,
                nombreArchivo,
                exportType
            })
        }
    }
};
</script>
