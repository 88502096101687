<template>
  <div class="container">
    <canvas
      ref="elemento"
      :id="IdGrafico"
      style="max-height: 500px"
    ></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);

export default {
  props: ["Labels", "DatosGraficoDataset","Title", "Labelsdataset1", "Labelsdataset2", "Labelscale_x", "Labelscale_y", "Background", "Border","IdGrafico"],
  data() {
    return {
    };
  },
  methods: {
    generarGrafico: function () {
      var popCanvas = document.getElementById(this.IdGrafico);
      new Chart(popCanvas, {
        plugins: [ChartDataLabels],
        type: "bar",
        data: {
          labels: this.Labels,
          datasets: [
            {
              label: this.Labelsdataset1,
              data: this.DatosGraficoDataset,
              backgroundColor: this.Background,
              borderColor: this.Border,
              hoverOffset: 4
            },
            {
              label: this.Labelsdataset2,
              backgroundColor: "rgba(255, 99, 132)",
              borderColor: "rgba(255,99,132,0.9)",
              hoverOffset: 4
            }
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: this.Title,
            },
            legend: {
              display: true,
              position: "top",
              labels: {
                color: "black",
                font: {
                  size: 11,
                },
              },
            },
            datalabels: {
              /* anchor puede ser "start", "center" o "end" */
              anchor: "center",
              /* Podemos modificar el texto a mostrar */
              //formatter: (dato) => dato + "%",
              formatter: (dato) => dato,
              /* Color del texto */
              color: "black",
              /* Formato de la fuente */
              font: {
                family: '"Times New Roman", Times, serif',
                size: "9",
                weight: "bold",
              },
              /* Formato de la caja contenedora */
              //padding: "4",
              //borderWidth: 2,
              //borderColor: "darkblue",
              //borderRadius: 8,
              //backgroundColor: "white"
            },
          },
          tooltips: {
            yAlign: "bottom",
            xAlign: "center",
            xPadding: 25,
            yPadding: 15,
            xPadding: 45,
            _bodyAlign: "center",
            _footerAlign: "center",
            backgroundColor: "#ccc",
            titleFontSize: 16,
            titleFontColor: "#0066ff",
            bodyFontColor: "#000",
            bodyFontSize: 14,
            displayColors: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: this.Labelscale_x,
              },
              suggestedMin: 0, // Asegura que el gráfico comience desde 0 en el eje X
            },
            y: {
              display: true,
              title: {
                display: true,
                text: this.Labelscale_y,
              },
              suggestedMin: 0
            },
          },
        },
      });
    },
  },
  mounted() {
    this.generarGrafico();
  },
};
</script>