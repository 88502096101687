<template>
  <div class="pa-5 mt-5">
    <v-col lg="12">
      <tabla  Titulo="Gestión de Clientes" :Cabecera="Cabecera" :Items="Items" :Excel="false" :Crear="true" :Loading="Loading" />
    </v-col>
  </div>
</template>
<script>
import tabla from "@/components/cliente/tabla.vue"

import { mapGetters, mapActions, mapState } from "vuex";
export default {
  components : {
    tabla
  },
  created(){
    const data = {menu:1,submenu:3}; //Eliminar
    this.CargarMenu(data);
    this.cargarClientes();
    this.cargarSectores();
    console.log("RDX > created()");
  },
  data(){
    return{
      Cabecera:[
        {text:'Acción',value:'accion'},
        {text:'Id',value:'id'},
        {text:'Razón Social',value:'razon_social'},
        {text:'Ruc',value:'ruc'},
        {text:'Estado',value:'estado'},
        {text:'Logo Tipo',value:'ruta_logo'},
        {text:'Costo por Defecto',value:'costo'},
      ]
    }
  },
  methods:{
    ...mapActions('cliente',['cargarClientes']),
    ...mapActions('sector',['cargarSectores']),
    ...mapActions('nav',['CargarMenu'])
  },

  computed:{
    ...mapGetters('cliente',['Items']),
    ...mapState('cliente',['Loading'])

  }
};
</script>