import Vue from 'vue'
import Vuex from 'vuex'
import { axios2 as axios } from "@/interceptor/useApi";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        MotivosScrap: []
    },
    mutations: {
        setMotivosScrap(state, payload) {
            state.MotivosScrap = payload
        }
    },
    actions: {
        cargarMotivosScrap: async function ({ commit }) {
            const url = "motivosscrap/index";
            let datos = {id_cliente: localStorage.getItem("idcliente")};
            await axios.post(url, datos)
                .then(response => {
                    let motivoScrapList = [];
                    response.data.data.forEach((element, i) => {
                        motivoScrapList[i] = {
                            value: element.id,
                            text: element.motivo
                        };
                    });
                    commit('setMotivosScrap', motivoScrapList);                 
                })
                .catch(error => {
                    this.errorMessage = error.response.data;
                    console.error("error!", error);
                });
        }
    }
}