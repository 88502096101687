<template>
    <div class="pa-5">
        <v-col cols="12">
            <v-card class="pa-5">
                <v-card-title> Reporte de ingreso de los usuarios al sistema </v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-row>
                            <v-col cols="12" md="4" sm="6">
                                <v-text-field type="date" label="Fecha Inicial *" v-model="item.fecha_inicial"
                                    hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="6">
                                <v-text-field type="date" label="Fecha Final *" v-model="item.fecha_final"
                                    hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="6">

                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mr-2" small :loading="loading" @click="filtrar">
                        Buscar
                    </v-btn>
                    <v-menu open-on-hover bottom offset-y  style="cursor: pointer;" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="info" dark v-bind="attrs" v-on="on">
                                Ver detalles
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item v-for="(item, index) in detalles" :key="index" style="cursor: pointer;"   class="hover-effect">
                                <v-list-item-title @click="onSeleccionar(index)">{{ item.title }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-menu open-on-hover bottom offset-y style="cursor: pointer;" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :loading="loader" small color="info" class="ml-2" dark v-bind="attrs" v-on="on">
                                Reporte Excel
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item v-for="(item, index) in exportar" :key="index"  style="cursor: pointer;" class="hover-effect">
                                <v-list-item-title @click="onExportar(index)">{{ item.title }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-card-actions>
                <br>
                <v-card-text>
                    <v-data-table dense :headers="cabecera" :items="items" :items-per-page="10" :search="search"
                        loading-text="Cargando... Por favor, espere"
                        no-data-text="No hay datos disponibles"
                        :loading="cargarDatos" 
                        class="elevation-1" :footer-props="{
                            'items-per-page-text': 'Resultados por página:',
                            'page-text': '{0}-{1} de {2}'
                        }">
                    </v-data-table>
                </v-card-text>
            </v-card>
            <reportesesionesusuarios :dialog="dialog" @cerrarModal="cerrarModal" :title="title" :cargarDatosDiariosMensuales="cargarDatosDiariosMensuales" 
                :encabezado="encabezados" :items="datos" />
        </v-col>
    </div>
</template>

<style> 
    
    .hover-effect {
    transition: background-color 0.3s ease;
    }

    /* Hover effect */
    .hover-effect:hover {
    background-color: #f0f0f0 !important; /* Gris claro */
    }

    /* Cambiar el color del porcentaje */
    .hover-effect .text-right span {
    color: grey !important; /* Cambia el color a gris */
    }
</style>
<script>
import reportesesionesusuarios from '@/components/sesiones/reportesesionesusuarios.vue';
import global from '@/global';
import {
    mapState,
    mapActions
} from 'vuex';
import axios from "axios";

export default {
    components: {
        reportesesionesusuarios
    },
    data() {
        return {
            loading: false,
            dialog: false,
            search: "",
            item: {
                fecha_inicial: '',
                fecha_final: '',
                reporte: ''
            },
            cabecera: [{
                text: "Id",
                value: "id",
                align: "left",
                class: "grey darken-1 dark white--text"
            },
            {
                text: "Nombre",
                value: "name",
                align: "left",
                class: "grey darken-1 dark white--text"
            },
            {
                text: "Email",
                value: "email",
                align: "left",
                class: "grey darken-1 dark white--text"
            },
            {
                text: "Fecha",
                value: "fecha_session",
                align: "left",
                class: "grey darken-1 dark white--text"
            }
            ],
            items: [],
            detalles: [{
                title: 'Reporte Diario'
            },
            {
                title: 'Reporte Mensual'
            }
            ],
            exportar: [{
                title: 'Reporte Ingreso'
            },
            {
                title: 'Reporte Diario'
            },
            {
                title: 'Reporte Mensual'
            }
            ],
            //parametros de las tablas de reporte
            title: "",
            encabezados: [],
            datos: [],
            loader: false
        }
    },
    computed: {
        ...mapState('reportes', ["SesionUsuarios","cargarDatos","cargarDatosDiariosMensuales"])
    },
    methods: {
        ...mapActions('reportes', ["cargarsesionusuarios", "cargarsesionusuariosdiaria", "cargarsesionusuariosmensual"]),

        onSeleccionar: async function (index) {
            if (index == 0) {
                this.dialog = true;
                this.title = "Reporte de Ingreso Diario"
                this.encabezados = [{
                    text: "Nombre",
                    value: "name",
                    align: "left",
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Email",
                    value: "email",
                    align: "left",
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Total ingresos",
                    value: "cantidad",
                    align: "left",
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Fecha Diaria",
                    value: "fecha_session",
                    align: "left",
                    class: "grey darken-1 dark white--text"
                }
                ];
                this.datos = await this.cargarsesionusuariosdiaria(this.item);
            } else {
                this.dialog = true;
                this.title = "Reporte de Ingreso Mensual"
                this.encabezados = [{
                    text: "Nombre",
                    value: "name",
                    align: "left",
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Email",
                    value: "email",
                    align: "left",
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Total ingresos",
                    value: "cantidad",
                    align: "left",
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Fecha Mensual",
                    value: "fecha_session",
                    align: "left",
                    class: "grey darken-1 dark white--text"
                }
                ];
                this.datos = await this.cargarsesionusuariosmensual(this.item);
            }
        },

        exportarAexcel: async function (url, data) {
            this.loader = true;
            await axios({
                url: url,
                method: "POST",
                data: data,
                responseType: "blob"
            })
                .then(async (response) => {
                    this.loader = false;
                    var nombre_archivo = response.headers["nombre-archivo"];
                    if (response.data.type == "text/html") {
                        // no es excel, probablemente es json con un mensaje de error
                        const res = JSON.parse(await response.data.text());
                        if (!res.exito) {
                            alert(res.mensaje || "Sucedió un error, intentelo nuevamente");
                        }
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", nombre_archivo);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
                .catch((error) => {
                    this.loader = false;
                    this.errorMessage = error.response.data;
                    console.error("error!", error);
                });
        },

        onExportar: function (index) {
            let url = global.ruta_api;
            if (index == 0) {
                this.item.reporte = '';
                url += "excel/reportesesionesusuarios";
            } else if (index == 1) {
                url += "excel/reportesesionesusuariosdiario";
                this.item.reporte = "sesionesdiarias";
            } else {
                url += "excel/reportesesionesusuariosmensual";
                this.item.reporte = "sesionesmensuales";
            }
            this.exportarAexcel(url, this.item);
        },

        filtrar: async function () {
            this.items = await this.cargarsesionusuarios(this.item);
        },

        cerrarModal: function () {
            this.dialog = false;
        }
    },
    async mounted() {
        this.items = await this.cargarsesionusuarios(this.item);
    }
}
</script>
