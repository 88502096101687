<template>
  <div class="pa-5">
    <v-col lg="12">
      <v-btn color="success" v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['2'])" @click="openModalScrap">
        <v-icon x-small>mdi-plus-circle</v-icon> Registrar neumático directo
        para SCRAP
      </v-btn>
    </v-col>

    <v-col lg="12">
      <nuevo
        :Item="Item"
        :Vehiculos="Vehiculos"
        :MotivosScrap="MotivosScrap"
      ></nuevo>
    </v-col>

    <v-col lg="12">
      <tabla
        Titulo="Neumáticos en SCRAP"
        :Cabecera="Cabecera"
        :Items="Items"
        :ItemTotal="ItemTotal"
        :MotivosScrap="MotivosScrap"
        :Excel="false"
        :Crear="false"
      />
    </v-col>

    <Scrap v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['2'])"
      ref="scrap"
      :dialog="dialog_scrap"
      :Marcas="Marcas"
      :Modelos="Modelos"
      :Medidas="Medidas"
      :Disenios="Disenios"
      :MotivosScrap="MotivosScrap"
      :Empresas="Empresas"
      @dialog="dialog_scrap = $event"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Scrap from "@/components/scrap/scrap.vue";
import tabla from "@/components/scrap/tabla.vue";
import nuevo from "@/components/scrap/nuevo.vue";
import global from '@/global';
export default {
  components: {
    tabla,
    nuevo,
    Scrap,
  },
  data() {
    return {
      Cabecera: [
        { text: "Opciones", value: "acciones", align: "left", width: 120 },
        { text: "Id", value: "id", align: "left" },
        { text: "N° serie", value: "num_serie", align: "left", width: 100 },
        { text: "Marca", value: "marca", align: "left" },
        { text: "Modelo", value: "modelo", align: "left", width: 100 },
        { text: "Medida", value: "medida", align: "left", width: 100 },
        { text: "Diseño", value: "disenio", align: "left", width: 100 },
        { text: "Motivo Scrap", value: "motivo", align: "left", width: 180 },
        {
          text: "Fecha Scrap",
          value: "fecha_scrap",
          align: "left",
          width: 120,
        },
        {
          text: "Remanente final",
          value: "remanente_final",
          align: "left",
        },
        { text: "Remanente límite", value: "remanente_limite", align: "left" },
        { text: "Imagen", value: "image", align: "left" },
      ],
      Item: {},
      dialog_scrap: false,
      valid_form: false,
      global:global,
    };
  },
  computed: {
    ...mapState("scrap", [
      "Items",
      "ItemTotal",
      "Vehiculos",
      "MotivosScrap",
      "Marcas",
      "Modelos",
      "Medidas",
      "Disenios",
      "Empresas"
    ]),
    ...mapState("navigation", ["Permisos"]),
  },
  methods: {
    ...mapActions("scrap", ["cargarNeumaticosScrap", "cargarDatosAdicionales"]),

    openModalScrap: function () {
      this.dialog_scrap = true;
    },
  },
  mounted: async function () {
    this.cargarNeumaticosScrap();
    await this.cargarDatosAdicionales();
  },
};
</script>
