<template>
  <v-img
    dark
    position="center"
    :class="imagenclase"
    :src="imagen"
    :height="height"
    :width="width"
  >
    <div class="text-center pt-3">
      <strong>{{ num_serie }}</strong>
    </div>
  </v-img>
</template>
<script>
export default {
  props: {
    num_serie: String,
    ancho: String,
    alto: String,
    clase: String
  },
  methods: {},
  data() {
    return {
      imagen: "/images/llanta2.png",
      width: "105",
      height: "45",
      imagenclase: "imagen-neumatico2",
    };
  },
  methods: {},
  mounted(){
    if(this.ancho != undefined && this.alto != undefined){
      this.width = this.ancho;
      this.height = this.alto;
    }

    if(this.clase != undefined && this.clase == "imagen-neumatico1"){
      this.imagenclase = "imagen-neumatico1";
    }
  }
};
</script>
<style scoped>
.imagen-neumatico1 {
  opacity: 0.4;
}

.imagen-neumatico2 {
  opacity: 1;
}
</style>




