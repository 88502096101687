<template>
  <div class="text-center ma-2">
    <v-snackbar
      v-model="snackbar"
      :color="color"
      :timeout="-1"
      :top="top"
      :bottom="bottom"
      right
    >
      <strong>{{ text }}</strong>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    props: ["text", "color", "top", "bottom"],
    data: () => ({
        snackbar: true,
        timeout: 5000,
    }),
  }
</script>