import Vue from 'vue'
import Vuex from 'vuex'
import imagenes from './imagenes'
import cliente from './cliente'
import login from './login'
import home from './home'
import nav from './nav'
import navigation from './navigation'
import neumatico from './neumatico'
import scrap from './scrap'
import enreencauche from './enreencauche'
import enreparacion from './enreparacion'
import enmalestado from './enmalestado'
import roles from './roles'
import permisos from './permisos'
import modulos from './modulos'
import usuario from './usuario'
import criterioaceptacion from './criterioaceptacion'
import tipocambio from './tipocambio'
import aplicacion from './aplicacion'
import importarvehiculo from './importarvehiculo'
import importarneumatico from './importarneumatico'
import importarcriterio from './importarcriterio'
import vehiculo from './vehiculo'
import configuracionvehiculo from './configuracionvehiculo'
import reporteconsolidado from './reporteconsolidado'
import reportes from './reportes'
import plantas from './plantas'
import inspeccion from './inspeccion'
import core from './core'
import usercliente from './usercliente'
import indicadores from './indicadores'
import suscripcion from './suscripcion'
import pruebas from './pruebas'
import marketing from './marketing'
import sector from './sector'
import motivosscrap from './motivosscrap'
import incidente from './incidente'
import versionapk from './versionapk'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    imagenes,
    cliente,
    login,
    nav,
    navigation,
    roles,
    permisos,
    modulos,
    usuario,
    criterioaceptacion,
    tipocambio,
    aplicacion,
    neumatico,
    scrap,
    enreencauche,
    enreparacion,
    enmalestado,
    importarvehiculo,
    importarneumatico,
    importarcriterio,
    vehiculo,
    configuracionvehiculo,
    home,
    reporteconsolidado,
    reportes,
    plantas,
    inspeccion,
    core,
    indicadores,
    usercliente,
    suscripcion,
    sector,
    motivosscrap,
    pruebas,
    marketing,
    incidente,
    versionapk
    
  }
})
