import Vue from "vue";
import Vuex from "vuex";
import { axios2 as axios } from "@/interceptor/useApi";
import Swal from 'sweetalert2';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    Years: [],
    Months: [],
    Cliente: {},
    Usuario: {},
    EstadoReporte: {},
    EquiposInspeccionados: [],
    DistribucionMedidasNeumaticos: [],
    PosicionRuedaSegunMarca: [],
    DistribucionEjeDireccional: [],
    DistribucionEjeTraccion: [],
    DistribucionEjeLibre: [],
    NeumaticosMalEstado: [],
    NeumaticosScrap: [],
    NeumaticosRetiro: [],
    NivelesRemanenteUnidad: [],
    ResumenRemanentesUnidad: [],
    NivelesRemanenteUnidadPosiciones: [],
    AplicacionesRemanenteUnidad: [],
    NeumaticosServicioReencauche: [],
    RotacionesIrregulares: [],
    NivelesRemanenteMedida: [],
    NivelesRemanenteMedidaoficial:[],
    ResumenRemanentesMedida: {},
    RemanentesMedida: [],
    TotalesMedida: [],
    TotalDatosMedida: 0,
    IndiceReencauche: [],
    IndiceReencauchabilidad: 0,
    Inspecciones: [],
    CostoPorKilometro: [],
    PerdidasPorScrap: [],
    MesesCostoPorKilometro: [],
    Recomendaciones: [],
    Reportes: [],
    Comentarios: [],
    AplicacionNivelesRemanentes: [],
    Loading: true,
  },
  getters: {
    Years(state) {
      return state.Years;
    },
    Months(state) {
      return state.Months;
    },
  },
  mutations: {
    DatosCliente(state, data) {
      state.Cliente = data;
    },
    DatosUsuario(state, data) {
      state.Usuario = data;
    },
    DatosReporte(state, data) {
      state.EstadoReporte = data;
    },
    LlenarYears(state, data) {
      state.Years = data;
    },
    LlenarMonths(state, data) {
      state.Months = data;
    },
    LlenarEquiposInspeccionados(state, data) {
      state.EquiposInspeccionados = data;
    },
    LlenarDistribucionMedidasNeumaticos(state, data) {
      state.DistribucionMedidasNeumaticos = data;
    },
    LlenarPosicionRuedaSegunMarca(state, data) {
      state.PosicionRuedaSegunMarca = data;
    },
    LlenarDistribucionEjeDireccional(state, data) {
      state.DistribucionEjeDireccional = data;
    },
    LlenarDistribucionEjeDireccional(state, data) {
      state.DistribucionEjeDireccional = data;
    },
    LlenarDistribucionEjeTraccion(state, data) {
      state.DistribucionEjeTraccion = data;
    },
    LlenarDistribucionEjeLibre(state, data) {
      state.DistribucionEjeLibre = data;
    },
    LlenarNeumaticosMalEstado(state, data) {
      state.NeumaticosMalEstado = data;
    },
    LlenarNeumaticosScrap(state, data) {
      state.NeumaticosScrap = data;
    },
    LlenarNeumaticosRetiro(state, data) {
      state.NeumaticosRetiro = data;
    },
    LlenarNivelesRemanenteUnidad(state, data) {
      state.NivelesRemanenteUnidad = data;
    },
    LlenarResumenRemanentesUnidad(state, data) {
      state.ResumenRemanentesUnidad = data;
    },
    LlenarNivelesRemanenteUnidadPosiciones(state, data) {
      state.NivelesRemanenteUnidadPosiciones = data;
    },
    LlenarAplicacionesRemanenteUnidad(state, data) {
      state.AplicacionesRemanenteUnidad = data;
    },
    LlenarNeumaticosServicioReencauche(state, data) {
      state.NeumaticosServicioReencauche = data;
    },
    LlenarRotacionesIrregulares(state, data) {
      state.RotacionesIrregulares = data;
    },
    LlenarInfladoNeumaticos(state, data) {
      state.InfladoNeumaticos = data;
    },
    LlenarPresionNeumaticos(state, data) {
      state.PresionNeumaticos = data;
    },
    LlenarNivelesRemanenteMedidaoficial(state, data) {
      state.NivelesRemanenteMedidaoficial = data;
    },
    LlenarNivelesRemanenteMedida(state, data) {
      state.NivelesRemanenteMedida = data;
    },
    LlenarRemanentesMedida(state, data) {
      state.RemanentesMedida = data;
    },
    LlenarTotalesMedida(state, data) {
      state.TotalesMedida = data;
    },
    LlenarTotalDatosMedida(state, data) {
      state.TotalDatosMedida = data;
    },
    LlenarResumenRemanentesMedida(state, data) {
      state.ResumenRemanentesMedida = data;
    },
    LlenarIndiceReencauche(state, data) {
      state.IndiceReencauche = data;
    },
    LlenarIndiceReencauchabilidad(state, data) {
      state.IndiceReencauchabilidad = data;
    },
    LlenarInspecciones(state, data) {
      state.Inspecciones = data;
    },
    LlenarCostoPorKilometro(state, data) {
      state.CostoPorKilometro = data;
    },
    LlenarMesesCostoPorKilometro(state, data) {
      state.MesesCostoPorKilometro = data;
    },
    LlenarPerdidasPorScrap(state, data) {
      state.PerdidasPorScrap = data;
    },
    LlenarRecomendaciones(state, data) {
      state.Recomendaciones = data;
    },
    AgregarRecomendacion(state, data) {
      state.Recomendaciones.push(data);
    },
    EliminarRecomendacion(state, data) {
      state.Recomendaciones.splice(data.index, 1);
    },
    LlenarComentarios(state, data) {
      state.Comentarios = data;
    },
    LlenarReportes(state, data) {
      state.Reportes = data;
    },
    LlenarAplicacionNivelesRemanentes(state, data) {
      state.AplicacionNivelesRemanentes = data;
    },
    LlenarLoading(state, data){
      state.Loading = data;
    }
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },

    cargarAnios: async function ({ commit, dispatch }) {
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        id_usuario: localStorage.getItem("id"),
      };

      try {
        const url = "reporte/anios";
        await axios
          .post(url, data)
          .then((response) => {
            let arrayanios = [];
            response.data.success.datos.forEach((element, i) => {
              arrayanios[i] = { value: element.anio, text: element.anio };
            });
            commit("LlenarYears", arrayanios);
            commit("DatosCliente", response.data.success.cliente);
            commit("DatosUsuario", response.data.success.usuario);
            commit("LlenarMonths", []);           
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarMeses: async function ({ commit, dispatch }, anio) {
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        year: anio,
      };

      try {
        const url = "reporte/meses";
        await axios
          .post(url, data)
          .then((response) => {
            let arraymeses = [];
            response.data.success.datos.forEach((element, i) => {
              arraymeses[i] = { value: element.numero_mes, text: element.mes };
            });
            commit("LlenarMonths", arraymeses);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    saveRecomendacion: async function ({ commit, dispatch }, item) {
      let recomendacion = {
        id: item.id_recomendacion,
        descripcion: item.descripcion_recomendacion,
      };
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        year: item.year,
        month1: item.month1,
        month2: item.month2,
        recomendacion: recomendacion,
      };
      let rpta = null;
      try {
        const url = "recomendacion/store";
        await axios
          .post(url, data)
          .then((response) => {
            rpta = recomendacion.id == null ? true : false;
            if (recomendacion.id == null) {
              commit("AgregarRecomendacion", response.data.success.datos);
            }
          })
          .catch((error) => {
            console.error("error!", error);
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });

        return rpta;
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    deleteRecomendacion: async function ({ commit, dispatch }, item) {
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        id_recomendacion: item.id,
      };

      try {
        const url = "recomendacion/delete";
        await axios
          .post(url, data)
          .then((response) => {
            commit("EliminarRecomendacion", item);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarDatosReporteConsolidado: async function ({ commit, dispatch }, item) {
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        id_usuario: localStorage.getItem("id"),
        year: item.year,
        month1: item.month1,
        month2: item.month2,
      };

      try {
        const url = "reporte/datosreporteconsolidado";
        await axios
          .post(url, data)
          .then((response) => {
            commit(
              "LlenarEquiposInspeccionados",
              response.data.equiposinspeccionados.success.datos
            );
            commit(
              "LlenarDistribucionMedidasNeumaticos",
              response.data.distribucionmedidaneumaticos.success.datos
            );
            commit(
              "LlenarPosicionRuedaSegunMarca",
              response.data.posicionruedasegunmarca.success.datos
            );
            commit(
              "LlenarDistribucionEjeDireccional",
              response.data.distribucionmarcaseje_1.success.datos
            );
            commit(
              "LlenarDistribucionEjeTraccion",
              response.data.distribucionmarcaseje_2.success.datos
            );
            commit(
              "LlenarDistribucionEjeLibre",
              response.data.distribucionmarcaseje_3.success.datos
            );
            commit(
              "LlenarNeumaticosMalEstado",
              response.data.resumenneumaticosmalestado.success.datos
            );
            commit(
              "LlenarNeumaticosScrap",
              response.data.resumenneumaticosscrap.success.datos
            );
            commit(
              "LlenarNeumaticosRetiro",
              response.data.resumenneumaticosretiro.success.datos
            );
            commit(
              "LlenarNivelesRemanenteUnidad",
              response.data.nivelesremanenteunidad.success.datos
            );
            commit(
              "LlenarAplicacionesRemanenteUnidad",
              response.data.nivelesremanenteunidad.success.aplicaciones
            );
            commit(
              "LlenarResumenRemanentesUnidad",
              response.data.nivelesremanenteunidad.success.resumen
            );
            commit(
              "LlenarNivelesRemanenteUnidadPosiciones",
              response.data.nivelesremanenteunidad.success.posiciones
            );
            commit(
              "LlenarNeumaticosServicioReencauche",
              response.data.neumaticosservicioreencauche.success.datos
            );
            commit(
              "LlenarRotacionesIrregulares",
              response.data.rotacionesirregulares.success.datos
            );
            commit(
              "LlenarInfladoNeumaticos",
              response.data.infladoneumaticos.success.datos
            );
            commit(
              "LlenarPresionNeumaticos",
              response.data.presionneumaticos.success.datos
            );
            let datos=[];
            let remanentes=[];
            let resumen=[];
            let totales=[];
            let total_datos=[];
            const nivelremmedida=response.data.nivelesremanentemedida.success;
            for (let i = 0; i < nivelremmedida.length; i++) {
              datos[i]=nivelremmedida[i].datos;
              remanentes[i]=nivelremmedida[i].remanentes;
              resumen[i]=nivelremmedida[i].resumen;
              totales[i]=nivelremmedida[i].totales;
              total_datos[i]=nivelremmedida[i].total_datos;
            }
            commit(
              "LlenarNivelesRemanenteMedidaoficial",
              response.data.nivelesremanentemedida.success
            );
            commit(
              "LlenarNivelesRemanenteMedida",
              datos
            );
            commit(
              "LlenarRemanentesMedida",
              remanentes
            );
            commit(
              "LlenarResumenRemanentesMedida",
              resumen
            );
            commit(
              "LlenarTotalesMedida",
              totales
            );
            commit(
              "LlenarTotalDatosMedida",
              total_datos
            );
            commit(
              "LlenarIndiceReencauche",
              response.data.reencauche_reencauchabilidad.success.datos
            );
            commit(
              "LlenarIndiceReencauchabilidad",
              response.data.reencauche_reencauchabilidad.success
                .indice_reencauchabilidad
            );
            commit(
              "LlenarInspecciones",
              response.data.inspecciones.success.datos
            );
            commit(
              "LlenarCostoPorKilometro",
              response.data.costoporkilometros.success.datos
            );
            commit(
              "LlenarMesesCostoPorKilometro",
              response.data.costoporkilometros.success.meses
            );
            commit(
              "LlenarPerdidasPorScrap",
              response.data.perdidasporscrap.success.datos
            );
            commit(
              "LlenarRecomendaciones",
              response.data.recomendaciones.success.datos
            );
            commit(
              "DatosReporte",
              response.data.verificarestadoreporte.success.datos
            );
            commit(
              "LlenarAplicacionNivelesRemanentes",
              response.data.aplicacion_niveles_remanentes.success.datos
            );
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarDatosAdministradorReportes: async function ({ commit, dispatch }) {
      const data = { id_cliente: localStorage.getItem("idcliente") };

      try {
        const url = "administradorreporte/index";
        await axios
          .post(url, data)
          .then((response) => {
            commit("LlenarReportes", response.data.success.datos);
            commit("LlenarLoading",false);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarComentarios: async function ({ commit, dispatch },datos) {
      const data = { id_cliente: localStorage.getItem("idcliente"),id_reportes:datos.id_reportes };

      try {
        const url = "comentarios/index";
        await axios
          .post(url, data)
          .then((response) => {
            commit("LlenarComentarios", response.data.success.datos);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },
  },
};
