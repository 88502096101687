<template>
    <div>
        <Nuevo :dialog="dialog" @dialog="dialog = $event" :Item="itemSelected"/>
        <v-card>
            <v-card-title>
            <v-spacer></v-spacer>
            <v-btn
                v-if="Excel"
                color = "primary"
                dark
                small
                class="mb-2"
                @click="DescargarExcel()"
            >
            Descargar Tabla
            </v-btn>          
            <v-spacer></v-spacer> 
            <v-btn
                v-if="global._autorizacion_usuario(Permisos,$route.meta.id,['2'])"
                color="primary"
                dark
                class="mb-2"
                @click="nuevoItem()"
                >
                Nuevo
            </v-btn>
            </v-card-title>
            <v-card-text>
              <div>
              <v-data-table 
                    height="430" :items="menuData"  item-value="menu" :headers="actionHeadersTable" fixed-header >
                <template v-slot:item="{ item }">
                  <tr>
                    <template v-if="item.menu_submenu.length >0">
                      <td style=" display: flex; justify-content: space-between; flex-wrap: nowrap; align-items: center;">
                        <div>
                          <v-icon style=" width: 36px; height: 36px; align-content: center; justify-items: center; text-align: center;">{{ item.icon }}</v-icon>{{ item.menu }}      
                        </div>
                        <div> 
                          <v-btn icon @click="toggleSubMenu(item)" >
                            <v-icon>{{ item.isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                          </v-btn>
                        </div> 
                      </td>
                      <td><center>{{ item.id_cliente=="0" ? 'Principal' : 'Secundario' }}</center></td>
                      <td v-for="action in actionHeaders" :key="action.id"></td>
                    </template>
                    <template v-else>
                      <td><v-icon style=" width: 36px; height: 36px; align-content: center; justify-items: center; text-align: center;">{{ item.icon }}</v-icon>{{ item.menu }}</td>
                      <td><center>{{ item.id_cliente=="0" ? 'Principal' : 'Secundario' }}</center></td>
                      <td v-for="action in actionHeaders" :key="action.id">
                        <center>
                          <template v-if="hasAction(item.acciones, action.id)">
                            <v-simple-checkbox
                              :value="isChecked(action.id, item.id)"
                              @click="global._autorizacion_usuario(Permisos,$route.meta.id,['3'])?updatepermisoxrol(item.id,action.id):''"
                              color="primary"
                              :readonly="!(global._autorizacion_usuario(Permisos,$route.meta.id,['3']))"
                            ></v-simple-checkbox>
                          </template>
                          <template v-else>
                          </template>
                        </center>
                      </td>
                    </template>
                  </tr>

                  <!-- Sub Items -->
                  <template v-if="item.isOpen" v-for="subItem in item.menu_submenu"> 
                    <tr :key="subItem.id">
                      <template v-if="subItem.menu_submenu.length >0">
                        <td style=" display: flex; justify-content: space-between; flex-wrap: nowrap; align-items: center;">
                          <div> <v-icon style=" width: 54px;"></v-icon>{{ subItem.menu }} </div>
                          <div> 
                            <v-btn icon @click="toggleSubMenu(subItem)" >
                              <v-icon>{{ subItem.isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                          </div> 
                        </td> 
                        <td><center>{{ subItem.id_cliente=="0" ? 'Principal' : 'Secundario' }}</center></td>
                        <td v-for="action in actionHeaders" :key="action.id"></td>
                      </template>
                      <template v-else>
                        <td><v-icon style=" width: 54px;"></v-icon>{{ subItem.menu }}</td>
                        <td><center>{{ subItem.id_cliente=="0" ? 'Principal' : 'Secundario' }}</center></td>
                        <td v-for="action in actionHeaders" :key="action.id">
                          <center>
                            <template v-if="hasAction(subItem.acciones, action.id)">
                              <v-simple-checkbox 
                                :value="isChecked(action.id,subItem.id)"
                                @click="global._autorizacion_usuario(Permisos,$route.meta.id,['3'])?updatepermisoxrol(subItem.id,action.id):''"
                                color="primary"
                                :readonly="!(global._autorizacion_usuario(Permisos,$route.meta.id,['3']))"
                              ></v-simple-checkbox> 
                            </template>
                            <template v-else>
                            </template>
                          </center>
                        </td>
                      </template> 
                    </tr>

                    <!-- Subsub Items -->
                    <template v-if="subItem.isOpen" v-for="subsubItem in subItem.menu_submenu">
                      <tr :key="subsubItem.id"> 
                        <template v-if="subsubItem.menu_submenu.length >0">
                          <td style=" display: flex; justify-content: space-between; flex-wrap: nowrap; align-items: center;">
                            <div> <v-icon style=" width: 72px;"></v-icon>{{ subsubItem.menu }} </div>
                            <div> 
                              <v-btn icon @click="toggleSubMenu(subsubItem)" >
                                <v-icon>{{ subsubItem.isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                              </v-btn>
                            </div> 
                          </td> 
                          <td><center>{{ subsubItem.id_cliente=="0" ? 'Principal' : 'Secundario' }}</center></td>
                          <td v-for="action in actionHeaders" :key="action.id"></td>
                        </template>
                        <template v-else>
                          <td><v-icon style=" width: 72px;"></v-icon>{{ subsubItem.menu }}</td>
                          <td><center>{{ subsubItem.id_cliente=="0" ? 'Principal' : 'Secundario' }}</center></td>
                          <td v-for="action in actionHeaders" :key="action.id">
                            <center>
                              <template v-if="hasAction(subsubItem.acciones, action.id)">
                                <v-simple-checkbox 
                                  :value="isChecked( action.id,subsubItem.id)"
                                  @click="global._autorizacion_usuario(Permisos,$route.meta.id,['3'])?updatepermisoxrol(subsubItem.id,action.id):''"
                                  color="primary"
                                  :readonly="!(global._autorizacion_usuario(Permisos,$route.meta.id,['3']))"
                                ></v-simple-checkbox> 
                              </template>
                              <template v-else>
                              </template>
                            </center>
                          </td>
                        </template> 
                      </tr>
                    </template>
                  </template>
                </template>
              </v-data-table>
              </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapActions,mapState } from 'vuex';
import Nuevo from './nuevo.vue'
import exportFromJSON from "export-from-json"
import global from "@/global"; 
export default {
    props: ["Titulo",  "Items", "Excel", "Crear","Loading","menuData","permisosxroles","headers","subHeaders","subSubHeaders","actionHeaders","actionHeadersTable","filtroRoles","selected"],
    component: {
        Nuevo
    },
    computed: {
      ...mapState("navigation", ["Permisos"]),
    },
    data() {
        return {
            dialog: false,
            itemSelected: { },
            global: global ,
        };
    }, 
    methods: {
        ...mapActions("roles", ["eliminarRoles","asignarPermisos"]), 

        toggleSubMenu(item) {
          item.isOpen = !item.isOpen; // Alterna el estado de isOpen
        },
        hasAction(acciones, actionId) {
          return acciones && acciones.includes(actionId);
        },
        isChecked(actionId,idmenu) {
          var aea=false;
          Object.keys(this.permisosxroles).forEach(key => {
            const element = this.permisosxroles[key];
            if (parseInt(element.menu) === parseInt(idmenu) && parseInt(element.accion) === parseInt(actionId)) {
              aea= true;
            }
          });
          return aea;
        },
        updatepermisoxrol(idmenu,idaccion){
          let data={name:this.selected,name_permiso:idmenu+"."+idaccion};
          this.asignarPermisos(data).then(()=>{
            this.filtroRoles();
          });
          console.log('aaaa',idmenu,idaccion)
        },
        getColor(edad) {
            if (edad > 40)
                return "red";
            else if (edad > 25)
                return "info";
            else
                return "green";
        },
        eliminar(item) {
            this.$alertify.confirm(
                "Desea Eliminar este Item ID: " + item.id + "?", 
                () => {
                        this.eliminarRoles(item)
                        .then(r => {
                            this.$alertify.success("Item ID: " + item.id + "  Eliminado correctamente");
                        });
                    }, 
                () => this.$alertify.error("Se canceló la acción")
            ).setHeader('<em> Aviso </em> ');
        },
        nuevoItem() {
            this.itemSelected = {
                id : 0,
                nombre : '',
                edad : 0,
                profesion : ''
            }
            this.dialog = true;
        },
        editarItem(item) {
            this.itemSelected = item
            this.dialog = true;
        },
        DescargarExcel(){
            const data = this.Items;
            const nombreArchivo = 'download';
            const exportType = exportFromJSON.types.xls; //xls, json, css, html,txt 
            exportFromJSON({data, nombreArchivo, exportType})
        }
    },
    components: { Nuevo }
};
</script>
