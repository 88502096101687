<template>
    <div>
        <Nuevo :dialog="dialog" @dialog="dialog = $event" :Item="itemSelected" :addoreditmodulo="addoreditmodulo"  @request-actions="handleRequestActions" :actionHeaders="actionHeaders" :tipo_menu="tipo_menu"/>
        <v-card>
            <v-card-title>
                {{ Titulo }}
                <v-spacer></v-spacer>
                <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                    Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <!-- <v-text-field v-model="search" append-icon="mdi-magnify" name="Busqueda" single-line hide-details ></v-text-field> -->
                <v-spacer></v-spacer>
                <v-btn  v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['2'])"  color="primary" dark class="mb-2" @click="nuevoItem(0,'')">
                    Nuevo
                </v-btn>
            </v-card-title>
            <v-card-text>
              <v-data-table :items="menuData"  :items-per-page="10"   :search="search" loading-text="Cargando... Por favor, espere" class="elevation-1" no-data-text="No hay datos disponibles"  :loading="Loading"  :footer-props="{ 'items-per-page-text': 'Resultados por página:', 'page-text': '{0}-{1} de {2}' }" > 
                <!-- Header -->
                <template v-slot:header="{ columns }">
                  <tr> 
                    <th>Modulo</th>
                    <th>Menu</th>
                    <th>Path</th>
                    <th></th>
                    <th v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['2','3','4'])">Acciones</th>
                  </tr>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <template v-if="item.menu_submenu.length >0">
                      <td style=" display: flex; justify-content: space-between; flex-wrap: nowrap; align-items: center;">
                        <div>
                          <v-icon style=" width: 36px; height: 36px; align-content: center; justify-items: center; text-align: center;">{{ item.icon }}</v-icon>{{ item.menu }}      
                        </div>
                        <div> 
                          <v-btn icon @click="toggleSubMenu(item)" >
                            <v-icon>{{ item.isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                          </v-btn>
                        </div> 
                      </td>
                      <td><center>{{ item.id_cliente=="0" ? 'Principal' : 'Secundario' }}</center></td>
                      <td><center>{{ item.path }}</center></td>
                      <td></td>
                    </template>
                    <template v-else>
                      <td style=" display: flex; justify-content: space-between; flex-wrap: nowrap; align-items: center;">
                        <div style=" width: 36px; height: 36px; align-content: center; justify-items: center; text-align: center;">
                          <v-icon style=" width: 36px; height: 36px; align-content: center; justify-items: center; text-align: center;">{{ item.icon }}</v-icon>
                        </div>
                        <div> 
                          {{ item.menu }}
                        </div>  
                      </td>
                      <td><center>{{ item.id_cliente=="0" ? 'Principal' : 'Secundario' }}</center></td>
                      <td><center>{{ item.path }}</center></td>
                      <td>
                        <center>
                          <template  v-for="action in actionHeaders">                          
                            <template v-if="hasAction(item.acciones, action.id)">
                              <v-chip style="margin-left: 2px; margin-right: 2px;font-size: 10px;">
                                  {{ action.name }}
                              </v-chip>
                            </template>
                          </template>
                        </center>
                      </td>
                    </template>
                    <td v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['2','3','4'])">
                      <center> 
                        <v-tooltip bottom  v-if="item.acciones==null && global._autorizacion_usuario( Permisos, $route.meta.id,['2'])">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn height="22" width="22" color="green lighten-2" fab x-small dark
                                    @click="nuevoItem(item.id,item.id_cliente)" v-bind="attrs" v-on="on">
                                    <v-icon x-small>mdi mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Agregar</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="Permisos && global._autorizacion_usuario( Permisos, $route.meta.id,['3'])">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn  class="ml-1" height="22" width="22" color="blue lighten-2" fab x-small dark
                                    @click="editarItem(item)" v-bind="attrs" v-on="on">
                                    <v-icon x-small>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="Permisos && global._autorizacion_usuario( Permisos, $route.meta.id,['4'])"> 
                          <template v-slot:activator="{ on, attrs }">
                              <v-btn class="ml-1" height="22" width="22" color="red lighten-2" fab x-small
                                  dark @click="eliminar(item)" v-bind="attrs" v-on="on">
                                  <v-icon x-small>mdi mdi-delete</v-icon>
                              </v-btn>
                          </template>
                          <span>Eliminar</span>
                      </v-tooltip>
                      </center>
                    </td>
                  </tr>

                  <!-- Sub Items -->
                  <template v-if="item.isOpen" v-for="subItem in item.menu_submenu"> 
                    <tr :key="subItem.id">
                      <template v-if="subItem.menu_submenu.length >0">
                        <td style=" display: flex; justify-content: space-between; flex-wrap: nowrap; align-items: center;">
                          <div> <v-icon style=" width: 54px;"></v-icon>{{ subItem.menu }} </div>
                          <div> 
                            <v-btn icon @click="toggleSubMenu(subItem)" >
                              <v-icon>{{ subItem.isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                          </div> 
                        </td> 
                        <td><center>{{ subItem.id_cliente=="0" ? 'Principal' : 'Secundario' }}</center></td>
                        <td><center>{{ subItem.path }}</center></td>
                        <td></td>
                      </template>
                      <template v-else>
                        <td><v-icon style=" width: 54px;"></v-icon>{{ subItem.menu }}</td>
                        <td><center>{{ subItem.id_cliente=="0" ? 'Principal' : 'Secundario' }}</center></td>
                        <td><center>{{ subItem.path }}</center></td>
                        <td>
                          <center>
                            <template  v-for="action in actionHeaders">                          
                              <template v-if="hasAction(subItem.acciones, action.id)">
                                <v-chip style="margin-left: 2px; margin-right: 2px;font-size: 10px;">
                                    {{ action.name }}
                                </v-chip>
                              </template>
                            </template>
                          </center>
                        </td>
                      </template> 
                      
                      <td v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['2','3','4'])">
                        <center> 
                        <v-tooltip bottom   v-if="subItem.acciones==null && global._autorizacion_usuario( Permisos, $route.meta.id,['2'])" >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn height="22" width="22" color="green lighten-2" fab x-small dark
                                    @click="nuevoItem(subItem.id,subItem.id_cliente)" v-bind="attrs" v-on="on">
                                    <v-icon x-small>mdi mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Agregar</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['3'])">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn  class="ml-1" height="22" width="22" color="blue lighten-2" fab x-small dark
                                    @click="editarItem(subItem)" v-bind="attrs" v-on="on">
                                    <v-icon x-small>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['4'])"> 
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="ml-1" height="22" width="22" color="red lighten-2" fab x-small
                                    dark @click="eliminar(subItem)" v-bind="attrs" v-on="on">
                                    <v-icon x-small>mdi mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Eliminar</span>
                        </v-tooltip>
                        </center>
                      </td>
                    </tr>

                    <!-- Subsub Items -->
                    <template v-if="subItem.isOpen" v-for="subsubItem in subItem.menu_submenu">
                      <tr :key="subsubItem.id"> 
                        <template v-if="subsubItem.menu_submenu.length >0">
                          
                          <td style=" display: flex; justify-content: space-between; flex-wrap: nowrap; align-items: center;">
                            <div> <v-icon style=" width: 72px;"></v-icon>{{ subsubItem.menu }} </div>
                            <div> 
                              <v-btn icon @click="toggleSubMenu(subsubItem)" >
                                <v-icon>{{ subsubItem.isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                              </v-btn>
                            </div> 
                          </td> 
                          <td><center>{{ subsubItem.id_cliente=="0" ? 'Principal' : 'Secundario' }}</center></td>
                          <td><center>{{ subsubItem.path }}</center></td>
                          <td ></td>
                        </template>
                        <template v-else>
                          <td><v-icon style=" width: 72px;"></v-icon>{{ subsubItem.menu }}</td>
                          <td><center>{{ subsubItem.id_cliente=="0" ? 'Principal' : 'Secundario' }}</center></td>
                          <td><center>{{ subsubItem.path }}</center></td>
                          
                          <td>
                            <center>
                              <template  v-for="action in actionHeaders">                          
                                <template v-if="hasAction(subsubItem.acciones, action.id)">
                                  <v-chip style="margin-left: 2px; margin-right: 2px;font-size: 10px;">
                                      {{ action.name }}
                                  </v-chip>
                                </template>
                              </template>
                            </center>
                          </td>
                        </template> 
                        
                      <td v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['2','3','4'])">
                        <center>
                          
                        <v-tooltip bottom  v-if="subsubItem.acciones==null && global._autorizacion_usuario( Permisos, $route.meta.id,['2'])">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn height="22" width="22" color="green lighten-2" fab x-small dark
                                    @click="nuevoItem(subsubItem.id,subsubItem.id_cliente)" v-bind="attrs" v-on="on">
                                    <v-icon x-small>mdi mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Agregar</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['3'])">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn  class="ml-1" height="22" width="22" color="blue lighten-2" fab x-small dark
                                    @click="editarItem(subsubItem)" v-bind="attrs" v-on="on">
                                    <v-icon x-small>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['4'])">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="ml-1" height="22" width="22" color="red lighten-2" fab x-small
                                    dark @click="eliminar(subsubItem)" v-bind="attrs" v-on="on">
                                    <v-icon x-small>mdi mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Eliminar</span>
                        </v-tooltip>
                        </center>
                      </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapActions,mapState } from 'vuex';
import Nuevo from './nuevo.vue'
import exportFromJSON from "export-from-json"
import global from '@/global';
export default {
    props: ["Titulo",  "Items", "Excel", "Crear","Loading","menuData","headers","subHeaders","subSubHeaders","actionHeaders","cargarPermisos"],
    component: {
        Nuevo
    },
    data() {
        return {
            dialog: false,
            itemSelected: { 
                id : "0",
                id_cliente : '',
                menu : '',
                menu_submenu : [],
                icon:'',
                acciones:null,
                id_menu:"",
                acciones_:[],
                path:'',
                visible:true,
              },
            tipo_menu:[
              {id:"0",name:'Principal'},
              {id:"1",name:'Secundario'},
            ], 
            search: "",
            global: global 
        };
    },
    computed: {
        ...mapState("modulos", ["Respuesta"]), 
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        ...mapActions("roles", ["eliminarRoles"]),
        ...mapActions("modulos", ["eliminarModulo","agregarModulo","editarModulo"]), 
        toggleSubMenu(item) {
          item.isOpen = !item.isOpen; // Alterna el estado de isOpen
        },
        handleRequestActions() {
            this.$emit('request-actions');
        },
        hasAction(acciones, actionId) {
          return acciones && acciones.includes(actionId);
        },   
        eliminar(item) {
          let message="<center>¿Desea eliminar el módulo: " + item.menu + "?</center>";
          if (item.menu_submenu.length>0) {
            message+="<br><center><p>Ten en cuenta que al eliminar este módulo, todos sus submódulos también se eliminarán de forma automática. Esta acción no se puede deshacer.</p></center>";
          }
            this.$alertify.confirm(
                message, 
                () => {
                        this.eliminarModulo(item)
                        .then(r => {
                            this.$alertify.success("Modulo: " + item.menu + "  Eliminado correctamente");
                            this.cargarPermisos();
                        });
                    }, 
                () => this.$alertify.error("Se canceló la acción")
            ).setHeader('<h3 class="alertTitle">Aviso</h3 ');
        },
        nuevoItem(id_menu,id_cliente) {
            this.itemSelected = {
                id : "0",
                id_cliente : id_cliente,
                menu : '',
                menu_submenu : [],
                icon:'',
                acciones:null,
                acciones_:[],
                path:'',
                visible:true,
                id_menu:id_menu
            }
            this.dialog = true;
        },
        editarItem(item) {
          
          this.itemSelected = {
                id :item.id,
                id_cliente :item.id_cliente ,
                menu :item.menu ,
                menu_submenu :item.menu_submenu ,
                icon:item.icon,
                acciones:item.acciones,
                path:item.path,
                visible:item.visible,
                acciones_:item.acciones ? item.acciones.split('.').map(id => parseInt(id)) : [], // Verifica si 'acciones' es una cadena
                id_menu:item.id_menu,
            } 
          this.dialog = true;
      },

        addoreditmodulo(funcion,item){ 
          // agregar
          if (funcion==0) {
            this.agregarModulo(item).then(res=>{
              this.dialog=false;
              this.$alertify.success(this.Respuesta.resultado); 
              this.cargarPermisos();
              console.log(this.Respuesta,'response');
            })
          }else{
            this.editarModulo(item).then(res=>{
              this.dialog=false;
              this.$alertify.success(this.Respuesta.resultado); 
              this.cargarPermisos();
            })
          }
        },
        DescargarExcel(){
            const data = this.Items;
            const nombreArchivo = 'download';
            const exportType = exportFromJSON.types.xls; //xls, json, css, html,txt 
            exportFromJSON({data, nombreArchivo, exportType})
        }
    }, 
    components: { Nuevo }
};
</script>
