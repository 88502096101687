import Vue from "vue";
import Vuex from "vuex";
import { axios2 as axios } from "@/interceptor/useApi";
import Swal from 'sweetalert2';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    DiseniosNeumaticos: [],
    CondicionNeumaticos: [],
    CondicionNeumaticosResumen: [],
    MedidasNeumaticos: [],
    RendimientoNeumaticos: [],
    RTDMinimos: [],
    KmRecorridos: [],
    Segmentaciones: [],
    TotalNeumaticosNuevo: 0,
    TotalNeumaticosReencauchados: 0,
    loading: true,
  },
  mutations: {
    setCondicionNeumaticos(state, payload) {
      state.CondicionNeumaticos = payload;
    },
    setCondicionNeumaticosResumen(state, payload) {
      state.CondicionNeumaticosResumen = payload;
    },
    setDiseniosNeumaticos(state, payload) {
      state.DiseniosNeumaticos = payload;
    },
    setMedidasNeumaticos(state, payload) {
      state.MedidasNeumaticos = payload;
    },
    setRendimientoNeumaticos(state, payload) {
      state.RendimientoNeumaticos = payload;
    },
    setRTDMinimos(state, payload) {
      state.RTDMinimos = payload;
    },
    setSegmentaciones(state, payload) {
      state.Segmentaciones = payload;
    },
    setKmRecorridos(state, payload) {
      state.KmRecorridos = payload;
    },
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                `,
        },
        hideClass: {
          popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                `,
        },
        backdrop: `
                rgba(255,0,0,0.1)
                left top
                no-repeat
              `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },
    //https://www.youtube.com/watch?v=87P2erOcXss
    DatosFiltroReporteMarketing: async function ({ commit, dispatch }, filtros) {
      const url = "etl/filtrosReportesMarketing";
      await axios
        .post(url, filtros)
        .then((response) => {
          commit("setCondicionNeumaticos", response.data.neumaticosxcondicion);
          commit("setRTDMinimos", response.data.nsdminimos),
            commit("setMedidasNeumaticos", response.data.neumaticosxmedida);
          commit("setSegmentaciones", response.data.sectores);
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },

    cargarNeumaticosPorCondicion: async function ({ commit, dispatch }, filtros) {
      const url = "etl/condicionneumaticosxcliente";
      await axios
        .post(url, filtros)
        .then((response) => {
          commit("setCondicionNeumaticos", response.data.neumaticos);
          commit("setCondicionNeumaticosResumen", response.data.condicion);          
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },

    cargarMedidaNeumaticos: async function ({ commit, dispatch }, filtros) {
      const url = "etl/medidaneumaticosxcliente";
      await axios
        .post(url, filtros)
        .then((response) => {
          commit("setMedidasNeumaticos", response.data.medidasneumaticos);
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },

    cargarDisenioNeumaticos: async function ({ commit, dispatch }, filtros) {
      const url = "etl/disenioneumaticosxcliente";
      await axios
        .post(url, filtros)
        .then((response) => {
          commit("setDiseniosNeumaticos", response.data.diseniosneumaticos);
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },

    cargarMedidaNeumaticosxSector: async function ({ commit, dispatch }, filtros) {
      const url = "etl/medidasneumaticosxsector";
      await axios
        .post(url, filtros)
        .then((response) => {
          commit("setMedidasNeumaticos", response.data.medidasneumaticos);
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },

    cargarRendimientoNeumaticos: async function ({ commit, dispatch }, filtros) {
      const url = "etl/rendimientoneumaticos";
      await axios
        .post(url, filtros)
        .then((response) => {
          commit(
            "setRendimientoNeumaticos",
            response.data.rendimientoneumaticos
          );
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },
  },
};
