import Vue from "vue";
import Vuex from "vuex";
import { axios2 as axios } from "@/interceptor/useApi";
import Swal from "sweetalert2";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: { 
    Download: {}, 
  },
  getters: { 
  },
  mutations: { 
    LlenarDownload(state, data) {
      state.Download = data;
    }, 
    
  },
  actions: { 
    //Datos del cliente con que se inicia sesión
    ObtenerVersionado: async function ({ commit, dispatch }) { 
      let url = "app/last/version"; 
      await axios
      .get(url) // Cambia a `get` porque es una consulta GET para obtener datos.
      .then((response) => {
          const data = response.data.resultado;
          commit("LlenarDownload", {
            text: 'Descargar App',
            icon: 'mdi-download',
            funcion: 'Dowloadapp',
            version: data.version,  // Asignamos la versión obtenida de la respuesta
            nombreapk: data.nombre   // Asignamos el nombre de la APK obtenida de la respuesta
          });
        })
        .catch((error) => {
          commit("LlenarDownload", null); // En caso de error, se limpia el estado
        });
    }, 
  }
};
