<template>
    <v-dialog v-model="dialog" persistent max-width="650px">
        <v-card>
            <v-card-title>
                <span v-if="Item.id == 0" class="text-h6">Nuevo Cliente</span>
                <span v-else class="text-h6">Editar cliente</span>
                <v-spacer></v-spacer>
                <span v-if="Item.id > 0">ID: {{ Item.id }}</span>
            </v-card-title>
            <v-card-text>
                <v-form enctype="multipart/form-data" ref="form" lazy-validation>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field label="Razon Social *" hide-details="auto" v-model="Item.razon_social" small
                                :rules="requeridos"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field label="Apodo  *" hide-details="auto" v-model="Item.slug" small
                                :rules="requeridosslug" ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field label="RUC  *" hide-details="auto" v-model="Item.ruc" type="number" small
                                :rules="[...requeridos,val => (val && val.toString().length <= 11) || 'Máximo 11 caracteres']"
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-autocomplete v-model="Item.id_sectors" :items="Sectores" class="mt-5" dense
                                label="Seleccionar Sector" :rules="requeridos" small>
                                <template v-slot:append-outer>
                                    <v-slide-x-reverse-transition mode="out-in">
                                        <v-icon color="success" @click="openModalSectores()"
                                            v-text="'mdi-plus-circle'"></v-icon>
                                    </v-slide-x-reverse-transition>
                                </template>
                            </v-autocomplete>
                            <!-- <v-autocomplete v-model="Item.id_sectors" :items="Sectores" class="mt-5" dense
                                label="Seleccionar Sector" :rules="requeridos"></v-autocomplete> -->
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field label="Dirección  *" hide-details="auto" v-model="Item.direccion" small
                                :rules="requeridos"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-autocomplete v-if="Item.id > 0" v-model="Item.estado" :items="['Activo', 'Inactivo']"
                                class="mt-4" dense label="Seleccionar Estado" :rules="requeridos" small></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-autocomplete v-if="Item.id > 0" v-model="Item.costo"
                                :items="['Costo por Kilometro', 'Costo por hora']" class="mt-4" dense
                                label="Indicador por Defecto" :rules="requeridos" small></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <div class="col-md-12">
                                <p><b>Logo Cliente (.PNG)</b></p>
                                <v-file-input id='img_firma' label="Seleccionar Imagen" filled prepend-icon="mdi-camera"
                                    accept="image/png, .jpeg, .jpg" @change="ObtenerImagen"
                                    :rules="Item.id > 0 ? [] : requeridos"></v-file-input>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <div align="center">
                                <img id='imagenFirmaPrevio' :src="Item.imagen ? Item.imagen : imagen"
                                    alt="imagen_no_disponible" width='120' height='120'>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cerrarDialogo()">
                    Cerrar
                </v-btn>
                <v-btn v-if="Item.id == 0" color="info" @click="agregar()">
                    Guardar
                </v-btn>
                <v-btn v-else color="info" @click="agregar()">
                    Actualizar
                </v-btn>
            </v-card-actions>
        </v-card>

        <!--MODAL ADD SECTOR-->
        <v-dialog v-model="dialogSector" max-width="400px" v-if="dialogSector == true">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="form_sector" v-model="valid">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field label="Sector *" v-model="sector"
                                        :rules="[(v) => !!v || 'Sector es requerido']"></v-text-field>
                                </v-col>
                                <small v-if="mostrarLabelDuplicado">
                                    <font color="red">Sector duplicado</font>
                                </small>
                            </v-row>
                        </v-form>
                    </v-container>
                    <small v-if="mostrarLabelDuplicado == false">*Indicador de campo requerido</small>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!valid" class="mt-4" style="width: 100%" small color="#009688"
                        @click="addSector(sector)">
                        <font color="white">Agregar</font>
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-overlay v-if="loader"
                        :model-value="loader"
                        class="align-center justify-center"
                        >
                        <div class="text-center">
                            Creacion en proceso<br><br>
                            <v-progress-circular
                                color="primary"
                                size="64"
                                indeterminate
                            ></v-progress-circular>
                        </div>
                    </v-overlay>

        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
    </v-dialog>
</template>

<script>
import {
    mapState,
    mapActions
} from 'vuex';
import MensajeTransicion from "../Alerta.vue"
export default {
    props: ['dialog', 'Item', 'Titulo'],
    components: {
        MensajeTransicion
    },
    data() {
        return {
            imagenminiatura: '/images/reporte/imagen_no_disponible.jpg',
            emailRules: [
                v => !!v || 'E-mail es requerido',
                v => /.+@.+\..+/.test(v) || 'E-mail no es valido',
            ],
            show1: false,
            rules: {
                required: v => !!v || 'Requerido.',
                min: v => v.length >= 6 || 'Min 6 characters'
            },
            requeridos: [
                v => !!v || 'Este Campo es Requerido.'
            ],
            requeridosslug:[
                v => !!v || 'Este Campo es requerido',
                v => /^[a-zA-Z0-9]+$/.test(v) || 'No se permiten caracteres especiales',
                v => v.length>=6 || 'Min 6 caracteres',
                v => v.length<=15 || 'Max 15 caracteres',
            ],
            mensaje: "",
            verAlerta: false,
            valid: true,
            sector: null,
            dialogSector: false,
            mostrarLabelDuplicado: false,
            loader:false,
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
        ...mapState("sector", ["Sectores"]),
        ...mapState('cliente',['validacionapodo']),
        imagen() {
            return this.imagenminiatura;
        }
    },
    methods: {
        ...mapActions('cliente', ['agregarCliente', 'cargarCliente']),
        ...mapActions('sector', ['crearSector']),

        async agregar() {
            if (this.$refs.form.validate()) {
                this.$alertify.confirm(
                    this.Item.id == 0 ? "Realmente desea registra el cliente!" : "Realmente desea editar el cliente!",
                    () => {
                        if (this.Item.id == 0) {
                            this.loader=true;
                            this.agregarCliente(this.Item)
                                .then(r => { 
                                    if (this.validacionapodo!='error') {   
                                        this.LimpiarCampos();
                                        this.cerrarDialogo();   
                                    }
                                    this.loader=false;
                                });
                        } else {
                                this.agregarCliente(this.Item)
                                    .then(r => {
                                        if (this.validacionapodo!='error') {   
                                            this.LimpiarCampos();
                                            this.cerrarDialogo();   
                                        }
                                        this.loader=false;
                                    });
                        }
                    },
                    () => {
                        this.cargarCliente()
                        this.$alertify.error("Se cancelo la acción")
                    }
                ).setHeader('<h3> Aviso </h3>').set('labels', { ok: 'Aceptar', cancel: 'Cancelar' });
            }
        },
        LimpiarCampos() {
            this.Item.id = 0,
                this.Item.razon_social = '',
                this.Item.ruc = '',
                this.Item.estado = '',
                this.Item.ruta_logo = '',
                this.Item.costo = '',
                this.Item.imagen = '',
                this.Item.slug = '',
                this.Item.direccion = ''
        },
        cerrarDialogo() {
            this.$emit('closeDialog');
        },
        ObtenerImagen() {
            let file = document.getElementById('img_firma').files[0];
            this.Item.ruta_logo = file;
            this.CargarImagen(file)
        },
        CargarImagen(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.imagenminiatura = e.target.result;
            }
            reader.readAsDataURL(file)
        },

        openModalSectores() {
            this.dialogSector = true;
        },

        closeModalSectores() {
            this.sector = null;
            this.dialogSector = false;
        },

        async addSector(sector) {
            let exists = this.Sectores.some(element => element.text == sector);
            if (exists) {
                this.mostrarLabelDuplicado = true;
            } else {
                this.mostrarLabelDuplicado = false;
                let rpta = await this.crearSector({sector: this.sector});
                console.log(rpta);
                this.Sectores.push({value: rpta.id, text: rpta.name});
                this.closeModalSectores();
            }
        }
    },
}
</script>
