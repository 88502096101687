<template>
<div class="pa-5">
    <v-col lg="12">
        <v-card outlined>
            <v-toolbar color="secondary" dark>
                <span class="text-h6"> Reporte de Costos</span>
            </v-toolbar>
            <v-card-text class="mb-10">
                <v-row>
                    <v-col cols="12" sm="12" md="5">
                        <font>
                            Serie de neumático: </font><strong>{{ costo.num_serie }}</strong>
                    </v-col>
                    <v-col cols="12" sm="12" md="5">
                        <font>
                            Costo acumulado sin IGV: </font><strong>$ {{ costo.precio.toFixed(2) }}</strong>
                    </v-col>

                    <v-col cols="12" sm="12" md="2">
                        <v-btn class="rounded-pill" color="error" @click="cancelar()"> Cancelar </v-btn>
                    </v-col>

                </v-row>
                <tabla Titulo="" :Cabecera="Cabecera" :loading="Loader" :Items="Items" :Excel="false" :Crear="false" @cargarCostos="cargarCostos" />
            </v-card-text>
        </v-card>
    </v-col>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import router from "@/router";
import tabla from "@/components/neumaticos/tablacosto.vue";
import {
    axios2 as axios
} from "@/interceptor/useApi";

export default {
    name: "NeumaticoReporteCosto",
    components: {
        tabla,
    },
    data() {
        return {
            costo: {
                num_serie: "",
                precio: 0
            },
            Loader: false,
            Cabecera: [{
                    text: "Opc.",
                    value: "acciones",
                    align: "left",
                    width: 80,
                    class: "grey darken-1 dark white--text",
                },
                {
                    text: "Id",
                    value: "id",
                    align: "left",
                    class: "grey darken-1 dark white--text",
                },
                {
                    text: "Fecha instalación",
                    value: "fecha_instalacion",
                    align: "left",
                    width: 150,
                    class: "grey darken-1 dark white--text",
                },
                {
                    text: "Fecha retiro",
                    value: "fecha_retiro",
                    align: "left",
                    width: 150,
                    class: "grey darken-1 dark white--text",
                },
                {
                    text: "Diseño",
                    value: "disenio",
                    align: "left",
                    width: 150,
                    class: "grey darken-1 dark white--text",
                },
                {
                    text: "Reencauchadora",
                    value: "empresa",
                    align: "left",
                    width: 150,
                    class: "grey darken-1 dark white--text",
                },
                {
                    text: "Costo",
                    value: "costo_convertido",
                    align: "left",
                    width: 150,
                    class: "grey darken-1 dark white--text",
                },
                {
                    text: "Tipo de cambio",
                    value: "moneda",
                    align: "left",
                    width: 150,
                    class: "grey darken-1 dark white--text",
                },
                {
                    text: "Condición",
                    value: "nom_condicion",
                    align: "left",
                    width: 150,
                    class: "grey darken-1 dark white--text",
                },
                {
                    text: "Motivo",
                    value: "nom_motivo",
                    align: "left",
                    width: 150,
                    class: "grey darken-1 dark white--text",
                },
                {
                    text: "Observación",
                    value: "observacion",
                    align: "left",
                    width: 150,
                    class: "grey darken-1 dark white--text",
                },
            ],
            Items: [],
        };
    },
    computed: {
        ...mapState("neumatico", ["Item","TipoCambio"]),
    },
    methods: {
        cargarCostos: async function () {
            try {
                const url = "neumaticos/cost";
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                    id_neumatico: location.pathname.substr(1).split("/")[1],
                };
                await axios
                    .post(url, data)
                    .then((response) => {
                        this.Items = response.data.success.datos;
                        this.costo.num_serie = this.Item.num_serie;

                        this.costo.precio = 0;
                        this.Items.forEach(element => {
                            if(element.costo != null)
                                this.costo.precio += parseFloat(element.costo);
                        });
                        this.$forceUpdate();
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        cancelar: function () {
            router.back();
        },
    },
    created() {
        this.cargarCostos();
    },
};
</script>

<style scoped>
.letra-8 {
    font-size: "8px";
}

.letra-10 {
    font-size: "10px";
}

.letra-11 {
    font-size: "11px";
}

.letra-12 {
    font-size: "12px";
}
</style>
