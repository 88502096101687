import Vue from 'vue'
import Vuex from 'vuex'
import {axios2 as axios} from "@/interceptor/useApi";

Vue.use(Vuex)

export default{
  namespaced:true,
  state: {
    Sectores : [],
  },
  getters: {
    Items(state){
      return state.Sectores
    }
  },
  mutations: {
    LlenarSectores(state,data){
      state.Sectores = data
    }
  },
  actions: {
    cargarSectores:async function({commit}){
      try {
        const  url = "sectors/index"
        await axios.get(url)
        .then(response => {
          let array = [];
          response.data.data.forEach((element, index )=> {
            array[index] = { value: element.id, text: element.name}
          });
          commit('LlenarSectores', array);       
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
        }); 
      } catch (error) {
        console.log(error)
      }
    },

    crearSector: async function({ commit }, datos){
      let rpta = null;
      try {
        const url = "sectors/create";
        await axios
          .post(url, datos)
          .then((response) => {
            rpta = response.data.data;
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
          });
      } catch (error) {
        console.log(error);
      }
      return rpta;
    }
  }
}


