<template>
<div class="pa-5">
    <v-col lg="12">
        <v-card outlined>
            <v-card-title>
                SCRAP Neumático
            </v-card-title>
            <v-card-text class="mb-5">
                <v-row class="mb-2">
                    <v-col cols="12" sm="12" md="12" v-if="Item">
                        <font>
                            Serie de neumático: </font><strong>{{ Item.num_serie }}</strong>
                    </v-col>

                    <v-col cols="12" sm="12" md="12" v-if="mostrarCheck">
                        <v-checkbox class="pa-0" :readonly="Items.length > 0" v-model="checkbox" label="SCRAP" @change="onSeleccionar()"></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="12" md="12" v-if="checkbox == true && showFormScrap == true">
                        <v-form ref="form-scrap" v-model="valid" class="pa-2">
                            <v-row>
                                <v-col cols="12" sm="4" md="4" class="mt-4">
                                    <v-autocomplete :items="MotivosScrap" v-model="scrap.id_motivo_scrap" label="Motivo Scrap:" :rules="[(v) => !!v || 'Motivo de scrap es requerido']" dense></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field type="number" v-model="scrap.remanente_final" placeholder="N°" label="Remanente final *" hide-details="auto" :rules="[(v) => !!v || 'Remanente final es requerido']"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field type="number" v-model="scrap.remanente_limite" placeholder="N°" label="Remanente límite *" hide-details="auto" :rules="[(v) => !!v || 'Remanente limite es requerido']" readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field type="date" v-model="scrap.fecha_scrap" label="Fecha scrap *" hide-details="auto" :rules="[(v) => !!v || 'La fecha es requerido']"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-spacer></v-spacer>
                        <v-btn class="rounded-pill" color="error" @click="cerrarDialogo()"> Cancelar </v-btn>
                        <v-btn v-if="scrap.remanente_final == null && mostrarElemento == true" color="info" class="ml-2" @click="guardarNeumaticoAScrap(scrap)" :disabled="!valid">
                            Guardar
                        </v-btn>
                        <v-btn v-if="scrap.remanente_final != null && mostrarElemento == true" color="info" class="ml-2" @click="guardarNeumaticoAScrap(scrap)" :disabled="!valid">
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>

                <tabla Titulo="" :Cabecera="Cabecera" :loading="Loader" :Items="Items" :Excel="false" :Crear="false" @mostrarFormularioScrap="mostrarFormularioScrap" />
            </v-card-text>
        </v-card>
    </v-col>
</div>
</template>

<script>
import {
    mapState,
    mapActions
} from "vuex";
import router from "@/router";
import tabla from "@/components/neumaticos/tablascrap.vue";
import axios from "axios";
import { notif } from "@/plugins/notyf"

export default {
    name: "NeumaticoReporteCosto",
    components: {
        tabla,
    },
    data() {
        return {
            scrap: {},
            valid: false,
            Loader: false,
            checkbox: false,
            Cabecera: [{
                    text: "Opc.",
                    value: "acciones",
                    align: "center",
                    width: 80,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Id",
                    value: "id",
                    align: "left",
                    width: 70,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Serie",
                    value: "num_serie",
                    align: "left",
                    width: 80,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Marca",
                    value: "marca",
                    align: "left",
                    width: 90,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Modelo",
                    value: "modelo",
                    align: "left",
                    width: 100,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Medida",
                    value: "medida",
                    align: "left",
                    width: 90,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Diseño",
                    value: "disenio",
                    align: "left",
                    width: 90,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Motivo scrap",
                    value: "nom_motivo",
                    align: "left",
                    width: 160,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Fecha scrap",
                    value: "fecha_scrap",
                    align: "left",
                    width: 120,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Remanente final",
                    value: "remanente_final",
                    align: "left",
                    width: 120,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Remanente límite",
                    value: "remanente_limite",
                    align: "left",
                    width: 120,
                    class: "grey darken-1 dark white--text"
                },
            ],
            Items: [],
            mostrarElemento: false,
            estadoNeumatico: "",
            showFormScrap: false,
            mostrarCheck: false
        };
    },
    computed: {
        ...mapState("neumatico", ["Item", "TipoCambio", "MotivosScrap"]),
        ...mapState("motivosscrap", ["MotivosScrap"])
    },
    methods: {

        ...mapActions("motivosscrap", ["cargarMotivosScrap"]),

        onSeleccionar() {
            if (this.checkbox == true) {
                this.mostrarElemento = true;
            } else {
                this.mostrarElemento = false;
            }
        },

        cargarDetallesNeumaticoEnScrap: async function () {
            let id_neumatico = location.pathname.substr(1).split("/")[1];
            try {
                const url = "scrap/find";
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                    id_neumatico: id_neumatico
                };
                await axios
                    .post(url, data)
                    .then((response) => {
                        this.Items = [];
                        let neumatico = response.data.success.datos;
                        this.estadoNeumatico = neumatico.estado;
                        if (this.estadoNeumatico != 3) {
                            this.mostrarCheck = true;
                        }
                        this.scrap.remanente_limite = neumatico.remanente_limite;
                        this.scrap.fecha_scrap = neumatico.fecha_scrap;
                        this.scrap.id = neumatico.id;

                        if (this.estadoNeumatico == 0) {
                            this.Items.push(neumatico);
                        }

                        if (this.Items.length > 0) {
                            this.checkbox = true;
                            this.showFormScrap = false;
                        } else {
                            this.showFormScrap = true;
                        }

                        if (this.estadoNeumatico == 0) {
                            this.showFormScrap = false;
                        }
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        mostrarFormularioScrap: function (item) {
            this.mostrarElemento = true;
            this.showFormScrap = true;
            //item.fecha = item.fecha_scrap;
            this.scrap = item;
            this.$forceUpdate();
        },

        cerrarDialogo: function () {
            router.back();
        },

        cancelar: function () {
            router.back();
        },

        guardarNeumaticoAScrap: async function (item) {
            //this.scrap.fecha = this.scrap.fecha_scrap;
            this.scrap.serieneumatico = item.num_serie; // Crear

            try {
                const url = "scrap/storesimplificado";
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                    id_usuario: localStorage.getItem("id"),
                    neumatico: this.scrap,
                };
                await axios
                    .post(url, data)
                    .then((response) => {
                        notif.success("Neumático con serie: "+ this.scrap.serieneumatico +" actualizado con éxito");
                        this.mostrarElemento = false;
                        this.cargarDetallesNeumaticoEnScrap();
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },
    },
    async created() {
        this.Loader = true;
        await this.cargarDetallesNeumaticoEnScrap();
        await this.cargarMotivosScrap();
        this.Loader = false;
    },
};
</script>

<style scoped>
.letra-8 {
    font-size: "8px";
}

.letra-10 {
    font-size: "10px";
}

.letra-11 {
    font-size: "11px";
}

.letra-12 {
    font-size: "12px";
}
</style>
