<template>
  <div class="pa-5">
    <v-col lg="12">
      <tabla
        Titulo="Neumáticos en mal estado"
        :Cabecera="Cabecera"
        :loading="Loader"
        :Items="Items"
        :ItemTotal="ItemTotal"
        :Excel="false"
        :Crear="false"
        @actualizartabla="actualizartabla"
      />
    </v-col>
  </div>
</template>

<script>
import tabla from "@/components/neumaticos/tablaenmalestado.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "EnMalEstado",
  components: {
    tabla,
  },
  created() {
    this.cargarNeumaticos();
  },
  data() {
    return {
      //Items: [],
      Cabecera: [
        {
          text: "Opciones",
          value: "acciones",
          align: "left",
        },
        {
          text: "Id",
          value: "id",
          align: "left",
        },
        {
          text: "Disponibilidad",
          value: "estado_descripcion",
          align: "left",
          width: 130,
        },
        {
          text: "Serie",
          value: "num_serie",
          width: 100,
          align: "left",
        },
        {
          text: "Marca",
          value: "marca",
          align: "left",
        },
        {
          text: "Modelo",
          value: "modelo",
          align: "left",
          width: 100,
        },
        {
          text: "Medida",
          value: "medida",
          align: "left",
          width: 100,
        },
        {
          text: "Diseño de banda",
          value: "disenio",
          align: "left",
          width: 100,
        },
        {
          text: "Motivo de retiro",
          value: "motivo_scrap",
          align: "left",
          width: 100,
        },
        {
          text: "Fecha de retiro",
          value: "fecha_scrap_local",
          width: 120,
          align: "left",
        },
        {
          text: "Remanente original",
          value: "remanente_original",
          width: 110,
          align: "left",
        },
        {
          text: "Remanente final",
          value: "remanente_final",
          width: 110,
          align: "left",
        },
        {
          text: "Remanente límite",
          value: "remanente_limite",
          width: 110,
          align: "left",
        },
        {
          text: "Costo($)",
          value: "costo",
          width: 120,
          align: "left",
        },
        {
          text: "Costo de pérdida($)",
          value: "costo_perdido",
          width: 120,
          align: "left",
        },
        {
          text: "Imagen",
          value: "image",
          width: 120,
          align: "left",
        }
      ],
      loading: false,
    };
  },
  methods: {
    ...mapActions("enmalestado", ["cargarNeumaticos"]),

    actualizartabla(){
      this.cargarNeumaticos();
    }
  },
  computed: {
    ...mapState("enmalestado", ["Items", "Loader", "ItemTotal"]),
  },
};
</script>
