import Vue from "vue";
import Vuex from "vuex";
import { axios2 as axios } from "@/interceptor/useApi";
import Swal from 'sweetalert2';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    ItemsOriginal: [],
    Items: [],
    ItemsSelect: [],
    Permisosxroles:[],
    Loading:true
  },
  getters: {
    Items(state) {
      return state.Items;
    },
    ItemsOriginal(state){
      return state.ItemsOriginal;
    },
    ItemsSelect(state) {
      return state.ItemsSelect;
    },
    Permisosxroles(state){
      return state.Permisosxroles
    }
  },
  mutations: {
    LlenarItemsOriginal(state,data){
      state.ItemsOriginal=data;
    },
    LlenarPermisosxroles(state,data){
      state.Permisosxroles=data;
    },
    LlenarItems(state, data) {
      console.log(data,'aea');
      state.Items = data;
    },
    LlenarItemsSelect(state, data) {
      state.ItemsSelect = data;
    },
    LlenarLoading(state, data) {
      state.Loading = data; 
    }
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },

    cargarPermisos: async function ({ commit, dispatch }) {
      try {
        var dataaux = [];
        var select = [];
        const url = "permisos";
        await axios
          .get(url)
          .then((response) => {
            commit("LlenarItems", response.data.success.resultado.permisos[0]);
            commit("LlenarItemsOriginal",response.data.success.resultado.permisos);
            commit("LlenarLoading", false);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },
    cargarPermisosxroles: async function ({ commit, dispatch },datos) {
      try {
        var dataaux = [];
        var select = [];
        const data = { level: datos };
        const url = "permisos/rol";
        await axios
          .post(url,data)
          .then((response) => {
            commit("LlenarPermisosxroles", response.data.success);
            commit("LlenarLoading", false);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },
    verRoles: async function ({ commit, state, dispatch }, datos) { 
        const url = "roles";
        const data = { rol: datos };
        await axios
          .get(url, data)
          .then((response) => {            
            commit("LlenarItemsSelect", response.data.success.resultado);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          }); 
    },
  },
};
