<template>
  <div class="pa-5">
    <v-col lg="12">
      <v-row align="center">
        <v-col lg="12">
          <v-card>
            <v-toolbar color="secondary" dark>
              <span class="text-h6">Pérdidas por scrap</span>
            </v-toolbar> 
            <v-card-subtitle>{{ Cliente.razon_social }} - RUC: {{ Cliente.ruc }}</v-card-subtitle>
            <!--
            <v-toolbar height="60" color="#545554" dark>
              Pérdidas por Scrap
            </v-toolbar>
            -->
            <v-container class="pa-4">
              <!--
              <v-row>
                <div class="col-sm-6">
                  <p><b>CLIENTE </b></p>
                  <label>{{ Cliente.razon_social }}</label>
                </div>

                <div class="col-sm-6">
                  <p><b>RUC</b></p>
                  <label>{{ Cliente.ruc }}</label>
                </div>
              </v-row>
              -->

              <v-row>
                <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                  <v-autocomplete :items="Marcas" v-model="Item.marca" label="Seleccione marca" dense
                    @change="onMarca"></v-autocomplete>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                  <v-autocomplete :items="modelosPorMarca" v-model="Item.modelo" label="Seleccione modelo" dense
                    @change="onModelo"></v-autocomplete>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                  <v-autocomplete :items="Medidas" v-model="Item.medida" label="Seleccione medida" dense
                    @change="onMedida"></v-autocomplete>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                  <v-autocomplete :items="reencauches" v-model="Item.condicion" label="Seleccione condición del neumático"
                    dense @change="onCondicion"></v-autocomplete>
                </div>
              </v-row>

              <v-row>
                <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                  <v-text-field type="date" label="Fecha inicial *" v-model="Item.fecha_inicial" hide-details="auto"
                    required></v-text-field>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                  <v-text-field type="date" label="Fecha final *" v-model="Item.fecha_final" hide-details="auto"
                    required></v-text-field>
                </div>
              </v-row>

              <v-row>
                <v-col cols="1" sm="1" md="1" class="mt-2">
                  <v-btn color="success" @click="cargarDatos" small>
                    Enviar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center" v-if="mostrarTabla == true">
        <v-col lg="12">
          <v-card>
            <v-toolbar color="secondary" dark>
              <span class="text-h6">Neumáticos</span>
            </v-toolbar> 
        
            <v-container class="pa-4">
              <div class="table-responsive">
                <table class="letra-9" style="width: 100%" id="table">
                  <tr style="background-color: #6d6b6b">
                    <td class="border texto-cen pa-1" style="width: 7%">
                      <strong>
                        <font color="white">N. serie</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1" style="width: 7%">
                      <strong>
                        <font color="white">Serie del cliente</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1" style="width: 7%">
                      <strong>
                        <font color="white">Marca</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1" style="width: 7%">
                      <strong>
                        <font color="white">Modelo</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1" style="width: 7%">
                      <strong>
                        <font color="white">Medida</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1" style="width: 8%">
                      <strong>
                        <font color="white">Condición de neumático</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1" style="width: 8%">
                      <strong>
                        <font color="white">Fecha de scrap</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1" style="width: 8%">
                      <strong>
                        <font color="white">Motivo scrap</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1" style="width: 8%">
                      <strong>
                        <font color="white">Remanente original</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1" style="width: 8%">
                      <strong>
                        <font color="white">Remanente final</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1" style="width: 8%">
                      <strong>
                        <font color="white">Remanente límite</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1" style="width: 8%">
                      <strong>
                        <font color="white">Costo ($)</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1" style="width: 8%">
                      <strong>
                        <font color="white">Costo de pérdida ($)</font>
                      </strong>
                    </td>
                  </tr>
                  <tr v-for="(item, index) in PerdidasPorScrap" :key="index">
                    <td class="border texto-cen pa-1">{{ item.num_serie }}</td>
                    <td class="border texto-cen pa-1">{{ item.serie_cliente }}</td>
                    <td class="border texto-cen pa-1">{{ item.marca }}</td>
                    <td class="border texto-cen pa-1">{{ item.modelo }}</td>
                    <td class="border texto-cen pa-1">{{ item.medida }}</td>
                    <td class="border texto-cen pa-1">{{ item.condicion }}</td>
                    <td class="border texto-cen pa-1">{{ item.fecha_scrap }}</td>
                    <td class="border texto-cen pa-1">{{ item.motivo }}</td>
                    <td class="border texto-cen pa-1">
                      {{ item.remanente_original }}
                    </td>
                    <td class="border texto-cen pa-1">{{ item.remanente_final }}</td>
                    <td class="border texto-cen pa-1">
                      {{ item.remanente_limite }}
                    </td>
                    <td class="border texto-cen pa-1">
                      <font color="blue"><strong>{{ item.costo }}</strong></font>
                    </td>
                    <td class="border texto-cen pa-1" style="background-color: #e4dddd">
                      <font color="red"><strong>{{ item.costo_perdido }}</strong></font>
                    </td>
                  </tr>
                  <tr v-if="PerdidasPorScrap.length > 0">
                    <td class="border texto-der pa-1" colspan="11">
                      <strong>Total general</strong>
                    </td>
                    <td class="border texto-cen pa-1">
                      <font color="blue"><strong>{{ total_costos }}</strong></font>
                    </td>
                    <td class="border texto-cen pa-1">
                      <font color="red"><strong>{{ total_perdidas }}</strong></font>
                    </td>
                  </tr>
                  <tr v-if="PerdidasPorScrap.length == 0">
                    <td class="border texto-cen pa-1" colspan="13">
                      No hay datos para pérdidas por scrap
                    </td>
                  </tr>
                </table>
              </div>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="1" sm="1" md="1" class="mt-2">
          <v-btn color="success" @click="exportarAExcel" small>
            Exportar Excel
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { axios2 as axios } from "@/interceptor/useApi";
export default {
  data() {
    return {
      Item: {
        marca: null,
        modelo: null,
        medida: null,
        condicion: null,
        fecha_inicial: null,
        fecha_final: null
      },
      mostrarTabla: true,
      modelosPorMarca: [],
      reencauches: [
        { value: "N", text: "Nuevo" },
        { value: 1, text: "1 reencauche" },
        { value: 2, text: "2 reencauche" },
        { value: 3, text: "3 reencauche" },
        { value: 4, text: "4 reencauche" },
        { value: 5, text: "5 reencauche" },
        { value: 6, text: "6 reencauche" },
        { value: "R", text: "Más de 6 reencauche" },
      ],
      total_costos: 0,
      total_perdidas: 0
    };
  },
  computed: {
    ...mapState("cliente", ["Cliente"]),
    ...mapState("reportes", [
      "PerdidasPorScrap",
      "Marcas",
      "Modelos",
      "Medidas",
    ]),
  },
  methods: {
    ...mapActions("cliente", ["ObtenerDatosCliente"]),
    ...mapActions("reportes", [
      "DatosReportePerdidasPorScrap",
      "DatosMarcasModelosMedidas",
    ]),

    onMarca: function () {
      this.modelosPorMarca = [];
      this.Modelos.forEach((element) => {
        if (element.id_marca == this.Item.marca) {
          this.modelosPorMarca.push(element);
        }
      });
      this.DatosReportePerdidasPorScrap(this.Item);
    },

    onModelo: function () {
      this.DatosReportePerdidasPorScrap(this.Item);
    },

    onMedida: function () {
      this.DatosReportePerdidasPorScrap(this.Item);
    },

    onCondicion: function () {
      this.DatosReportePerdidasPorScrap(this.Item);
    },

    _json_to_query_string: function (json) {
      json = Object.keys(json).map(item => {
        return item + '=' + json[item] || '';
      });
      return json.join("&");
    },

    exportarAExcel: function () {
      location.href =
        axios.defaults.baseURL + "excel/reporteperdidasscrap?" +
        this._json_to_query_string({
          id_cliente: localStorage.getItem('idcliente'),
          fecha_inicial: this.Item.fecha_inicial,
          fecha_final: this.Item.fecha_final,
          id_marca: this.Item.marca,
          id_modelo: this.Item.modelo,
          id_medida: this.Item.medida,
          condicion: this.Item.condicion
        });
    },

    cargarDatos: async function () {
      if (
        this.Item.fecha_inicial == undefined ||
        this.Item.fecha_final == undefined
      ) {
        this.$alertify.error("Por favor, ingresar fechas válidas");
      } else if (this.Item.fecha_inicial > this.Item.fecha_final) {
        this.$alertify.error(
          "Por favor, ingresar una fecha igual o mayor a la primera fecha seleccionada"
        );
      } else {
        await this.DatosReportePerdidasPorScrap(this.Item);
        this.total_costos = 0;
        this.total_perdidas = 0;

        this.PerdidasPorScrap.forEach(element => {
          // Convierte los valores de string a números decimales
          const costo = parseFloat(element.costo);
          const costo_perdido = parseFloat(element.costo_perdido);
          // Suma los valores convertidos a las variables totales
          this.total_costos += costo;
          this.total_perdidas += costo_perdido;
        });
        // Redondea los valores totales a dos decimales
        this.total_costos = this.total_costos.toFixed(2);
        this.total_perdidas = this.total_perdidas.toFixed(2);

        // console.log('total_costos 2 > ', this.total_costos);
        // console.log('total_perdidas 2 > ', this.total_perdidas);
      }
    },
  },
  created: async function () {
    await this.DatosReportePerdidasPorScrap(this.Item);
    this.total_costos = 0;
    this.total_perdidas = 0;

    this.PerdidasPorScrap.forEach(element => {
      const costo = parseFloat(element.costo);
      const costo_perdido = parseFloat(element.costo_perdido);

      this.total_costos += costo;
      this.total_perdidas += costo_perdido;
    });
    this.total_costos = this.total_costos.toFixed(2);
    this.total_perdidas = this.total_perdidas.toFixed(2);

    // console.log('total_costos 1 > ', this.total_costos);
    // console.log('total_perdidas 1 > ', this.total_perdidas);
    this.$forceUpdate();
  },
  mounted() {
    this.ObtenerDatosCliente();
    this.DatosMarcasModelosMedidas();

  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td {
  vertical-align: top;
}

.border {
  border-style: solid;
  border-width: 1px;
}

.border-bottom {
  border-bottom: solid;
  border-width: 1px;
}

.border-top {
  border-top: solid;
  border-width: 1px;
}

.border-left {
  border-left: solid;
  border-width: 1px;
}

.border-right {
  border-right: solid;
  border-width: 1px;
}

.no-border {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.no-border-left {
  border-left: none;
}

.no-border-right {
  border-right: none;
}

.texto-cen {
  text-align: center;
}

.texto-izq {
  text-align: left;
}

.texto-der {
  text-align: right;
}

.texto-just {
  text-align: justify;
}

.inline {
  display: inline-block;
}

.texto-arriba {
  vertical-align: text-top;
  vertical-align: top;
}

.letra-6 {
  font-size: 6pt;
}

.letra-7 {
  font-size: 7pt;
}

.letra-8 {
  font-size: 8pt;
}

.letra-9 {
  font-size: 9pt;
}

.letra-10 {
  font-size: 10pt;
}

.letra-11 {
  font-size: 11pt;
}

.letra-12 {
  font-size: 12pt;
}

.letra-14 {
  font-size: 14pt;
}

.letra-15 {
  font-size: 15pt;
}

.letra-18 {
  font-size: 18pt;
}

.letra-25 {
  font-size: 25pt;
}

.letra-30 {
  font-size: 30pt;
}

.margin-top-20 {
  margin-top: 20px;
}

#table {
  display: block;
  /* important */
  width: 1000px;
  overflow-x: scroll;
}
</style>