<template>
    <div>
        <v-row>
            <v-col v-for="(item, index) in Items" :key="index"
            class="ma-2" >
                <v-card class="mx-auto" max-width="344">
                <v-img :src="item.PLU_Imagen" height="200px"></v-img>

                <v-card-title> {{ item.PLU_Nombre }} </v-card-title>

                <v-card-subtitle> 
                    <p>{{ item.PLU_Descripcion }}</p>
                </v-card-subtitle>

                <v-card-actions>
                    <v-btn  :color="item.estado == 'Instalado' ? 'success lighten-2' : 'orange lighten-2'" text  @click="InstalarPlugin(item)"> {{ item.estado }} </v-btn>
                    <v-btn v-if="item.estado == 'Instalado'" color="red lighten-2" text  @click="DesinstalarPlugin(item)"> Desinstalar </v-btn>
                </v-card-actions>  
                <!-- :color="['btn', item.estado == 'instalado' ? 'orange' : 'blue']" -->              
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
        v-model="dialog"
        hide-overlay
        persistent
        width="300"
        >
            <v-card
                color="green accent-2"                
            >
                <v-card-text >
                    Instalando Plugin {{nameplugins}}
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
        v-model="dialog2"
        hide-overlay
        persistent
        width="300"
        >
            <v-card
                color="red lighten-2"
                dark
            >
                <v-card-text >
                    Desinstalando Plugin {{nameplugins}}
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        show: false,
        dialog: false,
        dialog2: false,
        nameplugins : ''
    }),

    props: ["Items"],
    methods:{
        InstalarPlugin(Item){
            if(Item.estado == 'Instalar'){
                this.nameplugins = Item.PLU_Nombre
                this.dialog = true
                const data = {idplugin: Item.id, idcliente: localStorage.getItem('idclient') };
                this.Instalar(data)
                .then(r => {
                    this.$alertify.success("Plugin : " + Item.PLU_Nombre + "  Instalado");
                    this.exitoCallback("dialog1")
                })
                .catch(error =>{
                    this.$alertify.error("Ocurrio un error, el plugin : " + Item.PLU_Nombre + " No se a Instalado");
                    this.falloCallback("dialog1")
                });
            }
        },
        DesinstalarPlugin(Item){
            this.$alertify.confirm(
                "Desea desinstalar el Plugin de " + Item.PLU_Nombre + "?", 
                () => {
                    if(Item.estado == 'Instalado'){
                        this.nameplugins = Item.PLU_Nombre
                        this.dialog2 = true
                        const data = {idplugin: Item.id, idcliente: localStorage.getItem('idclient') };  
                        this.Desinstalar(data)
                        .then(r => {
                            this.$alertify.success("Plugin : " + Item.PLU_Nombre + "  Desinstalado");
                            this.exitoCallback("dialog2")
                        })
                        .catch(error =>{
                            this.$alertify.error("Ocurrio un error, el plugin : " + Item.PLU_Nombre + " No se a Desinstalado");
                            this.falloCallback("dialog2")
                        });
                    }
                    }, 
                () => this.$alertify.error("Se cancelo la acción")
            ).setHeader('<em> Aviso </em> ');

            
        }, 
        exitoCallback($modal) {
            if($modal == "dialog1"){                
                setTimeout(() => (this.dialog = false,this.loadRoutes()), 1000)
            }else{
                setTimeout(() => (this.dialog2 = false,this.loadRoutes()), 1000)
            }      
        },
        falloCallback($modal) {
            if($modal == "dialog1"){                
                setTimeout(() => (this.dialog = false), 1000)  
            }else{             
                setTimeout(() => (this.dialog2 = false), 1000)  
            }  
        },       
        ...mapActions('home',['Instalar','Desinstalar']),
        ...mapActions('navigation',['loadRoutes'])
    },
};
</script>
