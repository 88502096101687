<template>
<div class="table-responsive pt-5">
    <table class="letra-9" style="width: 100%" id="table">
        <tr style="background-color: #6d6b6b">
            <td class="border texto-cen pa-2" style="width: 5%">
                <strong>
                    <font color="white">Proceso</font>
                </strong>
            </td>
            <td class="border texto-cen pa-2" style="width: 5%">
                <strong>
                    <font color="white">Indicador</font>
                </strong>
            </td>
            <td class="border texto-cen pa-2" style="width: 8%">
                <strong>
                    <font color="white">Marca</font>
                </strong>
            </td>
            <td class="border texto-cen pa-2" style="width: 8%">
                <strong>
                    <font color="white">Modelo (N)</font>
                </strong>
            </td>
            <td class="border texto-cen pa-2" style="width: 8%">
                <strong>
                    <font color="white">Medidas</font>
                </strong>
            </td>
            <td class="border texto-cen pa-2 pl-9 pr-9" style="width: 30%">
                <strong>
                    <font color="white">Criterio de aceptación</font>
                </strong>
            </td>
            <td class="border texto-cen pa-2" style="width: 6%" v-for="(item, index) in MesesCostoPorKilometro" :key="index">
                <strong>
                    <font color="white">{{ meses[parseInt(item) - 1] }}</font>
                </strong>
            </td>
        </tr>
        <tr v-for="(item, index) in CostoPorKilometro" :key="index">
            <td v-if="index == 0" class="border texto-cen pa-1" :rowspan="CostoPorKilometro.length">
                KPI
            </td>
            <td v-if="index == 0" class="border texto-cen pa-1" :rowspan="CostoPorKilometro.length">
                Costo x Kilómetro
            </td>
            <td class="border texto-cen pa-1">{{ item.marca }}</td>
            <td class="border texto-cen pa-1">{{ item.modelo }}</td>
            <td class="border texto-cen pa-1">{{ item.medida }}</td>
            <td class="border texto-cen letra-8">
                <div style="background-color: #1ac10c">
                    <strong>
                        <font color="white">( Objetivo < {{ item.criterio_superior }} )</font>
                    </strong>
                </div>
                <div style="background-color: #fcc109">
                    ( Tolerable [{{ item.rango_c_superior }} -
                    {{ item.rango_c_inferior }}] )
                </div>
                <div style="background-color: #f54c31">
                    <strong>
                        <font color="white">( Deficiente > {{ item.criterio_inferior }})</font>
                    </strong>
                </div>
            </td>
            <td v-for="(item2, index2) in MesesCostoPorKilometro" :key="index2" :class="pintarCostoPorKilometro(item, tdValorCosto(index, item2))">
                {{ tdValorCosto(index, item2) }}
            </td>
        </tr>
        <tr v-if="CostoPorKilometro.length == 0">
            <td :colspan="6 + MesesCostoPorKilometro.length" class="border texto-cen pa-2">
                No se registraron los Indicadores del Costo x Kilometro
            </td>
        </tr>
    </table>
</div>
</template>

<script>
import global from "../../global"
export default {
    props: ["Annio", "MesesCostoPorKilometro", "CostoPorKilometro"],
    data() {
        return {
            meses: [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre",
            ],
        };
    },
    methods: {
        exportarAexcel: function () {
            location.href =
                global.ruta_api +
                "excel/reporteindicadorcostokmhr?" +
                global._json_to_query_string({
                    id_cliente: localStorage.getItem("idcliente"),
                    id_usuario: localStorage.getItem("id"),
                    year: this.annio,
                    marca: this.item.marca,
                    unidadmedida: this.item.unidadmedida,
                });
        },

        pintarCostoPorKilometro: function (item, valor) {
            let pintar = "border texto-cen pa-1";
            if (valor) {
                if (valor >= item.rango_c_inferior && valor <= item.rango_c_superior)
                    pintar = "border texto-cen pa-1 bg-celda-amarilla";
                if (valor < item.criterio_superior)
                    pintar = "border texto-cen pa-1 bg-celda-verde";
                if (valor > item.criterio_inferior)
                    pintar = "border texto-cen pa-1 bg-celda-roja";
            }else{
                pintar = "border texto-cen pa-1 bg-celda-gris";
            }
            return pintar;
        },

        tdValorCosto: function (index, item2) {
            return this.CostoPorKilometro[index]["indicador_mes_" + item2];
        },
    },
};
</script>

<style scoped>
table {
    border-collapse: collapse;
}

.border {
    border-style: solid;
    border-width: 1px;
}

.border-bottom {
    border-bottom: solid;
    border-width: 1px;
}

.border-top {
    border-top: solid;
    border-width: 1px;
}

.border-left {
    border-left: solid;
    border-width: 1px;
}

.border-right {
    border-right: solid;
    border-width: 1px;
}

.no-border {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.no-border-left {
    border-left: none;
}

.no-border-right {
    border-right: none;
}

.texto-cen {
    text-align: center;
}

.texto-izq {
    text-align: left;
}

.texto-just {
    text-align: justify;
}

.inline {
    display: inline-block;
}

.texto-arriba {
    vertical-align: text-top;
    vertical-align: top;
}

.letra-6 {
    font-size: 6pt;
}

.letra-7 {
    font-size: 7pt;
}

.letra-8 {
    font-size: 8pt;
}

.letra-9 {
    font-size: 9pt;
}

.letra-10 {
    font-size: 10pt;
}

.letra-11 {
    font-size: 11pt;
}

.letra-12 {
    font-size: 12pt;
}

.letra-14 {
    font-size: 14pt;
}

.letra-15 {
    font-size: 15pt;
}

.letra-18 {
    font-size: 18pt;
}

.letra-25 {
    font-size: 25pt;
}

.letra-30 {
    font-size: 30pt;
}

.margin-top-20 {
    margin-top: 20px;
}

.bg-celda-azul {
    background: #91e3da;
}

.bg-celda-gris {
    background: #FDFEFE;
}

.bg-celda-amarilla {
    background: #faec5c;
    /*color:#EDDF04;*/
}

.bg-celda-verde {
    background: #a8fa6f;
}

.bg-celda-roja {
    background: #f55f50;
}

#table {
    display: block;
    /* important */
    width: 1000px;
    overflow-x: scroll;
}
</style>
