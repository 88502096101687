<template>
    <div class="pa-5">
        <v-col lg="12">
            <v-card elevation="2">
                <v-toolbar color="secondary" dark>
                    <v-spacer><span class="text-h6">Importar Archivo Excel de vehículos</span> </v-spacer>
                    <v-spacer></v-spacer>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                                <a target="_blank" href="https://helpdesk.tiresoft.pe/#importar_datos"
                                    style="text-decoration:none !important; color: white;">
                                    <v-icon dark>mdi-help-circle</v-icon>
                                </a>
                            </v-btn>
                        </template>
                        <span>¿Necesitas ayuda?</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-title> <strong>Importar Excel</strong> </v-card-title>
                <v-card-subtitle>
                    <v-timeline dense clipped>
                        <v-timeline-item fill-dot class="white--text mb-3" color="orange" large>
                            <template v-slot:icon>
                                <span>Pasos</span>
                            </template>
                            <v-text-field v-model="input" hide-details flat readonly>
                                Verificar el excel antes de subir
                                <template v-slot:append>
                                    <v-btn class="ma-2" outlined color="indigo" @click="download">
                                        Descargar plantilla del vehiculo
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-timeline-item>
                        <v-timeline-item class="mb-1" color="white--text green" icon-color="green lighten-2" medio>
                            <template v-slot:icon>
                                <span>1</span>
                            </template>
                            <v-row justify="space-between">
                                <v-col cols="12">
                                    Es obligatorio las columnas de titulos de color rojo ya que de
                                    este dependera que se relacione con la configuracion que
                                    registraras para los vehiculos
                                </v-col>
                            </v-row>
                        </v-timeline-item>

                        <v-timeline-item class="mb-1" color="white--text green" icon-color="green lighten-2" medio>
                            <template v-slot:icon>
                                <span>2</span>
                            </template>
                            <v-row justify="space-between">
                                <v-col cols="12">
                                    El sistema omitira automaticamente vehiculos que ya esten
                                    registrados
                                </v-col>
                            </v-row>
                        </v-timeline-item>
                    </v-timeline>

                    <v-card>
                        <v-toolbar color="grey" dark>VEHICULOS : {{ Item }}</v-toolbar>
                        <v-card-text>
                            <vue-dropzone v-on:vdropzone-sending="sendingEvent" :options="dropzoneOptions"
                                :include-styling="true" :useCustomSlot="true" v-on:vdropzone-success="uploadSuccess"
                                id="customdropzone">
                                <div class="dropzone-custom-content">
                                    <h3 class="dropzone-custom-title">
                                        Arrastra los archivos aquí para subirlos
                                    </h3>
                                    <v-icon> mdi-cloud-upload</v-icon>
                                </div>
                            </vue-dropzone>
                        </v-card-text>
                    </v-card>
                </v-card-subtitle>
            </v-card>
            <dialogalert :color="color" :icono="icono" :titulo="titulo" :mensaje="mensaje" :dialog="dialog"
                @dialog="dialog = $event" />
        </v-col>
    </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import dialogalert from "@/components/dialogalert.vue";
import {
    mapGetters,
    mapActions
} from "vuex";
import global from '../global';

export default {
    name: "ImportarVehiculos",
    components: {
        vueDropzone: vue2Dropzone,
        dialogalert,
    },
    created() {
        this.cargarVehiculos();
    },
    data() {
        return {
            dialog: false,
            color: "",
            icono: "",
            titulo: "",
            mensaje: "",
            events: [],
            input: "Verificar el excel antes de subir",
            nonce: 0,
            dropzoneOptions: {
                url: global.ruta_api + "importar/vehiculo",
                maxFilesize: 12,
                //headers: { "My-Awesome-Header": "header value" },
                headers: {
                    "Cache-Control": null,
                    "X-Requested-With": null,
                    "Access-Control-Allow-Origin": "*",
                },
                //previewTemplate: this.template()
            },
        };
    },
    computed: {
        timeline() {
            return this.events.slice().reverse();
        },
    },
    methods: {
        ...mapActions("nav", ["CargarMenu"]),
        ...mapActions("importarvehiculo", ["cargarVehiculos"]),
        sendingEvent(file, xhr, formData) {
            formData.append("id_cliente", localStorage.getItem("idcliente"));
            formData.append("id_usuario", localStorage.getItem("id"));
        },

        uploadSuccess: function (file, response) {
            var cadenas = response.split("&&&&");
            if (cadenas.length > 1) {
                var respuesta = cadenas[1].split("<br>");
                (this.color = "error"),
                    (this.icono = "mdi-cloud-alert"),
                    (this.titulo = "Error al Cargar Datos"),
                    (this.mensaje = respuesta);
            } else {
                var respuesta = response.split("<br>");
                (this.color = "success"),
                    (this.icono = "mdi-checkbox-marked-circle"),
                    (this.titulo = "Excel cargado Exitosamente"),
                    (this.mensaje = respuesta);
            }
            this.dialog = true;
            this.cargarVehiculos();
        },
        download() {
            const url = "/downloads/vehiculos.xls";
            window.location.href = url;
        },
    },
    computed: {
        ...mapGetters("importarvehiculo", ["Item"]),
    },
};
</script>

<style>
#customdropzone .dz-preview .dz-image>div {
    width: inherit;
    height: inherit;
    border-radius: 20%;
    background-size: contain;
}

#customdropzone .dz-preview {
    width: 130px;
    height: 150px;
    display: inline-block;
}

#customdropzone .dz-preview .dz-details {
    background-color: #00897b;
    border-radius: 10%;
}

/* #customdropzone {
    background-color: #FF7043;
    font-family: 'Arial', sans-serif;
    letter-spacing: 0.2px;
    color: whitesmoke;
    transition: background-color .2s linear;
    height: 200px;
    padding: 40px;
  }

  #customdropzone .dz-preview {
    width: 160px;
    display: inline-block
  }
  #customdropzone .dz-preview .dz-image {
    width: 80px;
    height: 80px;
    margin-left: 40px;
    margin-bottom: 10px;
  }

  #customdropzone .dz-preview .dz-image > img {
    width: 100%;
  }

    */
</style>
