<template>
    <div v-if="Item!=null">
        <v-row justify="center">
            <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            >
            <v-card>
                <v-card-title>
                    <span v-if="Item.id == 0" class="text-h5">Nuevo Modúlo</span>
                    <span v-else class="text-h5">Editar Modúlo</span>
                    <v-spacer></v-spacer>
                    <span v-if="Item.id > 0" >ID: {{Item.id}}</span>
                </v-card-title>
                <v-card-text>
                    <v-container> 
                        <v-form enctype="multipart/form-data" ref="form">
                            <v-row>
                                <v-col cols="12" sm="12" md="6" >
                                    <v-text-field label="Nombre del Modulo *" hide-details="auto" :rules="descripcionRules" small v-model="Item.menu" required ></v-text-field>
                                </v-col>
                            <v-col cols="12" sm="12" md="6" required >
                                <v-select v-model="Item.id_cliente"  item-value="id" item-text="name" :items="tipo_menu" :rules="descripcionRules" small :disabled="Item.id_menu!=0" label="Seleccionar Menu*" ></v-select>
                            </v-col>
                            <v-col v-if="Item.id_menu==0" cols="12" sm="12" md="6" > 
                                <v-text-field label="Icono *" hide-details="auto" :rules="descripcionRules" small v-model="Item.icon" required :append-icon="Item.icon" ></v-text-field> 
                            </v-col> 
                            <v-col v-if="Item.id == 0"  cols="12" sm="12" md="6" >
                                <v-checkbox  v-model="checkbox"  small :value=" Item.menu_submenu && !Item.menu_submenu.length>0"  label="El modulo posee acciones" ></v-checkbox>
                            </v-col>
                            <v-col v-if="!Item.menu_submenu.length>0 && checkbox" cols="12" sm="12" md="12" >
                                <v-select :rules="descripcionRulesarray" small v-model="Item.acciones_"  item-value="id" item-text="name" :items="actionHeaders" label="Seleccionar las acciones *" multiple chips persistent-hint>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @mousedown.prevent @click="toggle" >
                                            <v-list-item-action>
                                                <v-icon :color="Item.acciones_.length === actionHeaders.length ? 'indigo darken-4' : ''">
                                                    {{ icon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Seleccionar Todo
                                                </v-list-item-title>
                                            </v-list-item-content>
                                             
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>  
                                    <template v-slot:append-outer>
                                        <v-icon color="success" @click="openaddaction">mdi-plus-circle</v-icon>
                                    </template>
                                    <!-- <append-outer-icon color="success" @click="openaddaction" v-text="'mdi-plus-circle'"></append-outer-icon>  -->
                                </v-select> 
                            </v-col>
                            <v-col v-if="!Item.menu_submenu.length>0 && checkbox" cols="12" sm="12" md="12" > 
                                <v-text-field label="Direccion *" hide-details="auto" :rules="descripcionRules" small v-model="Item.path" required ></v-text-field> 
                            </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                    <small>*indica los campos requeridos</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="cerrarDialogo()" > Cerrar </v-btn> 
                    <v-btn color="info" @click="agregar()" > Guardar </v-btn> 
                </v-card-actions>
            </v-card>
            </v-dialog>
            
            <v-dialog v-model="dialogAccion" max-width="300px">
                <v-card>
                    <v-card-text>
                        <v-container>
                            <v-form ref="formAccion">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field label="Nombre de la accion *" required v-model="descripcion" :rules="descripcionRules"></v-text-field>
                                    </v-col>
                                    <small v-if="mostrarLabelDuplicado">
                                        <font color="red">Accion duplicada</font>
                                    </small>
                                </v-row>
                            </v-form>
                        </v-container>
                        <small v-if="mostrarLabelDuplicado == false">*Indicador de campo requerido</small>
                        <v-spacer></v-spacer>
                        <v-btn   class="mt-4" style="width: 100%" small color="#009688" @click="addAccion()">
                            <font color="white">Agregar</font>
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import {axios2 as axios} from "@/interceptor/useApi";
export default {
    props:['dialog','Item','actionHeaders','tipo_menu','addoreditmodulo'],
    methods:{ 

        fetchActions() {
            this.$emit('request-actions');
        },
        addAccion(){
            // aqui se agrega api 
            try {
                if (this.$refs.formAccion.validate()) {
                const url = "add/accionesmenu";
                let datos = {
                    descripcion: this.descripcion, 
                };
                axios.post(url, datos)
                    .then((response) => {
                        console.log(response.data.success.resultado);
                        this.mostrarLabelDuplicado=response.data.success.resultado;
                        if(this.mostrarLabelDuplicado==false){
                            this.$alertify.success("Agregado");
                            this.descripcion='';
                            this.$refs.formAccion.reset();
                            this.fetchActions();
                            this.dialogAccion=false;
                        }
                        // this.cerrarDialogo();
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        openaddaction(){
            this.dialogAccion=true;
        },
        agregar(){
            if (this.$refs.form.validate()) {
                if (this.Item.id==0) {
                    this.addoreditmodulo(0,this.Item); 
                }else{
                    this.addoreditmodulo(1,this.Item); 
                }
            }
        },
        cerrarDialogo() {  
            this.$refs.form.resetValidation(); // Resetea la validación
            // Asegúrate de limpiar cualquier mensaje de error adicional
            this.$emit('dialog', false); // Cierra el diálogo
        },
        toggle() {
            const allIds = this.actionHeaders.map(action => action.id); // Obtener todos los IDs

            // Si todos los IDs ya están seleccionados, deseleccionar
            if (this.Item.acciones_.length === allIds.length) {
                this.Item.acciones_ = []; // Deseleccionar todo
            } else {
                this.Item.acciones_ = allIds; // Seleccionar todo
            }
        },
    }, 
    watch:{
        dialogAccion() {
            this.descripcion='';
        }
    },
    computed: { 
      likesallactions () {
        return this.Item.acciones_.length === this.actionHeaders.length
      },
      likessomeactions () {
        return this.Item.acciones_.length > 0 && !this.likesallactions
      },
      icon () {
        if (this.likesallactions) return 'mdi-close-box'
        if (this.likessomeactions) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
    },
    data() {
        return {
            checkbox:true, 
            descripcionRules: [(v) => !!v || "Campo requerido"],
            descripcionRulesarray: [ (v) => Array.isArray(v) ? v.length > 0 || "Campo requerido" : "Campo requerido" ],
            checkbox2:true,
            dialogAccion:false, 
            descripcion:'', 
            mostrarLabelDuplicado:false,
        };
    },
    mounted(){ 
        // if (this.dialog==true) { 
        //     this.$refs.form.resetValidation(); // Resetea la validación
        // }
    }
}
</script>
