import Vue from 'vue'
import Vuex from 'vuex'
import {axios2 as axios} from "@/interceptor/useApi";

Vue.use(Vuex)

export default{
  namespaced:true,
  state: {
    Items : [],
    Loading:true
  },
  getters: {
    Items(state){
      return state.Items
    }
  },
  mutations: {
    LlenarItems(state,data){
      state.Items = data
    },
    LlenarLoading(state,data){
      state.Loading = data
    }
  },
  actions: {
    cargarAplicacion:async function({commit}){
      try {
        const  url = "aplicacion"
        const data = {id_cliente: localStorage.getItem("idcliente")}; 
        await axios.post(url,data)
        .then(response => {
          commit('LlenarItems',response.data.success.resultado.aplicacion);
          commit('LlenarLoading',false);       
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
        }); 
      } catch (error) {
        console.log(error)
      }
    },    
    agregarAplicacion: async function({commit,dispatch},datos){
      var  url = "aplicacion/store"
      datos.id_cliente = localStorage.getItem("idcliente");   
      await axios.post(url,datos)
      .then(response => {
        dispatch('cargarAplicacion')     
      })
      .catch(error => {
        this.errorMessage = error.response.data;
        console.error("error!", error);
      }); 
    },    
  }

}


