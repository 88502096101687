<template>
    <div class="pa-5">
        <v-col lg="12">
            <v-card class="pa-5">
                <v-card-title>
                    Reporte de última conexión
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col lg="3"></v-col>
                        <v-col lg="6">
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                                hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table dense :headers="LabelsUltimaActividad" :items="DatosUltimaActividad"
                        :items-per-page="10" :search="search" class="elevation-1 mt-5" :footer-props="{
                            'items-per-page-text': 'Resultados por página:',
                            'page-text': '{0}-{1} de {2}'
                        }" loading-text="Cargando... Por favor, espere" no-data-text="No hay datos disponibles"
                        :loading="CargarDatosUltimaActividad">
                    </v-data-table>
                </v-card-text>
            </v-card>

            <v-card class="pa-5 mt-5">
                <v-card-title>
                    Gráfico tiempo de sesión de usuarios
                </v-card-title>
                <v-card-text>
                    <v-form ref="formsesion" v-model="validsesion">
                        <v-row>
                            <v-col cols="12" md="4" sm="4">
                                <v-select class="mt-5" v-model="item.id_cliente" :items="clientes"
                                    label="Seleccione cliente" dense></v-select>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <v-text-field type="date" label="Fecha Inicial *" v-model="item.fecha_inicial"
                                    hide-details="auto"
                                    :rules="[(v) => !!v || 'La fecha inicial es requerida']"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <v-text-field type="date" label="Fecha Final *" v-model="item.fecha_final"
                                    hide-details="auto"
                                    :rules="[(v) => !!v || 'La fecha final es requerida']"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="info" small :loading="loadingexportar" @click="onExportarResumen('sesiones')"
                        v-if="vergraficotiemposesion == true">
                        Exportar
                    </v-btn>
                    <v-btn color="info" small :loading="loadingexportar" @click="onExportarDetalle('sesiones')"
                        v-if="vergraficotiemposesion == true">
                        Exportar detalle
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" small :loading="loadingbuscarsesiones" @click="onFiltrarSesiones">
                        Buscar
                    </v-btn>
                </v-card-actions>
                <v-card-text>
                    <v-row>
                        <GraficoBarTiempo Title="Horas de sesión" Labelsdataset="Tiempo de sesión en horas"
                            Labelscale_x="Tiempo" Labelscale_y="Usuarios" IdGrafico="graficotiemposesion"
                            :Labels="Labels" :DatosGrafico="DatosTiempoSesion" v-if="vergraficotiemposesion == true">
                        </GraficoBarTiempo>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-5 pa-5">
                <v-card-title>
                    Gráfico de descargas y búsquedas de reporte consolidado
                </v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-row>
                            <v-col cols="12" md="4" sm="4">
                                <v-autocomplete class="mt-5" v-model="reporte.id_cliente" :items="clientes"
                                    label="Seleccione cliente" dense></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <v-select class="mt-5" v-model="reporte.anio" :items="anios" label="Seleccione año"
                                    dense></v-select>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <v-select class="mt-5" v-model="reporte.mes" :items="meses" label="Seleccione mes" dense
                                    :disabled="reporte.anio == ''"></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="info" small :loading="loadingexportar" @click="onExportarResumen('descargas')"
                        v-if="vergraficoconsolidado == true">
                        Exportar
                    </v-btn>
                    <v-btn color="info" small :loading="loadingexportar" @click="onExportarDetalle('descargas')"
                        v-if="vergraficoconsolidado == true">
                        Exportar detalle
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" small :loading="loadingreporteconsolidado" @click="onFiltrarDescargas">
                        Buscar
                    </v-btn>
                </v-card-actions>

                <v-card-text>
                    <v-row>
                        <GraficoBar Title="" Labelsdataset="Cantidad de descargas" Labelscale_x="descargas"
                            Labelscale_y="Usuarios" IdGrafico="graficocantidaddescargas" :Labels="LabelsReporte"
                            :DatosGrafico="DatosDescargasReporte" Orientacion="y"
                            v-if="vergraficoconsolidado == true" />
                    </v-row>
                    <v-row>
                        <GraficoBar Title="" Labelsdataset="Cantidad de consultas" Labelscale_x="consultas"
                            Labelscale_y="Usuarios" IdGrafico="graficocantidadbusquedas" :Labels="LabelsBusquedaReporte"
                            :DatosGrafico="DatosBusquedaReporte" Orientacion="y" v-if="vergraficoconsolidado == true" />
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-5 pa-5">
                <v-card-title>
                    Gráficas de productividad
                </v-card-title>
                <v-card-text>
                    <v-form ref="formproductividad" v-model="validproductividad">
                        <v-row>
                            <v-col cols="12" md="4" sm="4">
                                <v-autocomplete class="mt-5" v-model="productividad.id_cliente" :items="clientes"
                                    label="Seleccione cliente *" dense
                                    :rules="[(v) => !!v || 'Seleccione un cliente']"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <v-text-field type="date" label="Fecha Inicial *" v-model="productividad.fecha_inicial"
                                    hide-details="auto"
                                    :rules="[(v) => !!v || 'La fecha inicial es requerida']"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <v-text-field type="date" label="Fecha Final *" v-model="productividad.fecha_final"
                                    hide-details="auto"
                                    :rules="[(v) => !!v || 'La fecha final es requerida']"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="info" small :loading="loadingexportar" @click="onExportarResumen('productividad')"
                        v-if="vergraficoproductividad == true">
                        Exportar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" small :loading="loadingbuscarproductividad" @click="onFiltrarProductividad">
                        Buscar
                    </v-btn>
                </v-card-actions>
                <v-card-text>
                    <v-row>
                        <GraficoBarComparativo Title="Operaciones por usuario" Labelscale_x="Operaciones"
                            Labelscale_y="Usuarios" IdGrafico="graficooperaciones" :Labels="LabelsOperacionesUsuario"
                            :Datasets="DatosOperacionesUsuario" Orientacion="y" v-if="vergraficoproductividad == true">
                        </GraficoBarComparativo>
                        <v-col lg="6">
                            <GraficoPie Title="Operaciones por usuarios (labelsol)"
                                IdGrafico="graficooperacionestotales" :Labels="LabelsOperacionesTotales"
                                :DatosGrafico="DatosOperacionesTotales" v-if="vergraficoproductividad == true" />
                        </v-col>
                        <v-col lg="6">
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-5 pa-5">
                <v-card-title>
                    Gráficas de operaciones por día
                </v-card-title>
                <v-card-text>
                    <v-form ref="formoperacionesxfecha" v-model="validoperacionesxfecha">
                        <v-row>
                            <v-col cols="12" md="4" sm="4">
                                <v-autocomplete class="mt-5" v-model="operacionfecha.id_cliente" :items="clientes"
                                    label="Seleccione cliente *" dense
                                    :rules="[(v) => !!v || 'Seleccione un cliente']"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <v-text-field type="date" label="Fecha Inicial *" v-model="operacionfecha.fecha_inicial"
                                    hide-details="auto"
                                    :rules="[(v) => !!v || 'La fecha inicial es requerida']"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <v-text-field type="date" label="Fecha Final *" v-model="operacionfecha.fecha_final"
                                    hide-details="auto"
                                    :rules="[(v) => !!v || 'La fecha final es requerida']"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <v-autocomplete class="mt-5" v-model="operacionfecha.id_usuario" :items="usuarios"
                                    label="Seleccione usuario *" dense></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="info" small :loading="loadingexportar" @click="onExportarOperacion('neumaticos')"
                        v-if="vergraficooperaciones == true">
                        Exportar (Neumáticos)
                    </v-btn>
                    <v-btn color="info" small :loading="loadingexportar" @click="onExportarOperacion('vehiculos')"
                        v-if="vergraficooperaciones == true">
                        Exportar (Vehículos)
                    </v-btn>
                    <v-btn color="info" small :loading="loadingexportar" @click="onExportarOperacion('inspecciones')"
                        v-if="vergraficooperaciones == true">
                        Exportar (Inspecciones)
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" small :loading="loadingbuscaroperacionesxfecha"
                        @click="onFiltrarOperacionesxFecha">
                        Buscar
                    </v-btn>
                </v-card-actions>
                <v-card-text>
                    <v-row>
                        <GraficoLine title="Gráfica de operaciones por día" :Labels="LabelsOperacionesxFecha"
                            :DatosGrafico="DataOperacionesPorFecha" Labelscale_x="Fechas"
                            Labelscale_y="Cantidad operaciones" v-if="vergraficooperaciones == true" />
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </div>
</template>

<script>
import {
    mapState,
    mapActions
} from 'vuex';
import GraficoBarTiempo from '@/components/GraficoBarTiempo.vue';
import GraficoBar from '@/components/GraficoBar.vue';
import GraficoPie from '@/components/GraficoPie.vue';
import GraficoBarComparativo from '@/components/GraficoBarComparativo.vue';
import GraficoLine from '@/components/GraficoLine.vue';
import global from '@/global';

export default {
    components: {
        GraficoPie,
        GraficoBar,
        GraficoLine,
        GraficoBarTiempo,
        GraficoBarComparativo
    },
    data() {
        return {
            search: "",
            validsesion: false,
            validproductividad: false,
            validproductividadusuario: false,
            validoperacionesxfecha: false,
            item: {
                fecha_inicial: '',
                fecha_final: '',
                id_cliente: '',
                reporte: ''
            },
            reporte: {
                id_cliente: '',
                anio: '',
                mes: '',
                reporte: ''
            },
            productividad: {
                fecha_inicial: '',
                fecha_final: '',
                id_cliente: '',
                reporte: ''
            },
            operacionfecha: {
                fecha_inicial: '',
                fecha_final: '',
                id_cliente: '',
                id_usuario: '',
                reporte: ''
            },
            background: [
                "#03F1FC",
                "#FC7C03",
                "rgb(255, 205, 86)",
                "#05F98E",
                "#FFC300",
                "#0FF9B6",
                "#B6CBD1",
                "#1EA607",
                "#F90F36",
                "#FED47F",
                "#0CB8EB"
            ],
            usuarios: [],
            clientes: [],
            loadingbuscarsesiones: false,
            loadingreporteconsolidado: false,
            loadingexportar: false,
            loadingbuscarproductividad: false,
            loadingbuscaroperacionesxfecha: false,
            vergraficotiemposesion: false,
            vergraficoconsolidado: false,
            vergraficoproductividad: false,
            vergraficooperaciones: false,
            Labels: [],
            LabelsReporte: [],
            LabelsUltimaActividad: [{
                text: "Nombre",
                value: "nombre",
                align: "left",
                class: "grey darken-1 dark white--text"
            },
            {
                text: "Fecha de ingreso",
                value: "fecha_ingreso",
                align: "left",
                class: "grey darken-1 dark white--text"
            },
            {
                text: "Hace",
                value: "tiempo",
                align: "left",
                class: "grey darken-1 dark white--text"
            },
            ],
            anios: [],
            meses: [{
                value: 1,
                text: "Enero"
            },
            {
                value: 2,
                text: "Febrero"
            },
            {
                value: 3,
                text: "Marzo"
            },
            {
                value: 4,
                text: "Abril"
            },
            {
                value: 5,
                text: "Mayo"
            },
            {
                value: 6,
                text: "Junio"
            },
            {
                value: 7,
                text: "Julio"
            },
            {
                value: 8,
                text: "Agosto"
            },
            {
                value: 9,
                text: "Septiembre"
            },
            {
                value: 10,
                text: "Octubre"
            },
            {
                value: 11,
                text: "Noviembre"
            },
            {
                value: 12,
                text: "Diciembre"
            }
            ],
            LabelsOperacionesTotales: [],
            LabelsOperacionesUsuario: [],
            LabelsOperacionesxFecha: [],
            LabelsBusquedaReporte: [],
            DatosTiempoSesion: [],
            DatosDescargasReporte: [],
            DatosBusquedaReporte: [],
            DatosUltimaActividad: [],
            DatosOperacionesTotales: [],
            DatosOperacionesUsuario: [],
            DataOperacionesPorFecha: [],
            operaciones: [
                "Neumáticos",
                "Vehículos",
                "Operaciones"
            ]
        }
    },
    computed: {
        ...mapState("cliente", {clienteItems: "Items"}),
        ...mapState("usuario", {usuarioItems: "Items"}),
        ...mapState("reportes", ["CargarDatosUltimaActividad"]),

    },
    methods: {
        ...mapActions("cliente", ["cargarClientes"]),
        ...mapActions("usuario", ["cargarUsuarioMinified"]),
        ...mapActions("reportes", ["cargartiemposesionusuarios", "cargardescargasconsolidado", "cargarbusquedasconsolidado", "cargarultimaactividad", "cargargraficasproductividad", "cargargraficasoperacionesxfecha"]),

        cargararrayclientes() {
            this.clientes = [];
            this.clienteItems.forEach(element => {
                this.clientes.push({
                    value: element.id,
                    text: element.razon_social
                });
            });
        },

        cargararrayusuarios() {
            this.usuarios = [];
            this.usuarioItems.forEach(element => {
                this.usuarios.push({
                    value: element.id,
                    text: element.name
                });
            });
        },

        async onFiltrarSesiones() {
            if (this.$refs.formsesion.validate()) {
                this.loadingbuscarsesiones = true;
                this.vergraficotiemposesion = false;
                this.Labels = [];
                this.DatosTiempoSesion = [];

                let array = await this.cargartiemposesionusuarios(this.item);
                array.forEach(element => {
                    this.Labels.push(element.usuario);
                    this.DatosTiempoSesion.push(element.tiempo);
                });
                this.vergraficotiemposesion = true;
                this.loadingbuscarsesiones = false;
            }
        },

        async onFiltrarDescargas() {
            this.LabelsReporte = [];
            this.DatosDescargasReporte = [];
            this.LabelsBusquedaReporte = [];
            this.DatosBusquedaReporte = [];
            this.vergraficoconsolidado = false;
            this.loadingreporteconsolidado = true;
            let array1 = await this.cargardescargasconsolidado(this.reporte);
            let array2 = await this.cargarbusquedasconsolidado(this.reporte);
            array1.forEach(element => {
                this.LabelsReporte.push(element.usuario);
                this.DatosDescargasReporte.push(element.total);
            });
            array2.forEach(element => {
                this.LabelsBusquedaReporte.push(element.usuario);
                this.DatosBusquedaReporte.push(element.total);
            });
            this.loadingreporteconsolidado = false;
            this.vergraficoconsolidado = true;
        },

        onFiltrarProductividad() {
            if (this.$refs.formproductividad.validate()) {
                this.loadingbuscarproductividad = true;
                this.CargarGraficaProductividad();
            }
        },

        async onFiltrarOperacionesxFecha() {
            this.vergraficooperaciones = false;
            if (this.$refs.formoperacionesxfecha.validate()) {
                this.loadingbuscaroperacionesxfecha = true;
                let array = await this.cargargraficasoperacionesxfecha(this.operacionfecha);
                this.LabelsOperacionesxFecha = array.fechas;
                this.DataOperacionesPorFecha = [{
                    label: "Operaciones (neumáticos)",
                    data: array.data_neumaticos,
                    borderColor: "rgba(0, 188, 212, 1)",
                    backgroundColor: "rgba(0, 188, 212, 0.1)",
                    pointBorderColor: "rgba(0, 188, 212, 0)",
                    pointBackgroundColor: "rgba(0, 188, 212)",
                    //pointBorderWidth: 1,
                    cubicInterpolationMode: "monotone",
                    //fill: true,
                },
                {
                    label: "Operaciones (vehículos)",
                    data: array.data_vehiculos,
                    borderColor: "rgba(252, 124, 3, 1)",
                    backgroundColor: "rgba(252, 124, 1, 0.1)",
                    pointBorderColor: "rgba(252, 124, 3, 0)",
                    pointBackgroundColor: "rgba(252, 124, 3)",
                    cubicInterpolationMode: "monotone",
                    //fill: true,
                },
                {
                    label: "Operaciones (inspecciones)",
                    data: array.data_inspecciones,
                    borderColor: "rgba(251, 207, 105, 1)",
                    backgroundColor: "rgba(251, 207, 105, 0.5)",
                    pointBorderColor: "rgba(251, 207, 105, 0)",
                    pointBackgroundColor: "rgba(251, 207, 105)",
                    cubicInterpolationMode: "monotone",
                    //fill: true,
                },
                ];
                this.loadingbuscaroperacionesxfecha = false;
                this.vergraficooperaciones = true;
            }
        },

        async CargarGraficaProductividad() {
            this.vergraficoproductividad = false;
            let array = await this.cargargraficasproductividad(this.productividad);
            let arraycito = [];
            //Grafico de barras

            array.operaciones_x_usuario.forEach((item, index) => {
                let cantidad = [];
                array.usuariosoperaciones.forEach(nombre => {
                    let pos = -1;
                    item.operaciones.forEach((item1, index1) => {
                        if (nombre == item1.nombre) {
                            pos = index1;
                        }
                    });
                    if (pos != -1) {
                        cantidad.push(item.operaciones[pos]["total"]);
                    } else {
                        cantidad.push(0);
                    }
                });

                arraycito.push({
                    label: item.descripcion,
                    data: cantidad,
                    backgroundColor: this.background[index],
                    borderColor: this.background[index],
                    hoverOffset: 4,
                    stack: 'Stack 0'
                });
            });

            this.DatosOperacionesUsuario = arraycito;
            this.LabelsOperacionesUsuario = array.usuariosoperaciones;

            //Grafico de pie
            this.LabelsOperacionesTotales = [];
            this.DatosOperacionesTotales = [];
            array.operaciones_totales.forEach(element => {
                this.LabelsOperacionesTotales.push(element.nombre + " (" + element.porcentaje + "%) ");
                this.DatosOperacionesTotales.push(element.total);
            });
            this.loadingbuscarproductividad = false;
            this.vergraficoproductividad = true;
        },

        CargarAnios() {
            let fecha = new Date();
            let anioactual = fecha.getFullYear()

            for (let index = 2018; index <= anioactual; index++) {
                this.anios.push(index);
            }
        },

        onExportarResumen(detalle = "") {
            if (detalle == "sesiones") {
                this.item.reporte = "";
                global._exportar_a_excel("excel/reportetiemposesionusuario", "POST", this.item);
            } else if (detalle == "descargas") {
                this.reporte.reporte = "";
                global._exportar_a_excel("excel/reportedescargasconsolidado", "POST", this.reporte);
            } else if (detalle == "productividad") {
                this.productividad.reporte = "";
                global._exportar_a_excel("excel/reporteproductividad", "POST", this.productividad);
            }
        },

        onExportarDetalle(detalle = "") {
            if (detalle == "sesiones") {
                this.item.reporte = "detalles";
                global._exportar_a_excel("excel/reportetiemposesionusuario", "POST", this.item);
            } else if (detalle == "descargas") {
                this.reporte.reporte = "detalles";
                global._exportar_a_excel("excel/reportedescargasconsolidado", "POST", this.reporte);
            }
        },

        onExportarOperacion(detalle = "") {
            if (detalle == "neumaticos") {
                this.operacionfecha.reporte = "neumaticos";
                global._exportar_a_excel("excel/reporteoperacionesxfecha", "POST", this.operacionfecha);
            } else if (detalle == "vehiculos") {
                this.operacionfecha.reporte = "vehiculos";
                global._exportar_a_excel("excel/reporteoperacionesxfecha", "POST", this.operacionfecha);
            } else if (detalle == "inspecciones") {
                this.operacionfecha.reporte = "inspecciones";
                global._exportar_a_excel("excel/reporteoperacionesxfecha", "POST", this.operacionfecha);
            }
        },

        inicializarfechas() {
            var moment = require("moment");
            let fecha = moment(new Date());
            this.item.fecha_inicial = fecha.format("YYYY-MM-DD");
            this.item.fecha_final = fecha.format("YYYY-MM-DD");
            this.productividad.fecha_inicial = fecha.format("YYYY-MM-DD");
            this.productividad.fecha_final = fecha.format("YYYY-MM-DD");
            this.operacionfecha.fecha_inicial = fecha.format("YYYY-MM-DD");
            this.operacionfecha.fecha_final = fecha.format("YYYY-MM-DD");
        },
    },
    async mounted() {
        this.CargarAnios();
        this.inicializarfechas();
        this.DatosUltimaActividad = await this.cargarultimaactividad();
        await this.cargarClientes();
        this.cargararrayclientes();
        await this.cargarUsuarioMinified();
        this.cargararrayusuarios();
    }
}
</script>
