<template>
  <div>
    <h1 class="text-center mt-5">Imagenes</h1>
    <imagen :items="Items"/>
  </div>
</template>
<script>
import imagen from "@/components/Imagenes.vue"
import { mapGetters, mapActions } from "vuex";
export default {
    name: "Imagenes",
    components : {
      imagen
    },

    created(){
      this.cargarImagenes();
    },
    data(){
      return{
      }
    },
    methods:{
      ...mapActions('imagenes',['cargarImagenes'])
    },

    computed:{
      ...mapGetters('imagenes',['Items'])
    }


};
</script>
