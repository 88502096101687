import Vue from 'vue'
import Vuex from 'vuex'
import {axios2 as axios} from "@/interceptor/useApi";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        TiposVehiculo: [],
        Configuraciones: [],
        Posiciones: []
    },
    mutations: {
        LlenarTiposVehiculo(state, payload){
            state.TiposVehiculo = payload
        },
        LlenarConfiguraciones(state, payload){
            state.Configuraciones = payload
        },
        LlenarPosiciones(state, payload){
            state.Posiciones = payload
        }
    },
    actions: {
        cargarDatosAdicionales: async function ({ commit }) {
            try {
                const url = "core/configuraciones";
                await axios
                    .post(url)
                    .then((response) => {
                        let tiposvehiculos = [];
                        response.data.success.tiposvehiculo.forEach((element, i) => {
                            tiposvehiculos[i] = {
                                value: element.id,
                                text: element.nomtipo
                              };
                        });
                        let configuraciones = [];
                        response.data.success.configuracionesxvehiculo.forEach((element, i) => {
                            configuraciones[i] = {
                                value: element.p_id,
                                text: element.p_nombre + '/' + element.p_nroneumaticos + '/' + element.p_tipo_configuracion,
                                id_tipovehiculo: element.p_id_vehiculotipo
                            };
                        });
                        commit("LlenarTiposVehiculo", tiposvehiculos);
                        commit("LlenarConfiguraciones", configuraciones);
                    })
                    .catch((error) => {
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        cargarPosiciones: async function ({ commit }, id) {
            try {
                const url = "core/posiciones";
                let data = {
                    id_configuracion: id
                }
                await axios
                    .post(url, data)
                    .then((response) => { 
                        commit("LlenarPosiciones", response.data.success.posiciones);
                    })
                    .catch((error) => {
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },
    }
}


