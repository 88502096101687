<template>
    <div>
        <v-container>
            <v-btn @click="CargarCampos1" color="primary" class="ma-2">Campos 1</v-btn>
            <v-btn @click="CargarCampos2" color="success" class="ma-2">Campos 2</v-btn>
            <v-btn @click="campos=[]" color="red" dark class="ma-2">Borrar</v-btn>
            <FormularioDinamico :campos="campos"/>
        </v-container>
    </div>
</template>
<script>
import FormularioDinamico from '@/components/formularioDinamico.vue'
//import FormalarioDinamico from formularioDinamicoVue
export default {
    components:{
        FormularioDinamico
    },
    data(){
        return{
            campos : []
        }
    },
    methods: {
        CargarCampos1(){
            this.campos = [
                {
                    etiqueta: 'v-text-field',
                    label: 'esto es una cada de texto',
                    valor: 'Vuejs',
                    type: 'text',
                },
                {
                    etiqueta: 'v-switch',
                    label: 'esto es un switch',
                    valor: 'true',
                    type: '',
                },
                {
                    etiqueta: 'v-select',
                    label: 'Lenguajes de Programación',
                    items: ['JavaScript','TypeScript','JAVA'],
                    valor: 'JavaScript',
                    type: 'text',
                },
                {
                    etiqueta: 'v-text-field',
                    label: 'Email',
                    valor: 'admin@admin.com',
                    type: 'email',
                }
            ]
        },
        
        CargarCampos2(){
            this.campos = [
                {
                    etiqueta: 'v-switch',
                    label: 'esto es un switch 2',
                    valor: 'false',
                },
                {
                    etiqueta: 'v-select',
                    label: 'Frutas',
                    items: ['Mangos','Fresas','Manzanas','Mandarinas'],
                    valor: 'Mangos',
                },
                {
                    etiqueta: 'v-text-field',
                    label: 'Nombre',
                    valor: 'Elias',
                    type: 'text',
                },
                {
                    etiqueta: 'v-text-field',
                    label: 'Apellido',
                    valor: 'Merlo',
                    type: 'text',
                },
                {
                    etiqueta: 'v-textarea',
                    label: 'Descripcion',
                    valor: 'El Leñador se puso a trabajar de inmediato, y su hacha estaba tan afilada que el árbol pronto se cortó casi por completo.',

                }
            ]
        }
    }
}
</script>
<style>
    body{
        margin:20px
    }
    /* Nota Sombras => box-shadow : rigth   bottom   resplandor  rgba  */
    .form{
        box-shadow: 0 1px 8px rgba(42, 181, 240, 0.3);
        background-color: white;
        width: 500px;
        padding: 10px;
        border-radius: 8px;

    }

</style>