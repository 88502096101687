<template>
<v-layout justify-center align-center fill-height style="
      background-image: url(/images/background/fondo.jpg);
      width: 100% !important;
      min-height: 100vh;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;
      z-index: 1;
      margin: 0 !important;
    ">
    <v-container fluid fill-height text-center>
        <v-layout align-center justify-center pa-10>
            <v-flex xs12 sm8 md4>
                <v-card class="elevation-12 rounded-xl pa-4">
                    <img style="width: 170px" src="/images/background/logo2.png" />
                    <v-card-text>
                        <v-form ref="form" lazy-validation @submit.prevent="restablecerpassword()">
                            <v-text-field v-model="passwordTemporal" prepend-icon="mdi-lock" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Ingrese contraseña temporal" hint="Al menos 6 caracteres" counter @click:append="show1 = !show1"></v-text-field>

                            <v-text-field v-model="password" prepend-icon="mdi-lock" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Ingrese una nueva contraseña" hint="Al menos 6 caracteres" counter @click:append="show1 = !show1"></v-text-field>

                            <v-text-field prepend-icon="mdi-lock" v-model="confirmPassword" name="confirmPassword" label="Confirm Password" type="password" placeholder="Confirmar Contraseña" required></v-text-field>
                            <v-btn :loading="loading" :disabled="!valid" dark type="submit" class="mb-5" color="error" value="log in" submit>
                                Cambiar
                            </v-btn>
                        </v-form>
                        <div class="red--text text--lighten-1 text-body-2 mb-4">
                            {{ errorMessage }}
                        </div>
                    </v-card-text>
                    <MensajeTransicion color="success" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</v-layout>
</template>

<script>
import router from '@/router';
import MensajeTransicion from "../components/Alerta.vue"
import {axios2 as axios} from "@/interceptor/useApi";

export default {
    name: "App",
    components: {
        MensajeTransicion
    },
    data() {
        return {
            loading: false,
            valid: true,
            password: "",
            passwordTemporal: "",
            confirmPassword: "",
            valid: true,
            show1: false,
            rules: {
                required: (v) => !!v || "Requerido.",
                min: (v) => v.length >= 6 || "Min 6 characters",
            },
            errorMessage: "",
            mensaje: "",
            verAlerta: false
        };
    },
    created() {
        localStorage.clear();
        localStorage.clear();
    },
    methods: {
        async restablecerpassword() {
            if (this.password == this.confirmPassword) {
                if (this.$refs.form.validate()) {
                    const url = "/usuarios/restorepassword"
                    try {
                        const data = {
                            passwordTemporal: this.passwordTemporal,
                            password: this.password
                        };
                        await axios.post(url, data)
                            .then(response => {
                                this.mensaje = "Contraseña restablecida con éxito"
                                this.verAlerta = true;
                                setTimeout(() => {
                                    this.verAlerta = false,
                                    router.push("/login");
                                }, 4000);
                                
                            })
                            .catch(error => {
                                this.errorMessage = error.response.data.error;
                                console.error("error!", error);
                            });
                    } catch (error) {
                        console.log(error)
                    }
                }
            } else {
                this.errorMessage = "Las contraseñas no coinciden";
            }
        },
    }
};
</script>
