import axios, { AxiosInstance } from 'axios'
import global from "@/global";

let api = AxiosInstance

export function createApi() {
  // Here we set the base URL for all requests made to the api
  api = axios.create({
    baseURL: global.ruta_api,
  })

  // We set an interceptor for each request to
  // include Bearer token to the request if user is logged in
  api.interceptors.request.use((config) => {
    let token = localStorage.getItem('token');

    if (localStorage.getItem('logueo')) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      }
    }

    return config
  })
  return api
}

const useApi = () => {
  //console.log(global);
  if (!api) {
    createApi()
  }
  return api
}

export const axios2 = useApi();