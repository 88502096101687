<template>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      sort-by="name"
      :group-by="grupo"
      show-group-by
      :items-per-page="10"
      loading-text="Cargando... Por favor, espere" 
      no-data-text="No hay datos disponibles"
      :footer-props="{
        'items-per-page-text': 'Resultados por página:',
        'page-text': '{0}-{1} de {2}' 
      }"
    ></v-data-table>
  </template>
  <script>
  export default {
    props: ["headers", "items", "grupo"],
    data () {
      return {

      }
    },
  }
</script>