import Vue from 'vue'
import Vuex from 'vuex'
import {axios2 as axios} from "@/interceptor/useApi";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        Roles: []
    },
    mutations: {
        LlenarRoles(state, payload){
            state.Roles = payload
        }
    },
    actions: {
        deleteUserCliente: async function({ commit }, id_usuario){
            try {
                const url = "userclientes/delete"
                const datos = { id_usuario: id_usuario}
                await axios.post(url, datos)
                    .then(response => {
                      
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error)
            }
        },

        sendCredentialsUserCliente: async function({ commit }, id_usuario){
            try {
                const url = "userclientes/sendcredentials"
                const datos = { id_usuario: id_usuario}
                await axios.post(url, datos)
                    .then(response => {
                      
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error)
            }
        }
    }
}


