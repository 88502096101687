<template>
  <div class="pa-5">
    <v-col lg="12">
      <v-row align="center">
        <v-col lg="12">
          <v-card>
            <v-toolbar color="secondary" dark>
              <span class="text-h6">Filtrar neumáticos</span>
            </v-toolbar>
            <v-container class="pa-4">
              <v-form ref="form" v-model="valid">
                <v-row justify="center" class="pa-4">
                  <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                    <v-autocomplete
                      :items="tiposCosto"
                      v-model="Item.tipocosto"
                      label="Seleccione tipo de costo *"
                      :rules="[(v) => !!v || 'Tipo de costo es requerido']"
                      dense
                    ></v-autocomplete>
                  </div>

                  <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                    <v-autocomplete
                      :items="condicionNeumatico"
                      v-model="Item.condicion"
                      label="Seleccione condición neumático *"
                      :rules="[
                        (v) => !!v || 'Condición del neumático es requerido',
                      ]"
                      @change="onCondicion"
                      dense
                    ></v-autocomplete>
                  </div>

                  <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                    <v-autocomplete
                      :items="ejes"
                      v-model="Item.eje"
                      label="Seleccione eje"
                      dense
                    ></v-autocomplete>
                  </div>

                  <div
                    class="col-lg-4 col-sm-6 col-md-6 col-xs-12"
                    v-if="Item.condicion != 2 && Item.condicion != null"
                  >
                    <v-autocomplete
                      :items="Modelos"
                      v-model="Item.modelo"
                      label="Seleccione modelo"
                      multiple
                      dense
                    ></v-autocomplete>
                  </div>

                  <div
                    class="col-lg-4 col-sm-6 col-md-6 col-xs-12"
                    v-if="Item.condicion != 2 && Item.condicion != null"
                  >
                    <v-autocomplete
                      :items="Medidas"
                      v-model="Item.medida"
                      label="Seleccione medida"
                      multiple
                      dense
                    ></v-autocomplete>
                  </div>

                  <div
                    class="col-lg-4 col-sm-6 col-md-6 col-xs-12"
                    v-if="Item.condicion == 2 && Item.condicion != null"
                  >
                    <v-autocomplete
                      :items="Disenios"
                      v-model="Item.disenio"
                      label="Seleccione diseño"
                      multiple
                      dense
                    ></v-autocomplete>
                  </div>

                  <div
                    class="col-lg-4 col-sm-6 col-md-6 col-xs-12"
                    v-if="Item.condicion == 2 && Item.condicion != null"
                  >
                    <v-autocomplete
                      :items="reencauches"
                      v-model="Item.cantidad"
                      label="Seleccione cantidad de reencauches"
                      multiple
                      dense
                    ></v-autocomplete>
                  </div>

                  <div
                    class="col-lg-4 col-sm-6 col-md-6 col-xs-12"
                    v-if="Item.condicion != null"
                  >
                    <v-autocomplete
                      :items="Aplicaciones"
                      v-model="Item.aplicacion"
                      label="Seleccione tipo de aplicación"
                      dense
                    ></v-autocomplete>
                  </div>
                </v-row>
              </v-form>
              <v-row>
                <v-col cols="1" sm="1" md="1" class="mt-2">
                  <v-btn
                    :disabled="!valid"
                    color="success"
                    @click="cargarDatos"
                    small
                  >
                    Filtrar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <vue-loaders
          v-if="loader == true"
          name="ball-beat"
          color="red"
          scale="1"
        ></vue-loaders>
      </v-row>

      <v-row justify="center">
        <v-col
          lg="12"
          v-if="Neumaticos.length > 0 && mostrarComponente == true"
        >
          <Neumaticos
            :Items="Neumaticos"
            :VerTooltip="VerTooltip"
            :dato_seleccionado="dato_seleccionado"
            :seleccionados="seleccionados"
            @seleccionarNeumatico="seleccionarNeumatico"
          ></Neumaticos>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col
          lg="12"
          v-if="Neumaticos.length > 0 && mostrarComponente == true"
        >
          <v-card>
            <v-toolbar height="60" color="#545554" dark>
              Tabla de comparación de neumáticos
            </v-toolbar>
            <v-card-title> 
              <v-alert style="width: 100%;margin-bottom: 0px;" border="top"
                colored-border
                type="info"
                elevation="2"
              >
              Haga clic en un neumático para quitarlo de la tabla de comparación.
              </v-alert>
            </v-card-title>
            <v-container class="pt-2 pb-2">
              <div class="table-responsive">
                <table class="letra-12" style="width: 100%" id="table">
                  <tr v-for="(item, index) in encabezados" :key="index">
                    <td
                      class="border texto-cen"
                      style="width: 15%; background-color: #6d6b6b"
                      v-if="item.visible == true"
                    >
                      <strong>
                        <font color="white">{{ item.descripcion }}</font>
                      </strong>
                    </td>
                    <td
                      :class="item.clase"
                      v-for="(item2, index2) in NeumaticosAComparar"
                      :key="index2"
                    >
                      <button
                        type="button"
                        style="
                          border-radius: 15px;
                          width: 145px;
                          background: none;
                          box-shadow: none;
                        "
                        @click="validacionNeumaticos(item2, index2)"
                        v-if="item.name == 'marca' || item.name == 'disenio'"
                      >
                        <img
                          :src="imagen"
                          alt="neumatico"
                          :class="item2.clase"
                        />
                        <p style="color: grey; text-shadow: none">
                          {{ item2[item.name] }}
                        </p>
                      </button>
                      <font v-else>{{ item2[item.name] }}</font>
                    </td>
                  </tr>
                </table>
              </div>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="mostrarComponente == true">
        <v-col cols="1" sm="1" md="1" class="mt-2">
          <v-btn color="success" @click="exportarAExcel" small>
            Exportar Excel
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Neumaticos from "../../components/NeumaticoHorizontalTable.vue";
import axios from "axios";
import global from "../../global";

export default {
  components: {
    Neumaticos,
  },
  data() {
    return {
      Item: {},
      Neumaticos: [],
      neumaticoSeleccionado: null,
      NeumaticosAComparar: [
        {
          pos: 0,
          id: "",
          asignado: false,
          clase: "imagen-neumatico1",
        },
        {
          pos: 1,
          id: "",
          asignado: false,
          clase: "imagen-neumatico1",
        },
        {
          pos: 2,
          id: "",
          asignado: false,
          clase: "imagen-neumatico1",
        },
        {
          pos: 3,
          id: "",
          asignado: false,
          clase: "imagen-neumatico1",
        },
        {
          pos: 4,
          id: "",
          asignado: false,
          clase: "imagen-neumatico1",
        },
      ],
      ejes: [
        {
          value: 1,
          text: "Direccional",
        },
        {
          value: 2,
          text: "Tracción",
        },
        {
          value: 3,
          text: "Eje libre",
        },
      ],
      valid: false,
      condicionNeumatico: [
        {
          value: 1,
          text: "Nuevo",
        },
        {
          value: 2,
          text: "Reencauchado",
        },
        {
          value: 3,
          text: "Todos",
        },
      ],
      reencauches: [
        {
          value: "N",
          text: "Nuevo",
        },
        {
          value: 1,
          text: "1 reencauche",
        },
        {
          value: 2,
          text: "2 reencauche",
        },
        {
          value: 3,
          text: "3 reencauche",
        },
        {
          value: 4,
          text: "4 reencauche",
        },
        {
          value: 5,
          text: "5 reencauche",
        },
        {
          value: 6,
          text: "6 reencauche",
        },
        {
          value: 7,
          text: "Más de 6 reencauche",
        },
      ],
      tiposCosto: [
        {
          value: 1,
          text: "Kilómetros",
        },
        {
          value: 2,
          text: "Horas",
        },
      ],
      tiposAplicacion: [],
      arrayItems: [
        {
          descripcion: "Marca",
          name: "marca",
          visible: true,
          clase: "border texto-cen pa-2",
        },
        {
          descripcion: "Diseño",
          name: "disenio",
          visible: true,
          clase: "border texto-cen pa-2",
        },
        {
          descripcion: "Cantidad",
          name: "cantidad",
          visible: true,
          clase: "border texto-cen pa-2",
        },
        {
          descripcion: "Recorrido Promedio Total",
          name: "recorrido_promedio",
          visible: true,
          clase: "border texto-cen bg-celda-1 pa-2",
        },
        {
          descripcion: "Costo por kilómetros promedio ($)",
          name: "costo_km_promedio",
          visible: true,
          clase: "border texto-cen bg-celda-3 pa-2",
        },
        {
          descripcion: "Costo Promedio Total ($)",
          name: "costo_promedio",
          visible: true,
          clase: "border texto-cen bg-celda-3 pa-2",
        },
        {
          descripcion: "Desgaste KM x mm",
          name: "desg_km_x_mm",
          visible: true,
          clase: "border texto-cen pa-2",
        },
        {
          descripcion: "Kilómetros proyectados",
          name: "km_proyectados",
          visible: true,
          clase: "border texto-cen bg-celda-4 pa-2",
        },
      ],
      encabezados: [],
      VerTooltip: false,
      imagen: axios.defaults.imageURL + "images/llanta.png",
      loader: false,
      mostrarComponente: false,
      dato_seleccionado:[],
      seleccionados:[],
      neumaticoseleccionadoacomparar:[],
    };
  },
  computed: {
    ...mapState("reportes", [
      "Marcas",
      "Modelos",
      "Medidas",
      "Disenios",
      "Aplicaciones",
    ]),
  },
  methods: {
    ...mapActions("reportes", [
      "DatosMarcasModelosMedidas",
      "DatosReporteComparativoMarca",
      "Marcas_o_Medidas_ReporteComparativoMarca",
    ]),

    onCondicion: function () {
      this.encabezados = [];
      this.arrayItems.forEach((element) => {
        if (this.Item.condicion == 2) {
          if (element.name != "marca") {
            this.encabezados.push(element);
          }
        } else {
          if (element.name != "disenio") {
            this.encabezados.push(element);
          }
        }
      });
      this.$forceUpdate();
    },

    cargarDatos: async function () {
      this.mostrarComponente = false;
      this.NeumaticosAComparar = [
        {
          pos: 0,
          asignado: false,
          clase: "imagen-neumatico1",
        },
        {
          pos: 1,
          asignado: false,
          clase: "imagen-neumatico1",
        },
        {
          pos: 2,
          asignado: false,
          clase: "imagen-neumatico1",
        },
        {
          pos: 3,
          asignado: false,
          clase: "imagen-neumatico1",
        },
        {
          pos: 4,
          asignado: false,
          clase: "imagen-neumatico1",
        },
      ];
      this.loader = true;
      this.Neumaticos = await this.Marcas_o_Medidas_ReporteComparativoMarca(
        this.Item
      );
      this.Neumaticos.forEach((element) => {
        if (this.Item.condicion == 2) {
          element.id = element.id_disenio;
          element.descripcion = element.disenio;
        } else {
          element.id = element.id_marca;
          element.descripcion = element.marca;
        }
      });

      this.loader = false;
      this.mostrarComponente = true;
    },

    validacionNeumaticos: async function (item, index) {
      let neumaticorepetido = false;
      if (item.asignado == false) {
        if (this.neumaticoSeleccionado != null) {
          this.NeumaticosAComparar.forEach((element) => {
            if (element.marca != undefined) {
              if (element.marca == this.neumaticoSeleccionado.marca) {
                neumaticorepetido = true;
              }
            }
            if (element.disenio != undefined) {
              if (element.disenio == this.neumaticoSeleccionado.disenio) {
                neumaticorepetido = true;
              }
            }
          });

          if (neumaticorepetido == false) {
            //consultar a neumatico comparación
            if (this.Item.condicion == 2) {
              this.Item.id = this.neumaticoSeleccionado.id_disenio;
            } else {
              this.Item.id = this.neumaticoSeleccionado.id_marca;
            }
            let neumatico = await this.DatosReporteComparativoMarca(this.Item);
            neumatico.pos = index;
            neumatico.asignado = true;
            neumatico.clase = "imagen-neumatico2";
            if (this.Item.condicion == 2) {
              neumatico.id = neumatico.id_disenio;
            } else {
              neumatico.id = neumatico.id_marca;
            }
            //Recorrido promedio
            /*
            neumatico.recorrido_promedio =
              recorrido_promedio.toLocaleString("en-US");
            */

            this.NeumaticosAComparar[index] = neumatico;
            this.neumaticoseleccionadoacomparar[index]=this.neumaticoSeleccionado;
            this.seleccionados.push(this.neumaticoseleccionadoacomparar[index]);
            this.neumaticoSeleccionado = null; 
            this.dato_seleccionado=[]; 
          }
        } else {
          console.log("neumático no seleccionado");
        }
      } else {
        this.seleccionados = this.seleccionados.filter(num_serie => num_serie !== this.neumaticoseleccionadoacomparar[index]); 
        this.NeumaticosAComparar[index] = {
          pos: index,
          id: "",
          asignado: false,
          clase: "imagen-neumatico1",
        };
        this.neumaticoseleccionadoacomparar[index]=null
        // this.neumaticoSeleccionado = null;
        
        if (this.neumaticoSeleccionado != null) {
          this.NeumaticosAComparar.forEach((element) => {
            if (element.marca != undefined) {
              if (element.marca == this.neumaticoSeleccionado.marca) {
                neumaticorepetido = true;
              }
            }
            if (element.disenio != undefined) {
              if (element.disenio == this.neumaticoSeleccionado.disenio) {
                neumaticorepetido = true;
              }
            }
          });

          if (neumaticorepetido == false) {
            //consultar a neumatico comparación
            if (this.Item.condicion == 2) {
              this.Item.id = this.neumaticoSeleccionado.id_disenio;
            } else {
              this.Item.id = this.neumaticoSeleccionado.id_marca;
            }
            let neumatico = await this.DatosReporteComparativoMarca(this.Item);
            neumatico.pos = index;
            neumatico.asignado = true;
            neumatico.clase = "imagen-neumatico2";
            if (this.Item.condicion == 2) {
              neumatico.id = neumatico.id_disenio;
            } else {
              neumatico.id = neumatico.id_marca;
            }
            //Recorrido promedio
            /*
            neumatico.recorrido_promedio =
              recorrido_promedio.toLocaleString("en-US");
            */

            this.NeumaticosAComparar[index] = neumatico;
            this.neumaticoseleccionadoacomparar[index]=this.neumaticoSeleccionado
            this.seleccionados.push(this.neumaticoseleccionadoacomparar[index]);
            this.neumaticoSeleccionado = null; 
            this.dato_seleccionado=[]; 
          }
        } else {
          console.log("neumático no seleccionado");
        }
      }
      this.$forceUpdate();
    },

    seleccionarNeumatico: function (item) {
      this.neumaticoSeleccionado = item;
      if (this.dato_seleccionado.includes(item)) {
        this.dato_seleccionado=[];
      }else{
        this.dato_seleccionado=[item];
      }
    },

    exportarAExcel: function () {
      let array_id_neumaticos = [];
      this.NeumaticosAComparar.forEach((element) => {
        if (element.id != "" && element.id != undefined) {
          array_id_neumaticos.push(element.id);
        }
      });

      location.href =
        axios.defaults.baseURL +
        "excel/reportecomparativomarcas?" +
        global._json_to_query_string({
          id_cliente: localStorage.getItem("idcliente"),
          array_id_neumaticos: array_id_neumaticos,
          tipo_costo: this.Item.tipocosto,
          condicion_neumatico: this.Item.condicion,
          eje: this.Item.eje,
          modelos: this.Item.modelo,
          medidas: this.Item.medida,
          aplicacion: this.Item.aplicacion,
          disenios: this.Item.disenio,
          reencauches: this.Item.cantidad,
        });
    },
  },
  mounted() {
    this.DatosMarcasModelosMedidas();
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td {
  vertical-align: top;
}

.border {
  border-style: solid;
  border-width: 1px;
}

.border-bottom {
  border-bottom: solid;
  border-width: 1px;
}

.border-top {
  border-top: solid;
  border-width: 1px;
}

.border-left {
  border-left: solid;
  border-width: 1px;
}

.border-right {
  border-right: solid;
  border-width: 1px;
}

.no-border {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.no-border-left {
  border-left: none;
}

.no-border-right {
  border-right: none;
}

.texto-cen {
  text-align: center;
}

.texto-izq {
  text-align: left;
}

.texto-just {
  text-align: justify;
}

.inline {
  display: inline-block;
}

.pa-4 {
  vertical-align: text-top;
  vertical-align: top;
}

.texto-cen {
  text-align: center;
}

.letra-10 {
  font-size: 11px;
}

.letra-12 {
  font-size: 12px;
}

.letra-14 {
  font-size: 14px;
}

.bg-celda-1 {
  background: #d2fcfe;
}

.bg-celda-2 {
  background: #ebf2f2;
}

.bg-celda-3 {
  background: #fbc4ee;
}

.bg-celda-4 {
  background: #9ec540;
}

.imagen-neumatico1 {
  width: 60px;
  opacity: 0.65;
}

.imagen-neumatico2 {
  width: 60px;
  opacity: 1;
}

.tooltip {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted black;*/
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 1px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
  top: -85px;
  left: 15%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

#table {
  display: block;
  /* important */
  width: 100%;
  overflow-x: scroll;
}
</style>