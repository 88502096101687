<template>
  <div>
    <VueFileAgent
      ref="vueFileAgent"
      :theme="'list'"
      :multiple="false"
      :deletable="true"
      :meta="true"
      :accept="extension"
      :maxSize="'10MB'"
      :maxFiles="1"
      :helpText="textoayuda"
      :errorText="{
        type: 'Tipo de archivo inválido', //. Solo se permite imagenes
        size: 'Los archivos no deben exceder los 10MB de tramaño',
      }"
      @select="filesSelected($event)"
      @beforedelete="onBeforeDelete($event)"
      @delete="fileDeleted($event)"
      :uploadUrl="uploadUrl"
      v-model="fileRecords"
    ></VueFileAgent>
    <!--
    <button :disabled="!fileRecordsForUpload.length" @click="uploadFiles()">
      Upload {{ fileRecordsForUpload.length }} files
    </button>
    -->
  </div>
</template>


<script>
import Vue from "vue";
import VueFileAgent from "vue-file-agent";
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
import global from "@/global";
Vue.use(VueFileAgent);

export default {
  props:["File"],
  data: function () {
    return {
      fileRecords: [],
      uploadUrl: "",
      uploadHeaders: { /*"X-Test-Header": "vue-file-agent"*/},
      fileRecordsForUpload: [], // maintain an upload queue
      extension: "",
      textoayuda: ""
    };
  },
  methods: {
    reset: function () {
      this.fileRecords = [];
    },
    invocar: function (valor) {
      this.mostrarLoader = valor;
      this.$emit("archivosubido", {
        valor: valor,
        fileRecords: this.fileRecords
      });
    },
    funccionn: function (that) {
      //console.log(that.fileRecords);
      //true=ya subió, false=todavía
      var bul = true;
      that.fileRecords.forEach((item) => {
        bul = bul && item.upload.data != null;
      });
      if (bul) {
        this.invocar(false);
        clearInterval(this.varRepeticiones);
      }
    },
    funcionnn: function () {
      this.invocar(true);
      var that = this;
      this.varRepeticiones = setInterval(this.funccionn, 500, that);
    },

    filesSelected: function (fileRecordsNewlySelected) {
      this.funcionnn();
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);
    },
    
    uploadFiles: function () {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(
        this.uploadUrl,
        this.uploadHeaders,
        this.fileRecordsForUpload
      );
      this.fileRecordsForUpload = [];
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },
  mounted() {
    this.uploadUrl = global.ruta_api + "imagen/subirimagen";
    if(this.File == 'excel'){
      this.extension = ".xls";
      this.textoayuda = "Arrastra los archivos aqui para subirlos";
    }else{
      this.extension = "image/*";
      this.textoayuda = "'Buscar imagenes'"
    }
  },
};
</script>
