<template>
    <div>
        <Nuevo :dialog="dialog" :Item="itemSelected" :Years="Years" v-if="dialog" @closeDialog="closeDialog"
            ref="nuevo" />
        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />

        <v-card>
            <v-toolbar color="secondary" dark>
                <span class="text-h6"> {{ Titulo }} </span>
                <v-spacer></v-spacer>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                            <a target="_blank" href="https://helpdesk.tiresoft.pe/#criterio_aceptacion"
                                style="text-decoration:none !important; color: white;">
                                <v-icon dark>mdi-help-circle</v-icon>
                            </a>
                        </v-btn>
                    </template>
                    <span>¿Necesitas ayuda?</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                    Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-btn  v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['2'])"  color="primary" dark class="mb-2" @click="nuevoItem()">
                    Nuevo
                </v-btn>
            </v-card-title>

            <v-data-table dense :headers="filteredCabecera" :items="Items" :items-per-page="5" :search="search"
                class="elevation-1" loading-text="Cargando... Por favor, espere"
                no-data-text="No hay datos disponibles"
                :loading="Loading"
                :footer-props="{
                  'items-per-page-text': 'Resultados por página:',
                  'page-text': '{0}-{1} de {2}' 
                }">
                <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn height="22" width="22" color="blue lighten-2" fab x-small dark
                                @click="editarItem(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                </template>
                <template v-slot:[`item.criterioaceptacion`]="{ item }">
                    <v-chip x-small color="green" label text-color="white">
                        <span>Objetivo</span>
                        <span style="margin-left:10px; margin-right:10px"> {{ mayor }} </span>
                        <span>{{ item.criterio_superior }}</span>
                    </v-chip>
                    <v-chip x-small color="orange" label text-color="white">
                        <span>Tolerable [{{ item.rango_c_inferior }} - {{ item.rango_c_superior }}] </span>
                    </v-chip>
                    <v-chip x-small color="pink" label text-color="white">
                        <span>Deficiente</span>
                        <span style="margin-left:10px; margin-right:10px"> {{ menor }} </span>
                        <span>{{ item.criterio_superior }}</span>
                    </v-chip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import {
    mapActions,
    mapGetters,
    mapState
} from 'vuex';
import Nuevo from './nuevo.vue'
import exportFromJSON from "export-from-json"
import MensajeTransicion from "../Alerta.vue"
import global from '@/global';
import { notif } from "@/plugins/notyf"

export default {
    props: ["Titulo", "Cabecera", "Items", "Excel", "Crear", "Years","Loading"],
    components: {
        Nuevo,
        MensajeTransicion
    },
    data() {
        return {
            search: "",
            dialog: false,
            itemSelected: {},
            mayor: "<",
            menor: "<",
            mensaje: "",
            verAlerta: false,
            global: global 
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
        ...mapGetters('criterioaceptacion', ['Indicador', 'Año']),
        canShowAccionColumn() {
            return ( global._autorizacion_usuario( this.Permisos, this.$route.meta.id,['3','4'])
            );
        },
        filteredCabecera() {
            return this.Cabecera.filter(col => !(col.value === 'acciones' && !this.canShowAccionColumn));
        }
    },
    methods: { 
        nuevoItem() { 
            this.itemSelected = {
                id: null,
                costoindicador: this.Indicador,
                modelomedida: "",
                anio: this.Año,
            };
            this.dialog = true;
            this.$nextTick(() => {
                if (this.$refs.nuevo) {
                    this.$refs.nuevo.cargarFormulario(this.itemSelected);
                }
            }); 
        },

        editarItem(item) {
            this.itemSelected = {
                id: item.id,
                costoindicador: this.Indicador,
                modelomedida: "",
                criterioobjetivo: item.criterio_superior,
                tolerablemaximo: item.rango_c_superior,
                tolerableminimo: item.rango_c_inferior,
                criteriodeficiente: item.criterio_inferior,
            };
            this.dialog = true;
            this.$nextTick(() => {
                if (this.$refs.nuevo) {
                    this.$refs.nuevo.cargarFormulario(this.itemSelected);
                }
            });
        },
        closeDialog() {
            this.dialog = false;
        },
        DescargarExcel() {
            const data = this.Items;
            const nombreArchivo = 'download';
            const exportType = exportFromJSON.types.xls; //xls, json, css, html,txt 
            exportFromJSON({
                data,
                nombreArchivo,
                exportType
            })
        }
    }
};
</script>
