<template>
    <div class="pa-5">
        <v-col lg="12">
            <v-card >
                <v-toolbar color="secondary" dark>
                    <span class="text-h6"> Curva de desgaste de Neumático</span>
                </v-toolbar>
                <!--
          <v-toolbar height="60" color="#545554" dark>
            Curva de desgaste de Neumático
          </v-toolbar>
          -->
                <v-card-text>
                    <v-row>
                        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <TablaNeumaticos @enviarDatos="enviarDatos" :Cabecera="Cabecera" :Items="Neumaticos">
                            </TablaNeumaticos>
                        </div>

                        <v-container v-if="visualizarGrafico == true" class="col-lg-9 col-md-12 col-sm-12 col-xs-12"
                            style="display: grid !important; justify-content: center !important; align-content: center !important;">
                            <v-container class="row clearfix pa-4">
                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                    <h4 class="font-bold">Neumático:</h4>
                                    <p class="letra_14">{{ Neumatico.num_serie }}</p>
                                </div>

                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                    <h4 class="font-bold">Marca:</h4>
                                    <p class="letra_14">{{ Neumatico.marca }}</p>
                                </div>

                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                    <h4 class="font-bold">Modelo:</h4>
                                    <p class="letra_14">{{ Neumatico.modelo }}</p>
                                </div>

                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                    <h4 class="font-bold">Medida:</h4>
                                    <p class="letra_14">{{ Neumatico.medida }}</p>
                                </div>

                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                    <h4 class="font-bold">Diseño:</h4>
                                    <p class="letra_14">{{ Neumatico.disenio }}</p>
                                </div>

                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                    <h4 class="font-bold">Estado:</h4>
                                    <p class="letra_14">{{ Neumatico.descripcion_estado }}</p>
                                </div>
                            </v-container>

                            <v-container class="pa-4" v-if="visualizarGrafico == true">
                                <GraficoLine :DatosGrafico="DatosGrafico" title="Curva de desgaste" :Labels="Labels"
                                    Labelscale_x="Kilómetros" Labelscale_y="Remanente (mm)"></GraficoLine>
                            </v-container>

                            <div hidden>
                                {{ calcularPorcentaje(Neumatico.porcentaje) }}
                            </div>

                            <div class="container" v-if="visualizarGrafico == true">
                                <div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <h4 class="font-bold">Remanente de retiro:</h4>
                                        <p class="letra_14">{{ parseNumber(Neumatico.remanente_limite) }}</p>
                                    </div>

                                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <h4 class="font-bold">Recorrido proyectado:</h4>
                                        <p class="letra_14">{{ parseNumber(Neumatico.km_proyectado) }}</p>
                                    </div>

                                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <h4 class="font-bold">Rendimiento:</h4>
                                        <p class="letra_14">{{ parseNumber(Neumatico.rendimiento) }}</p>
                                    </div>

                                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <h4 class="font-bold">Porcentaje de desgaste:</h4>
                                        <p class="letra_14">{{ Porcentaje }}</p>
                                    </div>

                                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <h4 class="font-bold">Desgaste (KM x mm):</h4>
                                        <p class="letra_14">{{ parseNumber(Neumatico.desgaste_km_mm) }}</p>
                                    </div>

                                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <h4 class="font-bold" id="title_mot_scrap"></h4>
                                        <p>{{ Neumatico.motivo }}</p>
                                    </div>
                                </div>
                            </div>
                        </v-container>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </div>
</template>

<script>
import TablaNeumaticos from "../../components/NeumaticoVerticalTable.vue";
import GraficoLine from "../../components/GraficoLine.vue";
import {
    mapState,
    mapActions
} from "vuex";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);
import axios from "axios";
import Swal from 'sweetalert2';

export default {
    components: {
        TablaNeumaticos,
        GraficoLine,
    },
    data() {
        return {
            Cabecera: [{
                text: "",
                value: "num_serie",
                align: "center",
                width: "100",
            },],
            Neumaticos: [],
            Porcentaje: "0 %",
            Labels: [],
            DatosGrafico: [],
            visualizarGrafico: false,
        };
    },
    computed: {
        ...mapState("reportes", [
            "KilometrosRecorridos",
            "RemanentesOriginales",
            "RemanentesProyectados",
            "RemanentesLimite",
            "Neumatico",
        ]),
    },
    methods: {
        ...mapActions("reportes", [
            "DatosGraficoCurvaRemanenteNeumatico",
            "VaciarObjetoNeumatico",
        ]),
        messageErrorAlert: function () {
            Swal.fire({
                title: "Upps, algo paso!",
                text: "Servidor desconectado, por favor actualice la ventana!",
                icon: "error",
                showCancelButton: false,
                confirmButtonColor: "#d33",
                confirmButtonText: "Actualizar",
                showClass: {
                    popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
                },
                hideClass: {
                    popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
                },
                backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
            }).then((result) => {
                if (result.isConfirmed) {
                    location.reload();
                }
            });
        },
        cargarNeumaticos: async function (item) {
            this.VaciarObjetoNeumatico();
            const data = {
                id_cliente: localStorage.getItem("idcliente"),
            };
            try {
                const url = "neumaticos/index";
                await axios
                    .post(url, data)
                    .then((response) => {
                        this.Neumaticos = response.data.success.resultado;                       
                    })
                    .catch((error) => {
                        console.error("error!", error);
                        this.errorMessage = error.response.data;
                        this.messageErrorAlert();
                    });
            } catch (error) {
                console.log(error);
                this.messageErrorAlert();
            }
        },
        enviarDatos: async function (item) {
            await this.DatosGraficoCurvaRemanenteNeumatico(item.id);
            this.generarGrafico();
        },
        calcularPorcentaje: function (porcentaje) {
            this.Porcentaje =
                parseFloat(
                    Math.round(
                        (parseFloat(100.0) - parseFloat(this.Neumatico.porcentaje)) * 100
                    ) / 100
                ).toFixed(2) + "%";
        },
        generarGrafico: async function () {
            this.Labels = this.KilometrosRecorridos;
            this.DatosGrafico = [{
                label: "Remanente (mm)",
                data: this.RemanentesOriginales,
                borderColor: "rgba(0, 188, 212, 1)",
                backgroundColor: "rgba(0, 188, 212, 0.1)",
                pointBorderColor: "rgba(51, 122, 183, 1)",
                pointBackgroundColor: "rgba(51, 122, 183, 1)",
                pointBorderWidth: 1,
                cubicInterpolationMode: "monotone",
                fill: true,
            },
            {
                label: "Remanente Proyectado (mm)",
                data: this.RemanentesProyectados,
                borderColor: "rgba(234, 164, 14, 1)",
                backgroundColor: "rgba(234, 164, 14, 0.5)",
                pointBorderColor: "rgba(234, 164, 14, 0)",
                pointBackgroundColor: "rgba(234, 164, 14, 0)",
                fill: false,
                borderDash: [5, 5],
                cubicInterpolationMode: "monotone",
            },
            {
                label: "Remanente Límite (mm)",
                data: this.RemanentesLimite,
                borderColor: "rgba(225, 11, 50, 1)",
                backgroundColor: "rgba(225, 11, 50, 0)",
                pointBorderColor: "rgba(225, 11, 50, 0)",
                pointBackgroundColor: "rgba(225, 11, 50, 0)",
                cubicInterpolationMode: "monotone",
            },
            ];
            this.visualizarGrafico = false;
            await this.$nextTick();
            this.visualizarGrafico = true;
        },
        parseNumber(numberString) {
            console.log(numberString);
            // Convertir el texto a un número flotante
            let number = parseFloat(numberString);
            // Verificar si la conversión fue exitosa
            if (isNaN(number)) {
               return "-";
            }
            // Formatear el número con separadores de miles
            let formattedNumber = number.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });

            return formattedNumber;    
        }
    },
    mounted() {
        this.cargarNeumaticos();
    },
};
</script>

<style scoped>
.letra_14 {
    font-size: 14px;
}
</style>
