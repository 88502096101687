<template>
    <div>
        <v-card>
            <Nuevo :dialog="dialog" :Item="itemSelected" @dialog="dialog = $event" :Roles="Roles"
                @cerrarModal="cerrarModal" v-if="verNuevo" />
            <Eliminar :dialog="dialogeliminar" :Descripcion="descripcioneliminar" @dialog="dialogeliminar = $event"
                @cerrarModalEliminar="cerrarModalEliminar" />
            <MensajeTransicion color="success" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
            <v-card-title>
                {{ Titulo }}
                <v-spacer></v-spacer>
                <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                    Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-btn  v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['2'])" color="primary" dark class="mb-2" @click="nuevoItem()">
                    Nuevo
                </v-btn>
                <v-btn  color="red" dark class="mb-2 ms-2" @click="regresar()">
                    Regresar
                </v-btn>
            </v-card-title>
            <v-data-table dense :headers="Cabecera" :loading="loading" :items="Items" :items-per-page="50"
                :search="search" class="elevation-1 mt-2" loading-text="Cargando... Por favor, espere"
                no-data-text="No hay datos disponibles" 
                :footer-props="{
                    'items-per-page-text': 'Resultados por página:',
                    'page-text': '{0}-{1} de {2}'
                }">
                <template v-slot:[`item.role`]="{ item }">
                    <v-chip color="success" outlined small>
                        {{ item.role }}
                    </v-chip>
                </template>
                <template v-slot:[`item.accion`]="{ item }">
                    <v-tooltip bottom v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['3'])">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn height="22" width="22" color="blue lighten-2" fab x-small dark
                                @click="editarItem(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['4'])">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn height="22" width="22" color="red" fab x-small dark @click="eliminarItem(item)"
                                v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn height="22" width="22" color="success" fab x-small dark
                                @click="enviarCredenciales(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-send</v-icon>
                            </v-btn>
                        </template>
                        <span>Enviar credenciales</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import {
    mapState,
    mapActions
} from 'vuex';
import Nuevo from "../miembros/nuevo.vue"
import Eliminar from "../ModalEliminar.vue"
import router from '@/router';
import MensajeTransicion from "../Alerta.vue"
import global from '@/global';

export default {
    props: ["Titulo", "Cabecera", "Items", "Excel", "Crear", "Roles", "Cliente"],
    components: {
        Nuevo,
        Eliminar,
        MensajeTransicion
    },
    inject: ['llamarindexMembers'], // Inyecta la función proporcionada por el Abuelo
    data() {
        return {
            search: "",
            mensaje: "",
            dialog: false,
            loading: false,
            itemSelected: {},
            verNuevo: false,
            verAlerta: false,
            dialogeliminar: false,
            descripcioneliminar: "",
            global: global 
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        ...mapActions("usercliente", ["deleteUserCliente", "sendCredentialsUserCliente"]),
        regresar(){
            router.go(-1);
        },
        nuevoItem() {
            let id_cliente = null;
            if (this.Cliente == undefined) {
                id_cliente = location.pathname.substr(1).split("/")[1];
            } else {
                id_cliente = this.Cliente;
            }

            this.itemSelected = {
                id: null,
                nombres: null,
                apellidos: null,
                email: null,
                role_id: null,
                id_cliente: id_cliente
            }
            this.dialog = true;
            this.verNuevo = true;
        },

        editarItem(item) {
            this.itemSelected = { ...item };
            this.itemSelected.id = this.itemSelected.id_user;
            this.dialog = true;
            this.verNuevo = true;
        },

        eliminarItem(item) {
            this.descripcioneliminar = "Desea eliminar el usuario " + item.name + " ?";
            this.itemSelected = { ...item };
            this.itemSelected.id = this.itemSelected.id_user;
            this.dialogeliminar = true;
        },

        async enviarCredenciales(item) {
            this.itemSelected = { ...item };
            this.itemSelected.id = this.itemSelected.id_user;
            this.loading = true;
            await this.sendCredentialsUserCliente(this.itemSelected.id);
            this.loading = false;
            this.verAlerta = true;
            this.mensaje = "Credenciales enviadas con éxito al correo " + this.itemSelected.email;
            setTimeout(() => [(this.verAlerta = false)], 4000);
        },

        cerrarModal(valor) {
            if (valor == true) {
                router.go();
            }
            this.verNuevo = false;
        },

        async cerrarModalEliminar() {
            let index = this.Items.findIndex((el) => el.id == this.itemSelected.id);
            this.dialogeliminar = false;
            this.loading = true;
            await this.deleteUserCliente(this.itemSelected.id);
            this.llamarindexMembers(this.$route.params.id);
            this.loading = false;
            this.verAlerta = true;
            this.mensaje = "El usuario ha sido eliminado";
            setTimeout(() => [(this.verAlerta = false)], 4000);
        }
    }
};
</script>
