<template>
<div class="pa-5">
    <v-col lg="12">
        <v-row>
            <v-col lg="12">
                <v-card class="pa-2">
                    <v-card-title>Indicador Costo por Kilómetro <v-spacer></v-spacer>{{ annio }}</v-card-title>
                    <v-card-subtitle>No se consideran neumáticos en SCRAP</v-card-subtitle>
                    <v-card-text>
                        <v-row justify="center">
                            <table class="table-responsive letra-9">
                                <tr>
                                    <td rowspan="2" class="pa-3">
                                        <font color="black"><strong><em>Costo por kilómetro : </em></strong></font>
                                    </td>
                                    <td class="text-center border-bottom pa-1">
                                        <font color="black"><em>Precio de neumáticos(usd)</em></font>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center pa-1">
                                        <font color="black"><em>Rendimiento de neumáticos(km)</em></font>
                                    </td>
                                </tr>
                            </table>
                        </v-row>

                        <IndicadorCosto :CostoPorKilometro="CostoPorKilometro" :MesesCostoPorKilometro="MesesCostoPorKilometro" />
                    </v-card-text>
                    <v-card-actions>
                        <v-col lg="6">
                            <v-btn @click="verDetalles()" :loading="loadingVerDetalle" color="#14DEDE" small block>Ver más</v-btn>
                        </v-col>
                        <v-col lg="6">
                            <v-btn @click="exportarExcelCostoxKilometro()" color="#32B1E9" :loading="loadingButtonCosto" small block>Exportar</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col lg="6">
                <v-card>
                    <v-card-title>Índice de reencauche <v-spacer></v-spacer>{{ annio }}</v-card-title>
                    <v-card-subtitle>No se consideran neumáticos en SCRAP</v-card-subtitle>
                    <v-card-text>
                        <v-row justify="center" class="pa-4">
                            <table class="table-responsive letra-9">
                                <tr>
                                    <td rowspan="2" class="pa-3">
                                        <font color="black"><strong><em>Índice de reencauche : </em></strong></font>
                                    </td>
                                    <td class="text-center border-bottom pa-1">
                                        <font color="black"><em>Total de neumáticos reencauchados</em></font>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center pa-1">
                                        <font color="black"><em>Total de neumáticos</em></font>
                                    </td>
                                </tr>
                            </table>
                        </v-row>

                        <GraficoBar Title="Índice de reencauche" Labelsdataset="Índice de reencauche(%)" Labelscale_x="Meses" Labelscale_y="Porcentaje de reencauche (%)" IdGrafico="graficoreencauche" :Labels="Labels" :DatosGrafico="datosGraficoReencauche" v-if="mostrarGraficoReencauche == true"></GraficoBar>
                    </v-card-text>
                    <v-card-actions>
                        <v-col lg="6">
                            <v-btn @click="ver('reencauche')" color="#14DEDE" small block>Ver más</v-btn>
                        </v-col>
                        <v-col lg="6">
                            <v-btn @click="exportarExcelIndiceReencauche()" :loading="loadingButtonReencauche" color="#32B1E9" small block>Exportar</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col lg="6">
                <v-card>
                    <v-card-title>Índice de reencauchabilidad <v-spacer></v-spacer>
                        {{ annio }}</v-card-title>
                    <v-card-subtitle>No se consideran neumáticos en SCRAP</v-card-subtitle>
                    <v-card-text>
                        <v-row justify="center" class="pa-4">
                            <table class="table-responsive letra-9">
                                <tr>
                                    <td rowspan="2" class="pa-3">
                                        <font color="black"><strong><em>Índice de Reencauchabilidad : </em></strong></font>
                                    </td>
                                    <td class="text-center border-bottom pa-1">
                                        <font color="black"><em>Número de veces reencauche</em></font>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center pa-1">
                                        <font color="black"><em>Total de carcasas reencauchadas</em></font>
                                    </td>
                                </tr>
                            </table>
                        </v-row>

                        <GraficoBar Title="Índice de reencauchabilidad" Labelsdataset="Índice de reencauchabilidad" Labelscale_x="Meses" Labelscale_y="Reencauchabilidad" IdGrafico="graficoreencauchabilidad" :Labels="Labels" :DatosGrafico="datosGraficoReencauchabilidad" v-if="mostrarGraficoReencauchabilidad == true"></GraficoBar>
                    </v-card-text>
                    <v-card-actions>
                        <v-col lg="6">
                            <v-btn @click="ver('reencauchabilidad')" color="#14DEDE" small block>Ver más</v-btn>
                        </v-col>
                        <v-col lg="6">
                            <v-btn @click="exportarExcelIndiceReencauchabilidad()" :loading="loadingButtonReencauchabilidad" color="#32B1E9" small block>Exportar</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col lg="6">
                <v-card>
                    <v-card-title>Neumáticos por Marca <v-spacer></v-spacer>Total:
                        {{ totalNeumaticosMarca }}</v-card-title>
                    <v-card-subtitle>No se consideran neumáticos en SCRAP</v-card-subtitle>
                    <v-card-text>
                        <GraficoPie Title="" IdGrafico="graficomarcas" :Labels="Marcas" :DatosGrafico="CantidadMarcas" v-if="mostrarGraficos == true"></GraficoPie>
                    </v-card-text>
                    <v-card-actions>
                        <v-col lg="6">
                            <v-btn @click="ver('neumaticos')" color="#14DEDE" small block>Ver más</v-btn>
                        </v-col>
                        <v-col lg="6">
                            <v-btn @click="exportarExcelNeumaticosPorMarca()" color="#32B1E9" :loading="loadingButtonMarca" small block>Exportar</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col lg="6">
                <v-card>
                    <v-card-title>Condición de Neumáticos <v-spacer></v-spacer>Total:
                        {{ totalNeumaticosMarca }}</v-card-title>
                    <v-card-subtitle>No se consideran neumáticos en SCRAP</v-card-subtitle>
                    <v-card-text>
                        <GraficoPie Title="" IdGrafico="graficocondicionneumaticos" :Labels="CondicionNeumaticos" :DatosGrafico="CantidadxCondicionNeumaticos" v-if="mostrarGraficos == true"></GraficoPie>
                    </v-card-text>
                    <v-card-actions>
                        <v-col lg="6">
                            <v-btn @click="ver('neumaticos')" color="#14DEDE" small block>Ver más</v-btn>
                        </v-col>
                        <v-col lg="6">
                            <v-btn @click="exportarExcelNeumaticosPorCondicion()" color="#32B1E9" :loading="loadingButtonCondicion" small block>Exportar</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col lg="12">
                <v-card class="pa-2">
                    <v-card-title>Neumáticos por Modelo y Diseño <v-spacer></v-spacer>Total:
                        {{ totalNeumaticosMarca }}</v-card-title>
                    <v-card-subtitle>No se consideran neumáticos en SCRAP</v-card-subtitle>
                    <v-card-text>
                        <GraficoBarDatasets Title="Índice de reencauche" Labelsdataset1="Modelos" Labelsdataset2="Disenios" Labelscale_x="" Labelscale_y="" IdGrafico="graficomodelosydisenios" :Background="BackgroundModelosyDisenios" :Border="BorderModelosyDisenios" :Labels="ModelosyDisenios" :DatosGraficoDataset="CantidadModelosyDisenios" v-if="mostrarGraficos == true"></GraficoBarDatasets>
                    </v-card-text>
                    <v-card-actions>
                        <v-col lg="6">
                            <v-btn @click="ver('neumaticos')" color="#14DEDE" small block>Ver más</v-btn>
                        </v-col>
                        <v-col lg="6">
                            <v-btn @click="exportarExcelNeumaticosPorModeloyDisenio()" color="#32B1E9" :loading="loadingButtonModelo" small block>Exportar</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col lg="6">
                <v-card>
                    <v-card-title>Neumáticos por Medida <v-spacer></v-spacer>Total:
                        {{ totalNeumaticosMarca }}</v-card-title>
                    <v-card-subtitle>No se consideran neumáticos en SCRAP</v-card-subtitle>
                    <v-card-text>
                        <GraficoBar Title="" Labelsdataset="Neumáticos" Labelscale_x="Medidas" Labelscale_y="Cantidad" IdGrafico="graficomedidas" :Labels="Medidas" :DatosGrafico="CantidadMedidas" :Tamanio="9" v-if="mostrarGraficos == true"></GraficoBar>
                    </v-card-text>
                    <v-card-actions>
                        <v-col lg="6">
                            <v-btn @click="ver('neumaticos')" color="#14DEDE" small block>Ver más</v-btn>
                        </v-col>
                        <v-col lg="6">
                            <v-btn @click="exportarExcelNeumaticosPorMedida()" color="#32B1E9" :loading="loadingButtonMedida" small block>Exportar</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col lg="6">
                <v-card>
                    <v-card-title>Reporte Scrap por Año <v-spacer></v-spacer>Total:
                        {{ totalNeumaticosScrap }}</v-card-title>
                    <v-card-subtitle>Últimos 5 años</v-card-subtitle>
                    <v-card-text>
                        <GraficoBar Title="" Labelsdataset="Neumáticos" Labelscale_x="Años" Labelscale_y="Cantidad" IdGrafico="graficoscrap" :Labels="Anios" :DatosGrafico="CantidadNeumaticosScrap" :Tamanio="9" v-if="mostrarGraficos == true"></GraficoBar>
                    </v-card-text>
                    <v-card-actions>
                        <v-col lg="6">
                            <v-btn @click="ver('neumaticos')" color="#14DEDE" small block>Ver más</v-btn>
                        </v-col>
                        <v-col lg="6">
                            <v-btn @click="exportarExcelNeumaticosEnScrapPorAnio()" color="#32B1E9" :loading="loadingButtonScrap" small block>Exportar</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col lg="12">
                <v-card>
                    <v-card-title>Neumáticos por Estado <v-spacer></v-spacer>Total:
                        {{ totalNeumaticosMarca }}</v-card-title>
                    <v-card-subtitle>No se consideran neumáticos en SCRAP</v-card-subtitle>
                    <v-card-text>
                        <GraficoPie Title="" IdGrafico="graficoestados" :Labels="Estados" :DatosGrafico="CantidadEstados" v-if="mostrarGraficos == true"></GraficoPie>
                    </v-card-text>
                    <v-card-actions>
                        <v-col lg="6">
                            <v-btn @click="ver('neumaticos')" color="#14DEDE" small block>Ver más</v-btn>
                        </v-col>
                        <v-col lg="6">
                            <v-btn @click="exportarExcelNeumaticosPorEstado()" :loading="loadingButtonEstado" color="#32B1E9" small block>Exportar</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-col>

    <!-- modal detalles costo por kilometro-->
    <v-dialog v-model="dialog" max-width="950px">
        <v-card>
            <v-card-title>Detalles del Indicador <v-spacer></v-spacer>{{ annio }}</v-card-title>
            <v-card-text class="pt-4">
                <table class="letra-9" style="width: 100%" id="table">
                    <tr style="background-color: #6d6b6b">
                        <td class="border texto-cen pa-2" style="width: 8%">
                            <strong>
                                <font color="white">Marca</font>
                            </strong>
                        </td>
                        <td class="border texto-cen pa-2" style="width: 8%">
                            <strong>
                                <font color="white">Modelo</font>
                            </strong>
                        </td>
                        <td class="border texto-cen pa-2" style="width: 8%">
                            <strong>
                                <font color="white">Medida</font>
                            </strong>
                        </td>
                        <td class="border texto-cen pa-2" style="width: 8%">
                            <strong>
                                <font color="white">Lote</font>
                            </strong>
                        </td>
                        <td class="border texto-cen pa-2" style="width: 15%">
                            <strong>
                                <font color="white">Suma Precio</font>
                            </strong>
                        </td>
                        <td class="border texto-cen pa-2" style="width: 15%">
                            <strong>
                                <font color="white">Promedio Precio</font>
                            </strong>
                        </td>
                        <td class="border texto-cen pa-2" style="width: 15%">
                            <strong>
                                <font color="white">Suma KM Recorrido</font>
                            </strong>
                        </td>
                        <td class="border texto-cen pa-2" style="width: 15%">
                            <strong>
                                <font color="white">KM Rendimiento</font>
                            </strong>
                        </td>
                        <td class="border texto-cen pa-2" style="width: 10%">
                            <strong>
                                <font color="white">Indicador</font>
                            </strong>
                        </td>
                        <td class="border texto-cen pa-2" style="width: 10%">
                            <strong>
                                <font color="white">Mes</font>
                            </strong>
                        </td>
                    </tr>
                    <tr v-for="(item, index) in detallesCosto" :key="index">
                        <td class="border texto-cen pa-1">{{ item.marca }}</td>
                        <td class="border texto-cen pa-1">{{ item.modelo }}</td>
                        <td class="border texto-cen pa-1">{{ item.medida }}</td>
                        <td class="border texto-cen pa-1">{{ item.total_lote }}</td>
                        <td class="border texto-cen pa-1">{{ item.suma_precio }}</td>
                        <td class="border texto-cen pa-1">{{ item.promedio_precio }}</td>
                        <td class="border texto-cen pa-1">{{ item.suma_km_recorrido }}</td>
                        <td class="border texto-cen pa-1">{{ item.promedio_km_recorrido }}</td>
                        <td class="border texto-cen pa-1">{{ item.indicador }}</td>
                        <td class="border texto-cen pa-1">{{ mes(item.fecha_mes) }}</td>
                    </tr>
                    <tr v-if="detallesCosto.length == 0">
                        <td colspan="10" class="border texto-cen">
                            No se encontraron datos
                        </td>
                    </tr>
                </table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="cerrarDialog()" color="error">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import plugin from "@/components/Plugin.vue";
import {
    mapState,
    mapActions
} from "vuex";
import IndicadorCosto from "../components/indicador/tabla.vue";
import GraficoBar from "../components/GraficoBar.vue";
import GraficoPie from "../components/GraficoPie.vue";
import GraficoBarDatasets from "../components/GraficoBarDatasets.vue";
import global from "../global";
import {
    axios2 as axios
} from "@/interceptor/useApi";

export default {
    name: "Home",
    components: {
        plugin,
        IndicadorCosto,
        GraficoBar,
        GraficoBarDatasets,
        GraficoPie,
    },
    data() {
        return {
            annio: "",
            meses: [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre",
            ],
            detallesCosto: [],
            datosGraficoReencauche: [],
            datosGraficoReencauchabilidad: [],
            mostrarGraficoReencauche: false,
            mostrarGraficoReencauchabilidad: false,
            mostrarGraficoMarcas: false,
            mostrarGraficos: false,
            totalNeumaticosMarca: 0,
            totalNeumaticosScrap: 0,
            loadingButtonCosto: false,
            loadingButtonReencauche: false,
            loadingButtonReencauchabilidad: false,
            loadingButtonMarca: false,
            loadingButtonCondicion: false,
            loadingButtonModelo: false,
            loadingButtonEstado: false,
            loadingButtonMedida: false,
            loadingButtonScrap: false,
            loadingVerDetalle: false,
            dialog: false
        };
    },
    computed: {
        ...mapState("indicadores", [
            "MesesCostoPorKilometro",
            "CostoPorKilometro",
            "IndicesReencauchePorMes",
            "IndicesReencauchabilidadPorMes",
        ]),

        ...mapState("home", [
            "Marcas",
            "CantidadMarcas",
            "CondicionNeumaticos",
            "CantidadxCondicionNeumaticos",
            "Estados",
            "CantidadEstados",
            "ModelosyDisenios",
            "CantidadModelosyDisenios",
            "BackgroundModelosyDisenios",
            "BorderModelosyDisenios",
            "Medidas",
            "CantidadMedidas",
            "Anios",
            "CantidadNeumaticosScrap",
        ]),
    },
    methods: {
        ...mapActions("indicadores", [
            "reporteCostoPorKilometro",
            "reporteIndiceReencauche",
            "reporteIndiceReencauchabilidad",
        ]),

        ...mapActions("home", ["datosGraficos"]),

        mes: function (numeromes) {
            return this.meses[parseInt(numeromes) - 1]
        },

        ver(valor) {
            if (valor == "costo") {

            }
            if (valor == "reencauche") {
                this.$router.push({
                    path: "/indicadorreencauche"
                });
            }

            if (valor == "reencauchabilidad") {
                this.$router.push({
                    path: "/indicadorreencauchabilidad"
                });
            }

            if (valor == "neumaticos") {
                this.$router.push({
                    path: "/neumaticos"
                });
            }
        },

        async verDetalles() {
            this.loadingVerDetalle = true;
            try {
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                    year: this.annio
                };
                const url = "reporte/detalles_costo_por_kilometro";
                await axios
                    .post(url, data)
                    .then((response) => {
                        this.detallesCosto = response.data.success.detalles;
                        this.loadingVerDetalle = false;
                        this.dialog = true;
                    })
                    .catch((error) => {
                        console.error("error!", error);
                        this.errorMessage = error.response.data;
                    });
            } catch (error) {
                console.log(error);
            }
        },

        exportarExcelCostoxKilometro() {
            this.loadingButtonCosto = true;
            const url = global.ruta_api + "excel/reporteindicadorcostokmhr?" +
                global._json_to_query_string({
                    id_cliente: localStorage.getItem("idcliente"),
                    id_usuario: localStorage.getItem("id"),
                    year: this.annio,
                    marca: null,
                    unidadmedida: null
                });
            this.exportarExcel(url).then(r => {
                this.loadingButtonCosto = false;
            });
        },

        exportarExcelIndiceReencauche() {
            this.loadingButtonReencauche = true;
            const url =
                global.ruta_api +
                "excel/reporteindicereencauche?" +
                global._json_to_query_string({
                    id_cliente: localStorage.getItem("idcliente"),
                    id_usuario: localStorage.getItem("id"),
                    year: this.annio,
                });
            this.exportarExcel(url).then(r => {
                this.loadingButtonReencauche = false;
            });
        },

        exportarExcelIndiceReencauchabilidad() {
            this.loadingButtonReencauchabilidad = true;
            const url =
                global.ruta_api + "excel/reporteindicereencauchabilidad?" +
                global._json_to_query_string({
                    id_cliente: localStorage.getItem("idcliente"),
                    id_usuario: localStorage.getItem("id"),
                    year: this.annio
                });

            this.exportarExcel(url).then(r => {
                this.loadingButtonReencauchabilidad = false
            });
        },

        exportarExcelNeumaticosPorMarca() {
            this.loadingButtonMarca = true;
            const url = global.ruta_api + "excel/reporteneumaticospormarca?" +
                global._json_to_query_string({
                    id_cliente: localStorage.getItem("idcliente"),
                    id_usuario: localStorage.getItem("id"),
                });
            this.exportarExcel(url).then(r => {
                this.loadingButtonMarca = false;
            });
        },

        exportarExcelNeumaticosPorCondicion() {
            this.loadingButtonCondicion = true;
            const url = global.ruta_api + "excel/reporteneumaticosporcondicion?" +
                global._json_to_query_string({
                    id_cliente: localStorage.getItem("idcliente"),
                    id_usuario: localStorage.getItem("id"),
                });
            this.exportarExcel(url).then(r => {
                this.loadingButtonCondicion = false;
            });
        },

        exportarExcelNeumaticosPorModeloyDisenio() {
            this.loadingButtonModelo = true;
            const url = global.ruta_api + "excel/reporteneumaticospormodeloydisenio?" +
                global._json_to_query_string({
                    id_cliente: localStorage.getItem("idcliente"),
                    id_usuario: localStorage.getItem("id"),
                });
            this.exportarExcel(url).then(r => {
                this.loadingButtonModelo = false;
            });
        },

        exportarExcelNeumaticosPorEstado() {
            this.loadingButtonEstado = true;
            const url = global.ruta_api + "excel/reporteneumaticosporestado?" +
                global._json_to_query_string({
                    id_cliente: localStorage.getItem("idcliente"),
                    id_usuario: localStorage.getItem("id"),
                });
            this.exportarExcel(url).then(r => {
                this.loadingButtonEstado = false;
            });
        },

        exportarExcelNeumaticosPorMedida() {
            this.loadingButtonMedida = true;
            const url = global.ruta_api + "excel/reporteneumaticospormedida?" +
                global._json_to_query_string({
                    id_cliente: localStorage.getItem("idcliente"),
                    id_usuario: localStorage.getItem("id"),
                });
            this.exportarExcel(url).then(r => {
                this.loadingButtonMedida = false;
            });
        },

        exportarExcelNeumaticosEnScrapPorAnio() {
            this.loadingButtonScrap = true;
            const url = global.ruta_api + "excel/reporteneumaticosenscrapporanio?" +
                global._json_to_query_string({
                    id_cliente: localStorage.getItem("idcliente"),
                    id_usuario: localStorage.getItem("id"),
                });
            this.exportarExcel(url).then(r => {
                this.loadingButtonScrap = false;
            });
        },

        async exportarExcel(url) {
            try {
                await axios({
                        url: url,
                        method: "GET",
                        responseType: "blob"
                    })
                    .then(async (response) => {
                        var nombre_archivo = response.headers["nombre-archivo"];
                        if (response.data.type == "text/html") {
                            // no es excel, probablemente es json con un mensaje de error
                            const res = JSON.parse(await response.data.text());
                            if (!res.exito) {
                                alert(res.mensaje || "Sucedió un error, intentelo nuevamente");
                            }
                        } else {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", nombre_archivo);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        cerrarDialog: function () {
            this.dialog = false
        },

        async cargarDatos() {
            var moment = require("moment");
            let fecha = moment(new Date(), "YYYY-MM-DD"); //2021-05-20 //YYYY-MM-DD
            this.annio = fecha.format("YYYY");
            let datos = {
                anio: this.annio,
                marca: null,
                unidadmedida: null,
            };

            this.Labels = this.meses;
            await this.reporteCostoPorKilometro(datos);
            await this.reporteIndiceReencauche(this.annio);
            this.IndicesReencauchePorMes.forEach((element) => {
                this.datosGraficoReencauche.push((element * 100).toFixed(2));
            });
            this.mostrarGraficoReencauche = true;

            await this.reporteIndiceReencauchabilidad(this.annio);
            this.datosGraficoReencauchabilidad = [];
            this.IndicesReencauchabilidadPorMes.forEach((element) => {
                this.datosGraficoReencauchabilidad.push(element);
            });
            this.mostrarGraficoReencauchabilidad = true;

            await this.datosGraficos();
            this.CantidadMarcas.forEach((element) => {
                this.totalNeumaticosMarca += parseInt(element);
            });

            this.CantidadNeumaticosScrap.forEach((element) => {
                this.totalNeumaticosScrap += parseInt(element);
            });

            this.mostrarGraficos = true;
        },
    },
    mounted() {
        this.cargarDatos();
    },
};
</script>

<style scoped>
table {
    border-collapse: collapse;
}

.border {
    border-style: solid;
    border-width: 1px;
}

.border-bottom {
    border-bottom: solid;
    border-width: 1px;
}

.border-top {
    border-top: solid;
    border-width: 1px;
}

.border-left {
    border-left: solid;
    border-width: 1px;
}

.border-right {
    border-right: solid;
    border-width: 1px;
}

.no-border {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.no-border-left {
    border-left: none;
}

.no-border-right {
    border-right: none;
}

.texto-cen {
    text-align: center;
}

.texto-izq {
    text-align: left;
}

.texto-just {
    text-align: justify;
}

.inline {
    display: inline-block;
}

.texto-arriba {
    vertical-align: text-top;
    vertical-align: top;
}

.letra-6 {
    font-size: 6pt;
}

.letra-7 {
    font-size: 7pt;
}

.letra-8 {
    font-size: 8pt;
}

.letra-9 {
    font-size: 9pt;
}

.letra-10 {
    font-size: 10pt;
}

.letra-11 {
    font-size: 11pt;
}

.letra-12 {
    font-size: 12pt;
}

.letra-14 {
    font-size: 14pt;
}

.letra-15 {
    font-size: 15pt;
}

.letra-18 {
    font-size: 18pt;
}

.letra-25 {
    font-size: 25pt;
}

.letra-30 {
    font-size: 30pt;
}

.margin-top-20 {
    margin-top: 20px;
}

.bg-celda-azul {
    background: #91e3da;
}

.bg-celda-amarilla {
    background: #faec5c;
    /*color:#EDDF04;*/
}

.bg-celda-verde {
    background: #a8fa6f;
}

.bg-celda-roja {
    background: #f55f50;
}

#table {
    display: block;
    /* important */
    width: 1000px;
}
</style>
