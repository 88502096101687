import Vue from "vue";
import Vuex from "vuex";
import { axios2 as axios } from "@/interceptor/useApi";
import Swal from "sweetalert2";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    Items: [],
    ItemsContacto: [],
    ItemsCargo: [],
    ItemContacto: {
      id: 0,
      id_cliente: "",
      nombre: "",
      correo: "",
      telefono: "",
      cargo: "",
      reporte: false,
    },
    ItemsClienteSelect: [],
    ClientesUsuario: [],
    Cliente: {},
    Usuarios: [],
    Roles: [],
    Loading:true,
    LoadingUserClients:true,
    validacionapodo:'correcto',
  },
  getters: {
    Items(state) {
      return state.Items;
    },
    ItemsContacto(state) {
      return state.ItemsContacto;
    },
    ItemsCargo(state) {
      return state.ItemsCargo;
    },
    ItemContacto(state) {
      return state.ItemContacto;
    },
    ItemsClienteSelect(state) {
      return state.ItemsClienteSelect;
    },
    validacionapodo(state){
      return state.validacionapodo;
    }
  },
  mutations: {
    LlenarItems(state, data) {
      state.Items = data;
    },
    LlenarItemsContacto(state, data) {
      state.ItemsContacto = data;
    },
    LlenarItemsCargo(state, data) {
      state.ItemsCargo = data;
    },
    LlenarItemContacto(state, data) {
      state.ItemContacto = data;
    },
    LlenarClientesUsuario(state, data) {
      state.ClientesUsuario = data;
    },
    LlenarItemsClienteSelect(state, data) {
      state.ItemsClienteSelect = data;
    },
    LlenarRoles(state, data) {
      state.Roles = data;
    },
    LlenarUsuarios(state, data) {
      state.Usuarios = data;
    },
    LlenarCliente(state, data) {
      state.Cliente = data;
    },
    LlenarLoading(state, data) {
      state.Loading = data;
    },
    LlenarLoadingUserClients(state, data) {
      state.LoadingUserClients = data;
    },
    LlenarValidacion(state, data) {
      state.validacionapodo = data;
    },
    
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },

    actualizarItemContacto: async function ({ commit }, data) {
      commit("LlenarItemContacto", data);
    },

    //Datos del cliente con que se inicia sesión
    ObtenerDatosCliente: async function ({ commit, dispatch }) {
      var url = "cliente/show";
      let datos = { id_cliente: localStorage.getItem("idcliente") };
      if (localStorage.getItem("idcliente") != null) {
        await axios
          .post(url, datos)
          .then((response) => {
            commit("LlenarCliente", response.data.success.datos);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      }
    },

    cargarClientes: async function ({ commit, dispatch }) {
      try {
        var array = [];
        var con = 0;
        const url = "cliente";
        await axios
          .get(url)
          .then((response) => {
            response.data.success.resultado.forEach((element) => {
              // console.log(element.imagen);
              array[con] = {
                name: element.razon_social,
                ruc: element.ruc,
                avatar: element.imagen,
                id: element.id,
              };
              con++;
            });
            commit("LlenarItems", response.data.success.resultado);
            commit("LlenarItemsClienteSelect", array);
            commit("LlenarLoading",false)
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    agregarCliente: async function ({ commit, dispatch }, datos) {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data ",
          Accept: "application/json",
        },
      };
      let formdata = new FormData();
      formdata.append("file", datos.ruta_logo);
      formdata.append("data", JSON.stringify(datos));
      var url = "";
      if (datos.id == 0) {
        var url = "cliente/store";
      } else {
        var url = "cliente/store";
      }
      await axios
        .post(url, formdata, config)
        .then((response) => {
          dispatch("cargarClientes");
          console.log("response > ",response);
          console.log("response > ",response.data.success);
          if(!response.data.success.status){
            Swal.fire({
              icon: "warning",
              title: "Advertencia",
              text: response.data.success,
              showCancelButton: false,
              confirmButtonColor: "#d33",
              confirmButtonText: "Aceptar",
            });
            commit('LlenarValidacion','error');
          }else{
            if (datos.id == 0) {
              Swal.fire({
                icon: "success",
                title: "Registrado",
                text: "Se guardo correctamente!",
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Actualizado",
                text: "Se actualizo correctamente!",
                showConfirmButton: false,
                timer: 2000
              });
            }
            commit('LlenarValidacion','correcto');
          }
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },

    verContacto: async function ({ commit, dispatch }, datos) {
      const url = "contacto/show";
      const data = { id: datos.id };
      await axios
        .post(url, data)
        .then((response) => {
          commit(
            "LlenarItemsContacto",
            response.data.success.resultado.contactos
          );
          commit("LlenarItemsCargo", response.data.success.resultado.cargos);
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },

    agregarContacto: async function ({ commit, dispatch }, datos) {
      var url = "contacto/store";
      await axios
        .post(url, datos)
        .then((response) => {
          commit("LlenarItemsContacto", response.data.success.resultado);
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },

    obtenerClientesPorUsuario: async function ({ commit, dispatch }) {
      var url = "userclientes/clientesxusuario";
      const datos = { id_usuario: localStorage.getItem("id") };
      await axios
        .post(url, datos)
        .then((response) => {
          commit("LlenarClientesUsuario", response.data.data);
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },

    obtenerUsuariosPorClientes: async function ({ commit, dispatch }) {
      var url = "cliente/usuariosxclientes";
      await axios
        .post(url)
        .then((response) => {
          commit("LlenarUsuarios", response.data.success.usuarios);
          commit("LlenarLoadingUserClients",false)
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },

    obtenerUsuariosPorCliente: async function ({ commit, dispatch }) {
      var url = "cliente/usuariosxcliente";
      let datos = { id_cliente: localStorage.getItem("idcliente") };
      await axios
        .post(url, datos)
        .then((response) => {
          let arrayroles = response.data.success.roles;
          let roles = [];
          arrayroles.forEach((element, i) => {
            if (element.id == 4 || element.id == 5) {
              roles[i] = {
                value: element.id,
                text: element.name,
              };
            }
          });
          commit("LlenarRoles", roles);
          commit("LlenarCliente", response.data.success.usuarios[0] ?? {});
          commit(
            "LlenarUsuarios",
            response.data.success.usuarios[0]["usuariosporcliente"] ?? []
          );
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },

    retornarDatosCliente: async function ({ commit, dispatch }, id_cliente) {
      var url = "cliente/show";
      let datos = { id_cliente: id_cliente };
      let rpta = null;
      await axios
        .post(url, datos)
        .then((response) => {
          rpta = response.data.success.datos;
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
      return rpta;
    },

    guardarAccesoACliente: async function ({ commit, dispatch }) {
      var url = "accesoscliente/store";
      let datos = {
        id_cliente: localStorage.getItem("idcliente"),
        id_usuario: localStorage.getItem("id"),
      };
      let rpta = null;
      await axios
        .post(url, datos)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },
  },
};
