import Vue from "vue";
import Vuex from "vuex";
import { axios2 as axios } from "@/interceptor/useApi";
import Swal from 'sweetalert2';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    Respuesta: [],
    Loading: true
  },
  getters: {
    Respuesta(state) {
      return state.Respuesta;
    },
  },
  mutations: {
    LlenarRespuesta(state, data) {
      state.Respuesta = data;
    },
    LlenarLoading(state, data) { 
      state.Loading = data;
    }
  },
  actions: { 
    eliminarModulo: async function ({ commit, dispatch }, datos) {
      const url = "modulos/eliminar";
      const data = { datos: datos };
      await axios
        .post(url, data)
        .then((response) => { 
          commit("LlenarRespuesta", response.data.success);
          commit("LlenarLoading", false);
        })
        .catch((error) => {
          this.errorMessage = error.response.data; 
        });
    },
    editarModulo: async function ({ commit, dispatch }, datos) {
      const url = "modulos/editar"; 
      const data = { datos:datos };
      await axios
        .post(url, data)
        .then((response) => {
          commit("LlenarRespuesta", response.data.success);
          commit("LlenarLoading", false);
        })
        .catch((error) => {
          this.errorMessage = error.response.data; 
        });
    },
    agregarModulo: async function ({ commit, dispatch }, datos) {
      const url = "modulos/agregar"; 
      const data = { datos:datos };
      await axios
        .post(url, data)
        .then((response) => {
          commit("LlenarRespuesta", response.data.success);
          commit("LlenarLoading", false);
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },
  },
};
