<template>
    <div>
        <Nuevo  :dialog="dialog" @dialog="dialog = $event" :Item="itemSelected1" />
        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
        <v-card>
            <v-card-title>
                {{ Titulo }}
                <v-spacer></v-spacer>
                <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                    Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-btn v-if="global._autorizacion_usuario(Permisos,$route.meta.id,['2'])" color="primary" dark class="mb-2" @click="nuevoItem()">
                    Nuevo
                </v-btn>
            </v-card-title>

            <v-data-table dense 
            :headers="filteredCabecera" :items="Items" :items-per-page="5" :search="search"
                class="elevation-1" loading-text="Cargando... Por favor, espere"
                no-data-text="No hay datos disponibles"
                :loading="Loading"
                :footer-props="{
                    'items-per-page-text': 'Resultados por página:',
                    'page-text': '{0}-{1} de {2}'
                }"> 
                <template v-slot:[`item.name`]="{ item }">
                    <v-chip style="width: 100%; align-items: center; justify-content: center;" color="success" outlined small>
                        {{ item.name }}
                    </v-chip>
                </template>
                <template v-slot:[`item.accion`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small class="mr-2" @click="editarItem(item)" v-bind="attrs" v-on="on">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                </template>
            </v-data-table>

        </v-card>
    </div>
</template>

<script>
import {
    mapActions,
    mapState
} from 'vuex';
import Nuevo from './nuevo.vue'
import exportFromJSON from "export-from-json"
import MensajeTransicion from "../Alerta.vue"
import global from '@/global';

export default {
    props: ["Titulo", "Cabecera", "Items", "Excel", "Crear","Loading"],
    components: {
        Nuevo,
        MensajeTransicion
    },
    data() {
        return {
            search: "",
            dialog: false,
            itemSelected1: { id: null, description: '', name: '' },
            mensaje: "",
            verAlerta: false,
            global: global 
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
        canShowAccionColumn() {
            return (global._autorizacion_usuario(this.Permisos,this.$route.meta.id,['3']));
        },
        filteredCabecera() {
            return this.Cabecera.filter(col => !(col.value === 'accion' && !this.canShowAccionColumn));
        }
    },
    methods: {
        ...mapActions("roles", ["eliminarRoles"]), 

        getColor(edad) {
            if (edad > 40)
                return "red";
            else if (edad > 25)
                return "info";
            else
                return "green";
        },

        nuevoItem() { 
            this.itemSelected1 = {
                id: '0',
                name: '',
                description: '',
            }
            this.dialog = true; 
        },

        eliminar(item) {
            this.$alertify.confirm(
                `¿Estás seguro de que deseas eliminar el rol "${item.name}" (ID: ${item.id})? Esta acción es irreversible`,
                () => { 
                    this.eliminarRoles(item)
                        .then(r => {
                            this.$alertify.success(`El rol "${item.name}" (ID: ${item.id}) ha sido eliminado exitosamente.`
);
                        }); 
                }
            ).setHeader('<h3 class="alertTitle">Aviso</h3');
        },

        editarItem(item) {
            this.itemSelected1 = { ...item }; 
            this.dialog = true;
        },
        DescargarExcel() {
            const data = this.Items;
            const nombreArchivo = 'download';
            const exportType = exportFromJSON.types.xls; //xls, json, css, html,txt 
            exportFromJSON({
                data,
                nombreArchivo,
                exportType
            })
        }
    }
};
</script>
