<template>
    <div>
        <v-app-bar dense dark app color="grey darken-3" elevate-on-scroll scroll-target="#scrolling-techniques-7">
            <v-app-bar-nav-icon @click="navigation()"></v-app-bar-nav-icon>
            {{ Cliente.razon_social }}
            <v-spacer></v-spacer>
            <!-- <v-menu offset-y style="cursor: pointer;"  v-if="hasClientId">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="openModalModeloMedidas()">
                        <v-icon>mdi mdi-traffic-light</v-icon>
                    </v-btn>
                </template>
            </v-menu>
            <v-menu offset-y style="cursor: pointer;"  v-if="hasClientId" >
                <template v-slot:activator="{ on, attrs }">
                    <v-badge :content="adminreportescount!=0?adminreportescount:'0'" color="orange" overlap class="custom-badge">
                        <v-btn icon v-bind="attrs" v-on="on" @click="redireccionar_reportes()">
                            <v-icon>mdi mdi-file-document</v-icon>
                        </v-btn>
                    </v-badge>
                </template> 
            </v-menu> -->
            <v-menu offset-y style="cursor: pointer;"  v-if="hasClientId">
                <template v-slot:activator="{ on, attrs }">
                    <v-badge :content="itemsneuproxreencauchar.length!=0?itemsneuproxreencauchar.length:'0'" color="red" overlap class="custom-badge">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi mdi-flag-variant</v-icon>
                    </v-btn>
                    </v-badge> 
                </template>
                <v-list dense> 
                    <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold" style="font-size: 14px;">
                        NEUMÁTICOS PRÓXIMOS A REENCAUCHAR  
                        </v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>  
                    <div class="scrollable-list">
                        <template v-for="(item, i) in itemsneuproxreencauchar">
                            <v-divider></v-divider>   
                            <v-list-item  class="hover-effect" style="cursor: pointer;">
                                <v-list-item-content> 
                                    <v-list-item-title style="margin-top: 4px; font-size: 13px;"> 
                                    {{ item.num_serie }} - {{ item.marca }}
                                    </v-list-item-title> 
                                    <div class="text-right" style="margin-top: -16px; color: grey; font-size: 12px;">  
                                    <span>{{ item.porcentaje_desgaste }}%</span>
                                    </div> 
                                    <v-progress-linear
                                    style="margin-bottom: 4px;"
                                    :value="item.porcentaje_desgaste"
                                    height="8"  
                                    color="orange"
                                    class="mt-1"
                                    ></v-progress-linear>
                                </v-list-item-content> 
                            </v-list-item> 
                        </template>
                        <template v-if="itemsneuproxreencauchar.length==0">
                            <v-divider></v-divider> 
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title  style="font-size: 12px;">
                                    No hay neumaticos proximos a reencauchar
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>  
                        </template>
                    </div>
                </v-list>
            </v-menu>
            <v-menu offset-y style="cursor: pointer;"  v-if="hasClientId">
                <template v-slot:activator="{ on, attrs }">
                    <v-badge :content="itemsneureencauchar.length!=0?itemsneureencauchar.length:'0'" color="red" overlap class="custom-badge">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi mdi-bell</v-icon>
                        </v-btn>
                    </v-badge>
                </template>
                <v-list dense style="width: 300px;"> 
                    <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold" style="font-size: 14px;">
                            NEUMÁTICOS A REENCAUCHAR
                        </v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>  
                    <div class="scrollable-list">
                        <template v-for="(item, i) in itemsneureencauchar">
                            <v-divider></v-divider>  
                            <v-list-item style="cursor: pointer;"  class="hover-effect"  @click="gomantvehi(item.vehiculo_id)">
                                <v-list-item-avatar>
                                    <v-icon class="red white--text">mdi-minus-circle</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <b>Serie: {{ item.num_serie }}</b> - {{ item.marca }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <i class="mdi mdi-clock-outline"></i> Remanente límite {{ item.remanente_limite }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <i class="mdi mdi-clock-outline"></i> Remanente {{ item.remanente_actual }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template v-if="itemsneureencauchar.length==0">
                            <v-divider></v-divider> 
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title  style="font-size: 12px;">
                                    No hay neumaticos a reencauchar
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>  
                        </template>
                    </div>
                </v-list>
            </v-menu>
            <!-- <v-menu offset-y style="cursor: pointer;"  v-if="hasClientId">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi mdi-layers-plus</v-icon>
                    </v-btn>
                </template>
                <v-list dense  > 
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-bold" style="font-size: 14px;">
                                Criterios de Aceptacion
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>  
                    <v-divider></v-divider>  
                    <v-list-item style="cursor: pointer;"  class="hover-effect" > 
                        <v-list-item-content>
                            <v-list-item-title>
                                Costo kilómetro
                            </v-list-item-title> 
                        </v-list-item-content>
                        <v-list-item-action style="margin-top: 8px; justify-content: center; margin-right: -3px;">
                            <v-badge :content="criterios_.criterios1 != 0 ?  criterios_.criterios1 : '0'" color="red" overlap> </v-badge>
                        </v-list-item-action>
                    </v-list-item> 
                    <v-list-item style="cursor: pointer;" class="hover-effect">
                        <v-list-item-content>
                            <v-list-item-title>
                                Costo por Hora
                            </v-list-item-title>
                        </v-list-item-content> 
                        <v-list-item-action style="margin-top: 8px; justify-content: center; margin-right: -3px;">
                            <v-badge :content="criterios_.criterios4 != 0 ?  criterios_.criterios4 : '0'" color="red" overlap> </v-badge>
                        </v-list-item-action>
                    </v-list-item> 
                    <v-list-item style="cursor: pointer;"  class="hover-effect" > 
                        <v-list-item-content>
                            <v-list-item-title>
                                Índice de Reencauche
                            </v-list-item-title> 
                        </v-list-item-content>
                        <v-list-item-action style="margin-top: 8px; justify-content: center; margin-right: -3px;">
                            <v-badge :content="criterios_.criterios2 != 0 ?  criterios_.criterios2 : '0'" color="red" overlap> </v-badge>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item style="cursor: pointer;"  class="hover-effect" > 
                        <v-list-item-content>
                            <v-list-item-title>
                                Índice de Reencauchabilidad 
                            </v-list-item-title> 
                        </v-list-item-content>
                        <v-list-item-action style="margin-top: 8px; justify-content: center; margin-right: -3px;">
                            <v-badge :content="criterios_.criterios3 != 0 ? criterios_.criterios3 : '0'" color="red" overlap> </v-badge>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-menu> -->
            <v-btn v-if="mostraricononotificaciones == true"  @click="openModalIncidencias()" icon>
                <v-icon>mdi-headset</v-icon>
            </v-btn>

            <v-menu offset-y v-if="mostraricononotificaciones == false">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-badge v-if="cantidadincidentes > 0" color="green" :content="cantidadincidentes" class="mt-2">
                            <v-icon>mdi-headset</v-icon>
                        </v-badge>
                        <v-icon v-else class="mt-2">mdi-headset</v-icon>
                    </v-btn>
                </template>

                <v-list width="350" class="overflow-y-auto" max-height="400" three-line>
                    <template v-for="(item, index) in notificaciones">
                        <v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader>

                        <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>

                        <v-list-item v-else :key="item.title">
                            <v-list-item-avatar>
                                <v-img :src="item.avatar"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title v-html="item.title"></v-list-item-title>
                                <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>
            </v-menu>

            <v-menu offset-y style="cursor: pointer;">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list :lines="false" dense>
                    <v-list-item v-for="(item, i) in items" :key="i" style="cursor: pointer;">
                        <v-list-item-icon style="margin-right: 10px;">
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content @click="Logout()" style="margin-right: 10px;">
                            <v-list-item-title v-text="item.text" @click="Logout()"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list :lines="false" dense v-if="Download">
                    <v-list-item style="cursor: pointer;">
                        <v-list-item-icon style="margin-right: 10px;">
                            <v-icon v-text="Download.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content @click="Dowloadapp(Download.nombreapk)" style="margin-right: 10px;">
                            <!-- Texto del item -->
                            <v-list-item-title v-text="Download.text" @click="Dowloadapp(Download.nombreapk)"></v-list-item-title>
                            <!-- Versión del item en color verde -->
                            <v-list-item-subtitle>
                                <span style="color: green;">Version : {{ Download.version }}</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <FormularioIncidencias :dialog="dialogformularioincidencia" v-if="verformularioincidencia"
            @cerrarModalIncidencias="cerrarModalIncidencias" />
            
        <FormularioModeloMedida :dialog="dialogformularioModeloMedida" v-if="verformularioModeloMedida"
            @cerrarModalModeloMedidas="cerrarModalModeloMedidas" />
    </div>
</template>
<style>
    .scrollable-list {
    max-height: 275px; /* Altura máxima del listado */
    overflow-y: auto;  /* Habilitar el desplazamiento vertical */
    }
    .v-application--is-ltr .custom-badge  .v-badge__badge { 
        inset: auto auto calc(100% - 20px) calc(100% - 20px) !important;
    }
    .custom-badge .v-badge__badge {
        border-radius: 10px;
        color: #fff;
        font-size: 10px !important;
        height: 16px !important;
        letter-spacing: 0;
        min-width: 20px;
        padding: 3px 5px !important;
        pointer-events: auto;
        position: absolute;
        text-align: center;
        text-indent: 0;
        top: auto;
        transition: .3s cubic-bezier(.25,.8,.5,1);
        white-space: nowrap;
    } 
    
    .hover-effect {
    transition: background-color 0.3s ease;
    }

    /* Hover effect */
    .hover-effect:hover {
    background-color: #f0f0f0 !important; /* Gris claro */
    }

    /* Cambiar el color del porcentaje */
    .hover-effect .text-right span {
    color: grey !important; /* Cambia el color a gris */
    }
</style>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import FormularioIncidencias from "@/components/incidencias/nuevo.vue";
import FormularioModeloMedida from "@/components/indicador/nuevo.vue";

export default {
    props: ['drawer'],
    components: {
        FormularioIncidencias,FormularioModeloMedida
    },
    data: () => ({
        selectedItem: 1,
        items: [
            { text: 'Cerrar la sesión', icon: 'mdi-account', funcion: 'Logout' },
        ],
        dowloads: [
            { text: 'Descargar App', icon: 'mdi-download', funcion: 'Dowloadapp', version: 'tiresoft-2.5', nombreapk: 'tiresoft-production.apk' },
        ],
        notificaciones: [],
        cantidadincidentes: 0,
        dialogformularioincidencia: false,
        verformularioincidencia: false,
        mostraricononotificaciones: false,
        mostrarcomponentenotificaciones: false,
        mostrarnotificaciones: false,
        cant_cost_kilo:0,
        cant_cost_hora:0,
        cant_indi_reencauche:0,
        cant_indi_reencauchabilidad:0,
        cant_reportes:0,
        dialogformularioModeloMedida:false,
        verformularioModeloMedida:false,
    }),
    computed: {
        ...mapState('incidente', ["Incidentes"]) ,
        ...mapState("cliente", ["Cliente"]),
        ...mapState("versionapk", ["Download"]),
        ...mapState("neumatico", ["itemsneureencauchar","itemsneuproxreencauchar",'criterios_','adminreportescount']),
        ...mapGetters('navigation', ['Mini']),
        hasClientId() { 
            return localStorage.getItem('idcliente') !== null;
        } , 
    },
    methods: {
        ...mapActions('navigation', ['Minimizar']),
        ...mapActions("versionapk", ["ObtenerVersionado"]),
        ...mapActions("cliente", ["ObtenerDatosCliente"]),
        ...mapActions("incidente", ["cargarIncidentes"]), 
        ...mapActions("neumatico", ["Neumaticosareencauchar","Neumaticosproxareencauchar","GetCriterios_","Getadminreportescount"]),
        obtenerincidentes() {
            console.log(this.Incidentes,'Incidentes');
            return this.Incidentes;
        },
        gomantvehi(id_vehiculo){ 
            this.$router.push({ path: `/ubicaciontemporal` });
            setTimeout(() => {
                this.$router.push({ path: `/vehiculo/instalacion/${id_vehiculo}` });
            }, 10); // Usa un retraso mínimo, como 10ms
        },
        redireccionar_reportes(){
            this.$router.push({ path: `/administracionreportes` });
        },
        navigation() {
            console.log("🚀 ~ file: Bar.vue:75 ~ navigation ~ this.drawer:", this.drawer);
            console.log("🚀 ~ file: Bar.vue:77 ~ navigation ~ this.Mini:", this.Mini)
            if (!this.Mini && !this.drawer) {
                this.$emit('drawer', !this.drawer);
            } else {
                this.Minimizar(!this.Mini);
                this.$emit("revertirDespliegue");
            }
        },
        openModalIncidencias() {
            this.verformularioincidencia = true;
            this.dialogformularioincidencia = true;
        },
        cerrarModalIncidencias() {
            this.verformularioincidencia = false;
            this.dialogformularioincidencia = false;
        },
        openModalModeloMedidas() {
            this.verformularioModeloMedida=true;
            this.dialogformularioModeloMedida=true;
        },
        cerrarModalModeloMedidas(){
            this.verformularioModeloMedida=false;
            this.dialogformularioModeloMedida=false;
        },
        Logout() {
            //localStorage.setItem('logueo','')
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();
            //router.push('/login')
        },
        Dowloadapp(nombreapk) {
            // const url = "/downloads/tiresoft-developers.apk";     
            const url = "/downloads/"+nombreapk;   
            
            window.location.href = url;
            console.log('url > ',url);
            
            // console.log('Dowloadapp > ', localStorage);
        }, 
    },
    async mounted() {
        let id_cliente = localStorage.getItem("idcliente");
        if (id_cliente == undefined || id_cliente == null) {
            this.mostraricononotificaciones = false;
            this.notificaciones = await this.cargarIncidentes();
            this.cantidadincidentes = this.notificaciones.length - 1;
        } else {
            this.mostraricononotificaciones = true;
            this.Neumaticosareencauchar();
            this.Neumaticosproxareencauchar();
            // this.GetCriterios_();
            this.Getadminreportescount();
        }
        this.ObtenerDatosCliente();
        this.ObtenerVersionado();
    }
}
</script>
