
<template>
  <div class="pa-5">
    <v-col lg="12">
      <tabla
        Titulo="Reporte de Vehiculo" 
        :Cabecera="Cabecera"
        :Items="Items"
        :ItemTotal="ItemTotal"
        :Loading="Loader"
        :Excel="false"
        :Crear="true"
      />
    </v-col>
  </div>
</template>

<script>
import tabla from "@/components/vehiculos/tabla.vue";

import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "Vehiculos",
  components: {
    tabla,
  },
  created() {
    this.cargarDatosAdicionales();
    this.cargarVehiculos();
  },
  data() {
    return {
      Cabecera: [
        { text: "Opc.", value: "acciones",width: 80, align: "left"},
        { text: "Id", value: "id",width: 70, align: "left" },
        { text: "Placa", value: "placa", width: 100, align: "left" },
        { text: "Código", value: "codigo",width: 100, align: "left" },
        { text: "Tipo", value: "tipo",width: 100, align: "left" },
        { text: "Marca", value: "marca",width: 100, align: "left" },
        { text: "Modelo", value: "modelo", width: 100, align: "left" },
        { text: "Configuración", value: "configuracion",width: 130, align: "left" },
        { text: "Aplicación", value: "aplicacion",width: 120, align: "left" },
        { text: "Registro", value: "fecha_registro", width: 110, align: "left" },
        { text: "Planta", value: "nomplanta", width: 120, align: "left" },
        { text: "Estado", value: "nomestado",width: 100, align: "left" },
      ],
    };
  },
  methods: {
    ...mapActions("vehiculo", ["cargarVehiculos", "cargarDatosAdicionales"]),
  },

  computed: {
    ...mapGetters("vehiculo", ["Items", "ItemTotal"]),
    ...mapState("vehiculo", ["Loader"])
  },
};
</script>




