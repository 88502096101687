<template>
<div class="pa-2">
    <v-dialog v-model="dialog" max-width="1000px">
        <v-card>
            <v-card-title>
                <span v-if="Item.id == null" class="text-h6">Registro de neumático</span>
                <span v-else class="text-h6">Editar neumático</span>
                <v-spacer></v-spacer>
                <span v-if="Item.id != null">ID: {{ Item.id }}</span>
            </v-card-title>
            <!--
            <v-card-subtitle v-if="Item.id != null">Serie: {{ Item.num_serie }}</v-card-subtitle>
            -->
            <!--
            <v-toolbar height="50" color="#6B6A6A" dark>
                <span v-if="Item.id == null" class="text-h6">Registro de neumático</span>
                <span v-else class="text-h6">Editar neumático</span>
                <v-spacer></v-spacer>
                <span v-if="Item.id != null">ID: {{ Item.id }}</span>
            </v-toolbar>
            -->
            <v-card-text>
                <v-form enctype="multipart/form-data" ref="form" v-model="valid_form">
                    <v-row>
                        <!-- REGISTER TIRES GROUP -->
                        <v-col cols="12" md="3" sm="3">
                            <div v-if="showGroups == true" class="mt-4">
                                <div>
                                    <v-btn color="#009688" small style="width: 100%" @click="openModalGroup()">
                                        <font color="white"> Agregar</font>
                                    </v-btn>

                                    <neumatico-group :neumaticoGroup="neumaticoGroup"></neumatico-group>

                                    <v-btn color="error" small style="width: 100%" @click="emptyGroups()">
                                        Limpiar
                                    </v-btn>
                                </div>
                                <div class="mt-10">
                                    <small v-if="neumaticoGroup.length == 0" class="pl-5">
                                        <font color="red">Serie de neumático * </font>
                                    </small>
                                </div>
                            </div>

                            <div v-else>
                                <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field label="N° de serie *" v-model="Item.num_serie" hide-details="auto" required :rules="[(v) => !!v || 'Marca es requerido']"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" class="mt-5">
                                        <v-text-field label="Serie del cliente" v-model="Item.serie_cliente" hide-details="auto" required></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <!--GENERAL TIRE DETAILS-->
                        <v-col cols="12" md="9" sm="9">
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field type="number" label="Costo sin igv *" v-model="Item.precio" hide-details="auto" :rules="[(v) => !!v || 'Costo es requerido']"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-select class="mt-5" :items="monedaList" v-model="Item.tipo_moneda" label="Moneda *" :rules="[(v) => !!v || 'Moneda es requerido']" dense></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-select class="mt-5" :items="condicionNeumaticoList" v-model="Item.nuevo" label="Condición del neumatico *" dense @change="limpiarRemanentes" :disabled="Item.id != null" :rules="[v => (v === 0 || v === 1) || 'Condición de neumáticos es requerido']"></v-select>
                                </v-col>

                                <v-col cols="12" sm="6" md="4" v-if="Item.nuevo == 0">
                                    <v-autocomplete class="mt-2" :items="cantidadReencaucheList" v-model="Item.cantidad_reencauche" label="Cantidad de reencauches *" :rules="[(v) => !!v || 'Cantidad de reencauches es requerido']" dense></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" v-if="Item.nuevo == 0">
                                    <v-autocomplete :items="disenioNeumaticoList" v-model="Item.id_disenio" label="Diseño de Banda *" :rules="[(v) => !!v || 'Diseño es requerido']" dense>
                                        <template v-slot:append-outer>
                                            <v-slide-x-reverse-transition mode="out-in">
                                                <v-icon color="success" @click="openModalDisennio()" v-text="'mdi-plus-circle'"></v-icon>
                                            </v-slide-x-reverse-transition>
                                        </template>
                                    </v-autocomplete>
                                    <!--
                                    <v-btn-toggle mandatory class="mt-2">
                                        <v-autocomplete :items="disenioNeumaticoList" v-model="Item.id_disenio" label="Diseño de Banda *" :rules="[(v) => !!v || 'Diseño es requerido']" dense></v-autocomplete>
                                        <v-btn color="success" height="30" width="15" class="text--color:#fff" x-small @click="openModalDisennio()">
                                            <v-icon x-small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                    -->
                                </v-col>
                                <v-col cols="12" sm="6" md="4" v-if="Item.nuevo == 0">
                                    <v-autocomplete :items="empresaList" v-model="Item.id_empresa" label="Empresa Reencauchadora *" :rules="[(v) => !!v || 'Empresa es requerido']" dense>
                                        <template v-slot:append-outer>
                                            <v-slide-x-reverse-transition mode="out-in">
                                                <v-icon color="success" @click="openModalEmpresa()" v-text="'mdi-plus-circle'"></v-icon>
                                            </v-slide-x-reverse-transition>
                                        </template>
                                    </v-autocomplete>
                                    <!--
                                    <v-btn-toggle mandatory class="mt-2">
                                        <v-autocomplete :items="empresaList" v-model="Item.id_empresa" label="Empresa Reencauchadora *" :rules="[(v) => !!v || 'Empresa es requerido']" dense></v-autocomplete>
                                        <v-btn color="success" height="30" width="15" class="text--color:#fff" x-small @click="openModalEmpresa()">
                                            <v-icon x-small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                    -->
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <v-autocomplete :items="marcaNeumaticoList" v-model="Item.id_marca" label="Marca de neumático" :rules="[(v) => !!v || 'Marca es requerido']" dense @change="cargarModelos(Item.id_marca)">
                                        <template v-slot:append-outer>
                                            <v-slide-x-reverse-transition mode="out-in">
                                                <v-icon color="success" @click="openModalMarca()" v-text="'mdi-plus-circle'"></v-icon>
                                            </v-slide-x-reverse-transition>
                                        </template>
                                    </v-autocomplete>
                                    <!--
                                    <v-btn-toggle mandatory class="mt-2">
                                        <v-autocomplete :items="marcaNeumaticoList" v-model="Item.id_marca" label="Marca de neumático" :rules="[(v) => !!v || 'Marca es requerido']" dense @change="cargarModelos(Item.id_marca)"></v-autocomplete>
                                        <v-btn color="success" height="30" width="15" class="text--color:#fff" x-small @click="openModalMarca()">
                                            <v-icon x-small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                    -->
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-autocomplete :items="modeloNeumaticoPorMarcaList" v-model="Item.id_modelo" label="Modelo de neumático" :rules="[(v) => !!v || 'Modelo es requerido']" dense>
                                        <template v-slot:append-outer>
                                            <v-slide-x-reverse-transition mode="out-in">
                                                <v-icon color="success" @click="openModalModelo()" v-text="'mdi-plus-circle'"></v-icon>
                                            </v-slide-x-reverse-transition>
                                        </template>
                                    </v-autocomplete>
                                    <!--
                                    <v-btn-toggle mandatory class="mt-2">
                                        <v-autocomplete :items="modeloNeumaticoPorMarcaList" v-model="Item.id_modelo" label="Modelo de neumático" :rules="[(v) => !!v || 'Modelo es requerido']" dense></v-autocomplete>
                                        <v-btn color="success" height="30" width="15" class="text--color:#fff" x-small @click="openModalModelo()">
                                            <v-icon x-small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                    -->
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-autocomplete :items="medidaNeumaticoList" v-model="Item.id_medida" label="Medida de neumático" :rules="[(v) => !!v || 'Medida es requerido']" dense>
                                        <template v-slot:append-outer>
                                            <v-slide-x-reverse-transition mode="out-in">
                                                <v-icon color="success" @click="openModalMedida()" v-text="'mdi-plus-circle'"></v-icon>
                                            </v-slide-x-reverse-transition>
                                        </template>
                                    </v-autocomplete>
                                    <!--
                                    <v-btn-toggle mandatory class="mt-2">
                                        <v-autocomplete :items="medidaNeumaticoList" v-model="Item.id_medida" label="Medida de neumático" :rules="[(v) => !!v || 'Medida es requerido']" dense></v-autocomplete>
                                        <v-btn color="success" height="30" width="15" class="text--color:#fff" x-small @click="openModalMedida()">
                                            <v-icon x-small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                    -->
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field class="pa-0" v-mask="numberDecimal" hint="(rec. <= 125 mm)" persistent-hint :readonly="Item.nuevo == 0" v-model="Item.remanente_original" placeholder="00.00" label="Profundidad de rodado original *" hide-details="auto" :rules="validarRemanenteOriginal"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field class="pa-0" v-mask="numberDecimal" hint="(rec. <= 125 mm)" persistent-hint :readonly="Item.nuevo == 1" v-model="Item.remanente_actual" placeholder="00.00" label="Profundidad de rodado reencauche*" hide-details="auto" :rules="validarRemanenteActual"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field class="pa-0" v-mask="numberEntero" hint="(rec. <= 160 PSI)" persistent-hint v-model="Item.presion_recomendada" placeholder="0" label="Presión recomendada *" hide-details="auto" :rules="presionRecomendada"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <v-select class="mt-4" :items="opcionesInstalacion" v-model="Item.unidad_medida" label="K/H de instalacion *" :rules="[
                        (v) => !!v || 'Opciones de instalación es requerido',
                      ]" dense></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field type="number" placeholder="00.00" v-model="Item.k_recorridos" hide-details="auto" class="pa-0 mt-2"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cerrarDialogo(false)"> Cerrar </v-btn>
                <v-btn v-if="Item.id == null" color="info" :loading="loader" @click="agregar()">
                    Guardar
                </v-btn>
                <v-btn v-else color="info" :loading="loader" @click="agregar()"> Actualizar </v-btn>
            </v-card-actions>
            <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
        </v-card>
    </v-dialog>

    <!--MODAL ADD GROUP-->
    <v-dialog v-model="dialogGroup" max-width="300px">
        <v-card>
            <v-card-text>
                <v-container>
                    <v-form ref="form_group" v-model="valid">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Serie del cliente*" required v-model="group.num_serie" :rules="[(v) => !!v || 'Serie del cliente es requerido']"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="DOT" v-model="group.serie_cliente"></v-text-field>
                            </v-col>
                            <small v-if="mostrarLabelDuplicado">
                                <font color="red">Serie de neumático duplicada</font>
                            </small>
                        </v-row>
                    </v-form>
                </v-container>
                <small v-if="mostrarLabelDuplicado == false">*Indicador de campo requerido</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn style="width: 100%" :disabled="!valid" :loading="loadingGroup" small color="#009688" @click="addGroup(group)">
                    <font color="white">Agregar</font>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!--MODAL ADD MARCA-->
    <v-row justify="center">
        <v-dialog v-model="dialogMarca" max-width="300px">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="form-marca" v-model="valid">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field label="Nombre de la marca *" required v-model="marca.descripcion" :rules="descripcionRules"></v-text-field>
                                </v-col>
                                <small v-if="mostrarLabelDuplicado">
                                    <font color="red">Marca duplicada</font>
                                </small>
                            </v-row>
                        </v-form>
                    </v-container>
                    <small v-if="mostrarLabelDuplicado == false">*Indicador de campo requerido</small>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!valid" class="mt-4" style="width: 100%" small color="#009688" @click="addMarca(marca)">
                        <font color="white">Agregar</font>
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>

    <!--MODAL ADD DISENIO-->
    <v-row justify="center">
        <v-dialog v-model="dialogDisenio" max-width="300px">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="form-disenio" v-model="valid">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field label="Diseño *" required v-model="disenio.nombre" :rules="descripcionRules"></v-text-field>
                                </v-col>
                                <small v-if="mostrarLabelDuplicado">
                                    <font color="red">Diseño duplicado</font>
                                </small>
                            </v-row>
                        </v-form>
                    </v-container>
                    <small v-if="mostrarLabelDuplicado == false">*Indicador de campo requerido</small>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!valid" class="mt-4" style="width: 100%" small color="#009688" @click="addDisenio(disenio)">
                        <font color="white">Agregar</font>
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>

    <!--MODAL ADD EMPRESA-->
    <v-row justify="center">
        <v-dialog v-model="dialogEmpresa" max-width="300px" v-if="dialogEmpresa == true">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="form_empresa" v-model="valid">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field v-mask="numberRUC" label="RUC *" v-model="empresa.ruc" :rules="[(v) => !!v || 'RUC es requerido']"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field label="Razon social *" required v-model="empresa.razon_social" :rules="descripcionRules"></v-text-field>
                                </v-col>
                                <small v-if="mostrarLabelDuplicado">
                                    <font color="red">Ruc duplicado</font>
                                </small>
                            </v-row>
                        </v-form>
                    </v-container>
                    <small v-if="mostrarLabelDuplicado == false">*Indicador de campo requerido</small>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!valid" class="mt-4" style="width: 100%" small color="#009688" @click="addEmpresa(empresa)">
                        <font color="white">Agregar</font>
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>

    <!--MODAL ADD MODELO-->
    <v-row justify="center" v-if="modal_form_modelo">
        <v-dialog v-model="dialogModelo" max-width="300px" @click:outside="cerrarDialogModelo">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="form-modelo" v-model="valid">
                            <v-row>
                                <v-col cols="12" class="mt-5">
                                    <v-autocomplete :items="marcaNeumaticoList" v-model="modelo.id_marca" label="Marca de neumático" :rules="[(v) => !!v || 'Marca es requerido']" dense></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field label="Nombre del modelo *" placeholder="HN08" required v-model="modelo.descripcion" :rules="descripcionRules"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                    <small>*Indicador de campo requerido</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!valid" style="width: 100%" small color="#009688" @click="addModelo(modelo)">
                        <font color="white">Agregar</font>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

    <!--MODAL ADD MEDIDA-->
    <v-row justify="center" v-if="modal_form_medida">
        <v-dialog v-model="dialogMedida" max-width="450px" @click:outside="cerrarDialogMedida">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="form-medida" v-model="valid">
                            <v-row>
                                <v-col cols="12" md="4" sm="4">
                                    <v-text-field hint="70" persistent-hint label="Ancho de la llanta *" @change="onEscribirDescripcion" v-model="medida.ancho" :rules="[(v) => !!v || 'Ancho es requerido']" type="number"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="4">
                                    <v-text-field hint="275" persistent-hint label="Perfil de la llanta *" @change="onEscribirDescripcion" v-model="medida.perfil" :rules="[(v) => !!v || 'Perfil es requerido']" type="number"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="4">
                                    <v-text-field hint="R22.5" persistent-hint label="Aro de la llanta *" @change="onEscribirDescripcion" v-model="medida.aro" :rules="[(v) => !!v || 'Aro es requerido']"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" sm="12">
                                    <v-text-field hint="275/70R22.5" :readonly="!checkbox" persistent-hint label="Medida/Descripción *" required v-model="medida.descripcion" :rules="descripcionRules"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" class="pa-0">
                                    <v-checkbox v-model="checkbox" label="Activar descripción"></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                    <small>*Indicador de campo requerido</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!valid" style="width: 100%" small color="#009688" @click="addMedida(medida)">
                        <font color="white">Agregar</font>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</div>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import neumaticoGroup from "./groups.vue";
import MensajeTransicion from "../Alerta.vue"
import global from "@/global";
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {
    notif
} from "@/plugins/notyf"
//https://github.com/text-mask/text-mask/tree/master/addons/#createnumbermask

const numberDecimal = createNumberMask({
    prefix: '',
    integerLimit: 3,
    decimalLimit: 2,
    allowDecimal: true,
})

const numberEntero = createNumberMask({
    prefix: '',
    integerLimit: 3
})

const numberRUC = createNumberMask({
    prefix: '',
    integerLimit: 11,
    decimalLimit: 0,
    allowDecimal: false,
    includeThousandsSeparator: false
})

export default {
    components: {
        MensajeTransicion,
        neumaticoGroup
    },
    props: [
        "dialog",
        "Item",
        "Titulo",
        "showGroups",
        "propiedadMd",
        "neumaticoCondicionList",
        "marcaNeumaticoList",
        "modeloNeumaticoList",
        "medidaNeumaticoList",
        "disenioNeumaticoList",
        "empresaList",
    ],
    data() {
        return {
            numberDecimal,
            numberEntero,
            numberRUC,
            modal_form_medida: true,
            modal_form_modelo: true,
            checkbox: false,
            valid: false,
            dialogGroup: false,
            dialogMarca: false,
            dialogModelo: false,
            dialogMedida: false,
            dialogDisenio: false,
            dialogEmpresa: false,
            valid_form: false,
            neumaticoGroup: [],
            group: {},
            marca: {},
            modelo: {},
            medida: {},
            disenio: {},
            empresa: {},
            condicionNeumaticoList: [{
                    value: 1,
                    text: "Nuevo",
                },
                {
                    value: 0,
                    text: "Reencauchado",
                },
            ],
            monedaList: [{
                    value: 2,
                    text: "$ Dólares",
                },
                {
                    value: 1,
                    text: "S/. Soles",
                },
            ],
            opcionesInstalacion: [{
                    value: "K",
                    text: "Kilómetros",
                },
                {
                    value: "H",
                    text: "Horas",
                },
            ],
            cantidadReencaucheList: [{
                    value: 1,
                    text: 1,
                },
                {
                    value: 2,
                    text: 2,
                },
                {
                    value: 3,
                    text: 3,
                },
                {
                    value: 4,
                    text: 4,
                },
                {
                    value: 5,
                    text: 5,
                },
                {
                    value: 6,
                    text: 6,
                },
            ],
            modeloNeumaticoPorMarcaList: [],
            profundidadRemanenteOriginal: [
                (v) => !!v || "Profundidad es requerida",
                (v) =>
                (v && v <= 125) ||
                "Profundidad recomendada debe ser (rec. <= 125 mm)",
            ],
            profundidadRemanenteActual: [
                (v) => !!v || "Profundidad es requerida",
                (v) =>
                (v && v <= 125) ||
                "Profundidad recomendada debe ser (rec. <= 125 mm)",
            ],
            presionRecomendada: [
                (v) => !!v || "La presión es requerida",
                (v) =>
                (v && v <= 160) ||
                "La presión recomendada debe ser (rec. <= 160 PSI)",
            ],
            validarRemanenteOriginal: this.profundidadRemanenteOriginal,
            validarRemanenteActual: this.profundidadRemanenteActual,
            descripcionRules: [(v) => !!v || "Descripción es requerido"],
            mostrarLabelDuplicado: false,
            verAlerta: false,
            mensaje: "",
            loader: false,
            loadingGroup: false
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"])
    },
    methods: {
        ...mapActions("neumatico", ["agregarNeumatico", "agregarItemArray", "validateSerialNumber"]),

        onEscribirDescripcion() {
            if (this.medida.ancho == "") {
                this.medida.descripcion = this.medida.perfil + this.medida.aro;
            } else if (this.medida.perfil == "" && this.medida.aro == "") {
                this.medida.descripcion = this.medida.ancho;
            } else if (this.medida.ancho != "" && this.medida.aro != "" && this.medida.perfil == "") {
                this.medida.descripcion = this.medida.ancho + this.medida.aro;
            } else {
                this.medida.descripcion = this.medida.perfil + "/" + this.medida.ancho + this.medida.aro;
            }
        },

        inicializarGroup: function () {
            this.group = {
                num_serie: null,
                serie_cliente: null,
            };
        },

        inicializarMarca: function () {
            this.marca = {
                descripcion: null,
            };
        },

        inicializarDisenio: function () {
            this.disenio = {
                nombre: null,
            };
        },

        inicializarEmpresa: function () {
            this.empresa = {
                ruc: "",
                razon_social: null
            };
        },

        inicializarModelo: function () {
            this.modelo = {
                id_marca: null,
                descripcion: null,
            };
        },

        inicializarMedida: function () {
            this.medida = {
                descripcion: null,
                perfil: "",
                ancho: "",
                aro: "",
            };
        },

        openModalGroup: function () {
            this.inicializarGroup();
            this.loadingGroup = false;
            this.dialogGroup = true;
            this.mostrarLabelDuplicado = false;
            if (this.$refs.form_group) {
                this.$refs.form_group.resetValidation();
            }
        },

        openModalMarca: function () {
            this.mostrarLabelDuplicado = false;
            this.inicializarMarca();
            this.dialogMarca = true;
        },

        openModalModelo: function () {
            this.mostrarLabelDuplicado = false;
            this.$nextTick().then(() => {
                this.modal_form_modelo = true;
            });
            this.inicializarModelo();
            if (this.Item.id_marca != null) {
                this.modelo.id_marca = this.Item.id_marca;
            }
            this.dialogModelo = true;
        },

        openModalMedida: function () {
            this.mostrarLabelDuplicado = false;
            this.$nextTick().then(() => {
                this.modal_form_medida = true;
            });
            this.inicializarMedida();
            this.dialogMedida = true;
        },

        openModalDisennio: function () {
            this.mostrarLabelDuplicado = false;
            this.inicializarDisenio();
            this.dialogDisenio = true;
        },

        openModalEmpresa: function () {
            this.mostrarLabelDuplicado = false;
            this.inicializarEmpresa();
            this.dialogEmpresa = true;
        },

        emptyGroups: function () {
            this.neumaticoGroup = [];
            this.$forceUpdate();
        },

        addGroup: async function (item) {
            this.loadingGroup = true;
            let rpta = await this.validateSerialNumber(item.num_serie.toUpperCase());
            if( rpta == ""){
                let existe = this.neumaticoGroup.find((el) => el.num_serie == item.num_serie.toUpperCase());
                let serie_cliente = item.serie_cliente == null ? null : item.serie_cliente.toUpperCase();

                if (existe == null) {
                    this.neumaticoGroup.push({
                        num_serie: item.num_serie.toUpperCase(),
                        serie_cliente: serie_cliente
                    });
                    this.dialogGroup = false;
                    this.loadingGroup = false;
                    this.$forceUpdate();
                } else {
                    this.loadingGroup = false;
                    this.mostrarLabelDuplicado = true;
                }
            }else{
                this.loadingGroup = false;
                this.mostrarLabelDuplicado = true;
                this.mensaje = rpta;
            }
        },

        addDisenio: async function (item) {
            if (
                !this.verificarDuplicados(
                    this.disenioNeumaticoList,
                    item.nombre.toUpperCase()
                )
            ) {
                //Guardar en la base de datos
                item.nombre = item.nombre.toUpperCase();
                let dato = await this.agregarItemArray({
                    url: "neumaticodisenio/store",
                    item: item,
                });
                this.disenioNeumaticoList.push({
                    value: dato.id,
                    text: dato.nombre,
                });
                this.dialogDisenio = false; //Dialog
            } else {
                this.mostrarLabelDuplicado = true;
            }
        },

        addMarca: async function (item) {
            if (
                !this.verificarDuplicados(
                    this.marcaNeumaticoList,
                    item.descripcion.toUpperCase()
                )
            ) {
                //Guardar en la base de datos
                item.descripcion = item.descripcion.toUpperCase();
                let dato = await this.agregarItemArray({
                    url: "neumaticomarca/store",
                    item: item,
                });
                this.marcaNeumaticoList.push({
                    value: dato.id,
                    text: dato.descripcion,
                });
                this.dialogMarca = false;
            } else {
                this.mostrarLabelDuplicado = true;
            }
        },

        addModelo: async function (item) {
            if (
                !this.verificarDuplicados(
                    this.modeloNeumaticoList,
                    item.descripcion.toUpperCase()
                )
            ) {
                item.descripcion = item.descripcion.toUpperCase();
                let dato = await this.agregarItemArray({
                    url: "neumaticomodelo/store",
                    item: item,
                });
                this.modeloNeumaticoList.push({
                    value: dato.id,
                    text: dato.descripcion,
                    marca: dato.marca,
                });
                this.modeloNeumaticoPorMarcaList = [];
                if (this.Item.id_marca != null) {
                    this.cargarModelos(this.Item.id_marca);
                }
                this.dialogModelo = false;
            } else {
                this.mostrarLabelDuplicado = true;
            }
        },

        addEmpresa: async function (item) {
            let existe = false;
            this.empresaList.forEach((element) => {
                if (element.ruc == item.ruc) {
                    existe = true;
                }
            });
            //!this.verificarDuplicados(this.empresaList,item.razon_social)
            if (!existe) {
                //Guardar en la base de datos
                item.razon_social = item.razon_social.toUpperCase();
                let dato = await this.agregarItemArray({
                    url: "empresas/store",
                    item: item,
                });
                this.empresaList.push({
                    value: dato.id,
                    text: dato.razon_social,
                });
                this.dialogEmpresa = false; //Dialog
            } else {
                this.mostrarLabelDuplicado = true;
            }
        },

        addMedida: async function (item) {
            if (
                !this.verificarDuplicados(
                    this.medidaNeumaticoList,
                    item.descripcion.toUpperCase()
                )
            ) {
                //Guardar en la base de datos
                item.descripcion = item.descripcion.toUpperCase();
                let dato = await this.agregarItemArray({
                    url: "neumaticomedida/store",
                    item: item,
                });
                this.medidaNeumaticoList.push({
                    value: dato.id,
                    text: dato.descripcion,
                });
                this.dialogMedida = false;
            } else {
                this.mostrarLabelDuplicado = true;
            }
        },

        cargarModelos: function (id_marca) {
            this.modeloNeumaticoPorMarcaList = [];
            this.modeloNeumaticoList.forEach((element) => {
                if (element.marca == id_marca) {
                    this.modeloNeumaticoPorMarcaList.push(element);
                }
            });
        },

        verificarDuplicados: function (list, item) {
            let existe = false;
            list.forEach((element) => {
                if (element.text == item) {
                    existe = true;
                }
            });
            return existe;
        },

        async agregar() {
            if (this.$refs.form.validate()) {
                this.loader = true;
                let datos = {
                    grupo: this.neumaticoGroup,
                    neumatico: this.Item
                };

                if (this.neumaticoGroup.length == 0 && this.Item.id == null) {
                    this.verAlerta = true;
                    this.mensaje = "Agregue serie del neumático";
                    setTimeout(() => [(this.verAlerta = false)], 4000);
                    this.loader = false;
                } else {
                    if (this.Item.id == null || this.Item.id == '') { 
                        await this.agregarNeumatico(datos).then((r) => {
                            //En la función se vuelve a cargar a los neumaticos
                            this.neumaticoGroup.forEach(element => {
                                notif.success('Se registro el neumático: ' + element.num_serie);
                            });
                            this.cerrarDialogo();
                        }).catch(error => {
                            console.error("error!", error);
                        }); 
                    } else {
                        await this.agregarNeumatico(datos).then((r) => {
                            //En la función se vuelve a cargar a los neumaticos
                            notif.success('Se actualizó los datos del neumático: ' + this.Item.num_serie);
                            this.cerrarDialogo(false);
                        });
                    }
                }
            }
        },

        limpiarRemanentes: function () {
            this.Item.id_disenio = null;
            this.Item.cantidad_reencauche = null;
            this.Item.id_empresa = null;

            this.Item.remanente_actual = null;
            this.Item.remanente_original = null;
            this.asignarReglasRemanente();
        },

        asignarReglasRemanente: function () {
            if (this.Item.nuevo == 1) {
                this.validarRemanenteOriginal = this.profundidadRemanenteOriginal;
                this.validarRemanenteActual = [];
            } else {
                this.validarRemanenteActual = this.profundidadRemanenteActual;
                this.validarRemanenteOriginal = [];
            }
        },

        LimpiarCampos() {
            this.Item.id = null;
            this.Item.razon_social = "";
            this.Item.ruc = "";
            this.Item.estado = "";
            this.Item.ruta_logo = "";
            this.Item.costo = "";
            this.Item.imagen = "";
            this.Item.direccion = "";
        },

        cerrarDialogo() {
            this.loader = false;
            this.$emit("cerrarModal");
            this.neumaticoGroup = [];
        },

        cerrarDialogMedida() {
            this.dialogMedida = false;
            this.modal_form_medida = false;
        },

        cerrarDialogModelo() {
            this.dialogModelo = false;
            this.modal_form_modelo = false;
        },
    },
    mounted() {
        if (this.Item.id != null && this.Item.id != '') {
            this.asignarReglasRemanente();
            this.cargarModelos(this.Item.id_marca);
        }
    }
};
</script>
<style>