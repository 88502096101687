import Vue from "vue";
import Vuex from "vuex";
import { axios2 as axios } from "@/interceptor/useApi";
import Swal from 'sweetalert2';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    Item: null,
    Items: [],
    Loader: true,
    ItemTotal: 0,
    Vehiculos: [],
    Neumaticos: [],
    MotivosScrap: [],
    Marcas: [],
    Modelos: [],
    Medidas: [],
    Disenios: [],
    Empresas: [],
  },
  mutations: {
    LlenarItem(state, data) {
      state.Item = data;
    },
    LlenarItems(state, data) {
      state.Items = data;
    },
    LlenarItemTotal(state, data) {
      state.ItemTotal = data;
    },
    LlenarVehiculos(state, data) {
      state.Vehiculos = data;
    },
    LlenarNeumaticos(state, data) {
      state.Neumaticos = data;
    },
    LlenarMotivosScrap(state, data) {
      state.MotivosScrap = data;
    },
    LlenarMarcas(state, data) {
      state.Marcas = data;
    },
    LlenarModelos(state, data) {
      state.Modelos = data;
    },
    LlenarMedidas(state, data) {
      state.Medidas = data;
    },
    LlenarDisenios(state, data) {
      state.Disenios = data;
    },
    LlenarEmpresas(state, data) {
      state.Empresas = data;
    },
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },
    cargarNeumaticosScrap: async function ({ commit, dispatch }) {
      try {
        const url = "neumaticos/enscrap";
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
        };

        await axios
          .post(url, data)
          .then((response) => {
            commit("LlenarItems", response.data.success.datos);
            commit("LlenarItemTotal", response.data.success.datos.length);            
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarDatosAdicionales: async function ({ commit, dispatch }) {
      try {
        const url = "scrap/list";
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
        };

        await axios
          .post(url, data)
          .then((response) => {
            let vehiculos = [];
            response.data.success.vehiculos.forEach((element, i) => {
              vehiculos[i] = { value: element.id, text: element.placa };
            });

            let motivosscrap = [];
            response.data.success.motivosscrap.forEach((element, i) => {
              motivosscrap[i] = { value: element.id, text: element.motivo };
            });

            var array_marcas = [];
            response.data.success.marcas.forEach((element, i) => {
              array_marcas[i] = {
                value: element.id,
                text: element.descripcion,
              };
            });

            var array_modelos = [];
            response.data.success.modelos.forEach((element, i) => {
              array_modelos[i] = {
                value: element.id,
                text: element.descripcion,
                marca: element.marca,
              };
            });

            var array_medidas = [];
            response.data.success.medidas.forEach((element, i) => {
              array_medidas[i] = {
                value: element.id,
                text: element.descripcion,
              };
            });

            var array_disenios = [];
            response.data.success.disenios.forEach((element, i) => {
              array_disenios[i] = { value: element.id, text: element.nombre };
            });

            var array_empresas = [];
            response.data.success.empresas.forEach((element, i) => {
              array_empresas[i] = {
                value: element.id,
                text: element.razon_social,
                ruc: element.ruc,
              };
            });

            commit("LlenarVehiculos", vehiculos);
            commit("LlenarMotivosScrap", motivosscrap);
            commit("LlenarMarcas", array_marcas);
            commit("LlenarModelos", array_modelos);
            commit("LlenarMedidas", array_medidas);
            commit("LlenarDisenios", array_disenios);
            commit("LlenarEmpresas", array_empresas);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarNeumaticosxVehiculo: async function ({ commit, dispatch }, id) {
      try {
        const url = "scrap/neumaticosxvehiculo";
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
          id_vehiculo: id,
        };

        await axios
          .post(url, data)
          .then((response) => {
            let neumaticos = [];
            response.data.success.neumaticos.forEach((element, i) => {
              neumaticos[i] = {
                value: element.id,
                text: element.num_serie,
                remanente_limite: element.remanente_limite,
              };
            });

            commit("LlenarNeumaticos", neumaticos);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },
  },
};
