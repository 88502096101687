<template>
  <div class="pa-5">
    <v-col lg="12">
      <tabla
        Titulo="Neumáticos retirados para reparación"
        :Cabecera="Cabecera"
        :loading="Loader"
        :Items="Items"
        :ItemTotal="ItemTotal"
        :Excel="false"
        :Crear="false"
      />
    </v-col>
  </div>
</template>

<script>
import tabla from "@/components/neumaticos/tablaenreparacion.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "EnReparacion",
  components: {
    tabla,
  },
  created() {
    this.cargarNeumaticos();
  },
  data() {
    return {
      //Items: [],
      Cabecera: [
        {
          text: "Opciones",
          value: "acciones",
          align: "left",
        },
        {
          text: "Id",
          value: "id",
          align: "left",
        },
        {
          text: "Serie",
          value: "num_serie",
          width: 80,
          align: "left",
        },
        {
          text: "DOT",
          value: "serie_cliente",
          width: 100,
          align: "left",
        },
        {
          text: "Marca",
          value: "marca",
          align: "left",
        },
        {
          text: "Modelo",
          value: "modelo",
          align: "left",
        },
        {
          text: "Medida",
          value: "medida",
          align: "left",
        },
        {
          text: "Diseño de banda",
          value: "disenio",
          align: "left",
          width: 100,
        },
        {
          text: "Condición del neumático",
          value: "condicion",
          align: "left",
        },
        {
          text: "Fecha de registro",
          value: "fecha_registro",
          width: 120,
          align: "left",
        },
      ],
      loading: false,
    };
  },
  methods: {
    ...mapActions("enreparacion", ["cargarNeumaticos"]),
  },
  computed: {
    ...mapState("enreparacion", ["Items", "Loader", "ItemTotal"]),
  },
};
</script>
