import Vue from 'vue'
import Vuex from 'vuex'
import { axios2 as axios } from "@/interceptor/useApi";

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    Menu: [],
    Mini: false,
    Drawer: true,
    Permisos: []
  },
  getters: {
    Menu(state) {
      return state.Menu
    },
    Mini(state) {
      return state.Mini
    },
    Drawer(state) {
      return state.Drawer
    }
  },
  mutations: {
    LlenarDatos(state, data) {
      state.Menu = data
    },
    LlenarMini(state, data) {
      state.Mini = data
    },
    LlenarDrawer(state, data) {
      state.Drawer = data
    },
    LlenarPermisos(state, data) {
      state.Permisos = data
    }
  },
  actions: {
    loadRoutes: async function ({ commit }, num_menu) {
      let id = localStorage.getItem('idcliente') == null ? 0 : 1;
      let datos = { id_cliente1: id, id_usuario: localStorage.getItem("id") };
      //let datos = id != null ? { id_cliente:  id,  id_usuario: localStorage.getItem("id")} : { id_usuario: localStorage.getItem("id") };
      const url = "menu/principal";
      await axios.post(url, datos)
        .then(response => {
          let menu = response.data.success.resultado;
          commit('LlenarDatos', menu);
          commit('LlenarPermisos', response.data.success.permisos);
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("There was an error!", error);
        });
    },
    guardarAccesosMenu: async function({ commit }, datosmenu) {
      let id = localStorage.getItem('idcliente');
      let datos = { id_cliente: id, id_menu: datosmenu.id_menu, id_submenu: datosmenu.id_submenu, id_submenu_pagina: datosmenu.id_submenu_pagina };
      const url = "accesosmenu/guardar";
      await axios.post(url, datos)
        .then(response => {
          //console.log(response);
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("There was an error!", error);
        });
    },
    Minimizar({ commit, state, dispatch }, data) {
      if (state.Drawer) {
        commit('LlenarDrawer', true);
        commit('LlenarMini', data);
      } else {
        commit('LlenarDrawer', true);
      }
    }
  }
}


