import Vue from 'vue'
import Vuex from 'vuex'
import {axios2 as axios} from "@/interceptor/useApi";
import Swal from 'sweetalert2';

Vue.use(Vuex)

export default{
  namespaced:true,
  state: {
    Items : [],
  },
  mutations: {
    LlenarItems(state,data){
      state.Items = data
    }
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },
    loadSuscripciones:async function({commit, dispatch}){
      try {
        const  url = "clientesuscripcion/index"
        await axios.get(url)
        .then(response => {
          commit('LlenarItems', response.data.data);            
        })
        .catch(error => {
          console.error("error!", error);
          dispatch("messageErrorAlert");  
        }); 
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");  
      }
    }   
  }

}


