import Vue from 'vue'
import Vuex from 'vuex'
import {axios2 as axios} from "@/interceptor/useApi";
import router from "@/router";
import Swal from 'sweetalert2';

Vue.use(Vuex)


export default {
    namespaced: true,
    state: {
        Configuracion: {},
        Items: [],
        ItemTotal: 0,
        ItemsEjes: [],
        ItemsTipoVehiculo: [],
        ItemsPosicionesNeumaticos: []
    },
    getters: {
        Configuracion(state) {
            return state.Configuracion
        },
        Items(state) {
            return state.Items
        },
        ItemTotal(state) {
            return state.ItemTotal
        },
        ItemsTipoVehiculo(state) {
            return state.ItemsTipoVehiculo
        },
        ItemsEjes(state) {
            return state.ItemsEjes
        },
        ItemsPosicionesNeumaticos(state) {
            return state.ItemsPosicionesNeumaticos
        }
    },
    mutations: {
        LlenarConfiguracion(state, data) {
            state.Configuracion = data
        },
        LlenarItems(state, data) {
            state.Items = data
        },
        LlenarItemTotal(state, data) {
            state.ItemTotal = data
        },
        LlenarItemsTipoVehiculo(state, data) {
            state.ItemsTipoVehiculo = data
        },
        LlenarItemsEjes(state, data) {
            state.ItemsEjes = data
        },
        LlenarItemsPosicionesNeumaticos(state, data) {
            state.ItemsPosicionesNeumaticos = data
        }
    },
    actions: {
        
        messageErrorAlert: function () {
            Swal.fire({
              title: "Upps, algo paso!",
              text: "Servidor desconectado, por favor actualice la ventana!",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#d33",
              confirmButtonText: "Actualizar",
              showClass: {
                popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                `,
              },
              hideClass: {
                popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                `,
              },
              backdrop: `
                rgba(255,0,0,0.1)
                left top
                no-repeat
              `,
            }).then((result) => {
              if (result.isConfirmed) {
                location.reload();
              }
            });
          },

        cargarConfiguracionVehiculo: async function ({ commit, dispatch }) {
            try {
                const url = "vehiculoconfiguracion/index"
                const data = { id_cliente: localStorage.getItem("idcliente") };
                await axios.post(url, data)
                    .then(response => {
                        commit('LlenarItems', response.data.success.resultado.configuracion);
                        commit('LlenarItemTotal', response.data.success.resultado.total);
                        //dispatch('cargarDatosAdicionales')                 
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                        dispatch("messageErrorAlert");
                    });
            } catch (error) {
                console.log(error)
                dispatch("messageErrorAlert");
            }
        },

        cargarDatosAdicionales: async function ({ commit, dispatch }) {
            try {
                const url = "vehiculoconfiguracion/list"
                const data = { id_cliente: localStorage.getItem("idcliente") };
                await axios.post(url, data)
                    .then(response => {
                        let arrayTipoVehiculo = [];
                        response.data.success.resultado.tipovehiculo.forEach((element, i) => {
                            arrayTipoVehiculo[i] = { value: element.id, text: element.nomtipo }
                        });
                        let arrayEjes = [];
                        response.data.success.resultado.ejes.forEach((element, i) => {
                            arrayEjes[i] = { value: element.id, text: element.descripcion }
                        });

                        commit('LlenarItemsTipoVehiculo', arrayTipoVehiculo);
                        commit('LlenarItemsEjes', arrayEjes);
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                        dispatch("messageErrorAlert");
                    });
            } catch (error) {
                console.log(error)
                dispatch("messageErrorAlert");
            }
        },
        cargarPosicionesNeumaticos: async function ({ commit, dispatch }, id) {
            try {
                var url = "vehiculoconfiguracion/show"
                let datos = {id_cliente: localStorage.getItem("idcliente"), id_configuracion: id};
                await axios.post(url, datos)
                    .then(response => {
                        let configuracion = response.data.success.resultado.configuracion
                        let arrayPosicionesNeumaticos = response.data.success.resultado.posiciones

                        commit('LlenarConfiguracion', configuracion);
                        commit('LlenarItemsPosicionesNeumaticos', arrayPosicionesNeumaticos);
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                        dispatch("messageErrorAlert");
                    });
            } catch (error) {
                console.log(error)
            }
        },

        agregarConfiguracionVehiculo: async function ({ commit, dispatch }, datos) {
            var url = "vehiculoconfiguracion/store"
            datos.id_cliente = localStorage.getItem("idcliente");
            datos.id_usuario = localStorage.getItem("id");

            await axios.post(url, datos)
                .then(response => {
                    router.push("/configuracionvehiculo");
                })
                .catch(error => {
                    this.errorMessage = error.response.data;
                    console.error("error!", error);
                    dispatch("messageErrorAlert");
                });
        },

        eliminarConfiguracionVehiculo: async function ({ commit, dispatch }, id) {
            let data = { 
                id_cliente: localStorage.getItem("idcliente"),
                id_configuracion: id
            }
            let rpta = null;
            try {
                const url = "vehiculoconfiguracion/delete"
                await axios.post(url, data)
                    .then(response => {
                        rpta = response.data.success.resultado;
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                        dispatch("messageErrorAlert");
                    });
            } catch (error) {
                console.log(error);
                dispatch("messageErrorAlert");
            }
            return rpta;
        },

        existeConfiguracion: async function ({ commit, dispatch }, item) {
            const data = {
                id_cliente: localStorage.getItem("idcliente"),
                tipovehiculo: item.id_vehiculotipo,
                nroneumaticos: item.nroneumaticos,
                tipoconfiguracion: item.tipo_configuracion,
                neumaticosDelanteros: item.neumaticosDelanteros
            };

            let rpta = null;
            try {
                const url = "vehiculoconfiguracion/validar"
                await axios.post(url, data)
                    .then(response => {
                        rpta = response.data.success.resultado;
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                        dispatch("messageErrorAlert");
                    });
            } catch (error) {
                console.log(error)
                dispatch("messageErrorAlert");
            }
            return rpta;
        },
    },
}