<template>
  <div class="pa-5">
    <v-col lg="12">
      <!--<tabla Titulo="Reporte de neumáticos" :Cabecera="Cabecera" :loading="Loader" :Items="Items" :ItemTotal="ItemTotal" :Excel="false" :Crear="true" :empresaList="empresaList" :marcaNeumaticoList="marcaNeumaticoList" :modeloNeumaticoList="modeloNeumaticoList" :medidaNeumaticoList="medidaNeumaticoList" :disenioNeumaticoList="disenioNeumaticoList"/>-->
      <tabla
        Titulo="Plantas"
        :Cabecera="Cabecera"
        :loading="Loader"
        :Items="Items"
        :ItemTotal="ItemTotal"
        :Excel="false"
        :Crear="true"
      />
    </v-col>
  </div>
</template>

<script>
import tabla from "@/components/plantas/tabla.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "Plantas",
  components: {
    tabla,
  },
  created() {
    this.cargarPlantas();
  },
  data() {
    return {
      //Items: [],
      Cabecera: [
        {
          text: "Opciones",
          value: "acciones",
          align: "left"
        },
        {
          text: "Nombre",
          value: "nombre",
          align: "left"
        },
        {
          text: "Dirección",
          value: "direccion",
          align: "left"
        },
        {
          text: "Cliente",
          value: "cliente",
          align: "left"
        },
        {
          text: "Fecha",
          value: "fecha",
          align: "left"
        }
      ],
      loading: false,
    };
  },
  methods: {
    ...mapActions("plantas", ["cargarPlantas"]),
  },
  computed: {
    ...mapState("plantas", ["Items", "Loader", "ItemTotal"]),
  },
};
</script>
