<template>
<div class="pa-5">
    <v-col lg="12">
        <tabla :Titulo="title" :Cabecera="Cabecera" :Items="items" :Roles="roles"  :loading="loader" :Excel="false" :Crear="true" />
    </v-col>
</div>
</template>

<script>
import tabla from "@/components/miembros/tabla.vue";
import {axios2 as axios} from "@/interceptor/useApi";

export default {
    name: "Miembros",
    components: {
        tabla,
    },
    provide() {
        return {
            llamarindexMembers: this.indexMembers
        };
    },
    data() {
        return {
            items: [],
            roles: [],
            itemTotal: 0,
            Cabecera: [{
                    text: "Nombre",
                    value: "name",
                    align: "left"
                },
                {
                    text: "Email",
                    value: "email",
                    align: "left"
                },
                {
                    text: "Rol",
                    value: "role",
                    align: "left"
                },
                {
                    text: "Acciones",
                    value: "accion",
                    align: "center"
                }
            ],
            loader: false,
            title: "",
            cliente: null
        };
    },
    methods: {
        indexMembers: async function (id_cliente) {
            try {
                const url = "userclientes/index"
                const datos = {
                    id_cliente: id_cliente
                }
                await axios.post(url, datos)
                    .then(response => {
                        this.items = response.data.success.miembros;
                        this.cliente = response.data.success.cliente;
                        this.itemTotal = response.data.success.miembros.length;
                        this.title = "Miembros de: " + this.cliente.razon_social;
                        let arrayroles = response.data.success.roles;
                        
                        arrayroles.forEach((element, i) => {
                            if (element.id == 4 || element.id == 5) {
                                this.roles[i] = {
                                    value: element.id,
                                    text: element.name
                                }
                            };
                        });
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error)
            }
        },
    },
    async created() {
        let id_cliente = location.pathname.substr(1).split("/")[1];
        this.indexMembers(id_cliente);
    }
};
</script>
