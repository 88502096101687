<template>
  <div class="pa-5">
    <v-col lg="12">
      <tabla  Titulo="Tipo de Cambio" :Cabecera="Cabecera" :Items="Items" :Excel="false" :Crear="true" :Loading="Loading" />
    </v-col>
  </div>
</template>
<script>
import tabla from "@/components/tipocambio/tabla.vue"

import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "TipoCambio",
  components : {
    tabla
  },

  created(){
    const data = {menu:1,submenu:1};
    this.CargarMenu(data);
    this.cargarTipoCambio();
  },
  data(){
    return{
      Cabecera:[
        {text:'Id',value:'id'},
        {text:'Valor',value:'valor'},
        {text:'Fecha',value:'fecha'},
        {text:'Estado',value:'estado'},
      ]
    }
  },
  methods:{
    ...mapActions('tipocambio',['cargarTipoCambio']),
    ...mapActions('nav',['CargarMenu'])
  },

  computed:{
    ...mapGetters('tipocambio',['Items']),
    ...mapState('tipocambio',['Loading'])   
  }


};
</script>




