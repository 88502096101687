<template>
  <v-card>
    <v-toolbar color="secondary" dark>
      <span class="text-h6">Neumáticos</span>
    </v-toolbar>
    <v-card-title> 
      <v-alert style="width: 100%;margin-bottom: 0px;" border="top"
        colored-border
        type="info"
        elevation="2"
      >
      Seleccione un neumático y haga clic en una columna vacía de la tabla de comparación.
      </v-alert>
    </v-card-title>
    <v-card-title style="
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 20px;
">
      <div class="col-lg-2 col-sm-2 col-md-2 col-xs-12"> 
      </div>
      <div class="col-lg-1 col-sm-1 col-md-1 col-xs-12"></div>
      <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busqueda"
          single-line
          hide-details
          @change="onBusqueda"
        ></v-text-field>
      </div>
    </v-card-title>
    <v-data-iterator class="mx-2"
      :items="Items"
      :items-per-page="6"
      :footer-props="{
        'items-per-page-options': [6, 12, 18],
        'items-per-page-text': 'Resultados por página:',
        'page-text': '{0}-{1} de {2}' 
      }"
      loading-text="Cargando... Por favor, espere" 
      no-data-text="No hay datos disponibles" 
      :search="search"
      >  
      <template v-slot:default="{ items, isExpanded, expand }">
        <v-row  justify="center">
          <v-col v-for="(item,index) in items" :key="index" cols="12" sm="6" md="3" lg="2" class="tooltip" justify="center" style="display: flex;justify-content: center;" >
          <button
            type="button" 
            :style="{
              borderRadius: '15px',
              width: '145px',
              background: 'none',
              boxShadow: 'none',
              border: dato_seleccionado2.includes(item) ? '2px solid red' : (seleccionados2.includes(item)?'2px dotted gray':'')
            }" 
            :disabled="seleccionados2.includes(item)"
            @click="seleccionarNeumatico(item)"
          >
            <center>
              <v-img :src="imagen" alt="neumatico" class="mb-2 imagen-neumatico2" > 
              </v-img>
            </center>
            <p style="color: grey; text-shadow: none; margin-bottom: 0px;">
              {{ item.descripcion }}
            </p>
          </button> 

          <Description
            v-if="VerTooltip == true"
            class="tooltiptext"
            :title="titulo(item.estado)"
            :subtitle="subtitulo(item.estado)"
            :item="item"
          ></Description>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-card>
</template>
<script>
import Description from "./instalacionneumaticos/description.vue";
// import {axios2 as axios} from "@/interceptor/useApi";
import axios from "axios";
export default {
  components: {
    Description,
  },
  props: ["Items", "VerTooltip","dato_seleccionado",'seleccionados'],
  data() {

    return {
      Neumaticos: [],
      opcionesPagina: [
        { value: 6, text: 6 },
        { value: 12, text: 12 },
        { value: 18, text: 18 },
        { value: "", text: "All" },
      ],
      paginacionInicial: [],
      porPagina: 6,
      neumaticoSeleccionado: null,
      imagen: axios.defaults.imageURL + "images/llanta.png",
      search: "",
      loader: false,
      paginas: 0,
      pagina_actual: 1,
      datosPaginados: [],
      dato_seleccionado2:this.dato_seleccionado,
      seleccionados2:this.seleccionados,
    };
  },
  watch: {
    dato_seleccionado(newVal) {
      this.dato_seleccionado2 = newVal; // Actualizar si el prop cambia
    },
    seleccionados(newVal) {
      this.seleccionados2 = newVal; // Actualizar si el prop cambia
    },
  },
  methods: {
    aea(param){
      console.log(param)
    },
    onBusqueda: function () {
      if (this.search == "") {
        this.cargarComponenteNeumaticos();
      } else {
        let aux = [];
        this.paginacionInicial.forEach((element) => {
          element.forEach((element1) => {
            let descripcion = element1.descripcion;
            if (descripcion.includes(this.search.toUpperCase()) == true) {
              aux.push(element1);
            }
          });
        });
        this.paginacion(aux.length, aux);
      }
    },
    paginacion: function (total, array) {
      this.Neumaticos = [];
      this.datosPaginados = [];
      let items_por_td = this.porPagina != "" ? this.porPagina : total;
      this.pagina_actual = 1;
      this.paginas = Math.ceil(total / items_por_td);
      var index = 0;
      for (let i = 0; i < this.paginas; i++) {
        var temporal = [];
        for (let j = 0; j < items_por_td && index < total; j++, index++) {
          temporal.push(array[index]);
        }
        this.Neumaticos.push(temporal);
      }
      this.datosPaginados.push(this.Neumaticos[this.pagina_actual - 1]);
    },

    seleccionarNeumatico: function (item) { 
      this.$emit("seleccionarNeumatico", item); 
    },

    cargarComponenteNeumaticos: function () {
      this.paginacion(this.Items.length, this.Items);
    },

    mostrarPagina: function () {
      this.datosPaginados = [];
      this.datosPaginados.push(this.Neumaticos[this.pagina_actual - 1]);
      this.$forceUpdate();
    },

    titulo: function (estado) {
      if (estado == 1) {
        return "Listo para instalar";
      } else if (estado == 2) {
        return "En reparación";
      } else if (estado == 3) {
        return "Instalado";
      } else if (estado == 4) {
        return "En reencauche";
      } else if (estado == 5) {
        return "Listo para instalar";
      } else {
        return "";
      }
    },

    subtitulo: function (estado) {
      if (estado == 1) {
        return "";
      } else if (estado == 2) {
        return "Registre el costo de reparación";
      } else if (estado == 3) {
        return "";
      } else if (estado == 4) {
        return "Registre el costo de reencauche";
      } else {
        return "";
      }
    },
  },
  mounted() {
    // this.paginacion(this.Items.length, this.Items);
    // this.paginacionInicial = this.Neumaticos;
  },
};
</script>
<style scoped>
.bottom-gradient {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, transparent 72px);
}

.repeating-gradient {
  background-image: repeating-linear-gradient(-45deg,
                      rgba(255,0,0,.25),
                      rgba(255,0,0,.25) 5px,
                      rgba(0,0,255,.25) 5px,
                      rgba(0,0,255,.25) 10px
                    );
}
.imagen-neumatico2 {
  width: 60px;
  opacity: 1;
}
.imagen-neumatico2:hover {
  transition: transform .2s;
  transform: scale(1.25);  
}


.tooltip {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted black;*/
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 1px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
  top: -85px;
  left: 15%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>