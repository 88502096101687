<template>
  <div>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Busqueda"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-data-table
      dense
      :headers="Cabecera"
      :items="Items"
      :items-per-page="5"
      :search="search"
      loading="true"
      class="elevation-1"
      loading-text="Cargando... Por favor, espere" 
      no-data-text="No hay datos disponibles"
      :footer-props="{
        'items-per-page-text': 'Resultados por página:',
        'page-text': '{0}-{1} de {2}' 
      }"
    >
      <template v-slot:[`item.num_serie`]="{ item }">
        <div class="col-lg-12 col-sm-1 col-md-1 col-xs-12 tooltip text-center">
          <v-btn
            style="
              border-radius: 15px;
              width: 105px;
              height: 45px;
              background-size: cover;
            "
            hide-details="auto"
            @click="enviarDatos(item)"
            :disabled="item.disabled == true"
          >
            <div v-if="mostrarImagenNeumatico == true">
              <Imagen :num_serie="item.num_serie"> </Imagen>
            </div>

            <div class="text-center" v-else>
              <Imagen v-if="(item.estado ==1 || item.estado ==5) && item.disabled == false" :num_serie="item.num_serie"> </Imagen>
              <div v-else>{{ item.num_serie }}</div>
            </div>
          </v-btn>

          <Description
            class="tooltiptext"
            :title="titulo(item.estado)"
            :subtitle="subtitulo(item.estado)"
            :item="item"
          ></Description>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Imagen from "./NeumaticoImageButton.vue";
import Description from "./instalacionneumaticos/description.vue";
export default {
  components: {
    Imagen,
    Description,
  },
  props: [
    "Titulo",
    "Cabecera",
    "Items",
    "ItemTotal",
    "Excel",
    "Crear",
    "MostrarImagen",
  ],
  data() {
    return {
      search: "",
      mostrarImagenNeumatico: true,
    };
  },
  methods: {
    enviarDatos: function (item) {
      this.$emit("enviarDatos", item);
    },

    titulo: function (estado) {
      if (estado == 1) {
        return "Listo para instalar";
      } else if (estado == 2) {
        return "En reparación";
      } else if (estado == 3) {
        return "Instalado";
      } else if (estado == 4) {
        return "En reencauche";
      } else if (estado == 5) {
        return "Listo para instalar";
      } else {
        return "";
      }
    },

    subtitulo: function (estado) {
      if (estado == 1) {
        return "";
      } else if (estado == 2) {
        return "Registre el costo de reparación";
      } else if (estado == 3) {
        return "";
      } else if (estado == 4) {
        return "Registre el costo de reencauche";
      } else {
        return "";
      }
    },
  },
  mounted() {
    if (this.MostrarImagen != undefined) {
      this.mostrarImagenNeumatico = this.MostrarImagen;
    }
  },
};
</script>

<style scoped>
.tooltip {
  /*position: relative;*/
  display: inline-block;
  /*border-bottom: 1px dotted black;*/
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 1px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
  top: 80px;
  left: 5%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
