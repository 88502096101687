<template>
<div class="pa-5">
    <v-col lg="12">
        <v-card>
            <v-card-text>
                <v-banner>
                    <v-autocomplete v-model="selected" @change="filtroRoles()" :items="ItemsSelect" class="mt-4" dense label="Seleccionar Roles" item-value="id" item-text="name" ></v-autocomplete>

                    <template v-slot:actions>
                        <v-row class="light--text">
                            <v-col cols="4">
                                <v-checkbox input-value="true" disabled></v-checkbox>
                                Permiso asignado
                            </v-col>
                            <v-col cols="4">
                                <v-checkbox disabled value></v-checkbox>
                                Permiso sin asignar
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                            </v-col>
                            <v-col cols="4">
                            </v-col>
                            <v-col cols="4">
                            </v-col>
                        </v-row>
                    </template>
                </v-banner>
            </v-card-text>
        </v-card>
        <tabla Titulo="Lista Permisos" :menuData="Items" :permisosxroles="Permisosxroles" :headers="headers" :actionHeadersTable="actionHeadersTable" :actionHeaders="actionHeaders" :subHeaders="subHeaders" :subSubHeaders="subSubHeaders"   :Excel="false" :Crear="false" :Loading="Loading" :filtroRoles="filtroRoles" :selected="selected" />
    </v-col>
</div>
</template>

<script>
import tabla from "@/components/permisos/tabla.vue"
import {axios2 as axios} from "@/interceptor/useApi";

import {
    mapGetters,
    mapActions,
    mapState
} from "vuex";
export default {
    name: "Permisos",
    components: {
        tabla
    }, 
    created() { 
        const data = {
            menu: 1,
            submenu: 1
        };
        
        this.getacciones();
            this.verRoles()
        this.CargarMenu(data);
        this.cargarPermisos()
            .then(r => {
                console.log('como estas ?')
                this.cargarPermisosxroles(1);
            })
            .catch(error => {
                console.log('que tal ?')
            });
    },
    data() {
        return {
            selected: 1,

            actionHeaders: [
                { id: 1, label: 'Visualizar' },
                { id: 2, label: 'Agregar' },
                { id: 3, label: 'Editar' },
                { id: 4, label: 'Eliminar' },
            ],
            headers: [
                { text: 'Módulo', value: 'menu' }, 
            ],
            subHeaders: [
                { text: 'Submódulo', value: 'description' }, 
            ],
            subSubHeaders: [
                { text: 'Sub-submódulo', value: 'description' }, 
            ],
            actionHeadersTable:[]
        }
    },
    methods: {
        filtroRoles() {
            this.cargarPermisosxroles(this.selected);
        },
        getacciones(){ 
            // aqui se agrega api 
            try { 
                const url = "getacciones";
                let datos = {};
                axios.post(url, datos)
                    .then((response) => {
                        this.actionHeaders=response.data.success.resultado; 
                        this.actionHeadersTable.push({ text: 'MÓDULO', value: 'MÓDULO',
                        sortable: false, align: 'center' });
                        this.actionHeadersTable.push({ text: 'MENU', value: 'MENU',
                        sortable: false, align: 'center' });
                        for (let i = 0; i < this.actionHeaders.length; i++) {
                            const text = this.actionHeaders[i].name;
                            const value = this.actionHeaders[i].id;
                            this.actionHeadersTable.push({ text: text, value: value,
                                sortable: false, align: 'center' });
                        }
                        // this.cerrarDialogo();
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    }); 
            } catch (error) {
                console.log(error);
            }
        },
        ...mapActions('permisos', ['cargarPermisos', 'verRoles','cargarPermisosxroles']),
        ...mapActions('nav', ['CargarMenu'])
    },

    computed: {
        ...mapGetters('permisos', ['Items', 'ItemsSelect','Permisosxroles']),
        ...mapState('permisos',['Loading'])
    }

};
</script>
