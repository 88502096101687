<template>
<div class="pa-5">
    <v-col lg="12">
        <tabla Titulo="Modúlos" :menuData="Items" :headers="headers" :actionHeaders="actionHeaders"  @request-actions="getacciones" :subHeaders="subHeaders" :subSubHeaders="subSubHeaders"   :Excel="false" :Crear="false" :Loading="Loading" :cargarPermisos="cargarPermisos" />
    </v-col>
</div>
</template>

<script>
import tabla from "@/components/modulos/tabla.vue"
import {axios2 as axios} from "@/interceptor/useApi";

import {
    mapGetters,
    mapActions,
    mapState
} from "vuex";
export default {
    name: "Permisos",
    components: {
        tabla
    }, 
    created() { 
        const data = {
            menu: 1,
            submenu: 1
        }; 
        this.CargarMenu(data);
        this.cargarPermisos();
        this.getacciones();
    },
    data() {
        return {
            selected: 1,

            actionHeaders: [
                { id: "1", label: 'Visualizar' },
                { id: "2", label: 'Agregar' },
                { id: "3", label: 'Editar' },
                { id: "4", label: 'Eliminar' },
            ],
            headers: [
                { text: 'Módulo', value: 'menu' }, 
            ],
            subHeaders: [
                { text: 'Submódulo', value: 'description' }, 
            ],
            subSubHeaders: [
                { text: 'Sub-submódulo', value: 'description' }, 
            ],
        }
    },
    methods: { 
        ...mapActions('permisos', ['cargarPermisos']),
        ...mapActions('nav', ['CargarMenu']),
        getacciones(){ 
            // aqui se agrega api 
            try { 
                const url = "getacciones";
                let datos = {};
                axios.post(url, datos)
                    .then((response) => {
                        console.log(response.data.success.resultado);
                        this.actionHeaders=response.data.success.resultado; 
                        // this.cerrarDialogo();
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    }); 
            } catch (error) {
                console.log(error);
            }
        }
    }, 
    computed: {
        ...mapGetters('permisos', ['Items']),
        ...mapState('permisos',['Loading'])
    }

};
</script>
