<template>
    <div>  
        <v-form @submit.prevent="" class="form d-flex flex-column">
            <h2>CAMPOS DINAMICOS</h2>
            <span class="message" v-if="!campos.length" > No hay datos que mostrar</span>
            <component
                v-for="(campo, index) in campos" :key ="index"
                :is="campo.etiqueta"
                :label="campo.label ? campo.label : ''"
                :type="campo.type ? campo.type : ''"
                :items ="campo.items ? campo.items : ''"
                v-model="campo.valor"
            ></component>
            <v-row>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="Guardar()" class="ma-2" elevation="10" >GUARDAR</v-btn>
                <v-btn color="error" @click="Cerrar()" class="ma-2" elevation="10" >CERRAR</v-btn>
            </v-row>
        </v-form>
    </div>
</template>
<script>
export default {
    props : ['campos']
}
</script>