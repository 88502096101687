<template>
    <v-sheet
      elevation="12"
      max-width="600"
      rounded="lg"
      width="100%"
      class="pa-4 text-center mx-auto"
    >
      <v-icon
        class="mb-5"
        color="success"
        size="112"
      >{{ icon }}</v-icon>
  
      <h2 class="text-h5 mb-6">{{ mensaje }}</h2>
  
      <!--
      <p class="mb-4 text-medium-emphasis text-body-2">
        To see a report on this reconciliation, click <a href="#" class="text-decoration-none text-info">View reconciliation report.</a>
  
        <br>
  
        Otherwise, you're done!
      </p>
    -->
  
      <v-divider class="mb-4"></v-divider>
  
      <div class="text-end" v-if="tipomensaje == 1">
        <v-btn
          class="text-none"
          color="success"
          rounded
          variant="flat"
          width="90"
          @click="enviarRespuesta('')"
        >
          OK
        </v-btn>
      </div>

      <div class="text-end" v-else>
        <v-btn
          class="text-none"
          color="error"
          rounded
          variant="flat"
          width="90"
          @click="enviarRespuesta('NO')"
        >
          NO
        </v-btn>

        <v-btn
          class="text-none"
          color="success"
          rounded
          variant="flat"
          width="90"
          @click="enviarRespuesta('SI')"
        >
          SI
        </v-btn>
      </div>
    </v-sheet>
  </template>
  <script>
  export default {
    props: ["mensaje", "icon", "tipomensaje"],
    data() {
      return{

      }
      
    },
    methods:{
      enviarRespuesta: function(rpta){
        this.$emit("enviarRespuesta", rpta);
      }
    }
  }
  </script>