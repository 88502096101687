<template>
    <div class="pa-5">
        <v-col lg="12">
            <v-card>
                <v-toolbar color="secondary" dark>
                    <span class="text-h6"> Reporte de medidas de neumáticos inspeccionados</span>
                    <v-spacer></v-spacer>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-3" fab dark small color="#FFC107" v-on="on">
                                <a target="_blank" href="https://helpdesk.tiresoft.pe/#marketing-medidas-neumaticos"
                                    style="text-decoration:none !important; color: white;">
                                    <v-icon dark>mdi-help-circle</v-icon>
                                </a>
                            </v-btn>
                        </template>
                        <span>¿Necesitas ayuda?</span>
                    </v-tooltip>
                </v-toolbar>
                <FiltrosReporteMarketing titulo="" :mostrarempresa="true" :mostrarrtd="false" :mostrarmedida="false"
                    :mostrarsegmentacion="false" @filtrarNeumaticos="filtrarNeumaticos" ref="filtrosmarketing">
                </FiltrosReporteMarketing>

            </v-card>


            <v-card class="mt-5" v-if="mostrarCard">
                <v-card-text>
                    <v-row class="mt-5">
                        <v-col cols="12" sm="12" md="6">
                            <TablaReporteMarketing :Encabezados="encabezados" :Items="neumaticosmedidas"
                                :TotalGeneral="totalgeneral" />
                        </v-col>
                        <!--
                    <v-col cols="12" sm="12" md="7">
                        <GraficoBar Title="Medidas inspeccionadas" Labelscale_x="Cantidad" Labelscale_y="Medidas" :Labels="Labels" :DatosGrafico="Dataset" IdGrafico="bar_medidas" Orientacion="y" v-if="mostrargrafico" />
                    </v-col>
                    -->
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-col cols="12" sm="12" md="6">
                        <v-btn color="#32B1E9" :loading="loadingexcel" @click="exportarExcel()" small block>Exportar</v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-col>
    </div>
</template>

<script>
import TablaReporteMarketing from '@/components/TablaReporteMarketing.vue'
import FiltrosReporteMarketing from "@/components/FiltrosReporteMarketing.vue"
import {
    notif
} from "@/plugins/notyf"
import {
    mapActions,
    mapState
} from 'vuex'
import GraficoBar from '@/components/GraficoBar.vue'
import global from '@/global'

export default {
    components: {
        TablaReporteMarketing,
        FiltrosReporteMarketing,
        GraficoBar
    },
    data() {
        return {
            item: null,
            loadingexcel: false,
            encabezados: [{
                text: "Etiquetas de fila",
                value: "name",
                align: "left"
            },
            {
                text: "Cantidad",
                value: "cantidad",
                align: "center"
            },
            {
                text: "Porcentaje",
                value: "porcentaje",
                align: "right"
            },
            ],
            neumaticosmedidas: [],
            totalgeneral: 0,
            Labels: [],
            Dataset: [],
            mostrargrafico: false,
            mostrarCard:false
        }
    },
    computed: {
        ...mapState("marketing", ["MedidasNeumaticos"])
    },
    methods: {
        ...mapActions("marketing", ["cargarMedidaNeumaticos"]),

        exportarExcel() {
            this.loadingexcel = true;
            global._exportar_a_excel("excel/mkgmedidasneumaticos", "POST", this.item);
            this.loadingexcel = false;
        },

        async cargarDatos(filtros) {
            this.item = filtros;
            this.$refs.filtrosmarketing.turnOnLoader();
            this.mostrargrafico = false;
            this.Labels = [];
            this.Dataset = [];
            await this.cargarMedidaNeumaticos(filtros);
            this.neumaticosmedidas = [];
            let datos = global._arrayValoresUnicos(this.MedidasNeumaticos, 'medida');
            this.totalgeneral = datos.total;
            this.neumaticosmedidas = datos.array;
            this.mostrargrafico = true; // to show table and graphic
            this.$refs.filtrosmarketing.turnOffLoader();
            this.mostrarCard = true;
        },

        filtrarNeumaticos(filtros) {
            if (filtros.fechainicio != '' && filtros.fechafin != "") {
                if (filtros.fechainicio > filtros.fechafin) {
                    notif.error("La fecha final debe ser mayor a la fecha inicial");
                } else {
                    this.cargarDatos(filtros);
                }
            } else {
                this.cargarDatos(filtros);
            }
        }
    },
}
</script>
