import { axios2 as axios } from "@/interceptor/useApi";
export default {

  // ruta_api: "http://127.0.0.1:8000/api/",
  // ruta_image: "http://127.0.0.1:8000/",

  // ruta_api: "https://tiresoft-api.lab-elsol.com/api/",
  // ruta_image: "https://tiresoft-api.lab-elsol.com/",

  ruta_api: process.env.VUE_APP_API_URL,
  ruta_image: process.env.VUE_APP_API_URL_IMAGE,

  _objeto_es_vacio: function (obj) {
    return Object.keys(obj).length == 0;
  },

  _json_to_query_string: function (json) {
    json = Object.keys(json).map((item) => {
      return item + "=" + json[item] || "";
    });
    return json.join("&");
  },

  _fecha_valida(fecha) {
    var moment = require("moment");
    return (fecha = moment(fecha, "YYYY-MM-DD", true).isValid());
  },

  _validar_fecha(fecha) {
    var moment = require("moment");
    let fechaactual = moment(new Date());
    fecha = moment(fecha, "YYYY-MM-DD");

    if (fechaactual >= fecha) {
      return fecha.format("YYYY-MM-DD");
    } else {
      return fechaactual.format("YYYY-MM-DD");
    }
  },
  _autorizacion_usuario(permisos, permiso, acciones) {
    // Inicializa la autorización
    let autorizacion = false;
  
    // Verifica si la acción está presente en los permisos del usuario
    for (let accionKey in permisos[permiso]) {
      // Comprueba si alguna acción en el array 'acciones' está en los permisos
      if (acciones.some(accion => permisos[permiso][accionKey] === accion)) {
        autorizacion = true;
        break; // Sale del bucle si encuentra al menos una coincidencia
      }
    }
  
    return autorizacion;
  },
  
  _arrayValoresUnicos: function (
    array_general,
    descripcion,
    limite = true,
    grupo = ""
  ) {
    let cantidad = 10;
    let total = 0;
    let totalgeneral = 0;
    let array_temporal = [];

    array_general.forEach((element, i) => {
      if (i == 0) {
        element.name = element[descripcion];
        array_temporal.push({
          ...element,
        });
        totalgeneral += element["cantidad"];
      } else {
        //buscar si el elemento existe, si existe sumar de lo contrario ingresar al array
        let dato = null;
        if (grupo != "") {
          dato = array_temporal.find(
            (el) =>
              el[descripcion] == element[descripcion] &&
              el[grupo] == element[grupo]
          );
        } else {
          dato = array_temporal.find(
            (el) => el[descripcion] == element[descripcion]
          );
        }

        if (dato) {
          dato.cantidad += element.cantidad;
          totalgeneral += element.cantidad;
        } else {
          element.name = element[descripcion];
          totalgeneral += element["cantidad"];
          array_temporal.forEach((value) => {
            if (value.name == element.name) {
              element.name = element.name + " ";
            }
          });

          array_temporal.push({
            ...element,
          });
        }
      }
    });
    //ordenar de mayor a menor cantidad
    array_temporal.sort((a, b) => b.cantidad - a.cantidad);

    let array = [];
    if (limite == false) {
      cantidad = array_general.length;
    }

    for (let index = 0; index < cantidad; index++) {
      if (array_temporal[index]) {
        array.push(array_temporal[index]);
        total += parseInt(array_temporal[index]["cantidad"]);
      }
    }

    array_temporal.forEach((element) => {
      element.porcentaje = ((element.cantidad / totalgeneral) * 100).toFixed(2);
    });

    let datos = {
      total: total,
      array: array,
    };

    return datos;
  },

  async _exportar_a_excel(ruta = "", method = "GET", data) {
    let url = this.ruta_api + ruta;
    await axios({
      url: url,
      method: method,
      data: data,
      responseType: "blob",
    })
      .then(async (response) => {
        var nombre_archivo = response.headers["nombre-archivo"];
        if (response.data.type == "text/html") {
          // no es excel, probablemente es json con un mensaje de error
          const res = JSON.parse(await response.data.text());
          if (!res.exito) {
            alert(res.mensaje || "Sucedió un error, intentelo nuevamente");
          }
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", nombre_archivo);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error) => {
        this.loader = false;
        this.errorMessage = error.response.data;
        console.error("error!", error);
      });
  },

  verificarDuplicados: function (list, item) {
    let existe = false;
    list.forEach((element) => {
      if (element.text == item) {
        existe = true;
      }
    });
    return existe;
  },
};
