<template>
<v-form enctype="multipart/form-data" ref="form" v-model="valid">
    <v-card>
        <v-toolbar color="secondary" dark>
        <span class="text-h6"> Enviar neumáticos instalados a SCRAP </span>
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
              <a href=""
                style="text-decoration:none !important; color: white;">
                <v-icon dark>mdi-help-circle</v-icon>
              </a>
            </v-btn>
          </template>
          <span>Panel de ayuda en desarrollo</span>
        </v-tooltip>
      </v-toolbar>

        <!-- <v-card-title>Enviar neumáticos instalados a SCRAP 1</v-card-title> -->
        <!--
        <v-toolbar height="50" color="#6B6A6A" dark>
            <span v-if="Item.id == null" class="text-h6">Enviar neumáticos instalados a SCRAP</span>
            <span v-else class="text-h6">Editar neumático</span>
            <v-spacer></v-spacer>
            <span v-if="Item.id != null">ID: {{ Item.id }}</span>
        </v-toolbar>
        -->
        <v-card-text>
            <v-row justify="center">
                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <v-autocomplete class="mt-5" :items="Vehiculos" @change="onSeleccionarVehiculo" v-model="Item.id_vehiculos" label="Seleccionar vehículo" dense :rules="[(v) => !!v || 'Vehículo recorrido es requerido']"></v-autocomplete>
                </div>

                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <v-text-field label="Km recorrido del vehículo" v-model="Item.km_recorridos" hide-details="auto" required :rules="[(v) => !!v || 'Km recorrido es requerido']"></v-text-field>
                </div>

                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <v-autocomplete class="mt-5" :items="MotivosRetiro" v-model="Item.motivo_retiro" label="Motivo de retiro" dense :rules="[(v) => !!v || 'Motivo de retiro es requerido']"></v-autocomplete>
                </div>

                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <v-autocomplete class="mt-5" :items="Neumaticos" @change="onSeleccionarNeumatico" v-model="Item.id_neumaticos" label="Seleccionar neumático" dense :rules="[(v) => !!v || 'Neumático es requerido']"></v-autocomplete>
                </div>

                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <v-text-field label="Remanente final" v-model="Item.remanente_final" hide-details="auto" required :rules="[(v) => !!v || 'Remanente final es requerido']"></v-text-field>
                </div>

                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <v-text-field label="Remanente límite" v-model="Item.remanente_limite" hide-details="auto" required :rules="[(v) => !!v || 'Remanente límite es requerido']"></v-text-field>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <v-autocomplete class="mt-5" :items="MotivosScrap" v-model="Item.motivo_scrap" label="Motivo SCRAP" dense :rules="[(v) => !!v || 'Motivo scrap es requerido']"></v-autocomplete>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <v-text-field type="date" label="Fecha RETIRO/SCRAP" v-model="Item.fecha" hide-details="auto" :rules="[(v) => !!v || 'Fecha de retiro es requerido']"></v-text-field>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <h4 class="font-bold">IMAGEN 1 DEL NEUMATICO EN MAL ESTADO:</h4>
                    <FileAgent @archivosubido="archivosubido1" style="z-index: 0" />
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <h4 class="font-bold">IMAGEN 2 DEL NEUMATICO EN MAL ESTADO:</h4>
                    <FileAgent @archivosubido="archivosubido2" style="z-index: 0" />
                </div>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="Item.id == null" color="info" @click="agregar()">
                Guardar
            </v-btn>
        </v-card-actions>
    </v-card>
</v-form>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import FileAgent from "../FileAgent.vue";
import {axios2 as axios} from "@/interceptor/useApi";
import { notif } from "@/plugins/notyf"

export default {
    components: {
        FileAgent
    },
    props: ["dialog", "Item", "Vehiculos", "MotivosScrap"],
    data() {
        return {
            valid: false,
            MotivosRetiro: [{
                    value: 1,
                    text: "POR REENCAUCHE"
                },
                {
                    value: 2,
                    text: "POR REPARACIÓN"
                },
                {
                    value: 3,
                    text: "POR ROTACIÓN"
                },
                {
                    value: 4,
                    text: "POR DESGASTE FINAL"
                },
                {
                    value: 5,
                    text: "POR FALLA"
                }
            ]
        };
    },
    computed: {
        ...mapState("scrap", ["Neumaticos"]),
    },
    methods: {
        ...mapActions("scrap", ["cargarNeumaticosxVehiculo"]),

        archivosubido1: function ({
            valor,
            fileRecords
        }) {
            this.Item.ruta1 = fileRecords;
            console.log(this.Item.ruta1);
        },

        archivosubido2: function ({
            valor,
            fileRecords
        }) {
            this.Item.ruta2 = fileRecords;
            console.log(this.Item.ruta2);
        },

        onSeleccionarVehiculo: async function (id) {
            await this.cargarNeumaticosxVehiculo(id);
        },

        onSeleccionarNeumatico: function (id) {
            if (id != null) {
                let neumatico = this.Neumaticos.find((el) => el.value == id);
                this.Item.remanente_limite = neumatico.remanente_limite;
                this.Item.serieneumatico = neumatico.text;
            }
        },

        cerrarDialogo: function () {},

        agregar: async function () {
            try {
                if (this.$refs.form.validate()) {
                    const url = "scrap/store";
                    const data = {
                        id_cliente: localStorage.getItem("idcliente"),
                        id_usuario: localStorage.getItem("id"),
                        neumatico: this.Item
                    };
                    await axios
                        .post(url, data)
                        .then((response) => {
                            notif.success("Operación realizada con éxito");
                            setTimeout(() => (this.$router.go()), 1000)
                        })
                        .catch((error) => {
                            this.errorMessage = error.response.data;
                            console.error("error!", error);
                        });
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
