<template>
  <div class="pa-5">
    <v-col cols="12">
      <v-card>
        <v-toolbar color="secondary" dark>
          <span class="text-h6">Costo por Kilómetro</span>
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                <a target="_blank" href="https://helpdesk.tiresoft.pe/#costo_kilometro"
                  style="text-decoration:none !important; color: white;">
                  <v-icon dark>mdi-help-circle</v-icon>
                </a>
              </v-btn>
            </template>
            <span>¿Necesitas ayuda?</span>
          </v-tooltip>
        </v-toolbar>
        <!-- <v-card-title> Costo por Kilometro </v-card-title> -->
        <v-card-text>
          <v-form ref="form" enctype="multipart/form-data">
            <v-row>
              <v-col cols="12" sm="6" md="5">
                <v-text-field type="text" label="Cliente" v-model="Cliente.razon_social" hide-details="auto" readonly
                  required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field type="text" label="RUC" v-model="Cliente.ruc" hide-details="auto" readonly
                  required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select :items="tiposcosto" v-model="item.unidadmedida" label="Tipo de costo *" variant="underline"
                  class="mt-5" dense></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select :items="Years" v-model="item.anio" @change="onAnnio" label="Año *" variant="underline"
                  class="mt-5" dense></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-autocomplete :items="MarcasNeumatico" v-model="item.marca" label="Marca de Neumático *"
                  dense></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" small class="rounded-pill ml-2" @click="filtrar()">Filtrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="loader == true">
      <v-row justify="center">
        <vue-loaders name="ball-beat" color="red" scale="1"></vue-loaders>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-card class="pb-3 elevation-2">
        <v-card-title> KPI </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <table class="table-responsive">
              <tr>
                <td rowspan="2" class="pa-3">
                  <font color="black"><strong><em>Costo por kilómetro : </em></strong></font>
                </td>
                <td class="text-center border-bottom pa-1">
                  <font color="black"><em>Precio de neumáticos(usd)</em></font>
                </td>
              </tr>
              <tr>
                <td class="text-center pa-1">
                  <font color="black"><em>Rendimiento de neumáticos(km)</em></font>
                </td>
              </tr>
            </table>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-card-title> Año: {{ annio }} </v-card-title>
        <v-card-subtitle>Costo por Kilómetro</v-card-subtitle>
        <v-card-text>
          <IndicadorCosto :CostoPorKilometro="CostoPorKilometro" :MesesCostoPorKilometro="MesesCostoPorKilometro" />
        </v-card-text>

        <v-card-actions>
          <v-btn color="success" class="rounded-pill" @click="exportarAexcel" small>Exportar Excel</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import global from "../global";
import IndicadorCosto from "../components/indicador/tabla.vue"

export default {
  components: {
    IndicadorCosto
  },
  data() {
    return {
      item: {
        year: null,
        marca: null,
        unidadmedida: null
      },
      annio: "",
      tiposcosto: [
        {
          value: "K",
          text: "Kilómetros",
        },
        {
          value: "H",
          text: "Horas",
        },
      ],
      meses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      loader: false,
    };
  },
  computed: {
    ...mapState("cliente", ["Cliente"]),
    ...mapState("reporteconsolidado", ["Years"]),
    ...mapState("indicadores", [
      "MarcasNeumatico",
      "CostoPorKilometro",
      "MesesCostoPorKilometro",
    ]),
  },
  methods: {
    ...mapActions("reporteconsolidado", ["cargarAnios"]),
    ...mapActions("indicadores", [
      "cargarMarcasVehiculo",
      "reporteCostoPorKilometro",
    ]),

    onAnnio: function () {
      this.annio = this.item.anio;
    },

    filtrar: async function () {
      this.loader = true;
      await this.reporteCostoPorKilometro(this.item);
      this.loader = false;
    },

    exportarAexcel: function () {
      location.href =
        global.ruta_api + "excel/reporteindicadorcostokmhr?" +
        global._json_to_query_string({
          id_cliente: localStorage.getItem("idcliente"),
          id_usuario: localStorage.getItem("id"),
          year: this.annio,
          marca: this.item.marca,
          unidadmedida: this.item.unidadmedida
        });
    },

    pintarCostoPorKilometro: function (item, valor) {
      let pintar = "border texto-cen pa-1";
      if (valor) {
        if (valor >= item.rango_c_inferior && valor <= item.rango_c_superior)
          pintar = "border texto-cen pa-1 bg-celda-amarilla";
        if (valor < item.criterio_superior)
          pintar = "border texto-cen pa-1 bg-celda-verde";
        if (valor > item.criterio_inferior)
          pintar = "border texto-cen pa-1 bg-celda-roja";
      }
      return pintar;
    },

    tdValorCosto: function (index, item2) {
      return this.CostoPorKilometro[index]["indicador_mes_" + item2];
    },
  },
  created() {
    this.cargarAnios();
    this.cargarMarcasVehiculo();
    var moment = require("moment");
    let fecha = moment(new Date(), "YYYY-MM-DD"); //2021-05-20 //YYYY-MM-DD
    this.annio = fecha.format("YYYY");
    this.item.anio = fecha.format("YYYY");
    this.item.unidadmedida = "K";
    this.filtrar();
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
}

.border {
  border-style: solid;
  border-width: 1px;
}

.border-bottom {
  border-bottom: solid;
  border-width: 1px;
}

.border-top {
  border-top: solid;
  border-width: 1px;
}

.border-left {
  border-left: solid;
  border-width: 1px;
}

.border-right {
  border-right: solid;
  border-width: 1px;
}

.no-border {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.no-border-left {
  border-left: none;
}

.no-border-right {
  border-right: none;
}

.texto-cen {
  text-align: center;
}

.texto-izq {
  text-align: left;
}

.texto-just {
  text-align: justify;
}

.inline {
  display: inline-block;
}

.texto-arriba {
  vertical-align: text-top;
  vertical-align: top;
}

.letra-6 {
  font-size: 6pt;
}

.letra-7 {
  font-size: 7pt;
}

.letra-8 {
  font-size: 8pt;
}

.letra-9 {
  font-size: 9pt;
}

.letra-10 {
  font-size: 10pt;
}

.letra-11 {
  font-size: 11pt;
}

.letra-12 {
  font-size: 12pt;
}

.letra-14 {
  font-size: 14pt;
}

.letra-15 {
  font-size: 15pt;
}

.letra-18 {
  font-size: 18pt;
}

.letra-25 {
  font-size: 25pt;
}

.letra-30 {
  font-size: 30pt;
}

.margin-top-20 {
  margin-top: 20px;
}

.bg-celda-azul {
  background: #91e3da;
}

.bg-celda-amarilla {
  background: #faec5c;
  /*color:#EDDF04;*/
}

.bg-celda-verde {
  background: #a8fa6f;
}

.bg-celda-roja {
  background: #f55f50;
}

#table {
  display: block;
  /* important */
  width: 1000px;
  overflow-x: scroll;
}
</style>
