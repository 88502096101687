<template>
    <div>
        <v-card-title>
            <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                Descargar Tabla
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                hide-details></v-text-field>
            <v-spacer></v-spacer>
            <v-btn v-if="Crear" color="primary" dark class="mb-2" @click="nuevoItem()">
                Nuevo
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
            <v-data-table dense :headers="Cabecera" :items="Items" :items-per-page="10" :search="search"
                :loading="loading" loading-text="Cargando... Por favor, espere" class="elevation-1"
                no-data-text="No hay datos disponibles"
                :footer-props="{
                    'items-per-page-text': 'Resultados por página:',
                    'page-text': '{0}-{1} de {2}'
                }">
                <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn height="22" width="22" color="blue lighten-2" fab x-small dark
                                @click="mostrarFormularioScrap(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card-text>
    </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import {
    mapState
} from "vuex";

export default {
    props: [
        "Titulo",
        "Cabecera",
        "loading",
        "Items",
        "Item",
        "ItemTotal",
        "Excel",
        "Crear"
    ],
    data() {
        return {
            costo: {},
            search: "",
            mostrarIcono: true
        };
    },
    computed: {
        ...mapState('neumatico', ['TipoCambio'])
    },
    methods: {
        mostrarFormularioScrap: function (item) {
            this.$emit("mostrarFormularioScrap", item);
        }
    },
};
</script>
