import Vue from "vue";
import Vuex from "vuex";
import { axios2 as axios } from "@/interceptor/useApi";
import Swal from 'sweetalert2';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    Vehiculo: {},
    Neumatico: {},
    KilometrosRecorridos: [],
    RemanentesProyectados: [],
    RemanentesLimite: [],
    RemanentesOriginales: [],
    NeumaticosVehiculo: [],
    InspeccionesVehiculo: [],
    NeumaticosScrapMesMotivo: [],
    MotivosScrap: [],
    NeumaticosScrapMesMarca: [],
    Marcas: [],
    Modelos: [],
    Medidas: [],
    Disenios: [],
    Aplicaciones: [],
    PerdidasPorScrap: [],
    CargarAccionUsuarios:true,
    CargarDatosUltimaActividad:true,
    cargarDatos: true,
    cargarDatosDiariosMensuales: true,
    
  },
  getters: {},
  mutations: {
    LlenarVehiculo(state, payload) {
      state.Vehiculo = payload;
    },
    LlenarMarcas(state, payload) {
      state.Marcas = payload;
    },
    LlenarModelos(state, payload) {
      state.Modelos = payload;
    },
    LlenarMedidas(state, payload) {
      state.Medidas = payload;
    },
    LlenarDisenios(state, payload) {
      state.Disenios = payload;
    },
    LlenarAplicaciones(state, payload) {
      state.Aplicaciones = payload;
    },
    LlenarNeumatico(state, payload) {
      state.Neumatico = payload;
    },
    LlenarKilometrosRecorridos(state, payload) {
      state.KilometrosRecorridos = payload;
    },
    LlenarRemanentesProyectados(state, payload) {
      state.RemanentesProyectados = payload;
    },
    LlenarRemanentesLimite(state, payload) {
      state.RemanentesLimite = payload;
    },
    LlenarRemanentesOriginales(state, payload) {
      state.RemanentesOriginales = payload;
    },
    LlenarNeumaticosVehiculo(state, payload) {
      state.NeumaticosVehiculo = payload;
    },
    LlenarInspeccionesVehiculo(state, payload) {
      state.InspeccionesVehiculo = payload;
    },
    LlenarNeumaticosScrapMesMotivo(state, payload) {
      state.NeumaticosScrapMesMotivo = payload;
    },
    LlenarMotivosScrap(state, payload) {
      state.MotivosScrap = payload;
    },
    LlenarNeumaticosScrapMesMarca(state, payload) {
      state.NeumaticosScrapMesMarca = payload;
    },
    LlenarPerdidasPorScrap(state, payload) {
      state.PerdidasPorScrap = payload;
    },
    LLenarcargarDatos(state,payload){
      state.cargarDatos = payload;
    },
    LLenarcargarDatosDiariosMensuales(state,payload){
      state.cargarDatosDiariosMensuales = payload;
    },
    LlenarCargarAccionUsuarios(state,payload){
      state.CargarAccionUsuarios = payload;
    },
    LlenarCargarDatosUltimaActividad(state,payload){
      state.CargarDatosUltimaActividad = payload;
    }
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                `,
        },
        hideClass: {
          popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                `,
        },
        backdrop: `
                rgba(255,0,0,0.1)
                left top
                no-repeat
              `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },

    DatosMarcasModelosMedidas: async function ({ commit, dispatch }, item) {
      const data = { id_cliente: localStorage.getItem("idcliente") };
      try {
        const url = "reporte/filtros";
        await axios
          .post(url, data)
          .then((response) => {
            var array_marcas = [];
            response.data.success.marcas.forEach((element, i) => {
              array_marcas[i] = {
                value: element.id,
                text: element.descripcion,
              };
            });

            var array_modelos = [];
            response.data.success.modelos.forEach((element, i) => {
              array_modelos[i] = {
                value: element.id_modelo,
                text: element.descripcion,
                id_marca: element.id_marca,
              };
            });

            var array_medidas = [];
            response.data.success.medidas.forEach((element, i) => {
              array_medidas[i] = {
                value: element.id,
                text: element.descripcion,
              };
            });

            var array_disenios = [];
            response.data.success.disenios.forEach((element, i) => {
              array_disenios[i] = {
                value: element.id,
                text: element.descripcion,
              };
            });

            var array_aplicaciones = [];
            response.data.success.aplicaciones.forEach((element, i) => {
              array_aplicaciones[i] = {
                value: element.id,
                text: element.descripcion,
              };
            });

            commit("LlenarMarcas", array_marcas);
            commit("LlenarModelos", array_modelos);
            commit("LlenarMedidas", array_medidas);
            commit("LlenarDisenios", array_disenios);
            commit("LlenarAplicaciones", array_aplicaciones);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    DatosGraficoCurvaRemanenteNeumatico: async function ({ commit, dispatch }, id) {
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        id_neumatico: id,
      };

      try {
        const url = "reporte/curvaremanenteneumatico";
        await axios
          .post(url, data)
          .then((response) => {
            commit("LlenarNeumatico", response.data.success.neumatico);
            commit(
              "LlenarKilometrosRecorridos",
              response.data.success.kilometrosrecorridos
            );
            commit(
              "LlenarRemanentesProyectados",
              response.data.success.remanentesproyectados
            );
            commit(
              "LlenarRemanentesLimite",
              response.data.success.remanenteslimite
            );
            commit(
              "LlenarRemanentesOriginales",
              response.data.success.remanentesoriginales
            );
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    DatosGraficoCurvaRemanenteVehiculo: async function ({ commit, dispatch }, id) {
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        id_vehiculo: id,
      };

      try {
        const url = "reporte/curvaremanentevehiculo";
        await axios
          .post(url, data)
          .then((response) => {
            commit("LlenarVehiculo", response.data.success.vehiculo);
            commit(
              "LlenarKilometrosRecorridos",
              response.data.success.kilometrosrecorridos
            );
            commit(
              "LlenarRemanentesLimite",
              response.data.success.remanenteslimite
            );
            commit(
              "LlenarNeumaticosVehiculo",
              response.data.success.neumaticosdelvehiculo
            );
            commit(
              "LlenarInspeccionesVehiculo",
              response.data.success.inspeccionesvehiculo
            );
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    DatosReporteAnalisisScrap: async function ({ commit, dispatch }, year) {
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        year: year,
      };

      try {
        const url = "reporte/scrapmotivoretiro";
        await axios
          .post(url, data)
          .then((response) => {
            commit(
              "LlenarNeumaticosScrapMesMotivo",
              response.data.success.datosreporte
            );
            commit("LlenarMotivosScrap", response.data.success.motivosscrap);          
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    DatosReporteScrapMesMarca: async function ({ commit, dispatch }, year) {
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        year: year,
      };

      try {
        const url = "reporte/scrapmarca";
        await axios
          .post(url, data)
          .then((response) => {
            commit(
              "LlenarNeumaticosScrapMesMarca",
              response.data.success.datosreporte
            );
            commit("LlenarMarcas", response.data.success.marcas);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    DatosReportePerdidasPorScrap: async function ({ commit, dispatch }, item) {
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        id_marca: item.marca,
        id_modelo: item.modelo,
        id_medida: item.medida,
        condicion: item.condicion,
        fecha_inicial: item.fecha_inicial,
        fecha_final: item.fecha_final,
      };
      try {
        const url = "reporte/perdidasscrap";
        await axios
          .post(url, data)
          .then((response) => {
            commit(
              "LlenarPerdidasPorScrap",
              response.data.success.datosreporte
            );
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    DatosReporteComparativoConfiguracion: async function ({ commit, dispatch }, id) {
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        id_neumatico: id,
      };
      let rpta = null;
      try {
        const url = "reporte/comparativoneumatico";
        await axios
          .post(url, data)
          .then((response) => {
            rpta = response.data.success.neumatico;
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
      return rpta;
    },

    Marcas_o_Medidas_ReporteComparativoMarca: async function (
      { commit, dispatch },
      item
    ) {
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        tipo_costo: item.tipocosto,
        condicion_neumatico: item.condicion,
        eje: item.eje,
        modelos: item.modelo,
        medidas: item.medida,
        aplicacion: item.aplicacion,
        disenios: item.disenio,
        reencauches: item.cantidad,
      };
      let rpta = null;
      try {
        const url = "reporte/marcas_o_disenios_comparativo";
        await axios
          .post(url, data)
          .then((response) => {
            rpta = response.data.success.neumaticos;
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
      return rpta;
    },

    DatosReporteComparativoMarca: async function ({ commit, dispatch }, item) {
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        id_marca_o_disenio: item.id,
        tipo_costo: item.tipocosto,
        condicion_neumatico: item.condicion,
        eje: item.eje,
        modelos: item.modelo,
        medidas: item.medida,
        aplicacion: item.aplicacion,
        disenios: item.disenio,
        reencauches: item.cantidad,
      };
      let rpta = null;
      try {
        const url = "reporte/comparativomarca";
        await axios
          .post(url, data)
          .then((response) => {
            rpta = response.data.success.neumaticos;
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
      return rpta;
    },

    cargarsesionusuarios: async function ({ commit, dispatch}, datos) {
      let rpta = [];
      try {
        const url = "reporte/sesionesusuarios";
        let data = {
          fecha_inicial: datos.fecha_inicial,
          fecha_final: datos.fecha_final,
        };
        await axios
          .post(url, data)
          .then((response) => {
            rpta = response.data.data.accesosusuario;
            commit("LLenarcargarDatos", false);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
      return rpta;
    },

    cargarsesionusuariosdiaria: async function ({ commit, dispatch }, datos) {
      let rpta = [];
      try {
        const url = "reporte/sesionesusuariosdiario";
        let data = {
          fecha_inicial: datos.fecha_inicial,
          fecha_final: datos.fecha_final,
        };
        await axios
          .post(url, data)
          .then((response) => {
            rpta = response.data.data.accesosdiarios;
            commit("LLenarcargarDatosDiariosMensuales", false);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
      return rpta;
    },

    cargarsesionusuariosmensual: async function ({ commit, dispatch }, datos) {
      let rpta = [];

      try {
        const url = "reporte/sesionesusuariosmensual";
        let data = {
          fecha_inicial: datos.fecha_inicial,
          fecha_final: datos.fecha_final,
        };
        await axios
          .post(url, data)
          .then((response) => {
            rpta = response.data.data.accesosmensuales;
            commit("LLenarcargarDatosDiariosMensuales", false);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
      return rpta;
    },

    cargaraccionesusuarios: async function ({ commit, dispatch }, datos) {
      let rpta = [];

      try {
        const url = "reporte/accionesusuario";
        let data = {
          fecha_inicial: datos.fecha_inicial,
          fecha_final: datos.fecha_final,
        };
        await axios
          .post(url, data)
          .then((response) => {
            rpta = response.data.data.accionesusuario;
            commit('LlenarCargarAccionUsuarios',false);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
      return rpta;
    },

    cargartiemposesionusuarios: async function ({ commit, dispatch }, datos) {
      let rpta = [];

      try {
        const url = "reporte/tiemposesionusuarios";
        let data = {
          fecha_inicial: datos.fecha_inicial,
          fecha_final: datos.fecha_final,
          id_cliente: datos.id_cliente,
        };
        await axios
          .post(url, data)
          .then((response) => {
            rpta = response.data.data;
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
      return rpta;
    },

    cargardescargasconsolidado: async function ({ commit, dispatch }, datos) {
      let rpta = [];

      try {
        const url = "reporte/reportedescargasconsolidado";
        let data = {
          anio: datos.anio,
          mes: datos.mes,
          id_cliente: datos.id_cliente,
        };
        await axios
          .post(url, data)
          .then((response) => {
            rpta = response.data.data;
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
      return rpta;
    },

    cargarbusquedasconsolidado: async function ({ commit, dispatch }, datos) {
      let rpta = [];

      try {
        const url = "reporte/reportebusquedasconsolidado";
        let data = {
          anio: datos.anio,
          mes: datos.mes,
          id_cliente: datos.id_cliente,
        };
        await axios
          .post(url, data)
          .then((response) => {
            rpta = response.data.data;
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
      return rpta;
    },

    cargarultimaactividad: async function ({ commit, dispatch }) {
      let rpta = [];

      try {
        const url = "reporte/reporteultimaconexion";
        await axios
          .post(url)
          .then((response) => {
            rpta = response.data.data;
            commit("LlenarCargarDatosUltimaActividad", false);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
      return rpta;
    },

    cargargraficasproductividad: async function ({ commit, dispatch }, datos) {
      let rpta = [];

      try {
        const url = "reporte/reporteproductividad";
        let data = {
          id_cliente: datos.id_cliente,
          fecha_inicial: datos.fecha_inicial,
          fecha_final: datos.fecha_final,
        };
        await axios
          .post(url, data)
          .then((response) => {
            rpta = response.data.data;
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
      return rpta;
    },

    cargargraficasoperacionesxfecha: async function ({ commit, dispatch }, datos) {
      let rpta = [];

      try {
        const url = "reporte/reporteoperacionesxfecha";
        let data = {
          id_cliente: datos.id_cliente,
          fecha_inicial: datos.fecha_inicial,
          fecha_final: datos.fecha_final,
          id_usuario: datos.id_usuario,
        };
        await axios
          .post(url, data)
          .then((response) => {
            rpta = response.data.data;
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
      return rpta;
    },

    VaciarObjetoNeumatico: function ({ commit }) {
      commit("LlenarNeumatico", {});
    },

    VaciarObjetoVehiculo: function ({ commit }) {
      commit("LlenarVehiculo", {});
    },
  },
};
