<template>
  <div class="pa-5">
    <v-col lg="12">
      <!--<tabla Titulo="Reporte de neumáticos" :Cabecera="Cabecera" :loading="Loader" :Items="Items" :ItemTotal="ItemTotal" :Excel="false" :Crear="true" :empresaList="empresaList" :marcaNeumaticoList="marcaNeumaticoList" :modeloNeumaticoList="modeloNeumaticoList" :medidaNeumaticoList="medidaNeumaticoList" :disenioNeumaticoList="disenioNeumaticoList"/>-->
      <v-row>
        <v-col lg="12">
          <tabla
          Titulo="Reporte de Inspecciones"
          :Cabecera="Cabecera"
          :loading="Loader"
          :Items="Items"
          :ItemTotal="ItemTotal"
          :Excel="false"
          :Crear="false"
        />
        </v-col>
      </v-row>

     <!--  <v-row justify="center">
        <vue-loaders
          v-if="loader == true"
          name="ball-pulse"
          color="#06A7C8"
          scale="1"
        ></vue-loaders>
      </v-row> -->

      <!-- <v-row class="ml-1">
        <v-col cols="12" sm="3">
          <v-overflow-btn
            class="pa-0 rounded-pill"
            :items="opcionesAexportar"
            label="Exportar"
            target="#dropdown-example"
            v-model="extension1"
            @change="exportarAExcel1"
            background-color="#CEECF5"
            dense
          ></v-overflow-btn>
        </v-col>

        <v-col cols="12" sm="3">
          <v-overflow-btn
            class="pa-0 rounded-pill"
            :items="opcionesAexportar"
            label="Exportar General"
            target="#dropdown-example"
            v-model="extension2"
            @change="exportarAExcel2"
            background-color="#CEECF5"
            dense
          ></v-overflow-btn>
        </v-col>
      </v-row> -->
    </v-col>
  </div>
</template>

<script>
import tabla from "@/components/inspeccion/tabla.vue";
import { mapState, mapActions } from "vuex";
import global from "../global";
import {axios2 as axios} from "@/interceptor/useApi";

export default {
  name: "Neumaticos",
  components: { tabla,},
  created() {
    this.cargarInspecciones();
  },
  data() {
    return {
      //Items: [],
      Cabecera: [
        {
          text: "Opciones",
          value: "acciones",
          align: "center",
        },
        {
          text: "Identificador",
          value: "identificador",
          align: "center",
        },
        {
          text: "Código",
          value: "insp_codigo",
          align: "center",
        },
        {
          text: "Placa",
          value: "placa",
          align: "center",
        },
        {
          text: "Código Vehículo",
          value: "vehi_codigo",
          align: "center",
        },
        {
          text: "Tipo Vehículo",
          value: "vehi_tipo",
          align: "center",
        },
        {
          text: "Fecha Inspección",
          value: "fecha_inspeccion_local",
          align: "center",
        },
        {
          text: "Km/Hr Inspección",
          value: "km_inspeccion",
          align: "right",
        },
      ],
      opcionesAexportar: [
        {
          id: 1,
          text: "Exportar Excel",
        },
        {
          id: 2,
          text: "Exportar CSV",
        },
      ],
      extension1: "",
      extension2: "",
      loader: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions("inspeccion", ["cargarInspecciones"]),

  /*   async exportarAExcel1() {
      try {
        this.loader = true;
        const url =
          global.ruta_api +
          "excel/reporteinspeccionesminified?" +
          global._json_to_query_string({
            id_cliente: localStorage.getItem("idcliente"),
            extension: this.extension1,
          });

        await axios({ url: url, method: "GET", responseType: "blob" })
          .then(async (response) => {
            this.loader = false;
            var nombre_archivo = response.headers["nombre-archivo"];
            if (response.data.type == "text/html") {
              // no es excel, probablemente es json con un mensaje de error
              const res = JSON.parse(await response.data.text());
              if (!res.exito) {
                alert(res.mensaje || "Sucedió un error, intentelo nuevamente");
              }
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", nombre_archivo);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
          });
      } catch (error) {
        console.log(error);
      }
    }, */

    /* async exportarAExcel2() {
      try {
        this.loader = true;
        const url =
          global.ruta_api +
          "excel/reporteinspecciones?" +
          global._json_to_query_string({
            id_cliente: localStorage.getItem("idcliente"),
            id_usuario: localStorage.getItem("id"),
            extension: this.extension2,
          });

        await axios({ url: url, method: "GET", responseType: "blob" })
          .then(async (response) => {
            this.loader = false;
            var nombre_archivo = response.headers["nombre-archivo"];
            if (response.data.type == "text/html") {
              // no es excel, probablemente es json con un mensaje de error
              const res = JSON.parse(await response.data.text());
              if (!res.exito) {
                alert(res.mensaje || "Sucedió un error, intentelo nuevamente");
              }
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", nombre_archivo);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
          });
      } catch (error) {
        console.log(error);
      }
    } */
  },
  computed: {
    ...mapState("inspeccion", ["Items", "ItemTotal", "Loader"]),
  },
};
</script>
