<template>
<div class="pa-5">
    <v-col lg="12">
        <!--<tabla Titulo="Reporte de neumáticos" :Cabecera="Cabecera" :loading="Loader" :Items="Items" :ItemTotal="ItemTotal" :Excel="false" :Crear="true" :empresaList="empresaList" :marcaNeumaticoList="marcaNeumaticoList" :modeloNeumaticoList="modeloNeumaticoList" :medidaNeumaticoList="medidaNeumaticoList" :disenioNeumaticoList="disenioNeumaticoList"/>-->
        <tabla Titulo="Reporte de neumáticos" :Cabecera="Cabecera" :loading="Loader" :Items="Items" :ItemTotal="ItemTotal" :Excel="false" :Crear="true" />
    </v-col>
</div>
</template>

<script>
import tabla from "@/components/neumaticos/tabla.vue";
import {
    mapState,
    mapActions
} from "vuex";

export default {
    name: "Neumaticos",
    components: {
        tabla,
    },
    created() {
        this.cargarNeumaticos();
        this.cargarDatosAdicionales();
    },
    data() {
        return {
            //Items: [],
            Cabecera: [{
                    text: "Opc.",
                    value: "acciones",
                    align: "left",
                    width: 80,
                },
                {
                    text: "Id",
                    value: "id",
                    align: "left",
                },
                {
                    text: "Serie Cliente",
                    value: "num_serie",
                    width: 120,
                    align: "left",
                },
                {
                    text: "DOT",
                    value: "serie_cliente",
                    width: 100,
                    align: "left",
                },
                {
                    text: "Marca",
                    value: "marca",
                    align: "left",
                    width: 100,
                },
                {
                    text: "Modelo",
                    value: "modelo",
                    width: 100,
                    align: "left",
                },
                {
                    text: "Medida",
                    value: "medida",
                    align: "left",
                    width: 100,
                },
                {
                    text: "Diseño banda",
                    value: "disenio",
                    align: "left",
                    width: 100,
                },
                {
                    text: "Costo",
                    value: "costo",
                    width: 120,
                    align: "left",
                },
                {
                    text: "Condición del neumático",
                    value: "condicion",
                    align: "left",
                    width: 100,
                },
                {
                    text: "Estado",
                    value: "estado_descripcion",
                    align: "left",
                    width: 100,
                },
                {
                    text: "Vehículo",
                    value: "placa",
                    align: "left",
                    width: 150,
                },
                {
                    text: "Fecha de registro",
                    value: "fecha_registro",
                    width: 150,
                    align: "left",
                },
            ],
            loading: false,
        };
    },
    methods: {
        ...mapActions("neumatico", ["cargarNeumaticos", "cargarDatosAdicionales"]),
    },
    computed: {
        ...mapState("neumatico", ["Items", "Loader", "ItemTotal"]),
    },
};
</script>
