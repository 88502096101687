<template>
<div class="pa-5">
    <v-col lg="12">
        <tabla Titulo="Administración de Reportes" :Cabecera="Cabecera" :loading="Loader" :Items="Reportes" :Excel="false" :Crear="false" @refrescar="refrescar" v-if="mostrartabla ==true" :Loading="Loading"/>
    </v-col>
</div>
</template>

<script>
import tabla from "@/components/administradorreporte/tabla.vue";
import {
    mapState,
    mapActions
} from "vuex";

export default {
    name: "Reportes",
    components: {
        tabla,
    },
    created() {
        this.cargarDatosAdministradorReportes();
    },
    data() {
        return {
            //Items: [],
            Cabecera: [{
                    text: "Nombre completo",
                    value: "name",
                    align: "left"
                },
                {
                    text: "Reporte",
                    value: "reporte",
                    align: "left"
                },
                {
                    text: "Última actualización",
                    value: "fecha",
                    align: "left"
                },
                {
                    text: "Reporte Generado",
                    value: "iconos",
                    align: "left"
                },
                {
                    text: "estado",
                    value: "estado",
                    align: "left"
                },
                {
                    text: "Aprobación",
                    value: "acciones",
                    align: "left"
                }
            ],
            loading: false,
            Loader: false,
            mostrartabla: true
        };
    },
    methods: {
        ...mapActions("reporteconsolidado", ["cargarDatosAdministradorReportes"]),

        refrescar: async function(){
            await this.cargarDatosAdministradorReportes();
            this.mostrartabla = false;
            this.$nextTick().then(() => {
                this.mostrartabla = true;
            });
        }
    },
    computed: {
        ...mapState("reporteconsolidado", ["Reportes"]),
        ...mapState("reporteconsolidado",["Loading"])
    },
};
</script>
