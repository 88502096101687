<template>
<div class="pa-5">
    <v-col lg="12">
        <tabla Titulo="Gestión de Usuarios por Cliente" :Cabecera="Cabecera" :Items="Usuarios" :Excel="false" :Crear="true" :Loading="LoadingUserClients"/>
    </v-col>
</div>
</template>

<script>
import tabla from "@/components/cliente/tablausuarios.vue"

import {
  mapActions,
    mapState
} from "vuex";
export default {
    components: {
        tabla
    },
    data() {
        return {
            Cabecera: [{
                    text: 'Id',
                    value: 'id',
                    width: 100
                },
                {
                    text: 'Cliente',
                    value: 'razon_social',
                    width: 400
                },
                {
                    text: 'Miembros',
                    value: 'miembros',
                    width: 350
                },
                {
                    text: 'Acción',
                    value: 'accion',
                    width: 150
                },
            ]
        }
    },
    computed: {
        ...mapState('cliente', ['Usuarios','LoadingUserClients'])
    },
    methods: {
        ...mapActions('cliente', ['obtenerUsuariosPorClientes']),
    },
    created() {
        this.obtenerUsuariosPorClientes();
    }
};
</script>
