<template>
    <div class="pa-5">
        <v-col lg="12">
            <v-card>
                <v-toolbar color="secondary" dark>
                    <span class="text-h6">Reporte de diseños de neumáticos inspeccionados</span>
                    <v-spacer></v-spacer>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                                <a target="_blank" href="https://helpdesk.tiresoft.pe/#marketing-disenios-neumaticos"
                                    style="text-decoration:none !important; color: white;">
                                    <v-icon dark>mdi-help-circle</v-icon>
                                </a>
                            </v-btn>
                        </template>
                        <span>¿Necesitas ayuda?</span>
                    </v-tooltip>
                </v-toolbar>
                <FiltrosReporteMarketing titulo="" :mostrarempresa="false" :mostrarrtd="false" :mostrarmedida="true"
                    :mostrarsegmentacion="false" @filtrarNeumaticos="filtrarNeumaticos" ref="filtrosmarketing">
                </FiltrosReporteMarketing>

            </v-card>


            <v-card class="mt-5" v-if="mostrarCard">
                <v-card-text>
                    <v-row class="mt-5">
                        <v-col cols="12" sm="12" md="6">
                            <TablaAgrupadaReporteMarketing :headers="encabezados" :items="neumaticosdisenios"
                                grupo="empresa"></TablaAgrupadaReporteMarketing>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-col cols="12" sm="12" md="6">
                        <v-btn color="#32B1E9" :loading="loadingexcel" @click="exportarExcel()" small block>Exportar</v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-col>
    </div>
</template>

<script>
import TablaAgrupadaReporteMarketing from '@/components/TablaAgrupadaReporteMarketing.vue'
import FiltrosReporteMarketing from "@/components/FiltrosReporteMarketing.vue"
import {
    notif
} from "@/plugins/notyf"
import {
    mapActions,
    mapState
} from 'vuex'
import global from '@/global'

export default {
    components: {
        FiltrosReporteMarketing,
        TablaAgrupadaReporteMarketing
    },
    data() {
        return {
            item: null,
            loadingexcel: false,
            encabezados: [{
                text: 'Disenios de neumáticos',
                align: 'start',
                value: 'name',
                groupable: false,
            },
            {
                text: 'empresa',
                value: 'empresa',
                align: 'right'
            },
            {
                text: 'cantidad',
                value: 'cantidad',
                align: 'right'
            }
            ],
            neumaticosdisenios: [],
            totalgeneral: 0,
            mostrarCard:false
        }
    },
    computed: {
        ...mapState("marketing", ["DiseniosNeumaticos"])
    },
    methods: {
        ...mapActions("marketing", ["cargarDisenioNeumaticos"]),

        exportarExcel() {
            this.loadingexcel = true;
            global._exportar_a_excel("excel/mkgdiseniosneumaticos", "POST", this.item);
            this.loadingexcel = false;
        },

        async cargarDatos(filtros) {
            this.item = filtros;
            this.$refs.filtrosmarketing.turnOnLoader();
            await this.cargarDisenioNeumaticos(filtros);
            this.neumaticosdisenios = [];
            let datos = global._arrayValoresUnicos(this.DiseniosNeumaticos, 'disenio', false, 'empresa');
            this.totalgeneral = datos.total;
            this.neumaticosdisenios = datos.array;
            this.$refs.filtrosmarketing.turnOffLoader();
            this.mostrarCard = true;
        },

        async filtrarNeumaticos(filtros) {
            if (filtros.fechainicio != '' && filtros.fechafin != "") {
                if (filtros.fechainicio > filtros.fechafin) {
                    notif.error("La fecha final debe ser mayor a la fecha inicial");
                } else {
                    this.cargarDatos(filtros);
                }
            } else {
                this.cargarDatos(filtros);
            }
        }
    },

}
</script>
