import Vue from 'vue'
import Vuex from 'vuex'
import {axios2 as axios} from "@/interceptor/useApi";
import Swal from 'sweetalert2';

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    Items: [],
    ItemsRoles: [],
    ItemsEmpresa: [],
    Loading: true,
  },
  getters: {
    Items(state) {
      return state.Items
    },
    ItemsRoles(state) {
      return state.ItemsRoles
    },
    ItemsEmpresa(state) {
      return state.ItemsEmpresa
    }
  },
  mutations: {
    LlenarItems(state, data) {
      state.Items = data
    },
    LlenarItemsRoles(state, data) {
      state.ItemsRoles = data
    },
    LlenarItemsEmpresa(state, data) {
      state.ItemsEmpresa = data
    },
    LlenarLoading(state, data) {
      state.Loading = data
    }
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },

    cargarUsuario: async function ({ commit, dispatch }) {
      try {
        var roles = []
        var clientes = []
        var con = 0;
        var con1 = 0;
        const url = "usuarios/get/"+localStorage.getItem("id");
        await axios.get(url)
          .then(response => {
            response.data.success.resultado.roles.forEach(element => {
              roles[con] = element.name;
              con++;
            });
            response.data.success.resultado.clientes.forEach(element => {
              clientes[con1] = element.razon_social;
              con1++;
            });
            commit('LlenarItems', response.data.success.resultado.usuarios);
            commit('LlenarItemsRoles', roles);
            commit('LlenarItemsEmpresa', clientes);  
            commit('LlenarLoading', false);          
          })
          .catch(error => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },
    cargarUsuarioMinified: async function({ commit, dispatch }){
      try {
        const url = "usuarios/indexminified"
        await axios.get(url)
          .then(response => {
            commit('LlenarItems', response.data.data);
          })
          .catch(error => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },
    agregarUsuario: async function ({ commit, dispatch }, datos) {
      let config = { headers: { 'Content-Type': 'multipart/form-data ', 'Accept': 'application/json' } }
      let formdata = new FormData();
      formdata.append('file', datos.imagen_firma);
      formdata.append("data", JSON.stringify(datos));
      var url = ''
      if (datos.id == 0) {
        var url = "usuarios/store"
      } else {
        var url = "usuarios/update"
      }
      await axios.post(url, formdata, config)
        .then(response => {
          dispatch('cargarUsuario')
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },
    eliminarUser: async function ({ commit, dispatch }, datos) {
      const url = "eliminarusuario"
      const data = { id: datos.id };
      await axios.post(url, data)
        .then(response => {
          dispatch('cargarUsuario')
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    }
  },
}


