<template>
    <div class="pa-5">
        <v-col lg="12">
            <!--Formulario de Encabezado de Inspecciones-->
            <v-row justify="center">
                <v-col lg="12">
                    <Nuevo :Item="Item" :Cliente="Cliente" @cargarPosiciones="cargarPosiciones"
                        @guardarInspeccion="guardarInspeccion" @refrescar="refrescar" @fechavalida="fechavalida"
                        v-if="mostrarNuevo" :Array_id_inspecciones="array_id_inspecciones"></Nuevo>
                </v-col>
            </v-row>

            <MensajeTransicion :color="color" :text="mensaje" v-if="verAlerta" :top="false" :bottom="true" />

            <!--loader-->
            <v-row justify="center">
                <vue-loaders v-if="loader == true" name="ball-beat" color="red" scale="1"></vue-loaders>
            </v-row>

            <!--Ubicacion de neumaticos e imagen de tipovehiculo-->
            <v-row justify="center">
                <v-col cols="12" sm="12" md="6">
                    <UbicacionNeumaticos Titulo="Ubicación de neumáticos" :ItemsPosicion="ItemsPosicion"
                        @seleccionarNeumatico="seleccionarNeumatico" v-if="mostrarubicacion == true" />
                </v-col>

                <v-col cols="12" sm="12" md="6" v-if="mostrartabla == true">
                    <v-card>
                        <v-toolbar height="50" color="#545554" dark>
                            {{ tipovehiculo }}
                        </v-toolbar>
                        <div class="text-center mt-2">
                            <img :src="rutaimagen" width="480px" height="265px" alt="tipovehiculo" />
                        </div>
                    </v-card>
                </v-col>
            </v-row>

            <!--Detalles de inspecciones-->
            <v-row justify="center">
                <v-col lg="12">
                    <TablaDetalles :Inspecciones="Inspecciones" v-if="mostrartabla == true"
                        @editarInspeccionParcial="editarInspeccionParcial"
                        @eliminarInspeccionParcial="eliminarInspeccionParcial" :mostrarBotonEliminar="true" />
                </v-col>
            </v-row>

            <!--Modal Formulario de Inspecciones-->
            <Detalle ref="detalle" :dialog="dialog" :Item="itemSelected" :Ejes="Ejes"
                :MotivosInaccesibilidad="MotivosInaccesibilidad" :TiposValvula="TiposValvula" :Duales="duales"
                :Observaciones="observaciones" @agregarInspeccionParcial="agregarInspeccionParcial"
                @cerrarModal="cerrarModal" v-if="dialog" />
                
        </v-col>
    </div>
</template>

<script>
import {
    mapState,
    mapActions
} from "vuex";
import Nuevo from "@/components/inspeccion/nuevo.vue";
import Detalle from "@/components/inspeccion/detalle.vue";
import TablaDetalles from "@/components/inspeccion/detalles.vue";
import MensajeTransicion from "../components/Alerta.vue"
import UbicacionNeumaticos from "@/components/inspeccion/ubicacionneumaticos.vue";
import {
    axios2 as axios
} from "@/interceptor/useApi";
import global from "../global";
import router from "@/router";

export default {
    components: {
        Nuevo,
        Detalle,
        MensajeTransicion,
        UbicacionNeumaticos,
        TablaDetalles
    },
    data() {
        return {
            Item: {
                id: null,
                codigo: null,
                id_vehiculo: null,
                repuesto: 0,
                portallantas: 0,
                id_planta: null,
                fecha_inspeccion: null
            },
            ItemsPosicion: [],
            mostrarubicacion: false,
            mostrartabla: false,
            rutaimagen: "",
            tipovehiculo: "",
            dialog: false,
            dialog_neumatico: false,
            itemSelected: {},
            id_vehiculo: null,
            km_inspeccion: "",
            Inspecciones: [],
            array_id_inspecciones: [],
            duales: [{
                opcion: false,
                text: "Diseño",
            },
            {
                opcion: false,
                text: "Tamaño",
            },
            {
                opcion: false,
                text: "Tipo de Construcción",
            },
            {
                opcion: false,
                text: "Medida de Neumático",
            },
            {
                opcion: true,
                text: "No Aplica",
            },
            ],
            observaciones: [{
                name: "Desg. Irregular",
                opcion: false,
                text: "Desg. Irregular",
            },
            {
                name: "Lista para Reparar",
                opcion: false,
                text: "Para Reparar",
            },
            {
                name: "Aro Defectuoso",
                opcion: false,
                text: "Aro Defectuoso",
            },
            {
                name: "Fallas en el flanco",
                opcion: false,
                text: "Fallas en el flanco",
            },
            ],
            mostrarNuevo: true,
            loader: false,
            mensaje: "",
            color: "success",
            verAlerta: false,
            verDetalle: false
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
        ...mapState("cliente", ["Cliente"]),
        ...mapState("inspeccion", [
            "Ejes",
            "MotivosInaccesibilidad",
            "TiposValvula",
        ]),
    },
    methods: {
        ...mapActions("cliente", ["ObtenerDatosCliente"]),

        refrescar: function () {
            this.ObtenerDatosCliente();
            this.Inspecciones = [];
            this.array_id_inspecciones = [];
            this.mostrarubicacion = false;
            this.mostrartabla = false;
        },

        fechavalida: function (fecha) {
            this.Item.fecha_inspeccion = fecha;
        },

        seleccionarNeumatico: function (detalle) {
                console.log("detalle <> ", detalle);
                this.itemSelected = {
                    id: null,
                    id_vehiculo: this.id_vehiculo,
                    num_serie: detalle.num_serie,
                    d_posicion: "P" + detalle.posicion, //posicion en el grafico
                    ubicacion: detalle.ubicacion,
                    id_neumaticos: detalle.id_neumaticos,
                    km_inspeccion: this.km_inspeccion,
                    posicion: detalle.posicion,
                    id_ejes: detalle.id_ejes,
                    tipo_presion: "PSI",
                    sep_duales: "Sep. Entre Duales- No Aplica",
                    estado: "Continuar en Operación",
                    array_desgirregular: [],
                    array_parareparar: [],
                    array_fallasflanco: [],
                    recomendacion: null,
                    disabled: false,
                    motivo_inaccesibilidad: null,
                    rem_proximo:detalle.rem_proximo,
                };
                console.log("itemSelected <> ", this.itemSelected);
                this.duales = [{
                    opcion: false,
                    text: "Diseño",
                },
                {
                    opcion: false,
                    text: "Tamaño",
                },
                {
                    opcion: false,
                    text: "Tipo de Construcción",
                },
                {
                    opcion: false,
                    text: "Medida de Neumático",
                },
                {
                    opcion: true,
                    text: "No Aplica",
                },
                ];

                this.observaciones = [{
                    name: "Desg. Irregular",
                    opcion: false,
                    text: "Desg. Irregular",
                },
                {
                    name: "Lista para Reparar",
                    opcion: false,
                    text: "Para Reparar",
                },
                {
                    name: "Aro Defectuoso",
                    opcion: false,
                    text: "Aro Defectuoso",
                },
                {
                    name: "Fallas en el flanco",
                    opcion: false,
                    text: "Fallas en el flanco",
                }
                ];
                this.verDetalle = true;
                this.dialog = true;
        },

        cargarPosiciones: async function (objeto) {
            this.loader = true;
            let vehiculo = objeto.vehiculo;
            this.id_vehiculo = vehiculo.id_vehiculo;
            this.km_inspeccion = objeto.inspeccion.km_inspeccion;

            try {
                const url = "inspecciones/posiciones";
                const datos = {
                    id_cliente: localStorage.getItem("idcliente"),
                    id_vehiculo: vehiculo.id_vehiculo,
                    id_configuracion: vehiculo.id_configuracion
                };

                await axios
                    .post(url, datos)
                    .then((response) => {
                        this.ItemsPosicion = response.data.success.posicion;
                        this.mostrarubicacion = true;
                        this.mostrartabla = true;
                        this.rutaimagen =
                            global.ruta_image +
                            "images/tipovehiculo/" +
                            vehiculo.id_tipo +
                            ".png";
                        this.tipovehiculo = vehiculo.tipovehiculo;
                        this.loader = false;
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        agregarInspeccionParcial: async function (id) {
            //cargarInspeccion
            if (id) {
                this.array_id_inspecciones.push(id);
            }

            try {
                const url = "inspecciones/obtenerInspeccionesParcial";
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                    array_id_inspecciones: this.array_id_inspecciones,
                };
                await axios
                    .post(url, data)
                    .then((response) => {
                        this.Inspecciones = response.data.inspecciones;
                        console.log(this.Inspecciones);
                        //deshabilitar neumatico inspeccionado
                        this.Inspecciones.forEach((element) => {
                            let pos = this.ItemsPosicion.findIndex(
                                (el) => el.num_serie == element.num_serie
                            );
                            this.ItemsPosicion[pos].disabled = true;
                            this.ItemsPosicion[pos].clase = "imagen-neumatico1";
                            element.id_vehiculo = this.id_vehiculo;
                        });
                        //refrescar componente de ubicacion de neumaticos
                        this.mostrarubicacion = false;
                        this.$nextTick().then(() => {
                            this.mostrarubicacion = true;
                        });
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        editarInspeccionParcial: function (item) {
            this.itemSelected = {
                ...item,
            };
            item.array_resultado.forEach((element1) => {
                this.duales.forEach((element2) => {
                    if (element1 == element2.text) {
                        element2.opcion = true;
                    }
                });
            });
            item.array_otros.forEach((element1) => {
                this.observaciones.forEach((element2) => {
                    if (element1 == element2.name) {
                        element2.opcion = true;
                    }
                });
            });
            this.duales = this.duales;
            this.observaciones = this.observaciones;
            this.dialog = true;
            this.verDetalle = true;
        },

        eliminarInspeccionParcial: async function (id) {
            this.array_id_inspecciones = this.array_id_inspecciones.filter((i) => i != id);

            try {
                const url = "inspecciones/deleteInspeccion";
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                    id_inspeccion: id
                };
                await axios
                    .post(url, data)
                    .then((response) => {
                        //habilitar el neumatico puesto se elimino la inspeccion
                        this.Inspecciones.forEach((element) => {
                            if (element.id == id) {
                                let pos = this.ItemsPosicion.findIndex(
                                    (el) => el.num_serie == element.num_serie
                                );
                                this.ItemsPosicion[pos].disabled = false;
                                this.ItemsPosicion[pos].clase = "imagen-neumatico2";
                            }
                        });
                        //eliminar item del array inspecciones
                        this.Inspecciones = this.Inspecciones.filter((i) => i.id != id);
                        this.mostrarubicacion = false;
                        this.$nextTick().then(() => {
                            this.mostrarubicacion = true;
                        });
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        guardarInspeccion: async function (opcion) {
            this.color = "success";
            this.verAlerta = true;
            this.mensaje = "Operación realizada con éxito";
            setTimeout(() => {
                this.verAlerta = false;
                router.push("/inspeccionreporte");
            }, 1500);

        },

        cerrarModal: function (valor) {
            this.dialog = false;
            this.verDetalle = false;
            if (valor) {
                this.color = "success";
                this.verAlerta = true;
                this.mensaje = "Inspeccion registrada con éxito";
                setTimeout(() => [(this.verAlerta = false)], 4000);
            }
        }
    },
    mounted: async function () {
        await this.ObtenerDatosCliente();
    },
};
</script>
