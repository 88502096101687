<template>
    <v-dialog v-model="dialog" persistent max-width="650px" @click:outside="cerrarDialogo()">
        <v-card>
            <v-card-title>
                Registrar Incidencia
            </v-card-title>
            <v-toolbar height="30" color="#545554" dark>
                <span class="letra-11">Datos de Cliente</span>
            </v-toolbar>
            <v-card-text>
                <v-row class="pt-4">
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field label="Razon social *" hide-details="auto" v-model="Cliente.razon_social" small
                            readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field label="RUC *" hide-details="auto" v-model="Cliente.ruc" small
                            readonly></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <!--
            <v-toolbar height="30" color="#545554" dark>
                <span class="letra-11">Datos de Usuario</span>
            </v-toolbar>
            <v-card-text>
                <v-row class="pt-4">
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field label="Nombre y Apellidos *" hide-details="auto" v-model="nombreusuario" small
                            readonly></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            -->
            <v-toolbar height="30" color="#545554" dark>
                <span class="letra-11">Detalles del Incidente</span>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-row class="mt-2">
                        <v-col cols="12" sm="12" md="12">
                            <v-textarea class="letra-12" autocomplete="Descripcion" label="Descripción"
                                v-model="item.descripcion" :rules="descripcionRules" rows="2" />
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <p><b>Cargar imagen incidencia 1</b></p>
                            <v-file-input id='img_firma1' label="Imagen Firma" filled prepend-icon="mdi-camera"
                                accept="image/png, .jpeg, .jpg" @change="subirImagen1"></v-file-input>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <p><b>Cargar imagen incidencia 2</b></p>
                            <v-file-input id='img_firma2' label="Imagen Firma" filled prepend-icon="mdi-camera"
                                accept="image/png, .jpeg, .jpg" @change="subirImagen2"></v-file-input>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col cols="12" sm="12" md="12" class="mt-2">
                            <v-label>
                                <font size="2">Seleccionar una placa y neumático solo si el incidente está vinculado a
                                    un vehículo o neumático específico, de lo contrario, puedes dejar estos campos en
                                    blanco.
                                    <!--Si el incidente no involucra ningún vehículo o neumático en particular, puedes dejar estos campos en blanco.-->
                                </font>
                            </v-label>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-autocomplete @change="onCargarNeumaticos()" :items="vehiculos"
                                v-model="incidente.id_vehiculo" label="Seleccionar Placa *" dense />
                        </v-col>
                        <v-col cols="12" sm="12" md="5">
                            <v-autocomplete @change="onSerieNeumatico()" :items="neumaticos"
                                v-model="incidente.id_neumatico" label="Seleccionar Neumático *" dense />
                        </v-col>
                        <v-col cols="12" sm="12" md="1" class="d-flex justify-end">
                            <v-btn @click="agregarIncidencia()" color="success" fab x-small dark>+</v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-data-table dense :headers="cabecera" :items="items" :items-per-page="10"
                                class="mt-3 elevation-1" loading-text="Cargando... Por favor, espere"
                                no-data-text="No hay datos disponibles" :footer-props="{
                                    'items-per-page-text': 'Resultados por página:',
                                    'page-text': '{0}-{1} de {2}'
                                }">
                                <template v-slot:[`item.accion`]="{ item }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn height="22" width="22" color="blue lighten-2" fab x-small dark
                                                @click="eliminarItem(item)" v-bind="attrs" v-on="on">
                                                <v-icon x-small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span> - </span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cerrarDialogo()"> Cerrar </v-btn>
                <v-btn color="info" @click="guardarIncidencia()" :loading="loading">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { notif } from '@/plugins/notyf';
export default {
    props: ['dialog'],
    data() {
        return {
            valid: false,
            loading: false,
            incidente: {},
            descripcionRules: [
                v => !!v || 'Descripción es requerido',
                v => (v && v.length <= 1000) || 'Descripción debe tener menos de 1000 caracteres',
            ],
            item: {
                aplicativo: "vue",
                descripcion: "",
                image_one: null,
                image_two: null
            },
            cabecera: [
                {
                    text: 'Placa',
                    value: 'placa_vehiculo',
                    width: 200,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: 'Serie',
                    value: 'serie_neumatico',
                    width: 200,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: 'Opcion',
                    value: 'accion',
                    width: 100,
                    class: "grey darken-1 dark white--text"
                },
            ],
            items: [],
            vehiculo: {},
            vehiculos: [],
            neumaticos: [],
            incidente: {
                placa: null,
                serie: null,
                id_neumatico: null,
                id_vehiculo: null
            },
            nombreusuario: ""
        };
    },
    computed: {
        ...mapState("cliente", ["Cliente"]),
    },
    methods: {
        ...mapActions("vehiculo", ["cargarVehiculosMinified", "cargarNeumaticosPorId"]),
        ...mapActions("neumatico", ["cargarNeumaticosMinified"]),
        ...mapActions("incidente", ["subirImagenes", "guardarIncidente"]),
        ...mapActions("cliente", ["ObtenerDatosCliente"]),

        async onCargarNeumaticos() {
            try {
                this.neumaticos = await this.cargarNeumaticosPorId(this.incidente.id_vehiculo);
                this.vehiculos.forEach(element => {
                    if (element.value == this.incidente.id_vehiculo) {
                        this.incidente.placa_vehiculo = element.text;
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },

        onSerieNeumatico() {
            this.neumaticos.forEach(element => {
                if (element.value == this.incidente.id_neumatico) {
                    this.incidente.serie_neumatico = element.text;
                }
            });
        },

        async guardarIncidencia() {
            let status = false;
            if (this.$refs.form.validate()) {
                try {
                    let datosaenviar = { incidente: this.item, detalles: this.items };
                    status = await this.guardarIncidente(datosaenviar);
                    if (status == true) {
                        notif.success("Incidencia enviada con éxito");
                        this.cerrarDialogo();
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        },

        agregarIncidencia() {
            let existe = false;
            this.items.forEach(element => {
                if (element.id_vehiculo == this.incidente.id_vehiculo && element.id_neumatico == this.incidente.id_neumatico) {
                    existe = true;
                }
            });
            //Si el neumatico no esta en el array incidentes registrar
            if (!existe) {
                if (this.incidente.id_vehiculo != null && this.incidente.id_neumatico != null) {
                    this.items.push({ ...this.incidente });
                } else {
                    if (this.incidente.id_vehiculo == null) {
                        notif.open({ type: 'warning', message: 'La placa del vehículo es requerida' });
                    } else {
                        notif.open({ type: 'warning', message: 'La serie de neumático es requerida' });
                    }
                }
            } else {
                notif.open({ type: 'warning', message: 'El neumático seleccionado ya a sido agregado' });
            }
            this.incidente.serie_neumatico = null;
            this.incidente.id_neumatico = null;
        },

        eliminarItem(item) {
            const pos = this.items.indexOf(item);
            this.items.splice(pos, 1);
        },

        async subirImagen1() {
            try {
                let file = document.getElementById('img_firma1').files[0];
                this.item.image_one = await this.subirImagenes(file);
            } catch (error) {
                console.log(error);
            }
        },

        async subirImagen2() {
            try {
                let file = document.getElementById('img_firma1').files[0];
                this.item.image_two = await this.subirImagenes(file);
            } catch (error) {
                console.log(error);
            }
        },

        cerrarDialogo() {
            this.$emit("cerrarModalIncidencias");
        }
    },
    async mounted() {
        this.vehiculos = await this.cargarVehiculosMinified();
        this.nombreusuario = localStorage.getItem("name");
    }
};
</script>