<template>
<div class="pa-5">
    <v-col lg="12">
        <v-row justify="center">
            <v-col lg="12">
                <v-card>
                    <v-toolbar color="secondary" dark>
                     <span class="text-h6">Neumáticos en scrap por marca</span>
                    </v-toolbar> 
                    <v-container class="pa-4">
                        <v-row style="margin-top: 20px">
                            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mt-1">
                                <v-text-field type="text" label="Cliente" v-model="Cliente.razon_social" hide-details="auto" readonly required></v-text-field>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mt-1">
                                <v-text-field type="text" label="RUC" v-model="Cliente.ruc" hide-details="auto" readonly required></v-text-field>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mt-5">
                                <v-autocomplete :items="Years" v-model="Item.year" label="Año *" @change="onMeses(Item.year)" dense></v-autocomplete>
                            </div>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row justify="center" v-if="visualizarGrafico == true">
            <v-col lg="12">
                <v-card>
                    <v-toolbar height="60" color="#545554" dark>
                        Remanente de banda
                    </v-toolbar>
                    <v-container class="pa-4">
                        <div class="table-responsive">
                            <table class="letra-10" style="width: 100%" id="table">
                                <tr>
                                    <td class="border texto-cen" style="width: 10%; background-color: #6d6b6b">
                                        <strong>
                                            <font color="white">Mes</font>
                                        </strong>
                                    </td>
                                    <td class="border texto-cen" style="width: 6%; background-color: #6d6b6b" v-for="(item, index) in Marcas" :key="index">
                                        <font color="white">{{ item.descripcion }}</font>
                                    </td>
                                    <td class="border texto-cen" style="width: 6%; background-color: #6d6b6b">
                                        <strong>
                                            <font color="white">Total general</font>
                                        </strong>
                                    </td>
                                    <td style="width: 6%">
                                        <strong>
                                            <font color="white">Porcentaje</font>
                                        </strong>
                                    </td>
                                </tr>
                                <tr v-for="(item, index) in NeumaticosScrapMesMarca" :key="index">
                                    <td class="border texto-cen">{{ item.nombre }}</td>
                                    <td class="border texto-cen" v-for="(item1, index1) in Marcas" :key="index1">
                                        {{
                      NeumaticosScrapMesMarca[index][
                        "cantidad_marca_" + item1.id
                      ]
                    }}
                                    </td>
                                    <td class="border texto-cen">{{ item.total_mes }}</td>
                                    <td class="border texto-cen">
                                        {{ calcularPorcentaje(item.total_mes) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border texto-cen">Total general</td>
                                    <td class="border texto-cen" v-for="(item, index) in totales_marca" :key="index">
                                        {{ item }}
                                    </td>
                                    <td class="border texto-cen">{{ totalgeneral }}</td>
                                    <td class="border texto-cen">
                                        <font color="red">100%</font>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td class="border texto-cen" v-for="(item, index) in totales_marca" :key="index">
                                        {{ calcularPorcentaje(item) }}
                                    </td>
                                    <td class="border texto-cen">
                                        <font color="red">100%</font>
                                    </td>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="visualizarGrafico == true">
            <v-col cols="1" sm="1" md="1" class="mt-2">
                <v-btn color="success" @click="exportarAExcel" small>
                    Exportar Excel
                </v-btn>
            </v-col>
        </v-row>
    </v-col>
</div>
</template>

<script>
import {
    mapState,
    mapActions
} from "vuex";
import {
    axios2 as axios
} from "@/interceptor/useApi";;
export default {
    data() {
        return {
            Item: {},
            visualizarGrafico: false,
            totalgeneral: 0,
            totales_marca: [],
        };
    },
    computed: {
        ...mapState("cliente", ["Cliente"]),
        ...mapState("reportes", ["NeumaticosScrapMesMarca", "Marcas"]),
        ...mapState("reporteconsolidado", ["Years"]),
    },
    methods: {
        ...mapActions("cliente", ["ObtenerDatosCliente"]),
        ...mapActions("reportes", ["DatosReporteScrapMesMarca"]),
        ...mapActions("reporteconsolidado", ["cargarAnios"]),

        onMeses: async function () {
            this.totales_marca = [];
            this.totalgeneral = 0;
            await this.DatosReporteScrapMesMarca(this.Item.year);
            this.NeumaticosScrapMesMarca.forEach((element) => {
                this.totalgeneral += element.total_mes;
            });

            this.Marcas.forEach((element1) => {
                let cantidad = 0;
                this.NeumaticosScrapMesMarca.forEach((element2) => {
                    cantidad += element2["cantidad_marca_" + element1.id];
                });
                this.totales_marca.push(cantidad);
            });

            this.visualizarGrafico = true;
        },

        calcularPorcentaje: function (cantidad) {
            let total = this.totalgeneral == 0 ? 1 : this.totalgeneral;
            let valor = ((cantidad / total) * 100).toFixed(2);
            return valor + "%";
        },

        _json_to_query_string: function (json) {
            json = Object.keys(json).map(item => {
                return item + '=' + json[item] || '';
            });
            return json.join("&");
        },

        exportarAExcel: function () {
            location.href =
                axios.defaults.baseURL + "excel/reportescrapmarca?" +
                this._json_to_query_string({
                    id_cliente: localStorage.getItem('idcliente'),
                    year: this.Item.year
                });
        }
    },
    mounted() {
        this.ObtenerDatosCliente();
        this.cargarAnios();
    },
};
</script>

<style scoped>
table {
    border-collapse: collapse;
}

td {
    vertical-align: top;
}

.border {
    border-style: solid;
    border-width: 1px;
}

.border-bottom {
    border-bottom: solid;
    border-width: 1px;
}

.border-top {
    border-top: solid;
    border-width: 1px;
}

.border-left {
    border-left: solid;
    border-width: 1px;
}

.border-right {
    border-right: solid;
    border-width: 1px;
}

.no-border {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.no-border-left {
    border-left: none;
}

.no-border-right {
    border-right: none;
}

.texto-cen {
    text-align: center;
}

.texto-izq {
    text-align: left;
}

.texto-just {
    text-align: justify;
}

.inline {
    display: inline-block;
}

.texto-arriba {
    vertical-align: text-top;
    vertical-align: top;
}

.letra-6 {
    font-size: 6pt;
}

.letra-7 {
    font-size: 7pt;
}

.letra-8 {
    font-size: 8pt;
}

.letra-9 {
    font-size: 9pt;
}

.letra-10 {
    font-size: 10pt;
}

.letra-11 {
    font-size: 11pt;
}

.letra-12 {
    font-size: 12pt;
}

.letra-14 {
    font-size: 14pt;
}

.letra-15 {
    font-size: 15pt;
}

.letra-18 {
    font-size: 18pt;
}

.letra-25 {
    font-size: 25pt;
}

.letra-30 {
    font-size: 30pt;
}

.margin-top-20 {
    margin-top: 20px;
}

.bg-celda-azul {
    background: #91e3da;
}

.bg-celda-amarilla {
    background: #faec5c;
    /*color:#EDDF04;*/
}

.bg-celda-verde {
    background: #a8fa6f;
}

.bg-celda-roja {
    background: #f55f50;
}

#table {
    display: block;
    /* important */
    width: 1000px;
    overflow-x: scroll;
}
</style>
