import Vue from 'vue'
import Vuex from 'vuex'
import {axios2 as axios} from "@/interceptor/useApi";
import Swal from 'sweetalert2';

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    Item: null,
    Items: [],
    Loader: true,
    ItemTotal: 0,
  },
  mutations: {
    LlenarItem(state, data) {
      state.Item = data;
    },
    LlenarItems(state, data) {
      state.Items = data;
    },
    AsignarItemTotal(state, data) {
      state.ItemTotal = data;
    },
    StateLoader(state, data) {
      state.Loader = data;
    }
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },

    cargarNeumaticos: async function ({ commit, dispatch }) {
      try {
        const url = "neumaticos/enreparacion";
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
        };

        await axios
          .post(url, data)
          .then((response) => {
            commit("LlenarItems", response.data.success.resultado);
            commit("AsignarItemTotal", response.data.success.resultado.length);
            commit("StateLoader", false); 
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },
  }
}


