<template>
  <v-row justify="center" v-if="modal_scrap">
    <v-dialog v-model="dialog" persistent max-width="850px">
      <v-form enctype="multipart/form-data" ref="form" v-model="valid">
        <v-card>
          <v-toolbar height="50" color="secondary" dark>
            <span class="text-h6"
              >{{Titulo}}: {{ Item.num_serie }}</span
            >
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <v-text-field
                  label="Remanente final"
                  v-model="Item.remanente_final"
                  hide-details="auto"
                  :rules="[(v) => !!v || 'Remanente final es requerido']"
                ></v-text-field>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <v-text-field
                  type="date"
                  label="Fecha RETIRO/SCRAP"
                  v-model="Item.fecha"
                  hide-details="auto"
                  required
                ></v-text-field>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" v-if="MotivosScrap.length > 0">
                <v-autocomplete
                  class="mt-5"
                  :items="MotivosScrap"
                  v-model="Item.id_motivo_scrap"
                  label="Motivo SCRAP"
                  dense
                  :rules="[(v) => !!v || 'Motivo scrap es requerido']"
                ></v-autocomplete>
              </div>
        
              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <h4 class="font-bold">IMAGEN 1 DEL NEUMATICO EN MAL ESTADO:</h4>
                <FileAgent @archivosubido="archivosubido1" style="z-index: 0"/>
                <v-row justify="center" class="mt-2">
                  <img
                  v-if="Item.neumaticoimgruta1 != null"
                  width="330px"
                  height="210px"
                  :src="ruta + Item.neumaticoimgruta1"
                  alt="Neumatico 1"
                  />
                  <h4 v-else>Imagen no disponible</h4>
                </v-row>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <h4 class="font-bold">IMAGEN 2 DEL NEUMATICO EN MAL ESTADO:</h4>
                <FileAgent @archivosubido="archivosubido2" style="z-index: 0"/>
                <v-row justify="center" class="mt-2">
                  <img
                  v-if="Item.neumaticoimgruta2 != null"
                  width="330px"
                  height="210px"
                  :src="ruta + Item.neumaticoimgruta2"
                  alt="Neumatico 2"
                />

                <h4 v-else>Imagen no disponible</h4>
                </v-row>
              </div>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="cerrarDialogo()"> Cerrar </v-btn>
            <v-btn
              :disabled="!valid"
              color="info"
              @click="agregar()"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
import {axios2 as axios} from "@/interceptor/useApi";
import global from "../../global";
import FileAgent from "../FileAgent.vue";
import { mapActions, mapState} from "vuex";

export default {
  props: ["Titulo","dialog", "Item", "MotivosScrap"],
  components: { FileAgent},
  data() {
    return {
      modal_scrap: true,
      ruta: "",
      valid: false
    };
  },
  methods: {
    ...mapActions("enmalestado", ["cargarNeumaticos"]),

    archivosubido1: function ({ valor, fileRecords }) {
      this.Item.ruta1 = fileRecords;
      console.log(this.Item.ruta1);
    },

    archivosubido2: function ({ valor, fileRecords }) {
      this.Item.ruta2 = fileRecords;
      console.log(this.Item.ruta2);
    },

    cerrarDialogo() {
      this.modal_scrap = false;
      this.$nextTick().then(() => {
        this.modal_scrap = true;
      });
      this.$emit("dialog", false);
    },

    async agregar(){
      try {
        const url = "scrap/update";
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
          id_usuario: localStorage.getItem("id"),
          neumatico: this.Item
        };
        await axios
          .post(url, data)
          .then((response) => {
            this.$emit("dialog", false);
            this.$alertify.success("Neumático editado satisfactoriamente");
            setTimeout(() => (this.$router.go()), 1000);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted() {
    this.ruta = global.ruta_image;
  },
};
</script>
