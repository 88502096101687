<template>
    <v-dialog v-model="dialogNeumatico" max-width="550px" @click:outside="cerrarDialogo(false)">
        <v-card>
            <v-card-title>
                <span class="text-h6">Neumático<small>(Detalle)</small> : <strong
                        v-text="this.arryNeumatico.num_serie"></strong></span>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-subtitle></v-card-subtitle>
            <v-card-text>
                <v-row>
                    <div class="col-lg-4 col-sm-12 col-md-4">
                        <v-text-field type="text" label="Marca" v-model="this.arryNeumatico.marca" hide-details="auto"
                            readonly></v-text-field>
                    </div>
                    <div class="col-lg-4 col-sm-12 col-md-4">
                        <v-text-field type="text" label="Modelo" v-model="this.arryNeumatico.modelo" hide-details="auto"
                            readonly></v-text-field>
                    </div>
                    <div class="col-lg-4 col-sm-12 col-md-4">
                        <v-text-field type="text" label="Medida" v-model="this.arryNeumatico.medida" hide-details="auto"
                            readonly></v-text-field>
                    </div>
                </v-row>
                <v-row>
                    <div class="col-lg-4 col-sm-12 col-md-4">
                        <v-text-field type="text" label="Condición" v-model="this.arryNeumatico.condicion"
                            hide-details="auto" readonly></v-text-field>
                    </div>
                    <div class="col-lg-4 col-sm-12 col-md-4">
                        <v-text-field type="text" label="Diseño (R)" v-model="this.arryNeumatico.disenio"
                            hide-details="auto" readonly></v-text-field>
                    </div>
                    <div class="col-lg-4 col-sm-12 col-md-4">
                        <v-text-field type="text" label="Presión (PSI)" v-model="this.arryNeumatico.presion"
                            hide-details="auto" readonly></v-text-field>
                    </div>
                </v-row>
                <v-row>
                    <div class="col-lg-4 col-sm-12 col-md-4">
                        <v-text-field type="text" label="Inspección/Instalación" v-model="this.arryNeumatico.fechita"
                            hide-details="auto" readonly></v-text-field>
                    </div>
                    <div class="col-lg-4 col-sm-12 col-md-4">
                        <v-text-field type="text" label="Km/Hr" v-model="this.arryNeumatico.kilometraje" hide-details="auto"
                            readonly></v-text-field>
                    </div>
                    <div class="col-lg-4 col-sm-12 col-md-4">
                        <v-text-field type="text" label="NSD" v-model="this.arryNeumatico.remanente_actual"
                            hide-details="auto" readonly></v-text-field>
                    </div>
                </v-row>
                <v-row>
                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <span class="message-info-neumatico">
                            <small v-text="this.messageNeumatico"></small>
                        </span>
                    </div>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cerrarDialogo()"> Cerrar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import FileAgent from "../FileAgent.vue";
import MensajeTransicion from "../Alerta.vue"

export default {
    props: ["dialogNeumatico", "arryNeumatico", "messageNeumatico"],
    components: {
        FileAgent,
        MensajeTransicion
    },
    methods: {
        cerrarDialogo: function () {
            this.$emit("closeNeumatico");
        },
    },

}
</script>

<style>
.message-info-neumatico {
    display: flow-root;
    color: white;
    background-color: indianred;
    border-radius: 5px;
    padding: 4px 0px 4px 10px;
}</style>