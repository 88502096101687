<template>
<div>
    <v-dialog v-model="dialog" max-width="600px" @click:outside="cerrarDialogo(false)">
        <v-card>
            <v-card-title>
                <span v-if="Item.id == null" class="text-h5">Nueva Aplicación</span>
                <span v-else class="text-h5">Editar Aplicación</span>
                <v-spacer></v-spacer>
                <span v-if="Item.id != null">ID: {{Item.id}}</span>
            </v-card-title>
            <v-card-text>
                <!-- <FormularioDinamico :campos="campos"/> -->
                <v-form ref="form" v-model="valid">
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field label="Aplicación *" hide-details="auto" v-model="Item.aplicacion" :rules="[(v) => !!v || 'Aplicación requerida']"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field type="number" label="Para Reencauche *" hide-details="auto" v-model="Item.reencauche" :rules="[(v) => !!v || 'Para reencauche requerido']"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field type="number" label="Próximo a Reencauche " hide-details="auto" v-model="Item.prox_reencauche" required></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <small>*indica los campos requeridos</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cerrarDialogo(false)">
                    Cerrar
                </v-btn>
                <v-btn color="info" @click="agregar()" :loading="loading" :disabled="!valid">
                    Guardar
                </v-btn>
            </v-card-actions>
            <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MensajeTransicion from "../Alerta.vue"
import { notif } from "@/plugins/notyf"
import global from '@/global'

export default {
    props: ['dialog', 'Item'],
    components: {
        MensajeTransicion
    },
    data() {
        return {
            valid: false,
            loading: false,
            mensaje: "",
            verAlerta: false
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        ...mapActions('aplicacion', ['agregarAplicacion', "cargarAplicacion"]),
        async agregar() {
            this.loading = true;
            if (this.Item.id == null || this.Item.id == '') {
                await this.agregarAplicacion(this.Item);
                this.cerrarDialogo();
            } else {
                    await this.agregarAplicacion(this.Item);
                    this.cerrarDialogo();
            }
        },
        cerrarDialogo(valor = true) {
            if(valor == true){
                this.cargarAplicacion();
                notif.success('Operación realizada con éxito');
            }
            this.loading = false;
            this.$emit('closeDialog');
        }
    }
}
</script>
