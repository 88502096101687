import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueAlertify from 'vue-alertify'
import Dropzone from "dropzone";
import "./plugins/vuetify-mask.js";
import './interceptor/axios'
import VueLoaders from './plugins/vue-loaders'
import VueMask from './plugins/v-mask'
import {notif} from './plugins/notyf'
import 'sweetalert2/dist/sweetalert2.all.min.js'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'alertifyjs/build/css/alertify.css'
import './assets/styles/global.css'

Vue.use(VueLoaders);
Vue.use(VueAlertify);
Vue.use(VueMask);
Vue.use(notif);
// Vue.use(VueSweetalert2);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  Dropzone,
  render: function (h) { return h(App) }
}).$mount('#app')
