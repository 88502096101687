<template>
<div class="pa-5">
    <v-col lg="12">
        <tabla Titulo="Gestión Usuarios" :Cabecera="Cabecera" :Items="Items" :Excel="false" :Crear="true" :Loading="Loading"/>
    </v-col>
</div>
</template>

<script>
import tabla from "@/components/usuario/tabla.vue"

import {
    mapGetters,
    mapActions,
    mapState
} from "vuex";
export default {
    components: {
        tabla
    },

    created() {
        this.cargarUsuario();
    },
    data() {
        return {
            Cabecera: [{
                    text: 'Código',
                    value: 'id',
                },
                {
                    text: 'Rol',
                    value: 'rol',
                },
                {
                    text: 'Nombre',
                    value: 'name',
                },
                {
                    text: 'Email',
                    value: 'email'
                },
                {
                    text: 'Firma',
                    value: 'img_firma'
                },
                {
                    text: 'Fecha',
                    value: 'fecha'
                },
                {
                    text: 'Acción',
                    value: 'accion'
                },
            ]
        }
    },
    methods: {
        ...mapActions('usuario', ['cargarUsuario']),
    },

    computed: {
        ...mapGetters('usuario', ['Items']),
        ...mapState('usuario',['Loading'])
    }

};
</script>
