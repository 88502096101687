<template>
<v-dialog v-model="dialog" max-width="600px" @click:outside="cerrarDialogo(false)">
    <v-card>
        <v-card-title>
            <span v-if="Item.id == null" class="text-h6">Nuevo Usuario </span>
            <span v-else class="text-h6">Editar Usuario</span>
            <v-spacer></v-spacer>
            <span v-if="Item.id != null">ID: {{Item.id}}</span>
        </v-card-title>
        <v-card-text>
            <v-form enctype="multipart/form-data" ref="form">
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field label="Nombres *" hide-details="auto" v-model="Item.nombres" :rules="descripcionRules" small></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field label="Apellidos *" hide-details="auto" v-model="Item.apellidos" :rules="descripcionRules" small></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field label="E-mail *" hide-details="auto" type="text" v-model="Item.email" :rules="emailRules" small></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete v-model="Item.role_id" :items="Roles" class="mt-5" dense label="Seleccionar Rol *" :rules="descripcionRules"></v-autocomplete>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="cerrarDialogo()">
                Cerrar
            </v-btn>
            <v-btn v-if="Item.id == null" color="info" :loading="loading" @click="agregar()">
                Guardar
            </v-btn>
            <v-btn v-else color="info" :loading="loading" @click="agregar()">
                Actualizar
            </v-btn>
        </v-card-actions>
        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
    </v-card>
</v-dialog>
</template>

<script>
import {axios2 as axios} from "@/interceptor/useApi";;
import {
    mapActions,
    mapState
} from 'vuex';
import MensajeTransicion from "../Alerta.vue"
import router from "@/router";

export default {
    inject: ['llamarindexMembers'], // Inyecta la función proporcionada por el Abuelo
    props: ['dialog', 'Item', "Roles"],
    components: {
        MensajeTransicion
    },
    data() {
        return {
            mensaje: "",
            verAlerta: false,
            loading: false,
            emailRules: [
                (v) => !!v || "E-mail es requerido",
                (v) => /.+@.+\..+/.test(v) || "E-mail no es valido",
            ],
            descripcionRules: [(v) => !!v || "Descripción es requerido"]
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        ...mapActions("usercliente", ["saveMembers"]), 
        async agregar() {
            let url = "";
            if (this.$refs.form.validate()) {
                this.loading = true;
                try {
                    if (this.Item.id == null) {
                        url = "userclientes/store"
                    } else {
                        url = "userclientes/update"
                    }

                    const datos = {
                        id: this.Item.id,
                        id_cliente: this.Item.id_cliente,
                        email: this.Item.email,
                        nombre: this.Item.nombres,
                        apellidos: this.Item.apellidos,
                        role_id: this.Item.role_id
                    }
                    await axios.post(url, datos)
                        .then(response => {
                            this.cerrarDialogo();  
                            this.llamarindexMembers(this.$route.params.id);
                        })
                        .catch(error => {
                            this.loading = false;
                            this.mensaje = error.response.data.error;
                            this.verAlerta = true;
                            setTimeout(() => [(this.verAlerta = false)], 4000);
                            console.error("error!", error);
                        });
                } catch (error) {
                    console.log(error)
                }
            }
        },

        cerrarDialogo(valor = false) {
            this.$refs.form.resetValidation();
            this.loading = false;
            this.$emit('dialog', false);
            this.$emit("cerrarModal", valor);
        }
    }
}
</script>
