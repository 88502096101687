import Vue from 'vue'
import Vuex from 'vuex'
import {axios2 as axios} from "@/interceptor/useApi";

Vue.use(Vuex)

export default{
  namespaced:true,
  state: {
    Datos : [],
    Menu : 1,
    SubMenu : 1,
    SubMenu1 : 1,
    SubSubMenu1 : 1,
    Menu2 : 1,
    SubMenu2 : 1,
    Menu3 : 1,
    SubMenu3 : 1,
    Menu4 : 1,
    SubMenu4 : 1,
    Menu5 : 1,
    SubMenu5 : 1,
    Mini : false,
    Drawer : true,
  },
  getters: {
    Datos(state){
      return state.Datos
    },
    Menu(state){
      return state.Menu
    },
    SubMenu(state){
      return state.SubMenu
    },
    SubMenu1(state){
      return state.SubMenu1
    },
    SubSubMenu1(state){
      return state.SubSubMenu1
    },    
    Menu2(state){
      return state.Menu2
    },
    SubMenu2(state){
      return state.SubMenu2
    },
    Menu3(state){
      return state.Menu3
    },
    SubMenu3(state){
      return state.SubMenu3
    },
    Menu4(state){
      return state.Menu4
    },
    SubMenu4(state){
      return state.SubMenu4
    },
    Menu5(state){
      return state.Menu5
    },
    SubMenu5(state){
      return state.SubMenu5
    },
    Mini(state){
      return state.Mini
    },
    Drawer(state){
      return state.Drawer
    }
  },
  mutations: {
    LlenarDatos(state,data){
      state.Datos = data
    },
    LlenarMenu(state,data){
      state.Menu = data
    },
    LlenarSubMenu(state,data){
      state.SubMenu = data
    },
    LlenarSubMenu1(state,data){
      state.SubMenu1 = data
    },
    LlenarSubSubMenu1(state,data){
      state.SubSubMenu1 = data
    },
    LlenarMenu2(state,data){
      state.Menu2 = data
    },
    LlenarSubMenu2(state,data){
      state.SubMenu2 = data
    },
    LlenarMenu3(state,data){
      state.Menu3 = data
    },
    LlenarSubMenu3(state,data){
      state.SubMenu3 = data
    },
    LlenarMenu4(state,data){
      state.Menu4 = data
    },
    LlenarSubMenu4(state,data){
      state.SubMenu4 = data
    },
    LlenarMenu5(state,data){
      state.Menu5 = data
    },
    LlenarSubMenu5(state,data){
      state.SubMenu5 = data
    },
    LlenarMini(state,data){
      state.Mini = data
    },
    LlenarDrawer(state,data){
      state.Drawer = data
    }
  },
  actions: {
    CargarRutas:async function({commit}){
      try {
        const data = {idcliente: localStorage.getItem('idclient') };  
        const  url = "listarutas"
        await axios.post(url,data)
        .then(response => {
          var array = []
          var dataaux = []
          array = [{
                icon: 'mdi-home',
                text: 'Home',
                ruta:'home',
                path:'/home'
              },
              {
                icon: 'mdi-chart-donut',
                text: 'Llantas',
                ruta:'imagenes',
                path:'/imagenes'
              },
              {
                icon: 'mdi-dialpad',
                text: 'Marcas',
                ruta:'excel',
                path:'/excel'
              }]
          var tamaño =  array.length;
          var con = 0;
          for(var i=0 ; i < tamaño; i++){
            if(i >0){
              var entro = 'false';
              for(var j=0 ; j < response.data.length; j++){
                if(response.data[j].PLU_Ruta == array[i].text){
                  entro = 'true'
                }
              } 
              if(entro == 'true'){
                dataaux[con]=array[i]
                con++
              }
            }else{
              dataaux[con]=array[i]  
              con++
            }
          }  
          commit('LlenarDatos',dataaux);
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("There was an error!", error);
        });        
      } catch (error) {
        
      }
    },
    CargarMenu({commit,dispatch},data){  
      commit('LlenarMenu',data.menu);
      commit('LlenarSubMenu',data.submenu);
      commit('LlenarSubMenu1',data.submenu1);
      commit('LlenarSubSubMenu1',data.subsubmenu1);
      commit('LlenarMenu2',data.menu2);
      commit('LlenarSubMenu2',data.submenu2);
      commit('LlenarMenu3',data.menu3);
      commit('LlenarSubMenu3',data.submenu3);
      commit('LlenarMenu4',data.menu4);
      commit('LlenarSubMenu4',data.submenu4);
      commit('LlenarMenu5',data.menu5);
      commit('LlenarSubMenu5',data.submenu5);
    },
    Minimizar({commit,state,dispatch},data){ 
      if(state.Drawer){
        commit('LlenarDrawer',true);
        commit('LlenarMini',data);
      }else{
        commit('LlenarDrawer',true);
      }
    }
  }
}


