<style>
html {
    overflow: visible !important;
}
</style>
<template>
    <v-card color="blue-grey darken-1" dark :loading="isUpdating" style="height: 100vh;">
        <template v-slot:progress>
            <v-progress-linear absolute color="green lighten-3" height="4" indeterminate></v-progress-linear>
        </template>
        <v-img height="35%" src="/images/background/fondo.jpg">
            
                <v-row align="center" justify="center">
                    <v-col class="text-center"
                        style="margin-top: 47px; font-weight: bold !important; color: white;   background-color: rgba(0,0,0,0.6);">
                        <h3 class="text-h3">
                            {{ hello }}
                        </h3>
                        <h3 class="text-h6">
                            {{ message }}
                        </h3>
                    </v-col>
                    
                </v-row><br>
                 <v-row class="mt-4" align="center" justify="center" v-if="mostraropcionesclientes">
                    <v-col cols="6" sm="6" md="3">
                        <v-radio-group inline v-model="activo" @change="onClientes" row>
                            <v-radio label="Activos" :value="1"></v-radio>
                            <v-radio label="Inactivos" :value="0"></v-radio>
                        </v-radio-group>
                    </v-col>
                 </v-row>
            
        </v-img>
        <v-form>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete v-model="clienteSeleccionado" :disabled="isUpdating" :items="clientes" filled chips
                            color="blue lighten-1" label="Seleccionar el cliente" item-text="name" item-value="name">
                            <template v-slot:selection="data" style="cursor: pointer;">
                                <v-chip style="font-size: 18px;" v-bind="data.attrs" :input-value="data.selected"
                                    @click="data.select">
                                    <v-avatar left>
                                        <v-img :src="data.item.imagen"></v-img>
                                    </v-avatar>
                                    {{ data.item.name }}
                                </v-chip>
                            </template>
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-avatar>
                                        <img :src="data.item.imagen" />
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                        <v-list-item-subtitle v-html="data.item.ruc"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn :loading="loading" :disabled="loading" color="success" class="ma-2 white--text"
                            @click="seleccionarcliente()">
                            Confirmar Cliente
                            <v-icon right dark> mdi-checkbox-marked-circle </v-icon>
                        </v-btn>
                    </v-col>

                </v-row>
            </v-container>
        </v-form>
        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
    </v-card>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import MensajeTransicion from "../components/Alerta.vue" 

export default {
    components: {
        MensajeTransicion
    },
    data() {
        return {
            loading: false,
            autoUpdate: true,
            clienteSeleccionado: "",
            isUpdating: false,
            hello: "Bienvenido a Tiresoft",
            message: "por favor seleccione el cliente para continuar :",
            mensaje: "",
            verAlerta: false,
            clientes: [],
            activo: 1,
            mostraropcionesclientes: false
        };
    },
    async created() {
        if(localStorage.getItem('role') == 1){
            this.mostraropcionesclientes = true;
        }
        this.loadRoutes(1);
        await this.obtenerClientesPorUsuario();
        this.clientes = this.ClientesUsuario;
        this.onClientes();
    },
    watch: {
        isUpdating(val) {
            if (val) {
                setTimeout(() => (this.isUpdating = false), 1000);
            }
        }
    },

    methods: {
        ...mapActions("cliente", ["obtenerClientesPorUsuario", "guardarAccesoACliente"]),
        ...mapActions("navigation", ["loadRoutes"]),

        onClientes() {
            if (this.activo == 1) {
                this.clientes = [];
                this.ClientesUsuario.forEach(element => {
                    if (element.estado == 'Activo') {
                        this.clientes.push(element);
                    }
                });
            } else {
                this.clientes = [];
                this.ClientesUsuario.forEach(element => {
                    if (element.estado == 'Inactivo') {
                        this.clientes.push(element);
                    }
                });
            }
        },

        seleccionarcliente: async function(){
            let idCliente = null;
            this.clientes.forEach((element) => {
                if (element.name == this.clienteSeleccionado) {
                    idCliente = element.id;
                }
            });
            if(idCliente != null){      
                this.loading = true;
                localStorage.setItem("idcliente", idCliente);
                await this.guardarAccesoACliente();
                await this.loadRoutes(1);
                window.location.reload();
                this.loading = false;
            }
        }
    },

    computed: {
        ...mapState("navigation", ["Permisos"]),
        ...mapState("cliente", ["ClientesUsuario"]),
    },
};
</script>
