<template>
<div class="container">
    <canvas ref="elemento" :id="IdGrafico" style="max-height: 280px"></canvas>
</div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);

export default {
    props: ["Labels", "DatosGrafico", "Title", "Labelsdataset", "IdGrafico"],
    data() {
        return {};
    },
    methods: {
        generarGrafico: function () {
            var popCanvas = document.getElementById(this.IdGrafico);
            new Chart(popCanvas, {
                plugins: [ChartDataLabels],
                type: "pie",
                data: {
                    labels: this.Labels,
                    datasets: [{
                        label: "My First Dataset",
                        data: this.DatosGrafico,
                        backgroundColor: [
                            "rgb(255, 99, 132)",
                            "rgb(27, 205, 249)",
                            "rgb(218, 247, 166)",
                            "rgb(255, 205, 86)",
                            "rgb(27, 216, 176)",
                            "rgb(253, 152, 37)",
                            "rgb(254, 243, 80 )",
                            "rgb(179, 241, 238)",
                            "rgb(125, 254, 80)",
                            "rgb(54, 162, 235)",
                        ],
                        hoverOffset: 4,
                    }, ],
                },
                options: {
                    //indexAxis: 'y',
                    plugins: {
                        title: {
                            display: true,
                            text: this.Title,
                        },
                        legend: {
                            display: true,
                            position: "right",
                            labels: {
                                color: "black",
                                font: {
                                    size: 11,
                                },
                            },
                        },
                        datalabels: {
                            /* anchor puede ser "start", "center" o "end" */
                            anchor: "center",
                            /* Podemos modificar el texto a mostrar */
                            //formatter: (dato) => dato + "%",
                            formatter: (dato) => dato,
                            /* Color del texto */
                            color: "black",
                            /* Formato de la fuente */
                            font: {
                                family: '"Times New Roman", Times, serif',
                                size: "10",
                                weight: "bold",
                            },
                            /* Formato de la caja contenedora */
                            padding: "4",
                            borderWidth: 2,
                            //borderColor: "darkblue",
                            borderRadius: 8,
                            backgroundColor: "white",
                        },
                    },
                    tooltips: {
                        yAlign: "bottom",
                        xAlign: "center",
                        xPadding: 25,
                        yPadding: 15,
                        xPadding: 45,
                        _bodyAlign: "center",
                        _footerAlign: "center",
                        backgroundColor: "#ccc",
                        titleFontSize: 16,
                        titleFontColor: "#0066ff",
                        bodyFontColor: "#000",
                        bodyFontSize: 14,
                        displayColors: false,
                    },
                },
            });
        },
    },
    mounted() {
        this.generarGrafico();
    },
};
</script>
