<template>
    <div>
        <h1 class="text-center" >Excel</h1>
        <input id="archivoExcel" type="file" @change="subirExcel()" />
        <v-card style="margin-top:20px">
            <tabla  v-if="Items.length > 0" Titulo="Productos" :Cabecera="Cabecera" :Items="Items" />
        </v-card>
    </div>
</template>
<script>

import tabla from "@/components/tabla.vue"
import readXlsxFile from "read-excel-file"
export default {
    components : {
        tabla
    },
    data(){
        return{
            //search: '',
            Items: [],
            Cabecera: [
                {text:'Id',value:'0'},
                {text:'Producto',value:'1'},
                {text:'Unidad',value:'2'},
                {text:'Stock',value:'3'},
                {text:'Precio Compra',value:'4'},
                {text:'Precio Venta',value:'5'}
            ],
        }
    },
    methods:{
        subirExcel(){
            const input = document.getElementById("archivoExcel");
            readXlsxFile(input.files[0]).then((rows) => {
                var obj = []
                for(let i= 0; i<rows.length ;i++){
                    obj[i] = Object.assign({}, rows[i])
                }
                this.Items = obj;             
            });
        }
    }
}
</script>