<template>
    <div>
        <Nuevo :dialog="dialog" :Item="itemSelected" @dialog="dialog = $event" @cerrarModal="cerrarModal"
            v-if="verNuevo" />
        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
        <v-card>
            <v-card-title>
                {{ Titulo }}
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-btn v-if="Crear" color="primary" dark class="mb-2" @click="nuevoItem()">
                    Nuevo
                </v-btn>
            </v-card-title>
            <v-data-table dense :headers="Cabecera" :items="Items" :items-per-page="10" :search="search"
                :loading="loading" class="elevation-1 mt-5" :item-class="rowClass"
                loading-text="Cargando... Por favor, espere" no-data-text="No hay datos disponibles"
                :footer-props="{
                    'items-per-page-text': 'Resultados por página:',
                    'page-text': '{0}-{1} de {2}'
                }">
                <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn height="22" width="22" color="blue lighten-2" fab x-small dark
                                @click="editarItem(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import axios from "axios";
import MensajeTransicion from "../Alerta.vue"
import Nuevo from '@/components/suscripcion/nuevo.vue'
import global from "@/global";

export default {
    props: [
        "Titulo",
        "Cabecera",
        "loading",
        "Items",
        "Item",
        "ItemTotal",
        "Excel",
        "Crear"
    ],
    components: {
        MensajeTransicion,
        Nuevo
    },
    data() {
        return {
            fechaactual: "",
            itemSelected: {},
            search: "",
            mostrarIcono: true,
            dialog: false,
            mensaje: "",
            verAlerta: false,
            verNuevo: false
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {

        cerrarModal: function () {
            this.dialog = false;
            this.verNuevo = false;
            this.$emit("loadSuscripcionesFunction");
        },

        nuevoItem: function () {
            this.itemSelected = {
                id: null,
                razon_social: null,
                id_cliente: null,
                date_start: null,
                date_end: null,
                trial_period: 'ACTIVO'
            }
            this.dialog = true;
            this.verNuevo = true;
        },

        editarItem: function (item) {
            this.itemSelected = { ...item };
            this.dialog = true;
            this.verNuevo = true;
        },

        rowClass(item) {
            let rowClass = "";
            if (this.fechaactual >= item.date_end) {
                rowClass = 'style-1'
            } else {
                rowClass = 'style-2'
            }
            return rowClass;
        }
    },
    mounted() {
        var moment = require("moment");
        let fecha = moment(new Date(), "YYYY-MM-DD");
        this.fechaactual = fecha.format("YYYY-MM-DD");

    }
};
</script>
<style lang="css">
.style-1 {
    background-color: rgb(253, 197, 185);
}

.style-2 {
    background-color: rgb(255, 255, 255);
}
</style>