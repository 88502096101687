<template>
    <div class="pa-5">
        <v-col lg="12">
            <v-card>

                <v-toolbar color="secondary" dark>
                    <span class="text-h6">Reporte de rendimiento de neumáticos por sector</span>
                    <v-spacer></v-spacer>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                                <a target="_blank" href="https://helpdesk.tiresoft.pe/#marketing-rendimiento-neumatico"
                                    style="text-decoration:none !important; color: white;">
                                    <v-icon dark></v-icon>
                                </a>
                            </v-btn>
                        </template>
                        <span>¿Necesitas ayuda?</span>
                    </v-tooltip>
                </v-toolbar>


                <FiltrosReporteMarketing titulo="Reporte de medidas de neumáticos por sector" :mostrarempresa="true"
                    :mostrarrtd="true" :mostrarmedida="false" :mostrarsegmentacion="true"
                    @filtrarNeumaticos="filtrarNeumaticos" ref="filtrosmarketing" />

            </v-card>

            <v-card class="mt-5">
                <v-card-text>
                    <v-row class="mt-5">
                        <v-col cols="12" sm="12" md="6">
                            <TablaReporteMarketing :Encabezados="encabezados" :Items="rendimientos"
                                :TotalGeneral="totalgeneral" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-col cols="12" sm="12" md="6">
                        <v-btn color="#32B1E9" :loading="loadingexcel" @click="exportarExcel()" small block>Exportar</v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-col>
    </div>
</template>

    
<script>
import FiltrosReporteMarketing from '@/components/FiltrosReporteMarketing.vue';
import TablaReporteMarketing from '@/components/TablaReporteMarketing.vue';
import { notif } from '@/plugins/notyf';
import {
    mapActions,
    mapState
} from 'vuex';
import global from '@/global';
export default {
    components: {
        FiltrosReporteMarketing,
        TablaReporteMarketing
    },
    data() {
        return {
            item: {},
            encabezados: [{
                text: "Etiquetas de fila",
                value: "name",
                align: "left"
            },
            {
                text: "Cantidad",
                value: "cantidad",
                align: "center"
            },
            {
                text: "Promedio",
                value: "promedio",
                align: "right"
            }
            ],
            rendimientos: [],
            totalgeneral: 0,
            loadingexcel: false
        }
    },
    computed: {
        ...mapState("marketing", ["RendimientoNeumaticos"]),
    },
    methods: {
        ...mapActions("marketing", ["cargarRendimientoNeumaticos"]),

        exportarExcel() {
            this.loadingexcel = true;
            global._exportar_a_excel("excel/mkgrendimientoneumaticos", "POST", this.item);
            this.loadingexcel = false;
        },

        async cargarDatos(filtros) {
            this.item = filtros;
            this.rendimientos = [];
            this.$refs.filtrosmarketing.turnOnLoader();
            await this.cargarRendimientoNeumaticos(filtros);
            this.rendimientos = this.RendimientoNeumaticos;
            //this.totalgeneral = datos.total;
            this.$refs.filtrosmarketing.turnOffLoader();
        },

        filtrarNeumaticos(filtros) {
            if (filtros.fechainicio != '' && filtros.fechafin != "") {
                if (filtros.fechainicio > filtros.fechafin) {
                    notif.error("La fecha final debe ser mayor a la fecha inicial");
                } else {
                    this.cargarDatos(filtros);
                }
            } else {
                this.cargarDatos(filtros);
            }
        }
    }
}
</script>
