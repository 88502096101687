<template>
    <v-container class="text-center fill-height" style="height: calc(100vh - 58px);">
      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <v-img
            :src="require('@/assets/404_images/404.webp')" 
            alt="Error Image"
            height="400"
            class="mb-4"
          />
          <h1 class="text-h3 primary--text pb-5">
            Oops!
          </h1>
          <p class="mb-5">La página que estabas buscando no existe</p>
          <v-btn :to="'/'" color="primary" outlined>
            ¡Volver al inicio!
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </template>
<script> 
import { mapGetters, mapActions, mapState } from "vuex"; 


export default {
    name: "Aplicacion", 
    components: { 
    },
    data() {
        return {
            Cabecera: [
                { text: 'Opcion', value: 'acciones' },
                { text: 'Aplicación', value: 'aplicacion' },
                { text: 'Para Reencauche', value: 'reencauche' },
                { text: 'Próximo a Reencauche', value: 'prox_reencauche' },
            ]
        }
    },
    computed: { 
    },
    methods: { 
    }, 
};
</script>
