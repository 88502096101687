<template>
        <v-row justify="center">
            <v-dialog
            v-model="dialog2"
            persistent
            max-width="900px"
            >
                
                    <v-card>
                        <v-form
                            enctype="multipart/form-data"
                            ref="form"
                            lazy-validation
                        >
                            <v-card-title>
                                <span class="text-h6">Registra Contactos de {{ItemsCliente.razon_social}}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-text-field
                                        label="Nombre Completo *"
                                        hide-details="auto"
                                        v-model="ItemContacto.nombre"
                                        small
                                        :rules="requeridos"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-text-field
                                        label="Correo  *"
                                        hide-details="auto"
                                        v-model="ItemContacto.correo"
                                        small
                                        :rules="emailRules"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-text-field
                                        label="Telefono *"
                                        hide-details="auto"
                                        v-model="ItemContacto.telefono"
                                        type="number"
                                        small
                                        :rules="requeridos"
                                        ></v-text-field>
                                    </v-col>
                                    
                                    <v-col
                                        cols="12"
                                        sm="5"
                                        md="5"
                                    >
                                        <v-autocomplete
                                            v-model="ItemContacto.cargo"
                                            :items="ItemsCargo"
                                            class="mt-4"
                                            dense
                                            label="Seleccionar Cargo"
                                            :rules="requeridos"
                                        ></v-autocomplete>
                                    </v-col> 
                                    
                                    <v-col
                                        cols="12"
                                        sm="1"
                                        md="1"
                                    >
                                        <v-btn
                                            color="green"
                                            fab
                                            x-small
                                            dark
                                            >
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-col>                               
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                        <v-checkbox
                                        v-model="ItemContacto.reporte"
                                        label="Incluir en Reporte Consolidado"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="error"
                                @click="cerrarDialogo()"
                            >
                                Cerrar
                            </v-btn>
                            <v-btn
                                color="warning"
                                @click="cancelar()"
                            >
                                Cancelar
                            </v-btn>
                            <v-btn
                                v-if="ItemContacto.id == 0"
                                color="info"
                                @click="agregar()"
                            >
                                Guardar
                            </v-btn>
                            <v-btn
                                v-else
                                color="info"
                                @click="agregar()"
                            >
                                Actualizar
                            </v-btn>
                            </v-card-actions>
                        </v-form>
                        <Tabla  Titulo="Contacto" :Cabecera="Cabecera" :Items="ItemsContacto" :Excel="false" :Buscar="false" />
                        
                    </v-card>
            </v-dialog>
        </v-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Tabla from "@/components/tabla.vue"
export default {
    props:['dialog2','ItemsCliente','Titulo'],
    components:{
        Tabla
    },
    methods:{
        ...mapActions('cliente',['agregarContacto','cargarContacto']),        
        async agregar(){
            if(this.$refs.form.validate()){
                this.$alertify.confirm(
                    this.ItemContacto.id  == 0 ? "Desea Agregar Item" : "Desea Editar Item", 
                    () => {
                            this.ItemContacto.id_cliente = this.ItemsCliente.id
                            this.agregarContacto(this.ItemContacto)
                            .then( r =>{
                                this.LimpiarCampos();
                                this.$alertify.success(this.ItemContacto.id  == 0 ? "Agregado" : "Actualizado");
                                this.$refs.form.reset();
                            });
                        }, 
                    () => {
                            this.verContacto(this.ItemsCliente)
                            this.$alertify.error("Se cancelo la acción")
                        } 
                ).setHeader('<em> Aviso </em> ');
            }
        },
        LimpiarCampos(){
            this.ItemContacto.id = 0;
            this.ItemContacto.reporte = false;
        },
        cerrarDialogo(){
            this.$emit('dialog2',false);
        },
        cancelar(){
            this.$refs.form.reset();
            LimpiarCampos();
        },
    },
    computed:{
        ...mapGetters('cliente',['ItemsContacto','ItemContacto','ItemsCargo'])  
    },
    data() {
        return {
            imagenminiatura: '/images/reporte/imagen_no_disponible.jpg',
            emailRules: [
                v => !!v || 'E-mail es requerido',
                v => /.+@.+\..+/.test(v) || 'E-mail no es valido',
            ],
            requeridos: [
                v => !!v || 'Este Campo es Requerido.'
            ],
            Cabecera:[
                {text:'Acción',value:'accion'},
                {text:'Id',value:'id'},
                {text:'Nombre',value:'nombre'},
                {text:'Correo',value:'correo'},
                {text:'Teléfono',value:'telefono'},
                {text:'Cargo',value:'cargo'},
                {text:'Incluido en Reporte',value:'reporte'},
            ],
        };
    },
}
</script>
