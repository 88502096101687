import Vue from 'vue'
import Vuex from 'vuex'
import {axios2 as axios} from "@/interceptor/useApi";

Vue.use(Vuex)

export default{
  namespaced:true,
  state: {
    Items : [],
    ItemsModeloMedida : [],
    Año : 0,
    Indicador : "",
    Loading: true
  },
  getters: {
    Items(state){
      return state.Items
    },
    Año(state){
      return state.Año
    },
    Indicador(state){
      return state.Indicador
    },
    ItemsModeloMedida(state){
      return state.ItemsModeloMedida
    }
  },
  mutations: {
    LlenarItems(state,data){
      state.Items = data
    },
    LlenarAño(state,data){
      state.Año = data
    },
    LlenarIndicador(state,data){
      state.Indicador = data
    },
    LlenarItemsModeloMedida(state,data){
      state.ItemsModeloMedida = data
    },
    LlenarLoading(state,data){
      state.Loading = data
    }
  },
  actions: {
    cargarCriterioAceptacion:async function({commit,state},datos){
      try {
        const url = "criterioaceptacion/index"
        const data = {id_cliente: localStorage.getItem("idcliente"), anio: state.Año, select_indicador: state.Indicador}; 
        await axios.post(url,data)
        .then(response => {
          var arraymedida = []
          var con = 0
          response.data.success.resultado.costos.forEach(element => {
            arraymedida[con] = element.modelo + ' - ' + element.medida;
            con++;
          });
          commit('LlenarItems',response.data.success.resultado.criterios);
          commit('LlenarAño',parseInt(response.data.success.resultado.anio) );
          commit('LlenarIndicador',response.data.success.resultado.indicador );
          commit('LlenarItemsModeloMedida',arraymedida );
          commit('LlenarLoading',false);
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
        }); 
      } catch (error) {
        console.log(error)
      }
    }, 
    CambioSelect: async function({commit},datos){
      commit('LlenarAño',parseInt(datos.anio) );
      commit('LlenarIndicador',datos.indicador );
    },    
  }

}


