<style>
/* Estilos para ocultar la barra de desplazamiento horizontal */
html {
  overflow: hidden !important;
}
.s_button {
  color: white !important;
  border: 2px solid white !important;
  border-radius: 5px !important;
  padding: 10px 25px !important;
  text-align: center !important;
  font-size: 15px !important;
  float: right;
  margin: 10px;
  letter-spacing: .1rem;
  text-decoration: none;
  text-transform: uppercase;  
}

.s_button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.s_button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.s_button:hover span {
  padding-right: 25px;
}

.s_button:hover span:after {
  opacity: 1;
  right: 0;
}
</style>

<template>

<v-layout justify-center align-center fill-height style="background-image: url(/images/background/fondo.jpg);     
  width: 100% !important;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  margin: 0 !important;">

     <div style='position: absolute; z-index: 2; width: 100%; top: 5px;'>
        <a class="s_button"  @click="loguearse()"><span> Ingresar </span></a>
    </div>
    
      <div class="title m-b-md " style="text-align: center;">
        <img style="width: 70%;	" src="/images/background/LOGO-PRINCIPAL-TIRESOFT.png">
      </div>
    </v-layout >
 
</template>
<script>
import NavigationDrawer from "@/components/layout/NavigationDrawer.vue";
import NavigationDrawer2 from "@/components/layout/NavigationDrawer2.vue";
export default {
  name: "App",
  components: {
    NavigationDrawer,
    NavigationDrawer2,
  },
  mounted() {
    this.isRegister = localStorage.getItem("logueo");
    this.isCliente = localStorage.getItem("idcliente");
  },
  data: () => ({
    isRegister: false,
    isCliente: localStorage.getItem("idcliente"),
  }),
  created() {
    this.isRegister = localStorage.getItem("logueo");
  },
  methods: {
    loguearse: function(){
      this.$router.push({
            path: "/login"
      });
    }
  },
};
</script>
