<template>
    <v-dialog v-model="dialog" persistent max-width="850px" @click:outside="cerrarDialogo()">
        <v-card>
            <v-card-title>
                CRITERIOS DE ACEPTACION
            </v-card-title>           
            <v-row style="margin: 0px;padding: 0px;">
                <v-col cols="12" sm="12" md="5">        
                    <v-toolbar height="30" color="#545554" dark>
                        <span class="letra-11">INDICES DE CRITERIO DE ACEPTACIÓN
                        </span>
                    </v-toolbar>
                        
                    <div class="table-responsive pa-2 ">
                        <table class="table table-hover table-sm letra-10" style="width: 100%">
                            <thead>
                                <tr>
                                    <th class="border  pa-1 pl-2 pr-2" style="width: 20%" rowspan="2">
                                        <strong>
                                            <font color="white">MODELO</font>
                                        </strong>
                                    </th>
                                    <th class="border  pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                        <strong>
                                            <font color="white">MEDIDA</font>
                                        </strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                <tr >
                                    <td class="border  pa-1 pl-1 pr-1">modelo prueba</td>
                                    <td class="border  pa-1 pl-1 pr-1">medida prueba</td>
                                    <!-- <td class="border  pa-1 pl-1 pr-1"><button class="btn btn-success">Registrar</button></td> -->
                                </tr>
                                <!-- <tr v-for="(item, index) in Inspecciones" :key="index">
                                    <td class="border  pa-1 pl-1 pr-1">
                                        <v-btn height="22" width="22" color="blue lighten-2" fab x-small dark
                                            @click="editarItem(item)">
                                            <v-icon x-small>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn v-if="mostrarBotonEliminar == true" height="22" width="22" color="red" fab
                                            x-small dark @click="eliminarItem(item)">
                                            <v-icon x-small>mdi-delete</v-icon>
                                        </v-btn>
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">{{ item.d_posicion }}</td>
                                    <td class="border  pa-1 pl-1 pr-1">{{ item.num_serie }}</td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.marca }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.modelo }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.medida }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.disenio }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.eje }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.condicion }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.cantidad_reencauche }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.empresa_reencauche }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.fecha_reencauche }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.presion }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.tipo_presion }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.d_valvula }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.d_accesibilidad }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.d_motivo_inaccesibilidad }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.exterior }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.medio }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.interior }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.resultado }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.sep_duales }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.d_desgirregular }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.d_parareparar }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.d_arodefectuoso }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.d_fallasflanco }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.d_tuercaestado }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.tuercacantidad }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.estado }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.tipo_costo }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.km_instalacion }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.km_inspeccion }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.km_recorrido }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.km_proyectado }}
                                    </td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.recomendacion }}
                                    </td>
                                </tr>
                                <tr v-if="Inspecciones.length == 0">
                                    <td class="border texto-cen" colspan="35">Not found Data</td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                </v-col>
                <v-col cols="12" sm="12" md="7">
                    <v-toolbar height="30" color="#545554" dark>
                        <span class="letra-11">INDICADOR COSTO POR KM / H
                        </span>
                    </v-toolbar>
                    <div class="table-responsive pa-2 ">
                        <table class="table table-hover table-sm letra-10" style="width: 100%">
                            <thead>
                                <tr >
                                    <th class="border  pa-1 pl-2 pr-2" style="width: 20%">
                                        <strong>
                                            <font color="white">MODELO</font>
                                        </strong>
                                    </th>
                                    <th class="border  pa-1 pl-4 pr-4" style="width: 20%">
                                        <strong>
                                            <font color="white">MEDIDA</font>
                                        </strong>
                                    </th>
                                    <th class="border  pa-1 pl-4 pr-4" style="width: 20%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td class="border  pa-1 pl-1 pr-1">modelo prueba</td>
                                    <td class="border  pa-1 pl-1 pr-1">medida prueba</td>
                                    <td class="border  pa-1 pl-1 pr-1"><button class="btn btn-success">Registrar</button></td>
                                </tr>
                                <!-- <tr v-if="Inspecciones.length == 0">
                                    <td class="border texto-cen" colspan="35">Not found Data</td>
                                </tr> -->
                                <!-- <tr v-for="(item, index) in Inspecciones" :key="index">
                                    <td class="border  pa-1 pl-1 pr-1">{{ item.d_posicion }}</td>
                                    <td class="border  pa-1 pl-1 pr-1">{{ item.num_serie }}</td>
                                    <td class="border  pa-1 pl-1 pr-1">
                                        {{ item.marca }}
                                    </td>
                                </tr>
                                <tr v-if="Inspecciones.length == 0">
                                    <td class="border texto-cen" colspan="35">Not found Data</td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                </v-col>
            </v-row>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cerrarDialogo()"> Cerrar </v-btn>
                <v-btn color="info" @click="guardarIncidencia()" :loading="loading">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { notif } from '@/plugins/notyf';
export default {
    props: ['dialog'],
    data() {
        return {
            valid: false,
            loading: false,
            incidente: {},
            descripcionRules: [
                v => !!v || 'Descripción es requerido',
                v => (v && v.length <= 1000) || 'Descripción debe tener menos de 1000 caracteres',
            ],
            item: {
                aplicativo: "vue",
                descripcion: "",
                image_one: null,
                image_two: null
            },
            cabecera: [
                {
                    text: 'Placa',
                    value: 'placa_vehiculo',
                    width: 200,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: 'Serie',
                    value: 'serie_neumatico',
                    width: 200,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: 'Opcion',
                    value: 'accion',
                    width: 100,
                    class: "grey darken-1 dark white--text"
                },
            ],
            items: [],
            vehiculo: {},
            vehiculos: [],
            neumaticos: [],
            incidente: {
                placa: null,
                serie: null,
                id_neumatico: null,
                id_vehiculo: null
            },
            nombreusuario: ""
        };
    },
    computed: {
        ...mapState("cliente", ["Cliente"]),
    },
    methods: {
        ...mapActions("vehiculo", ["cargarVehiculosMinified", "cargarNeumaticosPorId"]),
        ...mapActions("neumatico", ["cargarNeumaticosMinified"]),
        ...mapActions("incidente", ["subirImagenes", "guardarIncidente"]),
        ...mapActions("cliente", ["ObtenerDatosCliente"]),

        async onCargarNeumaticos() {
            try {
                this.neumaticos = await this.cargarNeumaticosPorId(this.incidente.id_vehiculo);
                this.vehiculos.forEach(element => {
                    if (element.value == this.incidente.id_vehiculo) {
                        this.incidente.placa_vehiculo = element.text;
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },

        onSerieNeumatico() {
            this.neumaticos.forEach(element => {
                if (element.value == this.incidente.id_neumatico) {
                    this.incidente.serie_neumatico = element.text;
                }
            });
        },

        async guardarIncidencia() {
            let status = false;
            if (this.$refs.form.validate()) {
                try {
                    let datosaenviar = { incidente: this.item, detalles: this.items };
                    status = await this.guardarIncidente(datosaenviar);
                    if (status == true) {
                        notif.success("Incidencia enviada con éxito");
                        this.cerrarDialogo();
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        },

        agregarIncidencia() {
            let existe = false;
            this.items.forEach(element => {
                if (element.id_vehiculo == this.incidente.id_vehiculo && element.id_neumatico == this.incidente.id_neumatico) {
                    existe = true;
                }
            });
            //Si el neumatico no esta en el array incidentes registrar
            if (!existe) {
                if (this.incidente.id_vehiculo != null && this.incidente.id_neumatico != null) {
                    this.items.push({ ...this.incidente });
                } else {
                    if (this.incidente.id_vehiculo == null) {
                        notif.open({ type: 'warning', message: 'La placa del vehículo es requerida' });
                    } else {
                        notif.open({ type: 'warning', message: 'La serie de neumático es requerida' });
                    }
                }
            } else {
                notif.open({ type: 'warning', message: 'El neumático seleccionado ya a sido agregado' });
            }
            this.incidente.serie_neumatico = null;
            this.incidente.id_neumatico = null;
        },

        eliminarItem(item) {
            const pos = this.items.indexOf(item);
            this.items.splice(pos, 1);
        },

        async subirImagen1() {
            try {
                let file = document.getElementById('img_firma1').files[0];
                this.item.image_one = await this.subirImagenes(file);
            } catch (error) {
                console.log(error);
            }
        },

        async subirImagen2() {
            try {
                let file = document.getElementById('img_firma1').files[0];
                this.item.image_two = await this.subirImagenes(file);
            } catch (error) {
                console.log(error);
            }
        },

        cerrarDialogo() {
            this.$emit("cerrarModalModeloMedidas");
        }
    },
    async mounted() {
        this.vehiculos = await this.cargarVehiculosMinified();
        this.nombreusuario = localStorage.getItem("name");
    }
};
</script>