<template>
  <div>
    <div class="letter">
      <div v-if="item.estado < 6">
        <div align="center">
          <strong><b>{{ item.num_serie }}</b></strong>
        </div>
        <div>Estado: {{ title }}</div>
        <div>{{ subtitle }}</div>
        <!-- <div v-if="item.placa != ''">
          Vehículo: <b>{{ item.placa }} / {{ item.codigo }}</b>
        </div> -->
        <div align="center">Marca: <b>{{ item.marca }}</b></div>
        <div>Modelo: <b> {{ item.modelo }} </b></div>
        <div>Medida: <b> {{ item.medida }} </b></div>
        <div v-if="item.nuevo == '1'">Condición: <b>Nuevo</b></div>
        <div v-else>Condición: <b>{{ item.nuevo_reenc }}</b></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    item: Object,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.letter {
  font-size: 11px;
}
</style>