<template>
    <div>
        <Nuevo :dialog="dialog" :showGroups="showGroups" @cerrarModal="cerrarModal" :Item="itemSelected"
            :empresaList="empresaList" :marcaNeumaticoList="marcaNeumaticoList"
            :modeloNeumaticoList="modeloNeumaticoList" :medidaNeumaticoList="medidaNeumaticoList"
            :disenioNeumaticoList="disenioNeumaticoList" v-if="cargaCompleta && verNuevo" />
        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
        <v-card>
            <v-toolbar color="secondary" dark>
                <span class="text-h6"> {{ Titulo }} : {{ ItemTotal }}</span>
                <v-spacer></v-spacer>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                            <a target="_blank" href="https://helpdesk.tiresoft.pe/#act_mast_neumaticos"
                                style="text-decoration:none !important; color: white;">
                                <v-icon dark>mdi-help-circle</v-icon>
                            </a>
                        </v-btn>
                    </template>
                    <span>¿Necesitas ayuda?</span>
                </v-tooltip>
            </v-toolbar>

            <v-card-title>
                <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                    Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-btn v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['2'])" color="primary" dark class="mb-2" @click="nuevoItem()">
                    Nuevo
                </v-btn>
            </v-card-title>
            <v-data-table dense :headers="Cabecera" :items="Items" :items-per-page="10" :search="search"
                :loading="loading" loading-text="Cargando... Por favor, espere" class="elevation-1"
                no-data-text="No hay datos disponibles" 
                :footer-props="{
                    'items-per-page-text': 'Resultados por página:',
                    'page-text': '{0}-{1} de {2}'
                }">
                <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip bottom v-if="global._autorizacion_usuario( Permisos, $route.meta.id,['3'])">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn style="margin-left: 2px" height="22" width="22" color="blue lighten-2" fab x-small
                                dark @click="editarItem(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn style="margin-left: 2px;margin-top: 2px" height="22" width="22" color="green" fab
                                x-small dark @click="costos(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-currency-usd</v-icon>
                            </v-btn>
                        </template>
                        <span>Costo</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn style="margin-left: 2px" height="22" width="22" color="#FFBC03" fab x-small dark
                                @click="kilometros(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-car</v-icon>
                            </v-btn>
                        </template>
                        <span>Kilometros</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn style="margin-left: 2px; margin-top: 2px" height="22" width="22" color="red" fab
                                x-small dark @click="scrap(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-download</v-icon>
                            </v-btn>
                        </template>
                        <span>Scrap</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import router from "@/router";
import Nuevo from "./nuevo.vue";
import axios from "axios";
import MensajeTransicion from "../Alerta.vue"
import {
    mapActions,
    mapState
} from "vuex";
import global from "@/global";

export default {
    //props: ["Titulo", "Cabecera", "loading", "Items", "ItemTotal", "Excel", "Crear", "marcaNeumaticoList", "modeloNeumaticoList", "medidaNeumaticoList", "disenioNeumaticoList", "empresaList"],
    props: ["Titulo", "Cabecera", "loading", "Items", "ItemTotal", "Excel", "Crear"],
    components: {
        Nuevo,
        MensajeTransicion
    },
    data() {
        return {
            search: "",
            dialog: false,
            showGroups: false,
            itemSelected: {},
            tipoCambio: null,
            empresaList: [],
            marcaNeumaticoList: [],
            modeloNeumaticoList: [],
            medidaNeumaticoList: [],
            disenioNeumaticoList: [],
            cargaCompleta: false,
            mensaje: "",
            verNuevo: false,
            verAlerta: false,
            global:global,
        };
    },
    created() {
        this.loadList().then(() => {
            this.cargaCompleta = true;
        });
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        ...mapActions("neumatico", ["asignarObjetoNeumatico"]),

        inicializarItemselected: function () {
            this.itemSelected = {
                precio: null,
                tipo_moneda: null,
                unidad_medida: null,
                nuevo: null,
                cantidad_reencauche: null,
                presion_recomendada: 0,
                id_modelo: null,
                id_marca: null,
                id_medida: null,
                id_disenio: null,
                id_empresa: null,
                remanente_actual: null,
                remanente_original: null
            }
        },

        nuevoItem: function () {
            this.inicializarItemselected();
            this.verNuevo = true;
            this.showGroups = true;
            this.dialog = true;
            this.$forceUpdate();
        },

        editarItem: function (item) {
            this.verNuevo = true;
            if (item.tipo_moneda == 1) {
                item.precio = item.precio_soles;
            }
            this.itemSelected = {
                id: item.id,
                num_serie: item.num_serie,
                precio: item.precio,
                serie_cliente: item.serie_cliente,
                tipo_moneda: item.tipo_moneda,
                unidad_medida: item.unidad_medida,
                nuevo: item.nuevo,
                cantidad_reencauche: item.cantidad_reencauche,
                presion_recomendada: item.presion_recomendada,
                id_modelo: item.id_modelo,
                id_marca: item.id_marca,
                id_medida: item.id_medida,
                id_disenio: item.id_disenio,
                id_empresa: item.id_empresa,
                k_recorridos: item.k_recorridos,
                remanente_actual: item.remanente_actual,
                remanente_original: item.remanente_original
            }
            this.itemSelected.serie_cliente = this.itemSelected.serie_cliente == "-" ? null : this.itemSelected.serie_cliente;
            this.itemSelected.presion_recomendada = String(this.itemSelected.presion_recomendada);
            this.showGroups = false;
            this.dialog = true;
        },

        costos: function (item) {
            this.asignarObjetoNeumatico(item);
            router.push("/neumaticoreportecosto/" + item.id);
        },

        kilometros: function (item) {
            this.asignarObjetoNeumatico(item);
            router.push("/neumaticoreportekilometro/" + item.id);
        },

        scrap: function (item) {
            this.asignarObjetoNeumatico(item);
            router.push("/neumaticoreportescrap/" + item.id);
        },

        loadList: async function () {
            try {
                const url = "neumaticos/list";
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                };
                await axios
                    .post(url, data)
                    .then((response) => {
                        response.data.success.marcas.forEach((element, i) => {
                            this.marcaNeumaticoList[i] = {
                                value: element.id,
                                text: element.descripcion,
                            };
                        });
                        response.data.success.modelos.forEach((element, i) => {
                            this.modeloNeumaticoList[i] = {
                                value: element.id,
                                text: element.descripcion,
                                marca: element.marca
                            };
                        });
                        response.data.success.medidas.forEach((element, i) => {
                            this.medidaNeumaticoList[i] = {
                                value: element.id,
                                text: element.descripcion,
                            };
                        });
                        response.data.success.disenios.forEach((element, i) => {
                            this.disenioNeumaticoList[i] = {
                                value: element.id,
                                text: element.nombre
                            };
                        });
                        response.data.success.empresas.forEach((element, i) => {
                            this.empresaList[i] = {
                                value: element.id,
                                text: element.razon_social,
                                ruc: element.ruc
                            };
                        });
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        cerrarModal: function () {
            this.dialog = false;
            this.verNuevo = false;
        }
    }
};
</script>
