<template>
<div class="pa-5">
    <v-col lg="12">
        <v-card>
            <v-card-title>
                Filtros
                <v-spacer></v-spacer>
                <v-autocomplete :items="ArrayIndicador" v-model="Filtro.indicador" @change="ActualizarTabla" class="mt-4" dense label="Seleccionar Indicador"></v-autocomplete>
                <v-spacer></v-spacer>
                <v-autocomplete :items="ArrayAños" v-model="Filtro.anio" @change="ActualizarTabla" class="mt-4" dense></v-autocomplete>
            </v-card-title>
        </v-card>
        <tabla Titulo="Criterios de aceptación" :Cabecera="Cabecera" :Items="Items" :Excel="false" :Crear="true" :Filtro="Filtro" :Loading="Loading"/>
    </v-col>
</div>
</template>

<script>
import tabla from "@/components/criterioaceptacion/tabla.vue";

import {
    mapState,
    mapGetters,
    mapActions
} from "vuex";
export default {
    name: "Criterio_Aceptacion",
    components: {
        tabla,
    },
    async created() {
        this.cargarAnios();
        await this.cargarCriterioAceptacion();
        this.Filtro = {
            indicador: this.Indicador,
            anio: this.Año
        };
    },
    data() {
        return {
            Cabecera: [
                {
                    text: "Acciones",
                    value: "acciones"
                },
                {
                    text: "Id",
                    value: "id"
                },
                {
                    text: "Modelo",
                    value: "modelo"
                },
                {
                    text: "Medida",
                    value: "medida"
                },
                {
                    text: "Criterio Superior",
                    value: "criterio_superior",
                    class: "green lighten-1 dark white--text",
                },
                {
                    text: "Criterio Tolerable Maximo",
                    value: "rango_c_superior",
                    class: "amber lighten-1  white--text",
                },
                {
                    text: "Criterio Tolerable Minimo",
                    value: "rango_c_inferior",
                    class: "amber lighten-1  white--text",
                },
                {
                    text: "Criterio Inferior",
                    value: "criterio_inferior",
                    class: "red lighten-1  white--text",
                },
                {
                    text: "Criterio de Aceptacion",
                    value: "criterioaceptacion"
                },
            ],
            ArrayIndicador: [
                "Costo por kilómetro",
                "Costo por hora",
                "Índice de reencauche",
                "Índice de reencauchabilidad",
            ],
            ArrayAños: [],
            Filtro: {},
        };
    },
    methods: {
        ...mapActions("criterioaceptacion", ["cargarCriterioAceptacion","CambioSelect",]),

        ActualizarTabla() {
            this.CambioSelect(this.Filtro).then((r) => {
                this.cargarCriterioAceptacion();
            });
        },
        cargarAnios() {
            const fecha = new Date();
            let anio_inicio = 2017;
            let anio_final = fecha.getFullYear() + 2;

            for (let i = anio_inicio; i <= anio_final; i++) {
                this.ArrayAños.push(i);
            }
            this.$forceUpdate();
        },
    },
    computed: {
        ...mapGetters("criterioaceptacion", ["Items", "Año", "Indicador"]),
        ...mapState("criterioaceptacion", ["Loading"]),
        //...mapState("reporteconsolidado", ["Years"]),
    },
};
</script>
