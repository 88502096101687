<template>
<v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
        <v-card-title>
            <span v-if="Item.id == null" class="text-h5">Nueva Planta</span>
            <span v-else class="text-h5">Editar Planta</span>
            <v-spacer></v-spacer>
            <span v-if="Item.id != null">ID: {{Item.id}}</span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-form ref="form" v-model="valid">
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field label="Nombre de la planta *" hide-details="auto" v-model="Item.nombre" :rules="[(v) => !!v || 'El nombre es requerido']"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field label="Dirección *" hide-details="auto" v-model="Item.direccion" :rules="[(v) => !!v || 'La dirección es requerida']"></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <small>*indica los campos requeridos</small>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="cerrarDialogo(false)">
                Cerrar
            </v-btn>
            <v-btn color="info" @click="agregar()" :loading="loading" :disabled="!valid">
                Guardar
            </v-btn>
        </v-card-actions>
        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
    </v-card>
</v-dialog>
</template>

<script>
import {
    mapActions,
    mapState
} from 'vuex';
import {axios2 as axios} from "@/interceptor/useApi";
import MensajeTransicion from "../Alerta.vue"
import global from '@/global';
import {
    notif
} from "@/plugins/notyf"

export default {
    props: ['dialog', 'Item'],
    components: {
        MensajeTransicion
    },
    data() {
        return {
            valid: false,
            mensaje: "",
            verAlerta: false,
            loading: false
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        ...mapActions('plantas', ["cargarPlantas"]), 

        async agregar() {
            try {
                const url = "plantas/store";
                this.loading = true;
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                    planta: this.Item
                };

                if (this.Item.id == null || this.Item.id == '') {
                    this.registrarPlanta(url, data);
                } else { 
                        this.registrarPlanta(url, data); 
                }
            } catch (error) {
                console.log(error);
            }
        },

        registrarPlanta: async function (url, data) {
            await axios
                .post(url, data)
                .then((response) => {
                    this.cargarPlantas();
                    this.cerrarDialogo();
                })
                .catch((error) => {
                    this.errorMessage = error.response.data;
                    console.error("error!", error);
                });
        },

        cerrarDialogo(valor = true) {
            this.$refs.form.reset();
            this.loading = false;
            if(valor){
                notif.success('Operación realizada con éxito');
            }
            this.$emit('dialog', false);
        }
    }
}
</script>
