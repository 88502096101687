import Vue from "vue";
import Vuex from "vuex";
import { axios2 as axios } from "@/interceptor/useApi";
import Swal from 'sweetalert2';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    IReencauche: {},
    IReencauchabilidad: {},
    MarcasNeumatico: [],
    MesesCostoPorKilometro: [],
    CostoPorKilometro: [],
    IndicesReencauchePorMes: [],
    IndicesReencauchabilidadPorMes: [],
  },
  getters: {},
  mutations: {
    LlenarIReencauche(state, payload) {
      state.IReencauche = payload;
    },
    LlenarIReencauchabilidad(state, payload) {
      state.IReencauchabilidad = payload;
    },
    LlenarMarcasNeumatico(state, payload) {
      state.MarcasNeumatico = payload;
    },
    LlenarCostoPorKilometro(state, payload) {
      state.CostoPorKilometro = payload;
    },
    LlenarMesesCostoPorKilometro(state, payload) {
      state.MesesCostoPorKilometro = payload;
    },
    LlenarIndicesReencauchePorMes(state, payload) {
      state.IndicesReencauchePorMes = payload;
    },
    LlenarIndicesReencauchabilidadPorMes(state, payload) {
      state.IndicesReencauchabilidadPorMes = payload;
    },
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },

    cargarMarcasVehiculo: async function ({ commit, dispatch }) {
      try {
        const url = "neumaticomarca/index";
        let data = { id_cliente: localStorage.getItem("idcliente") };
        await axios
          .post(url, data)
          .then((response) => {
            let marcas = [];
            response.data.success.resultado.forEach((element, i) => {
              marcas[i] = { value: element.id, text: element.descripcion };
            });
            commit("LlenarMarcasNeumatico", marcas);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    reporteCostoPorKilometro: async function ({ commit, dispatch }, item) {
      try {
        const url = "indicador/costo_por_kilometro";
        let data = {
          id_cliente: localStorage.getItem("idcliente"),
          marca: item.marca,
          year: item.anio,
          unidadmedida: item.unidadmedida,
        };
        await axios
          .post(url, data)
          .then((response) => {
            commit("LlenarCostoPorKilometro", response.data.success.costos);
            commit("LlenarMesesCostoPorKilometro", response.data.success.meses);          
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    reporteIndiceReencauche: async function ({ commit, dispatch }, year) {
      try {
        const url = "indicador/indice_reencauche";
        let data = {
          id_cliente: localStorage.getItem("idcliente"),
          year: year,
        };
        await axios
          .post(url, data)
          .then((response) => {
            commit("LlenarIReencauche", response.data.success.criterio);
            commit(
              "LlenarIndicesReencauchePorMes",
              response.data.success.indicesmensuales
            );
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    reporteIndiceReencauchabilidad: async function ({ commit, dispatch }, year) {
      try {
        const url = "indicador/indice_reencauchabilidad";
        let data = {
          id_cliente: localStorage.getItem("idcliente"),
          year: year,
        };
        await axios
          .post(url, data)
          .then((response) => {
            commit("LlenarIReencauchabilidad", response.data.success.criterio);
            commit(
              "LlenarIndicesReencauchabilidadPorMes",
              response.data.success.indicesmensuales
            );
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },
  },
};
