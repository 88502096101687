import Vue from 'vue'
import Vuex from 'vuex'
import {axios2 as axios} from "@/interceptor/useApi";
import Swal from 'sweetalert2';

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    Items: [],
    Marcas: [],
    CantidadMarcas: [],
    Medidas: [],
    CantidadMedidas: [],
    CondicionNeumaticos: [],
    CantidadxCondicionNeumaticos: [],
    Estados: [],
    CantidadEstados: [],
    ModelosyDisenios: [],
    CantidadModelosyDisenios: [],
    BackgroundModelosyDisenios: [],
    BorderModelosyDisenios: [],
    Anios: [],
    CantidadNeumaticosScrap: [],
  },
  getters: {
    Items(state) {
      return state.Items
    }
  },
  mutations: {
    LlenarItems(state, payload) {
      state.Items = payload
    },
    LlenarMarcas(state, payload) {
      state.Marcas = payload
    },
    LlenarCantidadMarcas(state, payload) {
      state.CantidadMarcas = payload
    },
    LlenarCondicionNeumaticos(state, payload){
      state.CondicionNeumaticos = payload
    },
    LlenarCantidadxCondicionNeumaticos(state, payload){
      state.CantidadxCondicionNeumaticos = payload
    },
    LlenarEstados(state, payload) {
      state.Estados = payload
    },
    LlenarCantidadEstados(state, payload) {
      state.CantidadEstados = payload
    },
    LlenarModelosyDisenios(state, payload){
      state.ModelosyDisenios = payload
    },
    LlenarCantidadModelosyDisenios(state, payload) {
      state.CantidadModelosyDisenios = payload
    },
    LlenarBackgroundModelosyDisenios(state, payload){
      state.BackgroundModelosyDisenios = payload
    },
    LlenarBorderModelosyDisenios(state, payload){
      state.BorderModelosyDisenios = payload
    },
    LlenarMedidas(state, payload){
      state.Medidas = payload
    },
    LlenarCantidadMedidas(state, payload){
      state.CantidadMedidas = payload
    },
    LlenarMedidas(state, payload){
      state.Medidas = payload
    },
    LlenarCantidadMedidas(state, payload){
      state.CantidadMedidas = payload
    },
    LlenarAnios(state, payload){
      state.Anios = payload
    },
    LlenarCantidadNeumaticosScrap(state, payload){
      state.CantidadNeumaticosScrap = payload
    }
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },

    datosGraficos: async function ({ commit, dispatch }) {
      const data = { id_cliente: localStorage.getItem("idcliente") };
      try {
        const url = "home/reportesvistaprincipal"
        await axios.post(url, data)
          .then(response => {
            let etiquetasmarcas = [];
            let datosmarcas = [];
            let cantidadotros = 0;
            //MARCAS
            response.data.success.marcas.forEach((element, index) => {
              if(index < 5){
                etiquetasmarcas.push(element.descripcion);
                datosmarcas.push(element.cantidad);
              }else{
                cantidadotros += element.cantidad;
              } 
            });

            if(cantidadotros > 0){
              etiquetasmarcas.push("OTROS");
              datosmarcas.push(cantidadotros);
            }
            //CONDICION DE NEUMATICOS
            let condicion = [];
            let cantidadxcondicion = [];
            condicion.push("Nuevo");
            condicion.push("Reencauchado");
            cantidadxcondicion.push(response.data.success.cantidadnuevos);
            cantidadxcondicion.push(response.data.success.cantidadreencauchados);

            //ESTADO DE NEUMATICOS
            let etiquetasestados = [];
            let datosestados = [];
            response.data.success.estados.forEach((element, index) => {
                etiquetasestados.push(element.descripcion);
                datosestados.push(element.cantidad);
            });

            //MODELOS Y DISENIOS
            let lmodelosydisenios = [];
            let datosmodelosydisenio = [];
            let background = [];
            let border = [];
            response.data.success.modelos.forEach((element, index) => {
              background.push("rgba(0, 188, 212)");
              border.push("rgba(0, 188, 212)");
              lmodelosydisenios.push(element.descripcion);
              datosmodelosydisenio.push(element.cantidad);
            });

            response.data.success.disenios.forEach((element, index) => {
              background.push("rgba(255, 99, 132)");
              border.push("rgba(255,99,132)");
              lmodelosydisenios.push(element.descripcion);
              datosmodelosydisenio.push(element.cantidad);
            });

            //MEDIDAS
            let etiquetasmedida = [];
            let datosmedida = [];
            response.data.success.medidas.forEach((element, index) => {
              etiquetasmedida.push(element.descripcion);
              datosmedida.push(element.cantidad);
            });

            //SCRAP
            let etiquetasscrap = [];
            let datosscrap = [];
            response.data.success.neumaticosscrap.forEach((element, index) => {
              etiquetasscrap.push(element.anio);
              datosscrap.push(element.cantidad);
            });

            //PROBAR GRAFICO DE DOS DATASET
            

            commit('LlenarMarcas', etiquetasmarcas);
            commit('LlenarCantidadMarcas', datosmarcas);
            commit('LlenarEstados', etiquetasestados);
            commit('LlenarCantidadEstados', datosestados);
            commit('LlenarModelosyDisenios', lmodelosydisenios);
            commit('LlenarCantidadModelosyDisenios', datosmodelosydisenio);
            commit('LlenarBackgroundModelosyDisenios', background);
            commit('LlenarBorderModelosyDisenios', border);
            commit('LlenarCondicionNeumaticos', condicion);
            commit('LlenarCantidadxCondicionNeumaticos', cantidadxcondicion);
            commit('LlenarMedidas', etiquetasmedida);
            commit('LlenarCantidadMedidas', datosmedida);
            commit('LlenarAnios', etiquetasscrap);
            commit('LlenarCantidadNeumaticosScrap', datosscrap);
            
          })
          .catch(error => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarPlugins: async function ({ commit, dispatch }) {
      try {
        const data = { idcliente: localStorage.getItem('idclient') };
        const url = "plugin"
        await axios.post(url, data)
          .then(response => {
            commit('LlenarItems', response.data);
          })
          .catch(error => {
            this.errorMessage = error.response.data;
            console.error("There was an error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },
    Instalar: async function ({ commit, dispatch }, datos) {
      try {
        const url = "storeplugin"
        await axios.post(url, datos)
          .then(response => {
            dispatch('cargarPlugins');
          })
          .catch(error => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },
    Desinstalar: async function ({ commit, dispatch }, datos) {
      try {
        const url = "storedesplugin"
        await axios.post(url, datos)
          .then(response => {
            dispatch('cargarPlugins');
          })
          .catch(error => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    }
  }

}


