<template>
    <div class="pa-5">
        <v-col lg="12">
            <v-card>
                
                <v-toolbar color="secondary" dark>
                    <span class="text-h6"> Reporte de neumáticos reencauchados</span>
                    <v-spacer></v-spacer>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                                <a target="_blank" href="https://helpdesk.tiresoft.pe/#marketing-neumaticos-reencauchados"
                                    style="text-decoration:none !important; color: white;">
                                    <v-icon dark>mdi-help-circle</v-icon>
                                </a>
                            </v-btn>
                        </template>
                        <span>¿Necesitas ayuda?</span>
                    </v-tooltip>
                </v-toolbar>

                <FiltrosReporteMarketing titulo="" :mostrarempresa="false"
                    :mostrarrtd="false" :mostrarmedida="false" :mostrarsegmentacion="false"
                    @filtrarNeumaticos="filtrarNeumaticos" ref="filtrosmarketing"> </FiltrosReporteMarketing>

            </v-card>

            <v-card class="mt-5" v-if="mostrarCard">
                <v-card-text>
                    <v-row class="mt-5">
                        <v-col cols="12" sm="12" md="6">
                            <TablaReporteMarketing :Encabezados="encabezados" :Items="neumaticoscondicion"
                                :TotalGeneral="totalgeneral"></TablaReporteMarketing>
                        </v-col>
                        <v-col cols="12" sm="12" md="1">
                        </v-col>
                        <v-col cols="12" sm="12" md="5">
                            <TablaReporteMarketing :Encabezados="encabezados2" :Items="neumaticoscondicion2"
                                :TotalGeneral="totalgeneral"></TablaReporteMarketing>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-col cols="6" sm="12" md="6">
                        <v-btn color="#32B1E9" :loading="loadingexcel" @click="exportarExcel()" small block>Exportar</v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-col>
    </div>
</template>

<script>
import TablaReporteMarketing from '@/components/TablaReporteMarketing.vue'
import FiltrosReporteMarketing from "@/components/FiltrosReporteMarketing.vue"
import {
    notif
} from "@/plugins/notyf"
import {
    mapActions,
    mapState
} from 'vuex'
import global from '@/global'

export default {
    components: {
        TablaReporteMarketing,
        FiltrosReporteMarketing
    },
    data() {
        return {
            loadingexcel: false,
            encabezados: [{
                text: "Etiquetas de fila",
                value: "name",
                align: "left"
            },
            {
                text: "Cantidad",
                value: "cantidad",
                align: "center"
            },
            {
                text: "Porcentaje",
                value: "porcentaje",
                align: "right"
            },
            ],
            encabezados2: [{
                text: "Etiquetas de fila",
                value: "condicion",
                align: "left"
            },
            {
                text: "Cantidad",
                value: "cantidad",
                align: "center"
            },
            {
                text: "Porcentaje",
                value: "porcentaje",
                align: "right"
            },
            ],
            neumaticoscondicion: [],
            neumaticoscondicion2: [],
            totalgeneral: 0,
            item: null,
            mostrarCard:false
        }
    },
    computed: {
        ...mapState("marketing", ["CondicionNeumaticos", "CondicionNeumaticosResumen"])
    },
    methods: {
        ...mapActions("marketing", ["cargarNeumaticosPorCondicion"]),

        exportarExcel() {
            this.loadingexcel = true;
            global._exportar_a_excel("excel/mkgneumaticosreencauchados", "POST", this.item);
            this.loadingexcel = false;
        },

        async cargarDatos(filtros) {
            this.item = filtros;
            this.$refs.filtrosmarketing.turnOnLoader();
            await this.cargarNeumaticosPorCondicion(filtros);
            this.neumaticoscondicion = [];
            let datos = global._arrayValoresUnicos(this.CondicionNeumaticos, 'empresa');
            let datos2 = global._arrayValoresUnicos(this.CondicionNeumaticosResumen, 'condicion');
            this.totalgeneral = datos.total;
            this.neumaticoscondicion = datos.array;
            this.neumaticoscondicion2 = datos2.array;
            this.$refs.filtrosmarketing.turnOffLoader();
            this.mostrarCard = true;
        },

        async filtrarNeumaticos(filtros) {
            if (filtros.fechainicio != '') {
                if (filtros.fechafin != '') {
                    if (filtros.fechainicio > filtros.fechafin) {
                        notif.error("La fecha final debe ser mayor a la fecha inicial");
                        this.mostrarCard = false;
                    } else {
                        this.cargarDatos(filtros);
                    }
                } else {
                    this.cargarDatos(filtros);
                }
            } else {
                this.cargarDatos(filtros);
            }
        }
    },

}
</script>
