<template>
    <div>
        <v-card>
            <v-card-title>
                {{ Titulo }}
                <v-spacer></v-spacer>
                <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                    Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table dense :headers="Cabecera" :items="Items" :items-per-page="10" :search="search"
                class="elevation-1" loading-text="Cargando... Por favor, espere" :loading="Loading" no-data-text="No hay datos disponibles"
                :footer-props="{
                    'items-per-page-text': 'Resultados por página:',
                    'page-text': '{0}-{1} de {2}'
                }">
                <template v-slot:[`item.accion`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="blue lighten-2" x-small dark @click="verMiembros(item)" v-bind="attrs"
                                v-on="on">
                                Ver miembros
                            </v-btn>
                        </template>
                        <span>Ver miembros</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import {
    mapState
} from 'vuex';
import router from "@/router";

export default {
    props: ["Titulo", "Cabecera", "Items", "Excel", "Crear","Loading"],
    data() {
        return {
            search: ""
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        verMiembros(item) {
            router.push("/miembroscliente/" + item.id);
        }
    }
};
</script>
