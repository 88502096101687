<template>
<div>
    <v-dialog v-model="dialogretiro" persistent max-width="400px">
        <v-card>
            <v-card-title>
                <span class="text-h5">DATOS DE RETIRO</span>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
                <v-form enctype="multipart/form-data" ref="form">
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field type="date" v-model="Item.fecha_retiro" label="Fecha de retiro *" hide-details="auto" required></v-text-field>
                            <p class="tamanio_texto" v-if="Item.fecha_instalacion > Item.fecha_retiro">
                                <font color="red">La fecha debe ser mayor o igual a la última fecha instalación del neumático ({{Item.fecha_instalacion}})</font>
                            </p>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field type="number" v-model="$v.Item.km_retiro.$model" label="Kilometraje del vehículo *" hide-details="auto" required></v-text-field>
                            <div class="tamanio_texto" v-if="Item.km_instalacion > Item.km_retiro">
                                <font color="red">El kilometraje debe ser mayor o igual al kilometraje de instalación del neumático ({{Item.km_instalacion}})</font>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-autocomplete :items="ItemsMotivoRetiro" v-model="$v.Item.motivo_retiro.$model" label="Seleccione Motivo de Retiro *" item-text="text" item-value="id" dense class="mt-5"></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-form>
                <small>*indica los campos requeridos</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cerrarDialogo()"> Cerrar </v-btn>
                <v-btn :disabled="$v.$invalid" color="info" @click="agregar()"> Guardar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import vuelidate from "../../plugins/vuelidate"
import {
    required
} from 'vuelidate/lib/validators'

export default {
    props: ["dialogretiro", "Item"],
    data() {
        return {
            ItemsMotivoRetiro: [{
                    id: 1,
                    text: "POR REENCAUCHE"
                },
                {
                    id: 2,
                    text: "POR REPARACIÓN"
                },
                {
                    id: 3,
                    text: "POR ROTACIÓN"
                },
                {
                    id: 4,
                    text: "POR DESGASTE FINAL"
                },
                {
                    id: 5,
                    text: "POR FALLA"
                },
                {
                    id: 6,
                    text: "POR ERROR DE INSTALACIÓN"
                }
            ]
        }
    },
    validations: {
        Item: {
            motivo_retiro: {
                required
            },
            km_retiro: {
                required
            }
        },
    },
    methods: {
        agregar() {
            this.$emit("desinstalarneumatico", {
                ...this.Item
            })
            this.$emit('dialogretiro', false);
            /*
                this.$alertify.confirm("Desea Guardar Item", 
                    () => {
                        this.$emit("desinstalarneumatico", this.Item)
                        this.cerrarDialogo();
                        this.$alertify.success("Agregado");
                        }, 
                    () => this.$alertify.error("Se cancelo la acción")
                ).setHeader('<em> Aviso </em> ');
            */
        },
        cerrarDialogo() {
            this.cargarAplicacion;
            this.$emit('dialogretiro', false);
        },
    },
};
</script>

<style scoped>
.tamanio_texto {
    font-size: 12px;
}
</style>
