<template>
    <div class="pa-5">
        <v-col lg="12">
        <v-card outlined>
            <v-card-title>
                Reporte de Recorridos
            </v-card-title>
            <v-card-text class="mb-10">
                <v-row justify="center">
                    <v-col cols="12" sm="12" md="5">
                        <font>
                            Serie de neumático: </font><strong>{{ Item.num_serie }}</strong>
                    </v-col>

                    <v-col cols="12" sm="12" md="5" v-if="Item.unidad_medida == 'K'">
                        <font>
                            Kilometraje total: </font><strong>{{ Item.k_recorridos }}</strong>
                    </v-col>

                    <v-col cols="12" sm="12" md="5" v-if="Item.unidad_medida == 'H'">
                        <font> Horas total: </font><strong>{{ Item.k_recorridos }}</strong>
                    </v-col>

                    <v-col cols="12" sm="12" md="2">
                        <v-btn class="rounded-pill" color="error" @click="cancelar()"> Cancelar </v-btn>
                    </v-col>
                </v-row>

                <tabla Titulo="" :Cabecera="Cabecera" :loading="Loader" :Items="Items" :Excel="false" :Crear="false" />
            </v-card-text>
        </v-card>
    </v-col>
    </div>
</template>

<script>
import {
    mapState
} from "vuex";
import router from "@/router";
import tabla from "@/components/neumaticos/tablacosto.vue";
import {axios2 as axios} from "@/interceptor/useApi";

export default {
    name: "NeumaticoReporteCosto",
    components: {
        tabla,
    },
    data() {
        return {
            costo: {},
            Loader: false,
            Cabecera: [{
                    text: "Id",
                    value: "id",
                    align: "left",
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Fecha Instalación",
                    value: "fecha_instalacion",
                    width: 150,
                    align: "left",
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Fecha Retiro",
                    value: "fecha_retiro",
                    width: 150,
                    align: "left",
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Km de instalación",
                    value: "km_instalacion",
                    width: 150,
                    align: "left",
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Km de retiro",
                    value: "km_retiro",
                    width: 150,
                    align: "left",
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Vehículo",
                    value: "placa",
                    width: 150,
                    align: "left",
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Rendimiento",
                    value: "km_rendimiento",
                    width: 150,
                    align: "left",
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Condición",
                    value: "nom_condicion",
                    width: 150,
                    align: "left",
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: "Motivo de retiro",
                    value: "nom_motivo_retiro",
                    width: 150,
                    align: "left",
                    class: "grey darken-1 dark white--text"
                },
            ],
            Items: [],
            Kilometers: 0,
        };
    },
    computed: {
        ...mapState("neumatico", ["Item", "TipoCambio"]),
    },
    methods: {
        cargarCostos: async function () {
            try {
                const url = "neumaticos/kilometers";
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                    id_neumatico: location.pathname.substr(1).split("/")[1],
                };
                await axios
                    .post(url, data)
                    .then((response) => {
                        this.Items = response.data.success.datos;
                        this.Kilometers = response.data.success.kilometraje;
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        cancelar: function () {
            router.push("/neumaticos");
        },
    },
    created() {
        this.cargarCostos();
    },
};
</script>
