<template>
<v-dialog v-model="dialog" max-width="600px" @click:outside="cerrarDialogo(false)">
    <v-card>
        <v-card-title>
            <span v-if="Item.id == 0" class="text-h5">Nuevo Tipo de Cambio</span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-form ref="form" v-model="valid">
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field type="number" label="Valor *" hide-details="auto" v-model="Item.valor" :rules="tipocambio_rule"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field label="Fecha *" hide-details="auto" type="date" v-model="Item.fecha" value="19/07/2022" required></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <small>*indica los campos requeridos</small>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="cerrarDialogo(false)"> Cerrar </v-btn>
            <v-btn color="info" @click="agregar()" :disabled="!valid"> Guardar </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import {
    mapActions
} from "vuex";
import {
    notif
} from "@/plugins/notyf"

export default {
    props: ["dialog", "Item"],
    methods: {
        ...mapActions("tipocambio", ["agregarTipoCambio", "cargarTipoCambio"]),

        agregar() {
            this.agregarTipoCambio(this.Item);
            this.cerrarDialogo();
        },

        cerrarDialogo(valor = true) {
            if(valor){
                this.cargarTipoCambio();
                notif.success('Operación realizada con éxito');
            }
            this.$emit("closeDialog");
        },
    },
    data() {
        return {
            valid: false,
            tipocambio_rule: [(v) => !!v || 'Valor es requerido', (v) => v.length < 7 || 'Cantidad de carcateres máximos es 5']
        };
    },
};
</script>
