<template>
    <div>
        <v-row justify="center">
            <v-dialog
            v-model="dialog"
            max-width="650px"
            @click:outside="cerrarDialogo(false)"
            >
            <v-card>
                <v-card-title>
                <span v-if="Item.id == null" class="text-h5">Nueva Suscripción</span>
                <span v-else class="text-h5">Editar Suscripción</span>
                <v-spacer></v-spacer>
                <span v-if="Item.id != null" >ID: {{Item.id}}</span>
                </v-card-title>
                <v-card-text>
                <v-container>
                    <v-row>
                    <v-col cols="12" sm="12" md="12" class="mt-2">
                        <v-autocomplete class="mt-2" :items="clientes" v-model="Item.id_cliente" :disabled="Item.id != null" label="Cliente *" dense :rules="[(v) => !!v || 'Cliente es requerido']"></v-autocomplete>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                        <v-text-field
                        type="date"
                        hide-details="auto"
                        label="Fecha inicio *"
                        v-model="Item.date_start"
                        :disabled="Item.id != null"
                        required
                        ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                        <v-text-field
                        type="date"
                        hide-details="auto"
                        label="Fecha Final *"
                        v-model="Item.date_end"
                        required
                        ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                    >
                        <v-autocomplete 
                        class="mt-5" 
                        :items="periodosPrueba" 
                        v-model="Item.trial_period" 
                        v-if="Item.id != null" 
                        label="Cliente *" 
                        dense 
                        :rules="[(v) => !!v || 'Cliente es requerido']"
                        >
                        </v-autocomplete>
                    </v-col>
                    </v-row>
                </v-container>
                <small>*indica los campos requeridos</small>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="error"
                    @click="cerrarDialogo()"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    color="info"
                    :loading="loading"
                    @click="agregar()"
                >
                    Guardar
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {axios2 as axios} from "@/interceptor/useApi";
import { mounted } from 'vue2-dropzone';

export default {
    props:['dialog','Item'],
    data() {
        return {
            loading: false,
            clientes: [],
            periodosPrueba: [
                {value: 'ACTIVO', text: 'ACTIVO'},
                {value: 'VENCIDO', text: 'VENCIDO'},
                {value: 'NO APLICA', text: 'NO APLICA'}
            ],
        };
    },
    computed: {
        ...mapState("cliente", ["ItemsClienteSelect"]),
    },
    methods:{
        ...mapActions("cliente", ["cargarCliente"]),

        async agregar(){
            this.loading = true;
            const  url = "clientesuscripcion/store"
            const data = { suscripcion: this.Item, id_usuario: localStorage.getItem('id')};  
            
            await axios.post(url,data)
            .then(response => { 
                this.$alertify.success(this.Item.id  == null ? "Agregado" : "Actualizado"); 
                this.cerrarDialogo();    
            })
            .catch(error => {
                this.loading = false;
                this.errorMessage = error.response.data.error;
                this.$alertify.error(this.errorMessage); 
                console.error("error!", error);
            }); 
        },

        cerrarDialogo(){
            this.loading = false;
            //this.cargarRoles();  
            this.$emit('cerrarModal',false);
        }
    },
    async mounted(){
       await this.cargarCliente();
       this.ItemsClienteSelect.forEach(element => {
        this.clientes.push({ value: element.id, text: element.name});
       });

       /*if(this.Item.id != null){
        var moment = require("moment");
        let fecha1 = moment(this.Item.date_start, "YYYY-MM-DD");
        let fecha2 = moment(this.Item.date_end, "YYYY-MM-DD");
        this.Item.date_start = fecha1.format("YYYY-MM-DD");
        this.Item.date_end= fecha2.format("YYYY-MM-DD");
       }*/
    }
}
</script>














































































