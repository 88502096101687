<template>
  <div class="container">
    <canvas
      ref="elemento"
      :id="IdGrafico"
      style="max-height: 300px"
    ></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);

export default {
  props: ["Labels", "DatosGrafico", "Title", "Labelsdataset", "Labelscale_x", "Labelscale_y", "IdGrafico", "Tamanio"],
  data() {
    return {
      tamanioletra: 0,
    };
  },
  methods: {
    generarGrafico: function () {
      var popCanvas = document.getElementById(this.IdGrafico);
      new Chart(popCanvas, {
        plugins: [ChartDataLabels],
        type: "bar",
        data: {
          labels: this.Labels,
          datasets: [
            {
              label: this.Labelsdataset,
              data: this.DatosGrafico,
              backgroundColor: ["rgb(66, 133, 244)"],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          indexAxis: "y",
          plugins: {
            title: {
              display: true,
              text: this.Title,
            },
            legend: {
              display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const value = context.parsed.x;
                        return `Horas acumuladas: ${this.secondsToHHmmss(value)}`;
                    },
                },
            },
            datalabels: {
              /* anchor puede ser "start", "center" o "end" */
              anchor: "center",
              /* Podemos modificar el texto a mostrar */
              //formatter: (dato) => dato + "%",
              formatter: (dato) => dato,
              /* Color del texto */
              color: "black",
              /* Formato de la fuente */
              font: {
                family: '"Times New Roman", Times, serif',
                size: this.tamanioletra,
                weight: "bold",
              }
            },
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: this.Labelscale_x,
              },
              suggestedMin: 0, // Asegura que el gráfico comience desde 0 en el eje X
              beginAtZero: true,
              ticks: {
                callback: (value) => this.secondsToHHmmss(value),
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: this.Labelscale_y,
              },
              suggestedMin: 0
            },
          },
        },
      });
    },

    secondsToHHmmss(totalSeconds) {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    },
  },
  mounted() {
    if(this.Tamanio != undefined && this.Tamanio != null){
      this.tamanioletra = 9
    }
    this.generarGrafico();
  },
};
</script>