<template>
  <div class="container">
    <canvas
      ref="elemento"
      id="lineCurvaDesgasteNeumatico"
      style="max-height: 400px"
    ></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);

export default {
  props: ["Labels", "DatosGrafico", "title", "Labelscale_x", "Labelscale_y"],
  data() {
    return {};
  },
  methods: {
    generarGrafico: function () {
      var popCanvas = document.getElementById("lineCurvaDesgasteNeumatico");
      new Chart(popCanvas, {
        plugins: [ChartDataLabels],
        type: "line",
        data: {
            labels: this.Labels,
            datasets: this.DatosGrafico
        },
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: this.title,
            },
            datalabels: {
              font: {
                size: "0",
              },
            },
            /*
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                        }
                        return label;
                    }
                }
            }
            */
          },
          interaction: {
            mode: "index",
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              suggestedMin: 0, // Asegura que el gráfico comience desde 0 en el eje X
              title: {
                display: true,
                text: this.Labelscale_x,
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: this.Labelscale_y,
              },
              suggestedMin: 0,
              suggestedMax: 10,
            },
          }
        },
      });
    },
  },
  mounted() {
    this.generarGrafico();
  },
};
</script>
