<template>
<div class="pa-5">
    <v-col cols="12" sm="12" md="12">
        <v-card>
            <v-card-title>Descargar excel de Inspecciones en general</v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-row class="pl-4 pt-4">
                        <v-col cols="12" sm="4" md="4">
                            <v-select class="mt-5" v-model="condicion" :items="array_condicion" @change="filtrarNeumaticosPorCondicion()" label="Condición de neumático" dense></v-select>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-text-field type="date" v-model="fechainicio" label="Fecha Inicio" hide-details="auto" :rules="[(v) => !!v || 'La fecha de inicio es requerido']"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-text-field type="date" v-model="fechafin" label="Fecha Final" hide-details="auto" :rules="[(v) => !!v || 'La fecha de fin es requerido']"></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-col lg="3">
                    <v-btn :loading="loader" @click="exportarAexcel()" color="#32B1E9" small block>Exportar</v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-col>
    <!--
    <v-col cols="12" sm="12" md="12">
        <v-card>
            <v-card-title>Reporte de km de neumáticos</v-card-title>
            <v-card-text>
                <v-col cols="12" sm="6" md="6">
                    <STable :Encabezados="encabezados_recorrido" :Items="recorridoskm" :TotalGeneral="totalgeneral_km"></STable>
                </v-col>
            </v-card-text>
            <v-card-actions>
                <v-col lg="6">
                    <v-btn color="#32B1E9" small block>Exportar</v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-col>
-->
</div>
</template>

<script>
import {
    mapActions,
    mapState
} from 'vuex'
import STable from '@/components/TablaReporteMarketing.vue'
import axios from "axios";
import global from '@/global';

export default {
    components: {
        STable
    },
    data() {
        return {
            neumaticoscondicion: [],
            neumaticosdisenio: [],
            neumaticosmedida: [],
            recorridoskm: [],
            condicion: 0,
            fechainicio: '',
            fechafin: '',
            array_condicion: [{
                value: 0,
                text: "Reencauchado"
            }],
            encabezados_condicion: [{
                    text: "Etiquetas de fila",
                    value: "name",
                    align: "left"
                },
                {
                    text: "",
                    value: "contador",
                    align: "center"
                },
                {
                    text: "Cantidad",
                    value: "cantidad",
                    align: "right"
                },
            ],
            encabezados_disenio: [{
                    text: "Etiquetas de fila",
                    value: "name",
                    align: "left"
                },
                {
                    text: "",
                    value: "contador",
                    align: "center"
                },
                {
                    text: "Cantidad",
                    value: "cantidad",
                    align: "right"
                },
            ],
            encabezados_medida: [{
                    text: "Etiquetas de fila",
                    value: "name",
                    align: "left"
                },
                {
                    text: "",
                    value: "contador",
                    align: "center"
                },
                {
                    text: "Cantidad",
                    value: "cantidad",
                    align: "right"
                },
            ],
            encabezados_recorrido: [{
                    text: "Etiquetas de fila",
                    value: "name",
                    align: "left"
                },
                {
                    text: "",
                    value: "contador",
                    align: "center"
                },
                {
                    text: "Cantidad",
                    value: "cantidad",
                    align: "right"
                }
            ],
            totalgeneral_condicion: 0,
            totalgeneral_disenio: 0,
            totalgeneral_medida: 0,
            totalgeneral_km: 0,
            loader: false,
            valid: false
        }
    },
    computed: {
        ...mapState("marketing", ["CondicionNeumaticos", "DiseniosNeumaticos", "MedidasNeumaticos", "KmRecorridos"])
    },
    methods: {
        ...mapActions("marketing", ["cargarNeumaticosPorCondicion"]),

        filtrarNeumaticosPorCondicion: function () {
            this.neumaticoscondicion = [];
            let datos = this.arrayValoresUnicos(this.CondicionNeumaticos, 'empresa');
            this.totalgeneral_condicion = datos.total;
            this.neumaticoscondicion = datos.array;
        },

        arrayValoresUnicos: function (array_general, descripcion) {
            let cantidad = 10;
            let total = 0;
            let array_temporal = [];

            array_general.forEach((element, i) => {
                if (i == 0) {
                    element.name = element[descripcion];
                    array_temporal.push({
                        ...element
                    });
                } else {
                    //buscar si el elemento xiste, si existe sumar de lo contrario ingresar al array
                    let dato = array_temporal.find((el) => el[descripcion] == element[descripcion]);
                    if (dato) {
                        dato.cantidad += element.cantidad;
                    } else {
                        element.name = element[descripcion];
                        array_temporal.push({
                            ...element
                        });
                    }
                }
            })
            //ordenar de mayor a menor cantidad
            array_temporal.sort((a, b) => b.cantidad - a.cantidad);

            let array = [];
            for (let index = 0; index < cantidad; index++) {
                if (array_temporal[index]) {
                    array.push(array_temporal[index]);
                    total += parseInt(array_temporal[index]['cantidad']);
                }
            }

            let datos = {
                total: total,
                array: array
            }

            return datos;
        },

        promedioValoresUnicos: function (array_general, descripcion) {
            let cantidad = 10;
            let total = 0;
            let array_temporal = [];

            //console.log(array_general);

            array_general.forEach((element, i) => {
                if (element.contador == undefined) {
                    element.contador = 0;
                }

                if (element.cantidad == undefined) {
                    element.cantidad = 0;
                }

                if (i == 0) {
                    element.name = element[descripcion];
                    element.contador = 1;
                    element.km_recorrido = parseFloat(element.km_recorrido);
                    array_temporal.push({
                        ...element
                    });
                } else {
                    //buscar si el elemento xiste, si existe sumar de lo contrario ingresar al array
                    let dato = array_temporal.find((el) => el[descripcion] == element[descripcion]);
                    if (dato) {
                        dato.km_recorrido += parseFloat(element.km_recorrido);
                        dato.contador += 1;
                    } else {
                        element.name = element[descripcion];
                        element.km_recorrido = parseFloat(element.km_recorrido);
                        element.contador += 1;
                        array_temporal.push({
                            ...element
                        });
                    }
                }
            })

            //ordenar de mayor a menor cantidad
            array_temporal.sort((a, b) => b.km_recorrido - a.km_recorrido);
            let array = [];
            let sumar = 0;
            let contar = 0;
            for (let index = 0; index < cantidad; index++) {
                if (array_temporal[index]) {
                    array_temporal[index]['cantidad'] = (array_temporal[index]['km_recorrido'] / array_temporal[index]['contador']).toFixed(2);
                    //array_temporal[index]['cantidad'] = (array_temporal[index]['cantidad']).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2 });
                    array.push(array_temporal[index]);
                    sumar += parseFloat(array_temporal[index]['km_recorrido']);
                    contar += parseFloat(array_temporal[index]['contador']);
                }
            }

            total += sumar / contar;

            let datos = {
                total: total.toFixed(2),
                array: array
            }

            return datos;
        },

        loadNeumaticosPorDisenio: function () {
            this.neumaticosdisenio = [];
            let datos = this.arrayValoresUnicos(this.DiseniosNeumaticos, "disenio");
            this.totalgeneral_disenio = datos.total;
            this.neumaticosdisenio = datos.array;
            /*
            let cantidad = 0;
            this.DiseniosNeumaticos.forEach(element => {
                if (cantidad < 10) {
                    element.name = element.disenio;
                    this.neumaticosdisenio.push({
                        ...element
                    });
                    this.totalgeneral_disenio += parseInt(element.cantidad);
                    cantidad++;
                }
            });
            */
        },

        loadNeumaticosPorMedida: function () {
            this.neumaticosmedida = [];
            let datos = this.arrayValoresUnicos(this.MedidasNeumaticos, "medida");
            this.totalgeneral_medida = datos.total;
            this.neumaticosmedida = datos.array;
        },

        loadKmRecorridos: function () {
            this.recorridoskm = [];
            let datos = this.promedioValoresUnicos(this.KmRecorridos, "disenio");
            this.totalgeneral_km = datos.total;
            this.recorridoskm = datos.array;
        },
        async exportarAexcel() {
            try {
                if (this.$refs.form.validate()) {
                    if (this.fechafin >= this.fechainicio) {
                        this.loader = true;
                        let datos = { fechainicio: this.fechainicio, fechafin: this.fechafin, condicion: this.condicion};
                        await global._exportar_a_excel("excel/reporteetlinspecciones", "POST", datos);
                        this.loader = false;
                    }else{
                        console.log("La fecha final es menor a la inicial");
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },

    },
    async mounted() {
        await this.cargarNeumaticosPorCondicion();
        this.filtrarNeumaticosPorCondicion(1);
        this.loadNeumaticosPorDisenio();
        this.loadNeumaticosPorMedida();
        this.loadKmRecorridos();
    }
}
</script>
