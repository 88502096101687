<template>
<div>
    <v-simple-table dense>
        <template v-slot:default>            
            <thead>
                <tr>
                    <th style="background-color: skyblue;" :class="'text-' + item.align" v-for="(item,index) in Encabezados" :key="index">
                        <strong><font color="black">{{ item.text }}</font></strong>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in Items" :key="index">
                    <td>{{ item.name }}</td>
                    <td class="text-right">{{ item.cantidad }}</td>
                    <td v-if="item.porcentaje" class="text-right">{{ item.porcentaje }}%</td>
                    <td v-else class="text-right">{{ redondearPromedio(item.promedio) }}</td>
                </tr>
                <!--
                <tr>
                    <td><strong>TOTAL GENERAL</strong></td>
                    <td class="text-right"><strong>{{ TotalGeneral }}</strong></td>
                    <td class="text-right"><strong> 100%</strong></td>
                </tr>
                -->
            </tbody>
        </template>
    </v-simple-table>
</div>
</template>

<script>
export default {
    props: ["Items", "Encabezados", "TotalGeneral"],
    data() {
        return {
        }
    },
    methods:{
        redondearPromedio(promedio){
            return Number(promedio).toFixed(2);
        }
    }
}
</script>
