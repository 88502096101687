<template>
    <div>
        <v-card>
            <v-card-title>
                <h5>{{Titulo}}</h5>
                <v-divider
                    class="mx-4"
                    small
                    inset
                    vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="Excel"
                    color = "primary"
                    dark
                    small
                    class="mb-2"
                    @click="DescargarExcel()"
                >
                Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field
                    v-if="Buscar"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busqueda"
                    single-line
                    hide-details
                ></v-text-field>            
                <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
            dense
            :headers="Cabecera"
            :items="Items"
            :items-per-page="5"
            :search="search"
            class="elevation-1"
            loading-text="Cargando... Por favor, espere" 
            no-data-text="No hay datos disponibles"
            item-key="task_id"
            :footer-props="{
              'items-per-page-text': 'Resultados por página:',
              'page-text': '{0}-{1} de {2}' 
            }"
            >
            <template v-slot:[`item.accion`]="{ item }">                    
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                height="22"
                                width="22"
                                color="blue lighten-2"
                                fab
                                x-small
                                dark
                                @click="editarItem(item)"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Editar</span> 
                    </v-tooltip>                    
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import exportFromJSON from "export-from-json"
export default {
    props: ["Titulo", "Cabecera", "Items", "Excel", "Buscar"],
    data() {
        return {
            search: "",
        };
    },
    methods: {
        ...mapActions("cliente", ["actualizarItemContacto"]),
        DescargarExcel(){
            const data = this.Items;
            const nombreArchivo = 'download';
            const exportType = exportFromJSON.types.xls; //json, css, html,txt 
            exportFromJSON({data, nombreArchivo, exportType})
        },
        editarItem(item) {
            this.actualizarItemContacto(item) ;
        }
    },
    computed:{
        ...mapGetters('cliente',['ItemContacto'])  
    },
};
</script>
