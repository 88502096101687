<template>
    <div>
        <v-alert :icon="icon" text :type="type" :title="title" outlined elevation="1">
           <span class="letra-11"> {{mensaje}}</span>
        </v-alert>
    </div>
</template>

<script>

export default ({
    props:['type','title','mensaje', 'icon'],
    
    methods:{
        salir(){
            this.$emit('salir',false)
        }
    }
})
</script>
<style scoped>
.letra-11 {
    font-size: 11pt;
}
</style>
