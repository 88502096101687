<template>
<v-dialog v-model="dialognuevo" persistent max-width="400px">
    <v-card>
        <v-card-title>
            <span class="text-h5">DATOS DE INSTALACIÓN</span>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
            <v-container>
                <!-- <FormularioDinamico :campos="campos"/> -->
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field type="date" v-model="$v.Item.fecha_instalacion.$model" label="Fecha de instalación *" hide-details="auto" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field type="number" v-model="$v.Item.km_instalacion.$model" label="Kilometraje del vehículo *" hide-details="auto" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-textarea label="Observación *" v-model="Item.observacion" hide-details="auto" rows="3" required></v-textarea>
                    </v-col>
                </v-row>
            </v-container>
            <small>*indica los campos requeridos</small>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="cerrarDialogo()"> Cerrar </v-btn>
            <v-btn color="info" @click="agregar()" :disabled="$v.$invalid"> Guardar </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import {
    required
} from 'vuelidate/lib/validators'

export default {
    props: ["dialognuevo", "Item"],
    validations: {
        Item: {
            fecha_instalacion: {
                required
            },
            km_instalacion: {
                required
            }
        },
    },
    methods: {
        agregar() {
            this.$emit("instalarneumatico", {
                ...this.Item
            })
            this.$emit('dialognuevo', false);
            /*
                  this.$alertify.confirm("Desea Guardar Item", 
                      () => {
                          this.$emit("instalarneumatico", this.Item)
                          this.cerrarDialogo();
                          this.$alertify.success("Agregado");
                          }, 
                      () => this.$alertify.error("Se cancelo la acción")
                  ).setHeader('<em> Aviso </em> ');
            */
        },
        cerrarDialogo() {
            this.cargarAplicacion;
            this.$emit('dialognuevo', false);
        },
    },
    data() {
        return {};
    },
};
</script>
