<template>
<div class="table-responsive" style="max-height: 150px">
    <table class="table table-striped">
        <thead>
            <tr>
                <td class="pt-1 pb-1 th" style="width: 150px">
                    <strong>N°</strong>
                </td>
                <td class="pt-1 pb-1 th" style="width: 350px">
                    <strong>N° Serie</strong>
                </td>
                <td class="pt-1 pb-1 th" style="width: 400px">
                    <strong>S. Cliente</strong>
                </td>
                <td class="pt-1 pb-1 th" style="width: 100px">
                    <strong>Opc.</strong>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in neumaticoGroup" :key="index">
                <td class="pt-1 pb-1 th">
                    {{ index + 1 }}
                </td>
                <td class="pt-1 pb-1 th">
                    {{ item.num_serie }}
                </td>
                <td class="pt-1 pb-1 th">
                    {{ item.serie_cliente }}
                </td>
                <td class="pt-1 pb-1 th">
                    <v-btn style="margin-left: 2px" height="15" width="15" fab x-small color="red" dark @click="deleteItem(index)">
                        -
                    </v-btn>
                </td>
            </tr>
        </tbody>
    </table>
</div>
</template>

<script>
export default {
    props: {
        neumaticoGroup: Array,
    },
    data() {
        return {};
    },
    methods: {
        deleteItem: function (index) {
            this.neumaticoGroup.splice(index, 1);
        },
    },
};
</script>

<style scoped>
.td {
    font-size: 11px !important;
}

.th {
    font-size: 11px !important;
}
</style>
