import Vue from "vue";
import Vuex from "vuex";
import { axios2 as axios } from "@/interceptor/useApi";
import Swal from 'sweetalert2';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    Items: [],
    Loading: true
  },
  getters: {
    Items(state) {
      return state.Items;
    },
  },
  mutations: {
    LlenarItems(state, data) {
      state.Items = data;
    },
    LlenarLoading(state, data) { 
      state.Loading = data;
    }
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },

    cargarRoles: async function ({ commit, dispatch,state }) {
      try {
        const url = "roles";
        await axios
          .get(url)
          .then((response) => {
            commit("LlenarItems", response.data.success.resultado);
            commit("LlenarLoading", false);          
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },
    eliminarRoles: async function ({ commit, dispatch }, datos) {
      const url = "roles/delete";
      const data = { id: datos.id };
      await axios
        .post(url, data)
        .then((response) => {
          dispatch("cargarRoles");
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },
    asignarPermisos: async function ({commit,dispatch},datos) {
      const url="roles/asignarpermiso";
      const data = { name: datos.name,name_permiso:datos.name_permiso };
      await axios
        .post(url, data)
        .then((response) => { 
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    }
  },
};
