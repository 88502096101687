<template>
<div class="pa-5 mt-5">
    <v-col lg="12">
        <tabla Titulo="Roles" :Cabecera="Cabecera" :Items="Items" :Excel="false" :Crear="true" :Loading="Loading"/>
    </v-col>
</div>
</template>
<script>
import tabla from "@/components/roles/tabla.vue"

import {
    mapGetters,
    mapActions,
    mapState
} from "vuex";
export default {
    name: "Roles",
    components: {
        tabla
    },

    created() {
        const data = {
            menu: 1,
            submenu: 0
        };
        this.CargarMenu(data);
        this.cargarRoles();
    },
    data() {
        return {
            Cabecera: [{
                    text: 'Código',
                    value: 'id'
                },
                {
                    text: 'Rol',
                    value: 'name'
                },
                {
                    text: 'Descripción',
                    value: 'description'
                },
                {
                    text: 'Acción',
                    value: 'accion'
                },
            ]
        }
    },
    methods: {
        ...mapActions('roles', ['cargarRoles']),
        ...mapActions('nav', ['CargarMenu'])
    },

    computed: {
        ...mapGetters('roles', ['Items']),
        ...mapState("roles", ['Loading'])
    }

};
</script>
