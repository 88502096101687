var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_c('span',{staticClass:"text-h6"},[_vm._v("Neumáticos")])]),_c('v-card-title',[_c('v-alert',{staticStyle:{"width":"100%","margin-bottom":"0px"},attrs:{"border":"top","colored-border":"","type":"info","elevation":"2"}},[_vm._v(" Seleccione un neumático y haga clic en una columna vacía de la tabla de comparación. ")])],1),_c('v-card-title',{staticStyle:{"margin-top":"0px","padding-top":"0px","padding-bottom":"20px"}},[_c('div',{staticClass:"col-lg-2 col-sm-2 col-md-2 col-xs-12"}),_c('div',{staticClass:"col-lg-1 col-sm-1 col-md-1 col-xs-12"}),_c('div',{staticClass:"col-lg-6 col-sm-6 col-md-6 col-xs-12"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busqueda","single-line":"","hide-details":""},on:{"change":_vm.onBusqueda},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-data-iterator',{staticClass:"mx-2",attrs:{"items":_vm.Items,"items-per-page":6,"footer-props":{
        'items-per-page-options': [6, 12, 18],
        'items-per-page-text': 'Resultados por página:',
        'page-text': '{0}-{1} de {2}' 
      },"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","search":_vm.search},scopedSlots:_vm._u([{key:"default",fn:function({ items, isExpanded, expand }){return [_c('v-row',{attrs:{"justify":"center"}},_vm._l((items),function(item,index){return _c('v-col',{key:index,staticClass:"tooltip",staticStyle:{"display":"flex","justify-content":"center"},attrs:{"cols":"12","sm":"6","md":"3","lg":"2","justify":"center"}},[_c('button',{style:({
              borderRadius: '15px',
              width: '145px',
              background: 'none',
              boxShadow: 'none',
              border: _vm.dato_seleccionado2.includes(item) ? '2px solid red' : (_vm.seleccionados2.includes(item)?'2px dotted gray':'')
            }),attrs:{"type":"button","disabled":_vm.seleccionados2.includes(item)},on:{"click":function($event){return _vm.seleccionarNeumatico(item)}}},[_c('center',[_c('v-img',{staticClass:"mb-2 imagen-neumatico2",attrs:{"src":_vm.imagen,"alt":"neumatico"}})],1),_c('p',{staticStyle:{"color":"grey","text-shadow":"none","margin-bottom":"0px"}},[_vm._v(" "+_vm._s(item.descripcion)+" ")])],1),(_vm.VerTooltip == true)?_c('Description',{staticClass:"tooltiptext",attrs:{"title":_vm.titulo(item.estado),"subtitle":_vm.subtitulo(item.estado),"item":item}}):_vm._e()],1)}),1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }