<template>
<div class="pa-5">
    <v-col lg="12">
        <v-card class="pa-5">
            <v-card-title> Análisis de inspecciones </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-row>
                        <v-col cols="12" sm="6" md="5">
                            <v-text-field type="text" label="Cliente" v-model="Cliente.razon_social" hide-details="auto" readonly required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-select :items="Years" v-model="item.anio" label="Año *" variant="underline" class="mt-5" @change="onMeses" dense></v-select>
                        </v-col>
                        <v-col cols="12" lg="2" md="2" sm="6" xs="6">
                            <v-autocomplete :items="Months" v-model="item.month1" label="Mes inicial *" class="mt-5" :rules="[(v) => !!v || 'El mes es requerido']" dense></v-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="2" md="2" sm="6" xs="6">
                            <v-autocomplete :items="Months" v-model="item.month2" label="Mes final *" class="mt-5" :rules="[(v) => !!v || 'El mes es requerido']" dense></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" small :loading="loader" @click="filtrar" :disabled="!valid">
                    Consultar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>

    <div v-if="verEstadisticos">
        <v-col cols="12">
            <v-card>
                <v-card-title>Gráfico estadístico de marcas de neumáticos</v-card-title>
                <v-card-text>
                    <GraficoBarComparativo Title="Marca de neumáticos" Labelscale_x="" Labelscale_y="Cantidad" IdGrafico="graficocomparativo_marca" :Labels="Meses" :Datasets="DatasetMarcas" v-if="mostrarGrafico == true">
                    </GraficoBarComparativo>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12">
            <v-card>
                <v-card-title>Gráfico estadístico de remanentes de neumáticos</v-card-title>
                <v-card-text>
                    <GraficoBarComparativo Title="Remanentes de neumáticos" Labelscale_x="" Labelscale_y="Cantidad" IdGrafico="graficocomparativo_disenio" :Labels="Meses" :Datasets="DatasetDisenios" v-if="mostrarGrafico2 == true" />
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12">
            <v-card>
                <v-card-title>Gráfico estadístico de tipos de vehículo inspeccionados</v-card-title>
                <v-card-text>
                    <GraficoBarComparativo Title="Tipos de vehículo" Labelscale_x="" Labelscale_y="Cantidad" IdGrafico="graficocomparativo_tiposvehiculo" :Labels="Meses" :Datasets="DatasetTiposvehiculo" v-if="mostrarGrafico3 == true" />
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12">
            <v-card>
                <v-card-title>Gráfico estadístico de Inspecciones por año</v-card-title>
                <v-card-text>
                    <GraficoBarComparativo Title="Inspecciones por año" Labelscale_x="" Labelscale_y="Cantidad" IdGrafico="graficoinspecciones" :Labels="meses_a" :Datasets="DatasetInspecciones" v-if="mostrarGrafico4 == true" />
                </v-card-text>
            </v-card>
        </v-col>
    </div>

    <!--
    <v-col cols="12">
      <v-card>
        <v-card-title>Gráfico estadístico de Inspecciones de cliente por año</v-card-title>
        <v-card-text>
          <GraficoBarComparativo 
            Title="Inspecciones de cliente por año" 
            Labelscale_x="" 
            Labelscale_y="Cantidad" 
            IdGrafico="graficoinspeccionescliente"
            :Labels="Anios"
            :Datasets="DatasetClientes"
            v-if="mostrarGrafico5 == true">
          </GraficoBarComparativo>
        </v-card-text>
      </v-card>
    </v-col>
    -->
</div>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import GraficoBarComparativo from "../components/GraficoBarComparativo.vue";

export default {
    components: {
        GraficoBarComparativo
    },
    data() {
        return {
            item: {
                anio: null,
                month1: null,
                month2: null
            },
            annio: "",
            loader: false,
            valid: false,
            DatosGrafico: [],
            Labels: [],
            verEstadisticos: false,
            mostrarGrafico: false,
            mostrarGrafico2: false,
            mostrarGrafico3: false,
            mostrarGrafico4: false,
            mostrarGrafico5: false,
            meses_a: [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre"
            ]
        };
    },
    computed: {
        ...mapState("cliente", ["Cliente"]),
        ...mapState("reporteconsolidado", ["Years", "Months"]),
        ...mapState("pruebas", ["DatasetMarcas", "DatasetInspecciones", "DatasetDisenios", "DatasetTiposvehiculo", "DatasetClientes", "Meses", "Anios"]),
    },
    methods: {
        ...mapActions("reporteconsolidado", ["cargarAnios", "cargarMeses"]),
        ...mapActions("pruebas", ["reporteMarcasInspeccionadas", "reporteDiseniosInspeccionados", "reporteInspeccionesAnuales", "reporteTiposvehiculoInspeccionados", "reporteInspeccionesAnualesxCliente"]),

        onMeses: async function () {
            this.verEstadisticos = false;
            this.item.month1 = '';
            this.item.month2 = '';
            if (this.$refs.form !== undefined) {
                this.$refs.form.resetValidation();
            }
            await this.cargarMeses(this.item.anio);
        },

        filtrar: async function () {
            if (parseInt(this.item.month1) <= parseInt(this.item.month2)) {
                this.verEstadisticos = true;
                this.loader = true;
                this.mostrarGrafico = false;
                this.mostrarGrafico2 = false;
                this.mostrarGrafico3 = false;
                this.mostrarGrafico4 = false;
                this.annio = this.item.anio;
                await this.reporteMarcasInspeccionadas(this.item);
                this.mostrarGrafico = true;

                await this.reporteDiseniosInspeccionados(this.item);
                this.mostrarGrafico2 = true;

                await this.reporteTiposvehiculoInspeccionados(this.item);
                this.mostrarGrafico3 = true;

                await this.reporteInspeccionesAnuales(this.item);
                this.mostrarGrafico4 = true;

                //await this.reporteInspeccionesAnualesxCliente();
                //this.mostrarGrafico5 = true;

                this.loader = false;
            } else {
                this.item.month2 = '';
            }
        },
    },
    async mounted() {
        await this.cargarAnios();
        var moment = require("moment");
        let fecha = moment(new Date(), "YYYY-MM-DD"); //2021-05-20 //YYYY-MM-DD
        this.annio = fecha.format("YYYY");
        this.item.anio = fecha.format("YYYY");
        this.cargarMeses(this.item.anio);
        //this.filtrar();
    },
};
</script>

<style scoped>
table {
    border-collapse: collapse;
}

.border {
    border-style: solid;
    border-width: 1px;
}

.border-bottom {
    border-bottom: solid;
    border-width: 1px;
}

.border-top {
    border-top: solid;
    border-width: 1px;
}

.border-left {
    border-left: solid;
    border-width: 1px;
}

.border-right {
    border-right: solid;
    border-width: 1px;
}

.no-border {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.no-border-left {
    border-left: none;
}

.no-border-right {
    border-right: none;
}

.texto-cen {
    text-align: center;
}

.texto-izq {
    text-align: left;
}

.texto-just {
    text-align: justify;
}

.inline {
    display: inline-block;
}

.texto-arriba {
    vertical-align: text-top;
    vertical-align: top;
}

.letra-6 {
    font-size: 6pt;
}

.letra-7 {
    font-size: 7pt;
}

.letra-8 {
    font-size: 8pt;
}

.letra-9 {
    font-size: 9pt;
}

.letra-10 {
    font-size: 10pt;
}

.letra-11 {
    font-size: 11pt;
}

.letra-12 {
    font-size: 12pt;
}

.letra-14 {
    font-size: 14pt;
}

.letra-15 {
    font-size: 15pt;
}

.bg-celda-amarilla {
    background: #faec5c;
    /*color:#EDDF04;*/
}

.bg-celda-verde {
    background: #a8fa6f;
}

.bg-celda-roja {
    background: #f55f50;
}

#table {
    display: block;
    /* important */
    width: 1000px;
    overflow-x: scroll;
}
</style>
