<template>
    <div class="pa-5">
        <v-col lg="12">
            <v-card>
                <v-toolbar color="secondary" dark>
                    <span class="text-h6"> Curva de desgaste de Vehículo</span>
                </v-toolbar>

                <v-card-subtitle>Incluye detalle, controles y costos de neumáticos</v-card-subtitle>
                <!--
          <v-toolbar height="60" color="#545554" dark>
            Curva de desgaste de Vehículo
          </v-toolbar>
        -->
                <v-card-text>
                    <v-row class="pa-2">
                        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <v-autocomplete class="mt-5" @change="onSeleccionarVehiculo" :items="Vehiculos"
                                label="Seleccione vehículo" dense v-model="VehiculoSeleccionado"></v-autocomplete>
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-if="datosVehiculosCargados">
                            <h4 class="font-bold">Marca:</h4>
                            <p class="letra-14">{{ Vehiculo.marca }}</p>
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-if="datosVehiculosCargados">
                            <h4 class="font-bold">Tipo:</h4>
                            <p class="letra-14">{{ Vehiculo.tipo }}</p>
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-if="datosVehiculosCargados">
                            <h4 class="font-bold">Aplicación:</h4>
                            <p class="letra-14">{{ Vehiculo.aplicacion }}</p>
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-if="datosVehiculosCargados">
                            <h4 class="font-bold">Tipo costo:</h4>
                            <p class="letra-14">{{ Vehiculo.descripcion_tipocosto }}</p>
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-if="datosVehiculosCargados">
                            <h4 class="font-bold">Kilometraje Inicial:</h4>
                            <p class="letra-14">{{ parseNumber(Vehiculo.km_inicial) }}</p>                           
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-if="datosVehiculosCargados">
                            <h4 class="font-bold">Kilometraje Actual:</h4>
                            <p class="letra-14">{{ parseNumber(Vehiculo.km_actual) }}</p>
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-if="datosVehiculosCargados">
                            <h4 class="font-bold">Kilometraje Recorrido:</h4>
                            <p class="letra-14">{{ parseNumber(Vehiculo.km_recorrido) }}</p>
                        </div>
                    </v-row>

                    <v-row justify="center">
                        <v-container class="pa-4" v-if="visualizarGrafico == true && datosVehiculosCargados ">
                            <GraficoLine :DatosGrafico="DatosGrafico" title="Curva de desgaste" :Labels="Labels"
                                Labelscale_x="Kilómetros" Labelscale_y="Remanente (mm)"></GraficoLine>
                        </v-container>
                    </v-row>

                    <v-row justify="center"  v-if="datosVehiculosCargados">
                        <v-col lg="12">
                            <v-container class="pa-2">
                                <p><strong>Detalle de neumáticos</strong></p>
                                <table class="letra-10" style="width: 100%" v-if="visualizarGrafico == true">
                                    <tr style="background-color: #6d6b6b">
                                        <td class="border texto-cen" style="width: 20%">
                                            <strong>
                                                <font color="white">MONTAJE</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 20%">
                                            <strong>
                                                <font color="white">MEDIDA Y DISEÑO</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 20%">
                                            <strong>
                                                <font color="white">N° SERIE</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 20%">
                                            <strong>
                                                <font color="white">PRECIO ($)</font>
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr v-for="(item, index) in NeumaticosVehiculo" :key="index">
                                        <td class="border texto-cen">POS {{ item.posicion }}</td>
                                        <td class="border texto-cen">
                                            {{ item.medida_neumatico }} {{ item.disenio_neumatico }}
                                        </td>
                                        <td class="border texto-cen">{{ item.num_serie }}</td>
                                        <td class="border texto-cen">
                                            {{ item.precio_neumatico_dolares }}
                                        </td>
                                    </tr>
                                </table>
                            </v-container>
                        </v-col>
                    </v-row>

                    <v-row justify="center"  v-if="datosVehiculosCargados">
                        <v-col lg="12">
                            <v-container class="pa-2">
                                <p><strong>Controles de neumáticos</strong></p>
                                <div class="table-responsive">
                                    <table class="letra-10" style="width: 100%" id="table" v-if="visualizarGrafico == true">
                                        <tr style="background-color: #6d6b6b">
                                            <td class="border texto-cen pa-1" style="width: 10%" rowspan="2">
                                                <strong>
                                                    <font color="white">CONTROL</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen pa-1" style="width: 10%" rowspan="2">
                                                <strong>
                                                    <font color="white">FECHA</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen pa-1" style="width: 10%" rowspan="2">
                                                <strong>
                                                    <font color="white">RECORRIDO {{ retornarUnidadMedida() }}</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen pa-1" style="width: 70%"
                                                :colspan="NeumaticosVehiculo.length">
                                                <strong>
                                                    <font color="white">P. GRABADO (mm)</font>
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr style="background-color: #6d6b6b">
                                            <td class="border texto-cen pa-1" style="width: 6%"
                                                v-for="(item, index) in NeumaticosVehiculo" :key="index">
                                                <font color="white">{{ item.num_serie }}</font>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="border texto-cen pa-1">MONTAJE</td>
                                            <td class="border texto-cen pa-1">
                                                {{ Vehiculo.fecha_instalacion }}
                                            </td>
                                            <td class="border texto-cen pa-1">0</td>
                                            <td class="border texto-cen pa-1" v-for="(item, index) in NeumaticosVehiculo"
                                                :key="index">
                                                {{ item.remanente_original_neumatico ?? "-" }}
                                            </td>
                                        </tr>
                                        <tr v-for="(item, index) in InspeccionesVehiculo" :key="index">
                                            <td class="border texto-cen pa-1">{{ index + 1 }} CONTROL</td>
                                            <td class="border texto-cen pa-1">
                                                {{ item.fecha_inspeccion }}
                                            </td>
                                            <td class="border texto-cen pa-1">
                                                {{
                                                    parseNumber(item.km_inspeccion - Vehiculo.km_inicial)
                                                }}
                                            </td>
                                            <td class="border texto-cen pa-1"
                                                v-for="(item1, index1) in item.arrayneumaticos" :key="index1">
                                                {{ imprimirRemanenteMenorDelVehiculo(item1, index1) }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </v-container>
                        </v-col>
                    </v-row>

                    <v-row justify="center"  v-if="datosVehiculosCargados">
                        <v-col lg="12">
                            <v-container class="pa-2">
                                <p><strong>Costos y rendimientos por neumáticos</strong></p>
                                <div class="table-responsive">
                                    <table class="letra-10" style="width: 100%" id="table" v-if="visualizarGrafico == true">
                                        <tr style="background-color: #6d6b6b">
                                            <td class="border texto-cen" style="width: 10%">
                                                <strong>
                                                    <font color="white">Numero de serie</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 6%"
                                                v-for="(item, index) in NeumaticosVehiculo" :key="index">
                                                <font color="white">{{ item.num_serie }}</font>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="border texto-cen pa-2">Remanente actual mm</td>
                                            <td class="border texto-cen pa-2" v-for="(item, index) in NeumaticosVehiculo"
                                                :key="index">
                                                {{ item.min_remanente }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="border texto-cen pa-2">Recorrido</td>
                                            <td class="border texto-cen pa-2" v-for="(item, index) in NeumaticosVehiculo"
                                                :key="index">
                                                {{ item.km_recorrido }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="border texto-cen pa-2">
                                                Desgaste {{ retornarUnidadMedida() }}/mm
                                            </td>
                                            <td class="border texto-cen pa-2" v-for="(item, index) in NeumaticosVehiculo"
                                                :key="index">
                                                {{ parseNumber(calcularValoresSegundaFila(item)) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="border texto-cen pa-2">
                                                Rend. Proyectado A {{ Vehiculo.remanente_reencauche }} mm
                                                ({{ retornarUnidadMedida() }})
                                            </td>
                                            <td class="border texto-cen pa-2" v-for="(item, index) in NeumaticosVehiculo"
                                                :key="index">
                                                {{ parseNumber(calcularValoresTerceraFila(item)) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="border texto-cen pa-2">
                                                Costo/{{ retornarUnidadMedida() }} SIN IGV
                                            </td>
                                            <td class="border texto-cen" v-for="(item, index) in NeumaticosVehiculo"
                                                :key="index">
                                                {{ calcularValoresCuartaFila(item) }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </div>
</template>

<script>
import GraficoLine from "../../components/GraficoLine.vue";
import {
    mapState,
    mapActions
} from "vuex";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);
import axios from "axios";
import Swal from 'sweetalert2';

export default {
    components: {
        GraficoLine,
    },
    data() {
        return {
            Labels: [],
            Vehiculos: [],
            Neumaticos: [],
            Porcentaje: "0 %",
            visualizarGrafico: false,
            DatosGrafico: [],
            VehiculoSeleccionado:null,
            datosVehiculosCargados:false
            
        };
    },
    computed: {
        ...mapState("reportes", [
            "Vehiculo",
            "RemanentesLimite",
            "NeumaticosVehiculo",
            "KilometrosRecorridos",
            "NeumaticosVehiculo",
            "InspeccionesVehiculo",
        ]),
    },
    methods: {
        ...mapActions("reportes", [
            "DatosGraficoCurvaRemanenteVehiculo",
            "VaciarObjetoVehiculo",
        ]),
        messageErrorAlert: function () {
            Swal.fire({
                title: "Upps, algo paso!",
                text: "Servidor desconectado, por favor actualice la ventana!",
                icon: "error",
                showCancelButton: false,
                confirmButtonColor: "#d33",
                confirmButtonText: "Actualizar",
                showClass: {
                    popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
                },
                hideClass: {
                    popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
                },
                backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
            }).then((result) => {
                if (result.isConfirmed) {
                    location.reload();
                }
            });
        },
        cargarVehiculos: async function (item) {
            this.VaciarObjetoVehiculo();
            const data = {
                id_cliente: localStorage.getItem("idcliente"),
            };
            try {
                const url = "vehiculos/showminified";
                await axios
                    .post(url, data)
                    .then((response) => {
                        response.data.data.forEach((element, i) => {
                            this.Vehiculos.push({
                                value: element.id,
                                text: element.placa + "/ " + element.codigo,
                            });
                        });                      
                    })
                    .catch((error) => {
                        console.error("error!", error);
                        this.errorMessage = error.response.data;
                        this.messageErrorAlert();
                    });
            } catch (error) {
                console.log(error);
                this.messageErrorAlert();
            }
        },

        onSeleccionarVehiculo: async function (id) {
            if (id!=null) {
                await this.DatosGraficoCurvaRemanenteVehiculo(id);
                this.DatosGrafico = [];
                //Grafico
                var datos = {
                    label: "Remanente Límite (mm)",
                    data: this.RemanentesLimite,
                    borderColor: "rgba(225, 11, 50, 1)",
                    backgroundColor: "rgba(225, 11, 50, 0)",
                    pointBorderColor: "rgba(225, 11, 50, 0)",
                    pointBackgroundColor: "rgba(225, 11, 50, 0)",
                    pointBorderWidth: 0.5,
                };
                this.DatosGrafico.push(datos);

                this.NeumaticosVehiculo.forEach((element) => {
                    var color = this.random_rgba();
                    var datos = {
                        label: element.num_serie,
                        data: element.array_remanente,
                        borderColor: "rgba(" + color + ",1)",
                        backgroundColor: "rgba(" + color + ",0.1)",
                        pointBorderColor: "rgba(" + color + ",1)",
                        pointBackgroundColor: "rgba(" + color + ",1)",
                        pointBorderWidth: 1,
                        cubicInterpolationMode: "monotone",
                        fill: true,  
                        spanGaps: true, // Esto conecta automáticamente los segmentos
                        // segment: {
                        //     borderColor: function(ctx) {
                        //         // ctx.p0: punto inicial del segmento
                        //         // ctx.p1: punto final del segmento
                        //         const prevValue = ctx.p0.parsed.y;  // Valor del punto anterior
                        //         const nextValue = ctx.p1.parsed.y;  // Valor del siguiente punto
                        //         return "rgba(" + color + ",1)";  // Color normal para los otros segmentos
                        //     }
                        // }
                    };
                    this.DatosGrafico.push(datos);
                });

                this.Labels = this.KilometrosRecorridos;
                this.visualizarGrafico = false;
                await this.$nextTick();
                this.visualizarGrafico = true;
                this.datosVehiculosCargados=true;
            }
        },
        //genera colores aleatorios
        random_rgba: function () {
            var o = Math.round,
                r = Math.random,
                s = 255;
            return o(r() * s) + "," + o(r() * s) + "," + o(r() * s); //',' + r().toFixed(1) ;
        },
        retornarUnidadMedida: function () {
            let unidad = "";
            if (this.Vehiculo.tipo_costo == 1) {
                unidad = "Km";
            } else {
                unidad = "Hr";
            }
            return unidad;
        },
        imprimirRemanenteMenorDelVehiculo: function (item, index) {
            let remanente = "";
            if (item == "") {
                remanente = "";
            } else {
                remanente = item.remanente_menor;
            }
            return remanente;
        },
        calcularValoresSegundaFila: function (item) {
            let remanenteoriginal = item.remanente_original_neumatico ?? 1;
            let remanenteminimo = item.min_remanente ?? 1;
            let km_recorrido = this.Vehiculo.km_recorrido ?? 0;
            let valor = (km_recorrido / (remanenteoriginal - remanenteminimo)).toFixed(1);
            if (valor=='Infinity') {
                return 'No determinado';
            }
            return valor;
        },
        calcularValoresTerceraFila: function (item) {
            let kmxmm = this.calcularValoresSegundaFila(item);
            if (kmxmm=='No determinado') {
                return 'No determinado';
            }
            let remanenteoriginal = item.remanente_original_neumatico ?? 1;
            let remanente_reencauche = this.Vehiculo.remanente_reencauche ?? 1;
            let valor = (kmxmm * (remanenteoriginal - remanente_reencauche)).toFixed(1);
            return valor;
        },
        calcularValoresCuartaFila: function (item) {
            let kmxmm = this.calcularValoresTerceraFila(item);
            if (kmxmm=='No determinado') {
                return '0';
            }
            let detalle_kmmm = (item.precio_neumatico_dolares / kmxmm).toFixed(4);
            return detalle_kmmm;
        },
        parseNumber(numberString) {
            console.log(numberString);
            // Convertir el texto a un número flotante
            let number = parseFloat(numberString);
            // Verificar si la conversión fue exitosa
            if (isNaN(number)) {
               return "-";
            }
            // Formatear el número con separadores de miles
            let formattedNumber = number.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });

            return formattedNumber;    
        }
    },
    mounted() {
        this.cargarVehiculos();
    },
};
</script>

<style scoped>
table {
    border-collapse: collapse;
}

td {
    vertical-align: top;
}

.border {
    border-style: solid;
    border-width: 1px;
}

.border-bottom {
    border-bottom: solid;
    border-width: 1px;
}

.border-top {
    border-top: solid;
    border-width: 1px;
}

.border-left {
    border-left: solid;
    border-width: 1px;
}

.border-right {
    border-right: solid;
    border-width: 1px;
}

.no-border {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.no-border-left {
    border-left: none;
}

.no-border-right {
    border-right: none;
}

.texto-cen {
    text-align: center;
}

.texto-izq {
    text-align: left;
}

.texto-just {
    text-align: justify;
}

.inline {
    display: inline-block;
}

.pa-4 {
    vertical-align: text-top;
    vertical-align: top;
}

.texto-cen {
    text-align: center;
}

.letra-10 {
    font-size: 11px;
}

.letra-14 {
    font-size: 14px;
}

#table {
    display: block;
    /* important */
    width: 100%;
    overflow-x: scroll;
}</style>
