<template>
    <div class="pa-5">
        <v-col lg="12">

            <v-card>

                <v-toolbar color="secondary" dark>
                    <span class="text-h6">Reporte de medidas de neumáticos por sector</span>
                    <v-spacer></v-spacer>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                                <a target="_blank" href="https://helpdesk.tiresoft.pe/#marketing-medidas-neumaticos-sector"
                                    style="text-decoration:none !important; color: white;">
                                    <v-icon dark>mdi-help-circle</v-icon>
                                </a>
                            </v-btn>
                        </template>
                        <span>¿Necesitas ayuda?</span>
                    </v-tooltip>
                </v-toolbar>

                <FiltrosReporteMarketing titulo="" :mostrarempresa="false" :mostrarrtd="true" :mostrarmedida="false"
                    :mostrarsegmentacion="true" @filtrarNeumaticos="filtrarNeumaticos" ref="filtrosmarketing">
                </FiltrosReporteMarketing>

            </v-card>
            <v-card class="mt-5" v-if="mostrarCard">
                <v-card-text>
                    <v-row class="mt-5">
                        <v-col cols="12" sm="5" md="5">
                            <TablaAgrupadaReporteMarketing :headers="encabezados1" :items="neumaticosmedidas"
                                grupo="sector"></TablaAgrupadaReporteMarketing>
                        </v-col>
                        <v-col cols="12" sm="1" md="1"></v-col>
                        <v-col cols="12" sm="6" md="6">
                            <TablaAgrupadaReporteMarketing :headers="encabezados2" :items="clientessector" grupo="sector">
                            </TablaAgrupadaReporteMarketing>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-col cols="12" sm="5" md="5">
                        <v-btn color="#32B1E9" :loading="loadingexcel" @click="exportarExcel()" small block>Exportar</v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-col>
    </div>
</template>

<script>
import TablaReporteMarketing from '@/components/TablaReporteMarketing.vue'
import TablaAgrupadaReporteMarketing from '@/components/TablaAgrupadaReporteMarketing.vue'
import FiltrosReporteMarketing from "@/components/FiltrosReporteMarketing.vue"
import {
    notif
} from "@/plugins/notyf"
import {
    mapActions,
    mapState
} from 'vuex'
import global from '@/global'

export default {
    components: {
        TablaReporteMarketing,
        FiltrosReporteMarketing,
        TablaAgrupadaReporteMarketing
    },
    data() {
        return {
            item: {
                reporte: "medidasxsector"
            },
            loadingexcel: false,
            encabezados1: [{
                text: 'Medidas de neumáticos',
                align: 'start',
                value: 'name',
                groupable: false,
            },
            {
                text: 'segmentación',
                value: 'sector',
                align: 'right'
            },
            {
                text: 'cantidad',
                value: 'cantidad',
                align: 'right'
            },
            ],
            encabezados2: [{
                text: 'Clientes',
                align: 'start',
                value: 'name',
                groupable: false,
            },
            {
                text: 'segmentación',
                value: 'sector',
                align: 'right'
            },
            {
                text: 'cantidad',
                value: 'cantidad',
                align: 'right'
            },
            ],
            neumaticosmedidas: [],
            clientessector: [],
            totalgeneral: 0,
            mostrarCard: false
        }
    },
    computed: {
        ...mapState("marketing", ["MedidasNeumaticos"])
    },
    methods: {
        ...mapActions("marketing", ["cargarMedidaNeumaticosxSector"]),

        exportarExcel() {
            this.loadingexcel = true;
            global._exportar_a_excel("excel/mkgmedidasneumaticos", "POST", this.item);
            this.loadingexcel = false;
        },

        async cargarDatos(filtros) {
            this.item = filtros;
            this.item.reporte = "medidasxsector";
            this.$refs.filtrosmarketing.turnOnLoader();
            filtros.reporteagrupado = true;
            await this.cargarMedidaNeumaticosxSector(filtros);
            this.neumaticosmedidas = [];
            let datos = global._arrayValoresUnicos(this.MedidasNeumaticos, 'medida', false, 'sector');
            let datos2 = global._arrayValoresUnicos(this.MedidasNeumaticos, 'cliente', false, 'sector');
            //this.totalgeneral = datos.total;
            this.neumaticosmedidas = datos.array;
            this.clientessector = datos2.array;
            this.$refs.filtrosmarketing.turnOffLoader();
            this.mostrarCard = true;
        },

        filtrarNeumaticos(filtros) {
            if (filtros.fechainicio != "" && filtros.fechafin != "") {
                if (filtros.fechainicio > filtros.fechafin) {
                    notif.error("La fecha final debe ser mayor a la fecha inicial");
                } else {
                    this.cargarDatos(filtros);
                }
            } else {
                this.cargarDatos(filtros);
            }
        }
    },

}
</script>
