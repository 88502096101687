import { mounted } from 'vue2-dropzone';
<template>
    <v-dialog v-model="dialog" max-width="800px" @click:outside="cerrarDialogo(false)">
        <v-card>
            <v-card-title>
                <span class="text-h6">{{ title }}</span>
            </v-card-title>
            <v-card-text>
                <v-data-table dense :headers="encabezado" :items="items" :items-per-page="10"
                    loading-text="Cargando... Por favor, espere" class="elevation-1 mt-5"
                    no-data-text="No hay datos disponibles" 
                    :loading="cargarDatosDiariosMensuales" 
                    :footer-props="{
                        'items-per-page-text': 'Resultados por página:',
                        'page-text': '{0}-{1} de {2}'
                    }">
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cerrarDialogo()">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: ["dialog", "title", "encabezado", "items","cargarDatosDiariosMensuales"],
    data() {
        return {
        }
    },
    methods: {
        cerrarDialogo() {
            this.$emit("cerrarModal");
        }
    },

}
</script>
