<template>
  <v-card>
    <v-toolbar height="50" color="#545554" dark>
      {{ Titulo }}
    </v-toolbar>
    <v-container class="pa-4">
      <v-row justify="center">
        <div class="table-responsive">
          <table class="table">
            <tr>
              <td></td>
              <td v-for="pos in fila_1" :key="pos">
                <v-btn width="100" height="40" class="pa-0 ml-2 button" v-if="existeposicion(pos, ItemsPosicion)"
                  @click="seleccionarposicion(pos, existeposicion(pos, ItemsPosicion))"
                  :disabled="existeposicion(pos, ItemsPosicion).disabled">
                  <Imagen ancho="100" alto="40" :clase="existeposicion(pos, ItemsPosicion).clase"
                    :num_serie="existeposicion(pos, ItemsPosicion).num_serie" v-if="existeposicion(pos, ItemsPosicion).id_neumaticos != '-'
                      ">
                  </Imagen>
                  <div v-else>
                    {{ existeposicion(pos, ItemsPosicion).num_serie }}
                  </div>

                </v-btn>
                <div v-else>&nbsp;</div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td v-for="pos in fila_1" :key="pos">
                <v-btn v-if="existeposicion(pos, ItemsPosicion)" width="100" class="pa-0 ml-2 btn-details"
                  @click="getDetailsNeumatico(pos, existeposicion(pos, ItemsPosicion))"
                  :disabled="existeposicion(pos, ItemsPosicion).disabled">
                  Detalle
                </v-btn>
              </td>
            </tr>

            <tr>
              <td v-for="pos in fila_2" :key="pos">
                <v-btn v-if="existeposicion(pos, ItemsPosicion)" width="100" height="40" class="pa-0 ml-2 button" @click="
                  seleccionarposicion(pos, existeposicion(pos, ItemsPosicion))
                  " :disabled="existeposicion(pos, ItemsPosicion).disabled">
                  <Imagen ancho="100" alto="40" :clase="existeposicion(pos, ItemsPosicion).clase"
                    :num_serie="existeposicion(pos, ItemsPosicion).num_serie"
                    v-if="existeposicion(pos, ItemsPosicion).id_neumaticos != '-'">
                  </Imagen>

                  <div v-else>
                    {{ existeposicion(pos, ItemsPosicion).num_serie }}
                  </div>
                </v-btn>

                <div v-else>&nbsp;</div>
              </td>
            </tr>
            <tr>
              <td v-for="pos in fila_2" :key="pos">

                <v-btn v-if="existeposicion(pos, ItemsPosicion)" width="100" class="pa-0 ml-2 btn-details"
                  @click="getDetailsNeumatico(pos, existeposicion(pos, ItemsPosicion))"
                  :disabled="existeposicion(pos, ItemsPosicion).disabled">
                  Detalle
                </v-btn>


              </td>
            </tr>
            <tr>
              <td v-for="pos in fila_3" :key="pos">
                <v-btn v-if="existeposicion(pos, ItemsPosicion)" width="100" height="40" class="pa-0 ml-2 button" @click="
                  seleccionarposicion(pos, existeposicion(pos, ItemsPosicion))
                  ">
                  <Imagen ancho="100" alto="40" :clase="existeposicion(pos, ItemsPosicion).clase"
                    :num_serie="existeposicion(pos, ItemsPosicion).num_serie"
                    v-if="existeposicion(pos, ItemsPosicion).id_neumaticos != '-'"
                    :disabled="existeposicion(pos, ItemsPosicion).disabled">
                  </Imagen>

                  <div v-else>
                    {{ existeposicion(pos, ItemsPosicion).num_serie }}
                  </div>
                </v-btn>
                <div v-else>&nbsp;</div>
              </td>
            </tr>
            <tr>
              <td v-for="pos in fila_3" :key="pos">
                <v-btn v-if="existeposicion(pos, ItemsPosicion)" width="100" class="pa-0 ml-2 btn-details"
                  @click="getDetailsNeumatico(pos, existeposicion(pos, ItemsPosicion))"
                  :disabled="existeposicion(pos, ItemsPosicion).disabled">
                  Detalle
                </v-btn>
              </td>
            </tr>
            <tr>
              <td></td>
              <td v-for="pos in fila_4" :key="pos">
                <v-btn width="100" height="40" class="pa-0 ml-2 button" v-if="existeposicion(pos, ItemsPosicion)"
                  @click="seleccionarposicion(pos, existeposicion(pos, ItemsPosicion))"
                  :disabled="existeposicion(pos, ItemsPosicion).disabled">
                  <Imagen ancho="100" alto="40" :clase="existeposicion(pos, ItemsPosicion).clase"
                    :num_serie="existeposicion(pos, ItemsPosicion).num_serie"
                    v-if="existeposicion(pos, ItemsPosicion).id_neumaticos != '-'">
                  </Imagen>

                  <div v-else>
                    {{ existeposicion(pos, ItemsPosicion).num_serie }}
                  </div>
                </v-btn>
                <div v-else>&nbsp;</div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td v-for="pos in fila_4" :key="pos">
                <v-btn v-if="existeposicion(pos, ItemsPosicion)" width="100" class="pa-0 ml-2 btn-details"
                  @click="getDetailsNeumatico(pos, existeposicion(pos, ItemsPosicion))"
                  :disabled="existeposicion(pos, ItemsPosicion).disabled">
                  Detalle
                </v-btn>
              </td>
            </tr>
            <br />
          </table>
        </div>
      </v-row>
    </v-container>

    <DetalleNeumatico ref="detalleNeumatico" :dialogNeumatico="dialog_neumatico" v-if="true"
      @closeNeumatico="closeNeumatico()" :arryNeumatico="arry_neumatico" :messageNeumatico="message_neumatico" />
  </v-card>
</template>

<script>
import Imagen from "../NeumaticoImageButton.vue";
import DetalleNeumatico from "@/components/inspeccion/detalleneumatico.vue";
import { axios2 as axios } from "@/interceptor/useApi";


export default {
  props: ["Titulo", "ItemsPosicion"],
  components: {
    Imagen,
    DetalleNeumatico
  },
  data() {
    return {
      dialog_neumatico: false,
      fila_1: [6, 10, 14, 18, 22],
      fila_2: [2, 4, 8, 12, 16, 20],
      fila_3: [1, 3, 7, 11, 15, 19],
      fila_4: [5, 9, 13, 17, 21],
      modalOpen: false, // Variable para controlar si el modal está abierto o cerrado
      arry_neumatico: [],
      message_neumatico: null
    };
  },
  methods: {
    existeposicion: function (pos, array) {
      let datos = array.find(
        (el) => el.ubicacion == pos && el.posicion != null
      );
      return datos;
    },

    seleccionarposicion: function (index, objeto) {
      if (objeto.disabled == false) {
        objeto.index = index;
        this.$emit("seleccionarNeumatico", { ...objeto });
      }
    },

    getDetailsNeumatico: async function (index, objeto) {

      const neumatico_id = objeto.id_neumaticos;

      try {
        const url = "inspecciones/detailsNeumatico";
        const datos = {
          id_cliente: localStorage.getItem("idcliente"),
          id: neumatico_id,
        };
        await axios
          .post(url, datos)
          .then((response) => {
            this.arry_neumatico = response.data.data[0];
            this.message_neumatico = response.data.message;
            this.dialog_neumatico = true;
            console.log('arry_neumatico > ', this.arry_neumatico);
            console.log(' this.message_neumatico > ', this.message_neumatico);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
          });
      } catch (error) {
        console.log(error);
      }

    },
    closeNeumatico: function () {
      this.dialog_neumatico = false;
    }
  },
  mounted: async function () { },
};
</script>
<style>
.btn-details {
  display: block !important;
  color: blue !important;
  background-color: transparent !important;
  font-size: xx-small !important;
  text-decoration: underline !important;
}

.btn-details.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: transparent !important;
}
</style>