import Vue from 'vue'
import Vuex from 'vuex'
import {axios2 as axios} from "@/interceptor/useApi"

Vue.use(Vuex)

export default{
  namespaced:true,
  state: {
    Items : [],
  },
  getters: {
    Items(state){
      return state.Items
    }
  },
  mutations: {
    LlenarItems(state,data){
      state.Items = data
    }
  },
  actions: {
    cargarImagenes:async function({commit}){
      try {
        const setting = {
          method : 'GET',
        }
        const  url = "https://picsum.photos/v2/list?page=2&limit=200"
        const data = await fetch(url,setting);
        const json = await data.json();
        commit('LlenarItems',json);
      } catch (error) {
        
      }
    },

    subirImagenes:async function( { commit }, file){
      try{
        const formData = new FormData();
        formData.append('file', file);
        let url = "imagen/subirimagen";
        await axios.post(url, formData, { headers: {'Content-Type': 'multipart/form-data'}})
          .then(response => {
            console.log(response);                
          });
          /*
          .catch(error => {
              this.errorMessage = error.response.data;
              console.error("error!", error);
          });
          */
      }catch(error){
        console.log(error)
      }
    }
  }
}


