<template>
<v-card>
    <v-card-title>
        {{ titulo }}
    </v-card-title>
    <v-card-text>
        <v-row class="pl-4 pt-4" ref="form_filtros">
            <v-col cols="12" sm="6" md="4">
                <v-select class="mt-5" v-model="condicion" :items="listacondicion" label="Condición de neumático" dense></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field type="date" v-model="fechainicio" label="Fecha Inicio" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field type="date" v-model="fechafin" label="Fecha Final" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="mostrarempresa">
                <v-select class="mt-5" v-model="empresareencauchadora" :items="empresasreencauchadoras" label="Empresa de reencauche" dense multiple>
                    <template #selection="{ item, index }">
                        <v-chip v-if="index < 1">
                            {{ item.text }}
                        </v-chip>
                        <span v-else-if="index === 1 && empresareencauchadora.length >= 2">
                            +{{ empresareencauchadora.length - 1 }} more
                        </span>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="mostrarrtd">
                <v-select class="mt-5" v-model="nsdminimo" :items="nsdminimos" label="RTD min" multiple dense>
                    <template #selection="{ item, index }">
                        <v-chip v-if="index < 1">
                            {{ item.text }}
                        </v-chip>
                        <span v-else-if="index === 1 && nsdminimo.length >= 2">
                            +{{ nsdminimo.length - 1 }} more
                        </span>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="mostrarmedida">
                <v-select class="mt-5" v-model="medida" :items="medidas" label="Medida" multiple dense>
                    <template #selection="{ item, index }">
                        <v-chip v-if="index < 1">
                            {{ item.text }}
                        </v-chip>
                        <span v-else-if="index === 1 && medida.length >= 2">
                            +{{ medida.length - 1 }} more
                        </span>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="mostrarsegmentacion">
                <v-select class="mt-5" v-model="sector" :items="sectores" label="Segmentación" multiple dense>
                    <template #selection="{ item, index }">
                        <v-chip v-if="index < 1">
                            {{ item.text }}
                        </v-chip>
                        <span v-else-if="index === 1 && sector.length >= 2">
                            +{{ sector.length - 1 }} more
                        </span>
                    </template>
                </v-select>
            </v-col>
        </v-row>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" small :loading="loading" @click="filtrarNeumaticosPorCondicion">
            Consultar
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import {
    mapState,
    mapActions
} from 'vuex'
export default {
    props: ["titulo", "mostrarempresa", "mostrarrtd", "mostrarmedida", "mostrarsegmentacion"],
    data() {
        return {
            condicion: 0,
            fechainicio: '',
            fechafin: '',
            medida: [],
            sector: [],
            nsdminimo: [],
            empresareencauchadora: [],
            loading: false,
            listacondicion: [{
                    value: 0,
                    text: "Reencauchado"
                },
                {
                    value: 1,
                    text: "Nuevo"
                }
            ],
            encabezados_condicion: [{
                    text: "Etiquetas de fila",
                    value: "name",
                    align: "left"
                },
                {
                    text: "",
                    value: "contador",
                    align: "center"
                },
                {
                    text: "Cantidad",
                    value: "cantidad",
                    align: "right"
                }
            ],
            empresasreencauchadoras: [],
            nsdminimos: [],
            medidas: [],
            sectores: []
        }
    },
    computed: {
        ...mapState("marketing", ["CondicionNeumaticos", "RTDMinimos", "MedidasNeumaticos", "Segmentaciones"])
    },
    methods: {
        ...mapActions("marketing", ["DatosFiltroReporteMarketing"]),

        setFiltros() {
            let filtros = {
                condicion: this.condicion,
                fechainicio: this.fechainicio,
                fechafin: this.fechafin,
                empresareencauchadora: this.empresareencauchadora,
                nsdminimo: this.nsdminimo,
                medida: this.medida,
                sector: this.sector
            }
            return filtros;
        },

        async filtrarNeumaticosPorCondicion() {
            let filtros = this.setFiltros();
            this.$emit('filtrarNeumaticos', {
                ...filtros
            });
        },

        turnOnLoader(){
            this.loading = true;
        },

        turnOffLoader(){
            this.loading = false;
        }
    },
    async mounted() {
        if (this.mostrarempresa == true || this.mostrarmedida == true || this.mostrarsegmentacion == true) {
            await this.DatosFiltroReporteMarketing({
                condicion: 0,
                fechainicio: '',
                fechafin: '',
                empresareencauchadora: []
            });
            this.CondicionNeumaticos.forEach(element => {
                this.empresasreencauchadoras.push({
                    value: element.empresa,
                    text: element.empresa
                });
            });
            this.RTDMinimos.forEach(element => {
                this.nsdminimos.push({
                    value: element,
                    text: element
                });
            });
            this.Segmentaciones.forEach(element => {
                this.sectores.push({
                    value: element.id,
                    text: element.name
                });
            });
            this.MedidasNeumaticos.forEach(element => {
                this.medidas.push({
                    value: element.medida,
                    text: element.medida
                });
            });
        }
    }
}
</script>
