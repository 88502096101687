<template>
    <div>
        <v-card-title>
            <v-btn v-if="Excel" color="secondary" dark small class="mb-2" @click="DescargarExcel()">
                Descargar Tabla
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                hide-details></v-text-field>
            <v-spacer></v-spacer>
            <v-btn v-if="Crear" color="primary" dark class="mb-2" @click="nuevoItem()">
                Nuevo
            </v-btn>
        </v-card-title>

        <v-card-text class="pa-0">
            <v-data-table dense :headers="Cabecera" :items="Items" :items-per-page="5" :search="search"
                :loading="loading" loading-text="Cargando... Por favor, espere" class="elevation-1"
                no-data-text="No hay datos disponibles"
                :footer-props="{
                    'items-per-page-text': 'Resultados por página:',
                    'page-text': '{0}-{1} de {2}'
                }">
                <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn height="22" width="22" color="#03A9F4" fab x-small dark v-if="item.estado != 0"
                                @click="openModalCosto({...item})" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card-text>
        <!--MODAL EDIT COSTO-->
        <v-row justify="center" v-if="modal_form_costo">
            <v-dialog v-model="dialogCosto" max-width="500px" @click:outside="cerrarDialogCosto">
                <v-card>
                    <v-card-text>
                        <v-container>
                            <v-form ref="form-medida" v-model="valid">
                                <v-row>
                                    <v-col cols="12" md="6" sm="6">
                                        <v-text-field label="Costo sin IGV *" required v-model="costo.costo_convertido"
                                            :rules="[(v) => !!v || 'Costo es requerido']"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-autocomplete class="mt-5" :items="monedaList" v-model="costo.tipo_moneda"
                                            label="Moneda *" :rules="[(v) => !!v || 'Moneda es requerido']"
                                            dense></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" v-if="costo.motivo == 1">
                                        <v-autocomplete :items="disenioNeumaticoList" v-model="costo.id_disenio"
                                            label="Diseño de Banda *" :rules="[(v) => !!v || 'Diseño es requerido']"
                                            dense>
                                            <template v-slot:append-outer>
                                                <v-slide-x-reverse-transition mode="out-in">
                                                    <v-icon color="success" @click="openModalDisennio()"
                                                        v-text="'mdi-plus-circle'"></v-icon>
                                                </v-slide-x-reverse-transition>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="6" v-if="costo.motivo == 1">
                                        <v-autocomplete :items="empresaList" v-model="costo.id_empresa"
                                            label="Empresa Reencauchadora *"
                                            :rules="[(v) => !!v || 'Empresa es requerido']" dense>
                                            <template v-slot:append-outer>
                                                <v-slide-x-reverse-transition mode="out-in">
                                                    <v-icon color="success" @click="openModalEmpresa()"
                                                        v-text="'mdi-plus-circle'"></v-icon>
                                                </v-slide-x-reverse-transition>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="6" sm="6" v-if="costo.motivo == 1">
                                        <v-text-field label="Remanente original *" required v-model="costo.rem_original"
                                            :rules="[(v) => !!v || 'Costo es requerido']"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" v-if="costo.motivo == 1">
                                        <v-autocomplete class="mt-5" :items="cantidadesReencauche" v-model="costo.n_reencauche" label="Observación *" disabled dense></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="12" sm="12" v-else>
                                        <v-text-field label="Observación *" required v-model="costo.observacion"
                                            :rules="[(v) => !!v || 'Observación es requerido']"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <small>*Indicador de campo requerido</small>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="!valid" style="width: 100%" small color="#009688" @click="editCosto(costo)">
                            <font color="white">Editar</font>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <!--MODAL ADD DISENIO-->
        <v-row justify="center">
            <v-dialog v-model="dialogDisenio" max-width="300px">
                <v-card>
                    <v-card-text>
                        <v-container>
                            <v-form ref="form-disenio" v-model="valid">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field label="Diseño *" required v-model="disenio.nombre"
                                            :rules="descripcionRules"></v-text-field>
                                    </v-col>
                                    <small v-if="mostrarLabelDuplicado">
                                        <font color="red">Diseño duplicado</font>
                                    </small>
                                </v-row>
                            </v-form>
                        </v-container>
                        <small v-if="mostrarLabelDuplicado == false">*Indicador de campo requerido</small>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="!valid" class="mt-4" style="width: 100%" small color="#009688"
                            @click="addDisenio(disenio)">
                            <font color="white">Agregar</font>
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>

        <!--MODAL ADD EMPRESA-->
        <v-row justify="center">
            <v-dialog v-model="dialogEmpresa" max-width="300px" v-if="dialogEmpresa == true">
                <v-card>
                    <v-card-text>
                        <v-container>
                            <v-form ref="form_empresa" v-model="valid">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-mask="numberRUC" label="RUC *" v-model="empresa.ruc"
                                            :rules="[(v) => !!v || 'RUC es requerido']"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="Razon social *" required v-model="empresa.razon_social"
                                            :rules="descripcionRules"></v-text-field>
                                    </v-col>
                                    <small v-if="mostrarLabelDuplicado">
                                        <font color="red">Ruc duplicado</font>
                                    </small>
                                </v-row>
                            </v-form>
                        </v-container>
                        <small v-if="mostrarLabelDuplicado == false">*Indicador de campo requerido</small>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="!valid" class="mt-4" style="width: 100%" small color="#009688"
                            @click="addEmpresa(empresa)">
                            <font color="white">Agregar</font>
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import {
    mapState, mapActions
} from "vuex";
import axios from "axios";
import {
    notif
} from "@/plugins/notyf"
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const numberRUC = createNumberMask({
    prefix: '',
    integerLimit: 11,
    decimalLimit: 0,
    allowDecimal: false,
    includeThousandsSeparator: false
})

export default {
    props: [
        "Titulo",
        "Cabecera",
        "loading",
        "Items",
        "Item",
        "ItemTotal",
        "Excel",
        "Crear"
    ],
    data() {
        return {
            numberRUC,
            costo: {},
            search: "",
            modal_form_costo: true,
            dialogCosto: false,
            valid: false,
            monedaList: [{
                value: 2,
                text: "$ Dólares",
            },
            {
                value: 1,
                text: "S/. Soles",
            },
            ],
            cargaCompleta: false,
            empresaList: [],
            medidaNeumaticoList: [],
            disenioNeumaticoList: [],
            cantidadesReencauche: [{
                    value: 1,
                    text: "1 Reencauche/s"
                },
                {
                    value: 2,
                    text: "2 Reencauche/s"
                },
                {
                    value: 3,
                    text: "3 Reencauche/s"
                },
                {
                    value: 4,
                    text: "4 Reencauche/s"
                },
                {
                    value: 5,
                    text: "5 Reencauche/s"
                },
                {
                    value: 6,
                    text: "6 Reencauche/s"
                },
            ],
            disenio: {},
            empresa: {},
            descripcionRules: [(v) => !!v || "Descripción es requerido"],
            dialogDisenio: false,
            dialogEmpresa: false,
            mostrarLabelDuplicado: false
        };
    },
    computed: {
        ...mapState('neumatico', ['TipoCambio']),
    },
    methods: {
        ...mapActions('enreencauche', ['actualizarNeumaticosReencauchados']),
        ...mapActions("neumatico", ["agregarItemArray"]),

        inicializarDisenio: function () {
            this.disenio = {
                nombre: null,
            };
        },

        inicializarEmpresa: function () {
            this.empresa = {
                ruc: "",
                razon_social: null
            };
        },

        openModalCosto: function (item) {
            //this.$refs.Nuevo.refrescar(item.id_marca);
            this.$nextTick().then(() => {
                this.modal_form_costo = true;
            });

            if (item.tipo_moneda == 1) {
                item.costo_convertido = item.costo_soles;
            }else{
                item.costo_convertido = item.costo;
            }

            if(item.motivo == 1){
                if(item.n_reencauche == null){
                    item.n_reencauche = 1;
                }else{
                    if(item.costo == null){
                        item.n_reencauche += 1;
                    }
                }
            }

            this.costo = item;
            this.dialogCosto = true;
        },

        cerrarDialogCosto: function (item) {
            this.modal_form_costo = false;
        },

        editCosto: function (item) {
            this.actualizarNeumaticosReencauchados(item); 
            console.log(item,'item');
            notif.success("El costo del neumatico se actualizo correctamente");
            this.$emit("cargarCostos");
            this.modal_form_costo = false; 
        },

        loadList: async function () {
            try {
                const url = "neumaticos/list";
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                };
                await axios
                    .post(url, data)
                    .then((response) => {
                        response.data.success.medidas.forEach((element, i) => {
                            this.medidaNeumaticoList[i] = {
                                value: element.id,
                                text: element.descripcion,
                            };
                        });
                        response.data.success.disenios.forEach((element, i) => {
                            this.disenioNeumaticoList[i] = {
                                value: element.id,
                                text: element.nombre
                            };
                        });
                        response.data.success.empresas.forEach((element, i) => {
                            this.empresaList[i] = {
                                value: element.id,
                                text: element.razon_social,
                                ruc: element.ruc
                            };
                        });
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        openModalDisennio: function () {
            this.mostrarLabelDuplicado = false;
            this.inicializarDisenio();
            this.dialogDisenio = true;
        },

        openModalEmpresa: function () {
            this.mostrarLabelDuplicado = false;
            this.inicializarEmpresa();
            this.dialogEmpresa = true;
        },

        verificarDuplicados: function (list, item) {
            let existe = false;
            list.forEach((element) => {
                if (element.text == item) {
                    existe = true;
                }
            });
            return existe;
        },

        addDisenio: async function (item) {
            if (
                !this.verificarDuplicados(
                    this.disenioNeumaticoList,
                    item.nombre.toUpperCase()
                )
            ) {
                //Guardar en la base de datos
                item.nombre = item.nombre.toUpperCase();
                let dato = await this.agregarItemArray({
                    url: "neumaticodisenio/store",
                    item: item,
                });
                this.disenioNeumaticoList.push({
                    value: dato.id,
                    text: dato.nombre,
                });
                this.dialogDisenio = false; //Dialog
            } else {
                this.mostrarLabelDuplicado = true;
            }
        },

        addEmpresa: async function (item) {
            let existe = false;
            this.empresaList.forEach((element) => {
                if (element.ruc == item.ruc) {
                    existe = true;
                }
            });
            //!this.verificarDuplicados(this.empresaList,item.razon_social)
            if (!existe) {
                //Guardar en la base de datos
                item.razon_social = item.razon_social.toUpperCase();
                let dato = await this.agregarItemArray({
                    url: "empresas/store",
                    item: item,
                });
                this.empresaList.push({
                    value: dato.id,
                    text: dato.razon_social,
                });
                this.dialogEmpresa = false; //Dialog
            } else {
                this.mostrarLabelDuplicado = true;
            }
        },
    },
    created() {
        this.loadList();
    }
};
</script>
