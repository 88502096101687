import Vue from 'vue'
import Vuex from 'vuex'
import {axios2 as axios} from "@/interceptor/useApi";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        DatasetMarcas: [],
        DatasetDisenios: [],
        DatasetInspecciones:[],
        DatasetTiposvehiculo: [],
        DatasetClientes: [],
        Meses: [],
        Anios: []
    },
    mutations: {
        setDatasetMarcas(state, payload) {
            state.DatasetMarcas = payload
        },
        setDatasetClientes(state, payload) {
            state.DatasetClientes = payload
        },
        setDatasetDisenios(state, payload){
            state.DatasetDisenios = payload
        },
        setDatasetTiposvehiculo(state, payload){
            state.DatasetTiposvehiculo = payload
        },
        setDatasetInspecciones(state, payload) {
            state.DatasetInspecciones = payload
        },
        setMeses(state, payload) {
            state.Meses = payload
        },
        setAnios(state, payload){
            state.Anios = payload
        }
    },
    actions: {
        reporteMarcasInspeccionadas: async function ({ commit }, item) {
            try {
                const url = "reporte/marcasinspeccionadas"
                let data = { id_cliente: localStorage.getItem("idcliente"), year: item.anio, month1: item.month1, month2: item.month2 }
                await axios.post(url, data)
                    .then(response => {
                        let arraycito = [];
                        let meses = response.data.success.meses;
                        let meses_descripcion = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]
                        
                        let background = [
                            "#FA765B",
                            "#F90F36",
                            "#FFC300",
                            "#0FF9B6",
                            "#03F1FC",
                            "#0374FC",
                            "#B6CBD1",
                            "#1EA607",
                            "#05F98E",
                            "#FC7C03",
                            "#FED47F",
                            "#0CB8EB",
                            "#FEBDF2",
                            "#F90FEE",
                            "#ACAAAC"
                        ];
                        response.data.success.marcasxmes.forEach((mes, index) => {
                            let cantidad = [];
                            let j = data.month1;
                            mes.forEach((element, i) => {
                                //cantidad.push(element.cantidad);
                                if (i == 0) { 
                                    if(element.mes > j){
                                        for (let b = j; b < element.mes; b++) {
                                            cantidad.push(0);
                                            j++;
                                        }
                                    }
                                    
                                    cantidad.push(element.cantidad);
                                    j++;
                                } else {
                                    if ((element.mes - j) == 0) {
                                        cantidad.push(element.cantidad);
                                        j++;
                                    } else {
                                        for (let a = j; a < element.mes; a++) {
                                            cantidad.push(0);
                                            j++;
                                        }
                                        cantidad.push(element.cantidad);
                                        j++;
                                    }
                                }
                            });


                            arraycito.push({
                                label: mes[0]['marca'],
                                data: cantidad,
                                backgroundColor: background[index],
                                borderColor: background[index],
                                hoverOffset: 4,
                                //stack: 'Stack 0',
                            });
                        });

                        let labels = [];
                        for (let l = parseInt(data.month1); l <= parseInt(data.month2) ; l++) {
                            if(meses.length > 0){
                                labels.push(meses_descripcion[l-1]);              
                            }        
                        }
                        commit('setDatasetMarcas', arraycito);
                        commit('setMeses', labels);
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error)
            }
        },

        reporteDiseniosInspeccionados: async function ({ commit, state }, item) {
            try {
                const url = "reporte/diseniosinspeccionados"
                let data = { id_cliente: localStorage.getItem("idcliente"), year: item.anio, month1: item.month1, month2: item.month2 }
                await axios.post(url, data)
                    .then(response => {
                        let arraycito = [];
                        let background = [
                            "#FA765B",
                            "#F90F36",
                            "#FFC300",
                            "#0FF9B6",
                            "#03F1FC",
                            "#0374FC",
                            "#B6CBD1",
                            "#1EA607",
                            "#05F98E",
                            "#FC7C03",
                            "#FED47F",
                            "#0CB8EB",
                            "#FEBDF2",
                            "#F90FEE",
                            "#ACAAAC"
                        ];

                        let meses = state.Meses;
                        response.data.success.diseniosxmes.forEach((mes, index) => {
                            let cantidad = [];
                            let j = data.month1;
                            mes.forEach((element, i) => {
                                //cantidad.push(element.cantidad);
                                if (i == 0) { 
                                    if(element.mes > j){
                                        for (let b = j; b < element.mes; b++) {
                                            cantidad.push(0);
                                            j++;
                                        }
                                    }
                                    cantidad.push(element.cantidad);
                                    j++;
                                } else {
                                    if ((element.mes - j) == 0) {
                                        cantidad.push(element.cantidad);
                                        j++;
                                    } else {
                                        for (let a = j; a < element.mes; a++) {
                                            cantidad.push(0);
                                            j++;
                                        }
                                        cantidad.push(element.cantidad);
                                        j++;
                                    }
                                }
                            });

                            arraycito.push({
                                label: mes[0]['disenio'],
                                data: cantidad,
                                backgroundColor: background[index],
                                borderColor: background[index],
                                hoverOffset: 4,
                                //stack: 'Stack 0',
                            });
                        });

                        commit('setDatasetDisenios', arraycito);
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error)
            }
        },

        reporteTiposvehiculoInspeccionados: async function ({ commit, state }, item) {
            try {
                const url = "reporte/tiposvehiculoinspeccionados"
                let data = { id_cliente: localStorage.getItem("idcliente"), year: item.anio, month1: item.month1, month2: item.month2 }
                await axios.post(url, data)
                    .then(response => {
                        let arraycito = [];
                        let background = [
                            "#B6CBD1",
                            "#0374FC",
                            "#1EA607",
                            "#05F98E",
                            "#FC7C03",
                            "#FED47F",
                            "#0CB8EB",
                            "#FEBDF2",
                            "#F90FEE",
                            "#ACAAAC",
                            "#FA765B",
                            "#F90F36",
                            "#FFC300",
                            "#0FF9B6",
                            "#03F1FC"
                        ];

                        let meses = state.Meses;
                        response.data.success.tiposvehiculoxmes.forEach((mes, index) => {
                            let cantidad = [];
                            let j = 0;
                            mes.forEach((element, i) => {
                                //cantidad.push(element.cantidad);
                                if (i == 0 && element.mes > 1) {
                                    if(meses.length == 12){
                                        for (let b = 1; b < element.mes; b++) {
                                            j++;
                                            cantidad.push(0);
                                        }
                                    }else{
                                        j = element.mes - 1;
                                    }
                                    
                                    cantidad.push(element.cantidad);
                                    j++;
                                } else {
                                    if ((element.mes - j) == 1) {
                                        cantidad.push(element.cantidad);
                                        j++;
                                    } else {
                                        for (let a = j + 1; a < element.mes; a++) {
                                            cantidad.push(0);
                                            j++;

                                        }
                                        cantidad.push(element.cantidad);
                                        j++;
                                    }
                                }
                            });


                            arraycito.push({
                                label: mes[0]['tipovehiculo'],
                                data: cantidad,
                                backgroundColor: background[index],
                                borderColor: background[index],
                                hoverOffset: 4,
                                stack: 'Stack 0',
                            });
                        });

                        commit('setDatasetTiposvehiculo', arraycito);
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error)
            }
        },

        reporteInspeccionesAnuales: async function ({ commit }, year) {
            try {
                const url = "reporte/inspeccionesanuales"
                let data = { id_cliente: localStorage.getItem("idcliente"), year: year }
                await axios.post(url, data)
                    .then(response => {
                        let arraycito = [];
                        let meses = [
                            "Enero",
                            "Febrero",
                            "Marzo",
                            "Abril",
                            "Mayo",
                            "Junio",
                            "Julio",
                            "Agosto",
                            "Septiembre",
                            "Octubre",
                            "Noviembre",
                            "Diciembre",
                        ];
                        let background = [
                            "#FC7C03",
                            "#0374FC",
                            "#03F1FC",
                            "#05F98E",
                            "#FFC300",
                            "#0FF9B6",
                            "#B6CBD1",
                            "#1EA607",
                            "#F90F36",
                            "#FED47F",
                            "#0CB8EB",
                            "#F90FEE",
                            "#ACAAAC"
                        ];

                        response.data.success.inspecciones.forEach((anio, index) => {
                            let cantidad = [];
                            let j = 0;
                            anio.forEach((element, i) => {
                                if (i == 0 && element.mes > 1) {
                                    for (let b = 1; b < element.mes; b++) {
                                        j++;
                                        cantidad.push(0);
                                    }
                                    cantidad.push(element.cantidad);
                                    j++;
                                } else {
                                    if ((element.mes - j) == 1) {
                                        cantidad.push(element.cantidad);
                                        j++;
                                    } else {
                                        for (let a = j + 1; a < element.mes; a++) {
                                            cantidad.push(0);
                                            j++;
                                        }
                                        cantidad.push(element.cantidad);
                                        j++;
                                    }
                                }
                            });


                            arraycito.push({
                                label: anio[0]['anio'],
                                data: cantidad,
                                backgroundColor: background[index],
                                borderColor: background[index],
                                hoverOffset: 4
                            });
                        });

                        let labels = [];
                        meses.forEach(element => {
                            labels.push(element.mes);
                        });

                        commit('setDatasetInspecciones', arraycito);
                        commit('setMeses', labels);
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error)
            }
        },

        reporteInspeccionesAnualesxCliente: async function ({ commit }) {
            try {
                const url = "etl/inspeccionesxcliente"
                await axios.post(url)
                    .then(response => {
                        let arraycito = [];
                        let anios = response.data.success.anios;
                        let background = [
                            "#FC7C03",
                            "#0374FC",
                            "#03F1FC",
                            "#05F98E",
                            "#FFC300",
                            "#0FF9B6",
                            "#B6CBD1",
                            "#1EA607",
                            "#F90F36",
                            "#FED47F",
                            "#0CB8EB",
                            "#F90FEE",
                            "#ACAAAC",
                            '#FFE4C4',
                            '#8A2BE2',
                            '#A52A2A',
                            '#B8860B',
                            '#A9A9A9',
                            '#FFFAF0',
                            '#228B22',
                            '#90EE90',
                            '#FFB6C1',
                            '#20B2AA',
                            '#FFA07A'
                        ];

                        response.data.success.inspecciones.forEach((anio, index) => {
                            let cantidad = [];
                            let j = anios[0]['anio']-1;
                            anio.forEach((element, i) => {
                                if (i == 0 && element.anio > anios[0]['anio']) {
                                    for (let b = anios[0]['anio']; b < element.anio; b++) {
                                        j++;
                                        cantidad.push(0);
                                    }
                                    cantidad.push(element.cantidad);
                                    j++;
                                } else {
                                    if ((element.anio - j) == 1) {
                                        cantidad.push(element.cantidad);
                                        j++;
                                    } else {
                                        for (let a = j + 1; a < element.anio; a++) {
                                            cantidad.push(0);
                                        }
                                        cantidad.push(element.cantidad);
                                        j++;
                                    }
                                }
                            });


                            arraycito.push({
                                label: anio[0]['cliente'],
                                data: cantidad,
                                backgroundColor: background[index],
                                borderColor: background[index],
                                hoverOffset: 4
                            });
                        });

                        let labels = [];
                        anios.forEach(element => {
                            labels.push(element.anio);
                        });

                        commit('setDatasetClientes', arraycito);
                        commit('setAnios', labels);
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error)
            }
        }
    }
}


