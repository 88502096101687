<template>
  <v-row justify="center" v-if="localDialog">
    <v-dialog v-model="localDialog" max-width="800px">
      <v-form enctype="multipart/form-data" ref="form">
        <v-card>
          <v-toolbar height="50" color="#6B6A6A" dark>
            <span class="text-h6">Imagen (es) de neumáticos en Scrap: {{ Item.id }}</span>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-row justify="center" class="mt-2">
              <v-col cols="12" sm="12" md="6">
                <v-row justify="center">
                  <img v-if="Item.neumaticoimgruta1 != null" width="350px" height="250px" :src="ruta + Item.neumaticoimgruta1" alt="Neumatico 1" />
                  <h4 v-else>Imagen no disponible</h4>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-row justify="center">
                  <img v-if="Item.neumaticoimgruta2 != null" width="350px" height="250px" :src="ruta + Item.neumaticoimgruta2" alt="Neumatico 2" />
                  <h4 v-else>Imagen no disponible</h4>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="cerrarDialogo">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import global from "../../global";

export default {
  props: ["dialog", "Item"],
  data() {
    return {
      localDialog: this.dialog,
      ruta: "",
    };
  },
  watch: {
    dialog(newVal) {
      this.localDialog = newVal;
    },
    localDialog(newVal) {
      this.$emit('dialog', newVal);
    },
  },
  methods: {
    cerrarDialogo() {
      this.localDialog = false;
    },
  },
  mounted() {
    this.ruta = global.ruta_image;
  },
};
</script>
